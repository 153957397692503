import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  send_rule_algorithm_approve_mail (query, data) {
    return Vue.http.post(API_BASE_URL + 'v1/dmp/algorithm/approve_mail?' + query, data);
  },
  add_algorithms_from_excel (query, data) {
    return Vue.http.post(API_BASE_URL + 'v1/dmp/algorithm/add/from_excel?' + query, data);
  },
  diagram_create_from_excel (query, data) {
    return Vue.http.post(API_BASE_URL + 'v1/diagram/create/from/excel?' + query, data);
  },
  create_diagram_branchs_as_rows (query) {
    return Vue.http.post(API_BASE_URL + 'v1/dmp/diagram/branch/list/rows?' + query);
  },
  create_diagram_from_branch_list (query, data) {
    return Vue.http.post(API_BASE_URL + 'v1/dmp/diagram/branch/create_from_branch?' + query, data);
  },
  dmp_copy (query) {
    return Vue.http.post(API_BASE_URL + 'v1/dmp/copy?' + query);
  }
};
