import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  chatbot_talk (query, data) {
    return Vue.http.post(API_BASE_URL + 'v1/chatbot/talk?' + query, data);
  },
  search_in_diagram (query, data) {
    return Vue.http.post(API_BASE_URL + 'v1/chatbot/talk/search_in_diagram?' + query, data);
  },
  chatbot_talk_diagram (query) {
    return Vue.http.post(API_BASE_URL + 'v1/chatbot/talk/diagram?' + query);
  },
  api_rule_operation (query, data) {
    return Vue.http.post(API_BASE_URL + 'v1/chatbot/talk/api_rule_operation?' + query, data);
  },
  control_and_get_dmp_by_this_token (query) {
    return Vue.http.post(API_BASE_URL + 'v1/chatbot/talk/control_and_get_dmp_by_this_token?' + query);
  }
};
