import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  save (puuid, file) {
    let url = API_BASE_URL + 'file/' + puuid + '/transfer/';
    return Vue.http.put(url, file);
  },
  get_file_with_key (data) {
    return Vue.http.post(API_BASE_URL + 'get_file_with_key', data);
  },
  save_titck_file (data) {
    return Vue.http.post(API_BASE_URL + 'file/save_titck_file', data);
  },
  save_titck_to_db (data) {
    return Vue.http.post(API_BASE_URL + 'file/save_titck_to_db', data);
  },
  convert_quick_new_user_excel (data) {
    return Vue.http.post(API_BASE_URL + 'file/convert_quick_new_user_excel', data);
  },
  convert_quick_brand_excel (data) {
    return Vue.http.post(API_BASE_URL + 'file/convert_quick_brand_excel', data);
  },
  get_titck_file (data) {
    return Vue.http.post(API_BASE_URL + 'file/get_titck_file', data);
  },
  convert_dataset_to_excel (data) {
    return Vue.http.post(API_BASE_URL + 'file/convert_dataset_to_excel', data);
  },
  convert_all_dataset_to_excel (data) {
    return Vue.http.post(API_BASE_URL + 'file/convert_all_dataset_to_excel', data);
  }
};
