<template>
  <div>
    <b-card no-body header-tag="header" :style="'margin-bottom: 2px; background-color:' + p_dmpSettings.style.backgroundColor + '; color:' + p_dmpSettings.style.color" key="upper_side">
      <!-- <b-row>
        <b-col sm="12" md="11">
          <span v-for="(menu, menu_ind) in d_menuList" :style="d_selectedMenu === menu.value ?'cursor: pointer; border-bottom: solid 3px #05b4c5; margin: 5px; background-color: #e2f4f9;' :'cursor: pointer; margin: 5px;'" @click="f_selectMenu(menu)">
            <img v-if="menu.value === 'decisionmakingpackage'" src="@/icon/1430869.png" style="width: 1.5em;" />
            <img v-if="menu.value === 'rulealgorithm'" src="@/icon/2884572.png" style="width: 1.5em;" />
            <img v-if="menu.value === 'testalgorithm'" src="@/icon/2976082.png" style="width: 1.5em;" />
            <img v-if="menu.value === 'general'" src="@/icon/2979152.png" style="width: 1.5em;" />
            <img v-if="menu.value === 'view'" src="@/icon/680848.png" style="width: 1.5em;" />
            <img v-if="menu.value === 'help'" src="@/icon/863488.png" style="width: 1.5em;" />
            <img v-if="menu.value === 'settings'" src="@/icon/3532765.png" style="width: 1.5em;" />
            {{ menu.label }}
          </span>
        </b-col>
        <b-col sm="12" md="1">
          <b-button class="pull-right" size="md" :variant="d_upperPartShowMode ? 'white' : 'warning'" :style="d_upperPartShowMode ? 'margin-right: 5px; padding: 0px;' : 'margin-right: 5px; padding: 0px;'" @click="d_upperPartShowMode ? d_upperPartShowMode = false : d_upperPartShowMode = true">
            <img src="@/icon/45774.png" title="Üst Alanı Aç Kapa" style="width: 2em;" />
          </b-button>
        </b-col>
      </b-row> -->
      <b-tabs>
        <b-tab active @click="d_selectedMenu = 'decisionmakingpackage'">
          <template slot="title">
            <img src="@/icon/1430869.png" style="width: 1.5em;">
            <span :style="'color:' + p_dmpSettings.style.tab_header.color">Karar destek paketi</span>
          </template>
          <template v-if="d_selectedMenu ==='decisionmakingpackage'">
            <b-row style="height: 75px; margin-left: 0px;;">
              <b-col cols="12">
                <div style="float: left;">
                  <img src="@/icon/2979152.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                </div>
                <div class="menuitem">
                  Genel
                  <br>
                  <div class="menusubitem">
                    <b-button variant="white" size="sm" @click="f_openDmpList()">
                      <img src="@/icon/2504347.png" style="width: 2em;" />
                    </b-button>
                  </div>
                  <div class="menusubitem">
                    <b-button variant="white" size="sm" @click="f_addNewWisdomData('wdm24')">
                      <img src="@/icon/2766751.png" style="width: 2em;" />
                    </b-button>
                  </div>
                  <div class="menusubitem">
                    <b-button :title="$t('wdm16.11144')" variant="white" size="sm" @click="f_getDmpRuleAlgorithmList()">
                      <img src="@/icon/139088.png" style="width: 2em;" />
                    </b-button>
                  </div>
                </div>
                <div class="menuitem">
                  Düzenle
                  <br>
                  <div class="menusubitem">
                    <b-button :title="$t('wdm16.11140')" variant="white" size="sm" @click="f_editSelectedWdmr('wdm24')">
                      <img src="@/icon/3094236.png" style="width: 2em;" />
                    </b-button>
                  </div>
                  <div class="menusubitem">
                    <b-button :title="$t('wdm16.11141')" variant="white" size="sm" @click="f_deleteSelectedChildWdmr('wdm24')">
                      <img src="@/icon/2960590.png" style="width: 2em;" />
                    </b-button>
                  </div>
                </div>
                <div class="menuitem">
                  Kopyala
                  <br>
                  <div class="menusubitem">
                    <b-button title="Karar destek paketini kurallarıyla birlikte kopyalar" variant="white" size="sm" @click="f_copyDmp()">
                      <img src="@/icon/3082142.png" style="width: 2em;" />
                    </b-button>
                  </div>
                </div>
                <div class="menuitem">
                  Wai
                  <br>
                  <div class="menusubitem">
                    <b-button :title="$t('wdm16.11143')" variant="white" size="sm" @click="f_publishDmp()">
                      <img src="@/icon/680848.png" style="width: 2em;" />
                    </b-button>
                  </div>
                </div>
                <div class="menuitem">
                  Api
                  <br>
                  <div class="menusubitem">
                    <b-button title="Pozitif algoritma sonuçları api üzerinden gönderilmek istendiğinde kullanılmaktadır." variant="white" size="sm" @click="f_addEditApiResultDocument()">
                      <img src="@/icon/1716471.png" style="width: 2em;" />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
        </b-tab>
        <b-tab @click="d_selectedMenu = 'rulealgorithm'">
          <template slot="title">
            <img src="@/icon/2884572.png" style="width: 1.5em;">
            <span :style="'color:' + p_dmpSettings.style.tab_header.color">Algoritma</span>
          </template>
          <template v-if="d_selectedMenu ==='rulealgorithm'">
            <b-row style="height: 75px; margin-left: 0px;">
              <b-col cols="12">
                <div style="float: left;">
                  <img src="@/icon/2884572.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                </div>
                <div class="menuitem">
                  Genel
                  <br>
                  <div class="menusubitem">
                    <b-dropdown variant="white" style="padding: 0px;">
                      <template slot="button-content">
                        <img src="@/icon/2766751.png" class="img-rounded img-responsive" style="width: 2em;" /> <span :style="'color:' + p_dmpSettings.style.color">Yeni Algoritma</span>
                      </template>
                      <b-dropdown-item @click="f_addNewWisdomData('rule')">
                        <img src="@/icon/2486994.png" class="img-rounded img-responsive" style="width: 2em;" /> Yeni Algoritma
                      </b-dropdown-item>
                      <b-dropdown-item @click="f_addAlgorithmsFromExcel()">
                        <img src="@/icon/2237824.png" class="img-rounded img-responsive" style="width: 2em;" /> Excelden Hazır Algoritmaları Ekle
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div class="menusubitem">
                    <b-button :title="$t('wdm16.11149')" variant="white" size="sm" @click="f_copyRuleAlgorithm()">
                      <img src="@/icon/2832792.png" style="width: 2em;" />
                    </b-button>
                  </div>
                  <div class="menusubitem">
                    <b-button :title="$t('wdm16.3897')" variant="white" size="sm" @click="f_editSelectedWdmr('rule')">
                      <img src="@/icon/3094236.png" style="width: 2em;" />
                    </b-button>
                  </div>
                  <div class="menusubitem">
                    <b-button :title="$t('wdm16.4398')" variant="white" size="sm" @click="f_deleteSelectedChildWdmr('rule')">
                      <img src="@/icon/2960590.png" style="width: 2em;" />
                    </b-button>
                  </div>
                  <div class="menusubitem">
                    <b-button :title="$t('wdm16.11188')" variant="white" size="sm" @click="f_openRuleAlgoritm()">
                      <img src="@/icon/2475229.png" style="width: 2em;" />
                    </b-button>
                  </div>
                  <div class="menusubitem">
                    <b-button :title="$t('wdm16.11189')" variant="white" size="sm" @click="f_drawAlgorithm()">
                      <img src="@/icon/139088.png" style="width: 2em;" />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
        </b-tab>
        <b-tab @click="d_selectedMenu = 'chatbot'">
          <template slot="title">
            <img src="@/icon/2024270.png" style="width: 1.5em;">
            <span :style="'color:' + p_dmpSettings.style.tab_header.color">ChatBot</span>
          </template>
          <template v-if="d_selectedMenu ==='chatbot'">
            <b-row style="height: 75px; margin-left: 0px;">
              <b-col cols="12">
                <div style="float: left;">
                  <img src="@/icon/2024270.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                </div>
                <div style="float: left; min-width: 75px; height: 100%; padding: 3px; border-left: solid 1px black;">
                  ChatBot
                  <br>
                  <div class="menusubitem">
                    <b-dropdown variant="white" style="padding: 0px;">
                      <template slot="button-content">
                        <img src="@/icon/2486994.png" class="img-rounded img-responsive" style="width: 2em;" /> <span :style="'color:' + p_dmpSettings.style.color">ChatBot WDM</span>
                      </template>
                      <b-dropdown-item @click="f_openChatBotModal()">
                        <img src="@/icon/2486994.png" class="img-rounded img-responsive" style="width: 2em;" /> Ekranı Aç
                      </b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown variant="white" style="padding: 0px;">
                      <template slot="button-content">
                        <img src="@/icon/2486994.png" class="img-rounded img-responsive" style="width: 2em;" /> <span :style="'color:' + p_dmpSettings.style.color">ChatBot Diyagram</span>
                      </template>
                      <b-dropdown-item @click="f_openChatBotDiagramModal()">
                        <img src="@/icon/2486994.png" class="img-rounded img-responsive" style="width: 2em;" /> Ekranı Aç
                      </b-dropdown-item>
                      <b-dropdown-item @click="f_goToChatBotIframe()">
                        <img src="@/icon/2486994.png" class="img-rounded img-responsive" style="width: 2em;" /> Chatbot Iframe Ekranını Aç
                      </b-dropdown-item>
                      <b-dropdown-item @click="f_openNewPageWithChatbotGlobalIframe()">
                        <img src="@/icon/2486994.png" class="img-rounded img-responsive" style="width: 2em;" /> Chatbot Iframe Global Ekranını Aç
                      </b-dropdown-item>
                      <b-dropdown-item @click="f_getChatbotIframeGlobalUrl()">
                        <img src="@/icon/2486994.png" class="img-rounded img-responsive" style="width: 2em;" /> Chatbot Iframe Global Linkini Al
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
        </b-tab>
        <b-tab @click="d_selectedMenu = 'testalgorithm'">
          <template slot="title">
            <img src="@/icon/2976082.png" style="width: 1.5em;">
            <span :style="'color:' + p_dmpSettings.style.tab_header.color">Test</span>
          </template>
          <template v-if="d_selectedMenu ==='testalgorithm'">
            <b-row style="height: 75px; margin-left: 0px;">
              <b-col cols="12">
                <div style="float: left;">
                  <img src="@/icon/2976082.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                </div>
                <div class="menuitem">
                  Genel
                  <br>
                  <div class="menusubitem">
                    <b-dropdown variant="white" style="padding: 0px;">
                      <template slot="button-content">
                        <img src="@/icon/3092008.png" class="img-rounded img-responsive" style="width: 2em;" /> <span :style="'color:' + p_dmpSettings.style.color">Seçilen alg</span>
                      </template>
                      <b-dropdown-item @click="f_analyzeDmpTest('selected')">
                        <img src="@/icon/3092008.png" class="img-rounded img-responsive" style="width: 2em;" /> Seçilen alg (diyagram kurgusuna bakılmaz)
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div class="menusubitem">
                    <b-dropdown variant="white" style="padding: 0px;">
                      <template slot="button-content">
                        <img src="@/icon/3092008.png" class="img-rounded img-responsive" style="width: 2em;" /> <span :style="'color:' + p_dmpSettings.style.color">Tüm alg</span>
                      </template>
                      <b-dropdown-item @click="f_analyzeDmpTest('all', 0)">
                        <img src="@/icon/3092008.png" class="img-rounded img-responsive" style="width: 2em;" /> Tüm alg (diyagram, tüm düğümlerdeki pozitif sonuçlar)
                      </b-dropdown-item>
                      <b-dropdown-item @click="f_analyzeDmpTest('all', 1)">
                        <img src="@/icon/3092008.png" class="img-rounded img-responsive" style="width: 2em;" /> Tüm alg (diyagram, en alt düğümdeki pozitif sonuçlar)
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                <div style="float: left; min-width: 75px; height: 100%; padding: 3px; border-left: solid 1px black;">
                  Api
                  <br>
                  <div class="menusubitem">
                    <b-dropdown variant="white" style="padding: 0px;">
                      <template slot="button-content">
                        <img src="@/icon/2486994.png" class="img-rounded img-responsive" style="width: 2em;" /> <span :style="'color:' + p_dmpSettings.style.color">Seçilen alg (api)</span>
                      </template>
                      <b-dropdown-item @click="f_analyzeDmpApiServiceTest('selected')">
                        <img src="@/icon/2486994.png" class="img-rounded img-responsive" style="width: 2em;" /> Seçilen alg (api) (diyagram kurgusuna bakılmaz)
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div class="menusubitem">
                    <b-dropdown variant="white" style="padding: 0px;">
                      <template slot="button-content">
                        <img src="@/icon/2486994.png" class="img-rounded img-responsive" style="width: 2em;" /> <span :style="'color:' + p_dmpSettings.style.color">Tüm alg (api)</span>
                      </template>
                      <b-dropdown-item @click="f_analyzeDmpApiServiceTest('all', 0)">
                        <img src="@/icon/2486994.png" class="img-rounded img-responsive" style="width: 2em;" /> Tüm alg (api) (diyagram, tüm düğümlerdeki pozitif sonuçlar)
                      </b-dropdown-item>
                      <b-dropdown-item @click="f_analyzeDmpApiServiceTest('all', 1)">
                        <img src="@/icon/2486994.png" class="img-rounded img-responsive" style="width: 2em;" /> Tüm alg (api) (diyagram, en alt düğümdeki pozitif sonuçlar
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
        </b-tab>
        <b-tab @click="d_selectedMenu = 'view'">
          <template slot="title">
            <img src="@/icon/680848.png" style="width: 1.5em;">
            <span :style="'color:' + p_dmpSettings.style.tab_header.color">Görünüm</span>
          </template>
          <template v-if="d_selectedMenu ==='view'">
            <b-row key="up_side_settings" style="height: 75px; margin-left: 0px;;">
              <b-col cols="12">
                <div style="float: left;">
                  <img src="@/icon/680848.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                </div>
                <div style="float: left; border-left: solid 1px #baafaf; height: 100%; padding: 3px;">
                  Style:
                  <br>
                  <div style="float: left;">
                    <div style="width: 50px; float: left;">back:</div>
                    <input style="border: 0px; padding: 0px; margin-right: 5px;" type="color" title="Genel arka plan" v-model="p_dmpSettings.style.backgroundColor" @change="f_changeStyle()">
                    <br>
                    <div style="width: 50px; float: left;">text:</div>
                    <input style="border: 0px; padding: 0px; margin-right: 5px;" type="color" title="Genel yazı rengi" v-model="p_dmpSettings.style.color" @change="f_changeStyle()">
                  </div>
                  <div style="float: left;">
                    <div style="width: 50px; float: left;">s-back:</div>
                    <input style="border: 0px; padding: 0px; margin-right: 5px;" type="color" title="Alt arka plan" v-model="p_dmpSettings.style.subBackgroundColor" @change="f_changeStyle()">
                    <br>
                    <div style="width: 50px; float: left;">s-text:</div>
                    <input style="border: 0px; padding: 0px; margin-right: 5px;" type="color" title="Alt yazı rengi" v-model="p_dmpSettings.style.subColor" @change="f_changeStyle()">
                  </div>
                  <div style="float: left;">
                    <div style="width: 50px; float: left;">h-back:</div>
                    <input style="border: 0px; padding: 0px; margin-right: 5px;" type="color" title="Genel arka plan" v-model="p_dmpSettings.style.dmp_header.backgroundColor" @change="f_changeStyle()">
                    <br>
                    <div style="width: 50px; float: left;">h-text:</div>
                    <input style="border: 0px; padding: 0px; margin-right: 5px;" type="color" title="Genel yazı rengi" v-model="p_dmpSettings.style.dmp_header.color" @change="f_changeStyle()">
                  </div>
                  <div style="float: left;">
                    <div style="width: 50px; float: left;">t-back:</div>
                    <input style="border: 0px; padding: 0px; margin-right: 5px;" type="color" title="Tab arka plan" v-model="p_dmpSettings.style.tab_header.backgroundColor" @change="f_changeStyle()">
                    <br>
                    <div style="width: 50px; float: left;">t-text:</div>
                    <input style="border: 0px; padding: 0px; margin-right: 5px;" type="color" title="Tab yazı rengi" v-model="p_dmpSettings.style.tab_header.color" @change="f_changeStyle()">
                  </div>
                </div>
                <div style="float: left; border-left: solid 1px #baafaf; height: 100%; padding: 3px;">
                  <b-dropdown variant="white" style="padding: 0px;">
                    <template slot="button-content">
                      <img src="@/icon/3532765.png" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_saveDmpStyleForMe()">
                      <img src="@/icon/719135.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> paket stilini benim için kaydet
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </b-col>
            </b-row>
          </template>
        </b-tab>
        <b-tab @click="d_selectedMenu = 'settings'">
          <template slot="title">
            <img src="@/icon/3532765.png" style="width: 1.5em;">
            <span :style="'color:' + p_dmpSettings.style.tab_header.color">Ayarlar</span>
          </template>
          <template v-if="d_selectedMenu ==='settings'">
            <b-row key="up_side_settings" style="height: 75px; margin-left: 0px;;">
              <b-col cols="12">
                <div style="float: left;">
                  <img src="@/icon/3532765.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                </div>
                <div class="menuitem">
                  User
                  <br>
                  <div class="menusubitem">
                    <b-button title="Kullanıcı yetkileri düzenle" variant="white" size="sm" @click="f_editSelectedWdmr('wdm24')">
                      <img src="@/icon/3094236.png" style="width: 2em;" />
                    </b-button>
                  </div>
                </div>
                <div class="menuitem" v-if="d_wisdomeraSystem.system_type !== 'mainserver'">
                  Veri modeli
                  <br>
                  <div class="menusubitem">
                    <b-button title="Veri modeli indir" variant="white" size="sm" @click="f_openGetNewModels()">
                      <img src="@/icon/3530181.png" style="width: 2em;" />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
        </b-tab>
        <b-tab @click="d_selectedMenu = 'help'">
          <template slot="title">
            <img src="@/icon/863488.png" style="width: 1.5em;">
            <span :style="'color:' + p_dmpSettings.style.tab_header.color">Yardım</span>
          </template>
          <template v-if="d_selectedMenu ==='help'">
            <b-row key="up_side_help" style="height: 75px; margin-left: 0px;;">
              <b-col cols="12">
                <div style="float: left;">
                  <img src="@/icon/863488.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                </div>
                <div class="menuitem">
                  <b-dropdown variant="white" style="padding: 0px;">
                    <template slot="button-content">
                      <img src="@/icon/2979152.png" style="border-radius: 50%; width: 2em;" />
                      <span :style="'color:' + p_dmpSettings.style.color">Genel</span>
                    </template>
                    <b-dropdown-item @click="f_goToDocsWisdomera('https://docs.wisdomera.io/#/documenttree?wdmr231=67')">
                      <img src="@/icon/2979152.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> genel dokümantasyon
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div class="menuitem">
                  <b-dropdown variant="white" style="padding: 0px;">
                    <template slot="button-content">
                      <img src="@/icon/1430869.png" style="border-radius: 50%; width: 2em;" />
                      <span :style="'color:' + p_dmpSettings.style.color">Karar Destek Paketi</span>
                    </template>
                    <b-dropdown-item @click="f_goToDocsWisdomera('https://docs.wisdomera.io/#/documenttree?wdmr231=150')">
                      <img src="@/icon/1430869.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> karar destek paketi nedir
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_goToDocsWisdomera('https://docs.wisdomera.io/#/documenttree?wdmr231=156')">
                      <img src="@/icon/1430869.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> karar destek paketi nasıl oluşturulur
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div class="menuitem">
                  <b-dropdown variant="white" style="padding: 0px;">
                    <template slot="button-content">
                      <img src="@/icon/2884572.png" style="border-radius: 50%; width: 2em;" />
                      <span :style="'color:' + p_dmpSettings.style.color">Algoritma</span>
                    </template>
                    <b-dropdown-item @click="f_goToDocsWisdomera('https://docs.wisdomera.io/#/documenttree?wdmr231=157')">
                      <img src="@/icon/2884572.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> yeni algoritma oluşturma
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_goToDocsWisdomera('https://docs.wisdomera.io/#/documenttree?wdmr231=158')">
                      <img src="@/icon/2884572.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> algoritma dizayn etme
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_goToDocsWisdomera('https://docs.wisdomera.io/#/documenttree?wdmr231=169')">
                      <img src="@/icon/2884572.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> tarihsel algoritma
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div class="menuitem">
                  <b-dropdown variant="white" style="padding: 0px;">
                    <template slot="button-content">
                      <img src="@/icon/2448909.png" style="border-radius: 50%; width: 2em;" />
                      <span :style="'color:' + p_dmpSettings.style.color">Diagram</span>
                    </template>
                    <b-dropdown-item @click="f_goToDocsWisdomera('https://docs.wisdomera.io/#/documenttree?wdmr231=163')">
                      <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> diagram model anlamı
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_goToDocsWisdomera('https://docs.wisdomera.io/#/documenttree?wdmr231=164')">
                      <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> karar ağacı oluşturma
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div class="menuitem">
                  <b-dropdown variant="white" style="padding: 0px;">
                    <template slot="button-content">
                      <img src="@/icon/2486994.png" style="border-radius: 50%; width: 2em;" />
                      <span :style="'color:' + p_dmpSettings.style.color">Api</span>
                    </template>
                    <b-dropdown-item @click="f_goToDocsWisdomera('https://docs.wisdomera.io/#/documenttree?wdmr231=161')">
                      <img src="@/icon/2486994.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Api dizayn etme
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_goToDocsWisdomera('https://docs.wisdomera.io/#/documenttree?wdmr231=162')">
                      <img src="@/icon/2486994.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Senin uygulamanla walgorithm arasındaki mimari model
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </b-col>
            </b-row>
          </template>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import {
  default as Modal
} from "@/components/widgets/Modal";
import FiletransferService from '@/services/file_transfer';
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'DmpMenu',
  computed: mapGetters({
    lang: 'lang',
    device: 'device'
  }),
  props: {
    f_openNewPageWithChatbotGlobalIframe: {
      type: Function,
      required: true
    },
    f_getChatbotIframeGlobalUrl: {
      type: Function,
      required: true
    },
    f_goToChatBotIframe: {
      type: Function,
      required: true
    },
    f_openGetNewModels: {
      type: Function,
      required: true
    },
    f_saveDmp: {
      type: Function,
      required: true
    },
    f_openRuleAlgoritm: {
      type: Function,
      required: true
    },
    f_drawAlgorithm: {
      type: Function,
      required: true
    },
    f_copyRuleAlgorithm: {
      type: Function,
      required: true
    },
    f_analyzeDmpApiServiceTest: {
      type: Function,
      required: true
    },
    f_analyzeDmpTest: {
      type: Function,
      required: true
    },
    f_ruleEngineTest: {
      type: Function,
      required: true
    },
    f_openDmpList: {
      type: Function,
      required: true
    },
    f_addNewWisdomData: {
      type: Function,
      required: true
    },
    f_addAlgorithmsFromExcel: {
      type: Function,
      required: true
    },
    f_editSelectedWdmr: {
      type: Function,
      required: true
    },
    f_deleteSelectedChildWdmr: {
      type: Function,
      required: true
    },
    f_publishDmp: {
      type: Function,
      required: true
    },
    f_copyDmp: {
      type: Function,
      required: true
    },
    f_getDmpRuleAlgorithmList: {
      type: Function,
      required: true
    },
    f_openChatBotModal: {
      type: Function,
      required: true
    },
    f_openChatBotDiagramModal: {
      type: Function,
      required: true
    },
    f_addEditApiResultDocument: {
      type: Function,
      required: true
    },
    p_dmpSettings: {
      type: Object,
      required: true
    },
    p_selectedDmpWdmrData: {
      type: Object,
      required: false
    }
  },
  components: {
    Modal,
    WisdomDataModal
  },
  data () {
    return {
      d_wisdomeraSystem: {},
      w_presentation: false,
      d_selectExcelData: { 'file': '', 'show': false },
      d_polestarProjectCopy: { 'show': false },
      d_wdmr22: {},
      d_projectSmsProvider: {
        'show': false,
        'selected_sms_provider': { 'label': '', 'key': '' }
      },
      d_projectListData: { 'list': [], 'show': false },
      d_showStatisticsModal: false,
      d_selectedAnalyzeModel: { 'analyze_model': '', 'type': '', 'row_parameter_group_type': '1' },
      d_cell_selection_mode: 'one_cell_select_mode',
      d_upperPartShowMode: true,
      analyze_column_list: [],
      column_parameter_list: [],
      row_parameter_list: [],
      d_selectedAnatomySchemaList: [],
      user: {},
      d_selectedMenu: 'decisionmakingpackage',
      d_menuList: [{
        'label': 'Karar destek paketi',
        'translation': {},
        'value': 'decisionmakingpackage'
      }, {
        'label': 'Algoritma',
        'translation': {},
        'value': 'rulealgorithm'
      }, {
        'label': 'Chatbot',
        'translation': {},
        'value': 'chatbot'
      }, {
        'label': 'Test',
        'translation': {},
        'value': 'testalgorithm'
      }, {
        'label': 'Görünüm',
        'translation': {},
        'value': 'view'
      }, {
        'label': 'Ayarlar',
        'translation': {},
        'value': 'settings'
      }, {
        'label': 'Yardım',
        'translation': {},
        'value': 'help'
      }],
    };
  },
  created: function () {
    this.d_wisdomeraSystem = JSON.parse(localStorage.getItem('wisdomera_system'));
    this.w_presentation = JSON.parse(localStorage.getItem('presentation'));
    this.d_wdmr22 = JSON.parse(localStorage.getItem('wdm22'));
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted: function () {},
  methods: {
    f_goToDocsWisdomera: function (uri) {
      window.open(uri, '_blank');
    },
    f_saveDmpStyleForMe: function () {
      if (!this.p_selectedDmpWdmrData.settings) {
        this.p_selectedDmpWdmrData.settings = {};
      }
      this.p_selectedDmpWdmrData.settings[this.user.username] = this.p_dmpSettings;
      this.f_saveDmp();
    },
    f_addRegressionModel: function (type) {
      if (type === 'lineer') {
        //
      } else {
        //
      }
    },
    f_createNewRandomForest: function () {
      return;
    },
    f_createNewKNN: function () {
      return;
    },
    f_createNewDecisionTree: function () {
      return;
    },
    f_createNewSVM: function () {
      return;
    },
    f_openUserPermissionsModal: function () {
      return;
    },
    f_openProjectMailProviderModal: function () {
      return;
    },
    f_addDataFromCsv: function () {
      return;
    },
    f_saveProjectStyleForMe: function () {
      if (!this.p_selectedProjectData.settings) {
        this.p_selectedProjectData.settings = {};
      }
      this.p_selectedProjectData.settings[this.user.username] = this.p_dmpSettings;
      this.f_savePoleStarProject();
    },
    f_changeStyle: function () {
      return;
      setTimeout(function () {
        this.$forceUpdate();
      }.bind(this), 300)
    },
    f_updateSelectedCellsByFollowingRuleHiearchy: function (cell_type = 'only_free') {
      if (this.d_selected_cells.length > 0) {
        let data = {
          'wdm3_id': this.p_selectedProjectData.id,
          'cell_type': cell_type,
          'cells_list': []
        };
        for (let i in this.d_selected_cells) {
          let append_obj = {};
          append_obj.row_ind = parseInt(this.d_selected_cells[i].split('-')[0]);
          append_obj.col_ind = parseInt(this.d_selected_cells[i].split('-')[1]);
          append_obj.dataset_key = this.d_dataset.case_id_list[append_obj.row_ind].dataset_key;
          append_obj.row_no = this.d_dataset.case_id_list[append_obj.row_ind].no;
          data.cells_list.push(append_obj);
        }
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Seçeneklere ait kural algoritmaları ile çalışma yapılıyor. Lütfen bekleyiniz' } });
        PoleStarService.update_selected_project_cells_by_following_rule_hierarchy(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status === 'success') {
              msg = 'Analiz işlemleri tamamlandı';
              this.f_getCases();
              // this.p_selectedProjectData.design.column_list = resp.data.result.column_list;
              // this.column_list.splice(0, this.column_list.length);
            } else {
              msg = resp.data.message;
            }
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Seçeneklere ait kural algoritmaları ile çalışma işlemleri hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          });
      } else {
        let msg = 'Seçili hücre bulunmamaktadır. Lütfen öncelikle hücre seçim işleminizi gerçekleştiriniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Seçili hücrelerin kural hiyerarşisine göre güncellenmesi işlemleri hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_updateCellsByFollowingRuleHiearchy: function (col_type, col_ind = '', cell_type) {
      let data = {
        'col_type': col_type,
        'col_ind': col_ind,
        'wdm3_id': this.p_selectedProjectData.id,
        'cell_type': cell_type
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Seçeneklere ait kural algoritmaları ile çalışma yapılıyor. Lütfen bekleyiniz' } });
      PoleStarService.update_all_project_cells_by_following_rule_hierarchy(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          let msg = '';
          if (resp.data.status === 'success') {
            msg = 'Analiz işlemleri tamamlandı';
            this.f_getCases();
            // this.p_selectedProjectData.design.column_list = resp.data.result.column_list;
            // this.column_list.splice(0, this.column_list.length);
          } else {
            msg = resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Seçeneklere ait kural algoritmaları ile çalışma işlemleri hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        });
    },
    f_updateCellsByColumnOptionMixedWdmrRule: function (col_type, col_ind = '', cell_type) {
      if (this.d_eligibleForColumnOptionMixedWdmrRuleMatch) {
        let data = {
          'col_type': col_type,
          'col_ind': col_ind,
          'wdm3_id': this.p_selectedProjectData.id,
          'cell_type': cell_type
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Seçeneklere ait kural algoritmaları ile çalışma yapılıyor. Lütfen bekleyiniz' } });
        PoleStarService.update_cells_by_column_option_mixed_wdmr_rule(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status === 'success') {
              msg = 'Analiz işlemleri tamamlandı';
              this.f_getCases();
              // this.p_selectedProjectData.design.column_list = resp.data.result.column_list;
              // this.column_list.splice(0, this.column_list.length);
            } else {
              msg = resp.data.message;
            }
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Seçeneklere ait kural algoritmaları ile çalışma işlemleri hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          });
      } else {
        let msg = 'Tüm seçenekleri kolon kuralı ve wdmr kuralı analizleri beraber sağlandığında otomatik doldur işlemi uygun değil. Lütfen kontrol ediniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Wdmr kuralı ve kolon kuralı birlikte hücreleri güncelleme hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_updateCellsByWdmrRule: function (col_type, col_ind = '', cell_type) {
      if (this.d_eligibleForWdmrRuleMatch) {
        let data = {
          'col_type': col_type,
          'col_ind': col_ind,
          'wdm3_id': this.p_selectedProjectData.id,
          'cell_type': cell_type
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Seçeneklere ait kural algoritmaları ile çalışma yapılıyor. Lütfen bekleyiniz' } });
        PoleStarService.update_cells_by_wdmr_rule(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status === 'success') {
              msg = 'Analiz işlemleri tamamlandı';
              this.f_getCases();
              // this.p_selectedProjectData.design.column_list = resp.data.result.column_list;
              // this.column_list.splice(0, this.column_list.length);
            } else {
              msg = resp.data.message;
            }
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Seçeneklere ait kural algoritmaları ile çalışma işlemleri hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          });
      } else {
        let msg = 'Wdmr kuralı ile hücreleri güncelleme işlemi uygun değil. Lütfen kontrol ediniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Wdmr kuralı ile hücreleri güncelleme hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_searchTextAnalyzeOnWdmrs: function () {
      if (this.d_eligibleForWmdrRuleSearch) {
        let data = {
          'col_type': 'all',
          'col_ind': '',
          'wdm3_id': this.p_selectedProjectData.id
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İfade arama işlemleri gerçekleştiriliyor. Lütfen bekleyiniz.' } });
        PoleStarService.search_text_analyze_on_wdmrs(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status === 'success') {
              msg = 'Analiz işlemleri tamamlandı';
              this.f_getCases();
              // this.p_selectedProjectData.design.column_list = resp.data.result.column_list;
              // this.column_list.splice(0, this.column_list.length);
            } else {
              msg = resp.data.message;
            }
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İfade analiz işlemleri hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          });
      } else {
        let msg = 'Verilerde ifade çalışma yapılması işlemi uygun değil. Lütfen kontrol ediniz.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Verilerde (WDMR) ifade çalışması yapma hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_updateCellsByColumnOptionRule: function (col_type, col_ind = '', cell_type) {
      if (this.d_eligibleForColumnOptionRuleMatch) {
        let data = {
          'col_type': col_type,
          'col_ind': col_ind,
          'wdm3_id': this.p_selectedProjectData.id,
          'cell_type': cell_type
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Seçeneklere ait kural algoritmaları ile çalışma yapılıyor. Lütfen bekleyiniz' } });
        PoleStarService.update_cells_by_column_option_rule(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status === 'success') {
              msg = 'Analiz işlemleri tamamlandı';
              this.f_getCases();
              // this.p_selectedProjectData.design.column_list = resp.data.result.column_list;
              // this.column_list.splice(0, this.column_list.length);
            } else {
              msg = resp.data.message;
            }
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Seçeneklere ait kural algoritmaları ile çalışma işlemleri hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          });
      } else {
        let msg = 'Seçenekleri hücrelere kural ile yerleştirme işlemi için seçenek kural algoritmalarınızı lütfen kontrol ediniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Seçeneklere yazılan kural algoritmaları işlemi' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_saveDataset: function (type = '') {
      if (this.p_userPolestarPermissions.indexOf('owner') !== -1 || this.p_userPolestarPermissions.indexOf('can_edit_manually_project_cells') !== -1) {
        let dataset = JSON.parse(JSON.stringify(this.d_dataset));
        dataset.selected_cells = this.d_selected_cells;
        let data = {
          'data': dataset
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Veri seti kayıt işlemleri gerçekleştiriliyor. Lütfen bekleyiniz.' } });
        PoleStarService.save_data_set(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status === 'success') {
              msg = 'Kayıt işlemi gerçekleştirildi';
            } else {
              msg = resp.data.message;
            }
            /*
              if (type === 'get_cases') {
                this.f_getCases();
              }
            */
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Veri Seti Kayıt işlemi' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          });
      } else {
        let msg = 'Proje datasetini kaydetme yetkiniz bulunmamaktadır. Lütfen proje yöneticisiyle temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Dataset kayıt işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_updatePatientWdmrCells: function (row_ind = '', type, row_type = 'this') {
      // type => delete_manuel_edit or not_delete_manuel_edit
      // console.log(row_ind, type);
      if (this.p_selectedProjectData.rule && this.p_selectedProjectData.rule.main_rule && this.p_selectedProjectData.rule.main_rule.query_list.length > 0) {
        let data = {};
        if (row_ind !== '' && row_type === 'this') {
          data = this.d_dataset.case_id_list[row_ind];
        }
        data.type = type;
        data.row_type = row_type;
        data.wdm3_id = this.p_selectedProjectData.id;
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Dataset Güncelleniyor. Lütfen bekleyiniz.' } });
        PoleStarService.update_patient_wdmr_cells(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status === 'success') {
              this.f_getCases();
              if (row_type === 'this') {
                // this.d_dataset.case_rows[row_ind] = resp.data.result.case_rows;
                // this.d_dataset.cell_dates[row_ind] = resp.data.result.cell_dates;
              }
              this.$forceUpdate();
            } else {
              alert('Error :' + resp.data.message);
            }
          });
      } else {
        let msg = 'Ana kuralınız tanımlı değil. Lütfen öncelikle ana kuralınızı tanımlayıp wdmr kolonları ekleyiniz.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Veri güncelleme işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_publishAsWaim: function () {
      if (this.user && this.user.permissions_result && this.user.permissions_result.indexOf('polestar_wisdomstats_publish-polestar-project-as-wai') !== -1) {
        if (this.p_userPolestarPermissions.indexOf('owner') !== -1 || this.p_userPolestarPermissions.indexOf('can_publish_as_wai_model') !== -1) {
          let data = {
            'wdm3_id': this.p_selectedProjectData.id
          };
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Proje yapay zeka modeli olarak yayınlama işlemi başlatıldı. Lütfen bekleyiniz.' } });
          PoleStarService.publish_as_waim(data)
            .then(resp => {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              if (resp.data.status === 'success') {
                let msg = 'Yayınlama işleminiz başarıyla gerçekleştirildi';
                let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Yapay zeka modeli yayınlama işlemi hakkında' };
                this.$store.commit('modal', modal_data);
                this.$store.commit('modal_show', true);
              } else {
                alert('Error :' + resp.data.message)
              }
            });
        } else {
          let msg = 'Waide yapay zeka modeli olarak yayınlamak için proje yetkilisinden yayınlama yetkisi almanız gerekmektedir.';
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Wai yapay zeka modeli yayınlama hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        }
      } else {
        let msg = 'Waide yapay zeka modeli olarak yayınlamak için yayınlama yetkisi almanız gerekmektedir.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Wai yapay zeka modeli yayınlama hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_copyPolestarProject: function () {
      if (this.user && this.user.permissions_result && this.user.permissions_result.indexOf('polestar_wisdomstats_copy-polestar-project') !== -1) {
        if (this.p_userPolestarPermissions.indexOf('owner') !== -1 || this.p_userPolestarPermissions.indexOf('project_copy_control') !== -1) {
          this.d_polestarProjectCopy.show = true;
        } else {
          let msg = 'Bu projeye ait kopyalama yetkiniz bulunmamaktadır. Lütfen proje yetkilisi ile temasa geçiniz';
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Proje kopyalama işlemi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        }
      } else {
        let msg = 'Proje Kopyalama yetkiniz bulunmamaktadır. Lütfen sistem yetkilisi ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Proje kopyalama işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_goToProjectList: function () {
      this.$router.push({ path: '/polestar/projects' });
    },
    f_goToWisdomStats: function (project_id) {
      this.$router.push({ path: '/polestar/wisdomstats', query: { project_id: project_id } });
      this.f_goToThisProject(project_id);
      this.d_projectListData.show = false;
    },
    DateFormat: function (date) {
      return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
    },
    f_selectThisProject: function (project_ind) {
      this.d_projectListData.index = project_ind;
      this.$forceUpdate();
    },
    f_getUserPolestarProjects: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Proje listeniz getiriliyor. Lütfen bekleyiniz.' } });
      ClsPolestar.f_getUserPolestarProjects(this.user.username).then(resp => {
        this.$store.commit('Loading', { 'status': false, 'data': {} });
        // console.log(resp);
        this.d_projectListData.list = resp;
        this.d_projectListData.show = true;
      }, resp => {
        alert('error : ', resp);
      });
    },
    go_to_this_project_details: function () {
      this.$router.push({ path: '/polestar/projects/details', query: { project_id: this.p_selectedProjectData.id } });
    },
    f_openProjectSmsProviderModal: function () {
      this.d_projectSmsProvider.show = true;
    },
    f_addThisSmsProviderToProject: function () {
      if (this.user && this.user.permissions_result && this.user.permissions_result.indexOf('sms_send-sms') !== -1) {
        let data = {
          'wdm3_id': this.p_selectedProjectData.id,
          'sms_provider': this.d_projectSmsProvider.selected_sms_provider
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Projeye sms sağlayıcı ekleniyor. Lütfen bekleyiniz.' } });
        SmsService.add_sms_provider_to_project(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status === 'success') {
              this.p_selectedProjectData.sms_provider = JSON.parse(JSON.stringify(this.d_projectSmsProvider.selected_sms_provider));
              this.f_motherChildren('forceUpdate');
              msg = 'Başarıyla tanımlandı.';
            } else {
              msg = resp.data.message;
            }
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Sms gönderme işlemi hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          });
      } else {
        let msg = 'Sms tanımlama işlemi yapabilmeniz için sms gönderim yetkisi almanız gerekmektedir.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Sms tanımlama işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_talkWithAiRobots: function () {
      return;
      let data = {
        'project_id': this.p_selectedProjectData.id,
        // 'main_column_val_list': this.d_dataset.main_column_val_list,
        'column_list': this.column_list,
        'receiver': 'airobot_drwisdom',
        'message': 'Merhabalar seninle konuşmak istiyorum.'
      };
      MessengerService.prepare_polestar_project_to_talk(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_showMessenger = true;
          } else {
            console.log('error', resp.data.message);
          }
        })
    },
    f_createTextOfRuleAlgoritm: function (rule) {
      let data = {
        'list': [rule],
        'type': 'standart'
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Algoritma yazı üretim işlemi başlatıldı. Lütfen bekleyiniz.' } });
      PoleStarService.create_text_of_rule_algoritm(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            if (resp.data.result.status === 'success') {
              let other_data = {
                'text_list': [{ 'text': resp.data.result.result.text, 'selected': 1 }]
              };
              this.p_f_showArticleList(false, [other_data]);
              // this.f_showArticleList(false, [other_data]);
            }
          } else {
            alert('Error :' + resp.data.message)
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          }
        });
    },
    f_menuStatusControl: function (x, status_type = 'bool') {
      if (!this.d_dataset.menu) {
        this.d_dataset.menu = {};
      }
      if (this.d_dataset.menu.data === undefined) {
        this.d_dataset.menu.data = {};
      }
      if (this.d_dataset.menu.data[x] === undefined) {
        this.d_dataset.menu.data[x] = {};
      }
      if (status_type === 'bool') {
        if (!this.d_dataset.menu.data[x].status) {
          this.d_dataset.menu.data[x].status = 1;
        } else {
          this.d_dataset.menu.data[x].status = 0;
        }
      } else {
        this.d_dataset.menu.data[x].status = status_type;
      }
      if (x === 'anonym_mode') {
        alert('anonimizasyon işlemini gerçekleştirmek için lütfen veri setini kaydediniz.');
      }
      this.f_motherChildren('forceUpdate');
      this.$forceUpdate();
    },
    f_createTextOfWisdomEraPolestarStatisticsTool: function () {
      let vers = JSON.parse(localStorage.getItem('version'));
      let txt = 'We get all data from WisdomEra Healthcare System (WHS). We used WisdomEra Polestar Module ' + vers.version + ' for assessing statistics and preparing the manuscript of this article. As statistics package, wisdomera uses python "statsmodels" module. WHS is an electronic health record, decision support and data management application for healthcare providers. It works with our health information management system (HIMS). All data are recorded as structured parameters. Recorded parameters are filtered to its polestar module by its rule engine module. We do not need to make text mining procedures or manually reading and writing parameters to get data. It gets data automatically in a few seconds as parameter format. This feature provides us to analyze data faster and more accurate data. And it can also analyze data automatically by its own cross analyze models. It makes millions of analysis by this way. It uses standart statistical packages also.';
      let other_data = { 'text_list': [{ 'text': txt, 'selected': 1 }] };
      this.p_f_showArticleList(false, [other_data]);
    },
    f_saveWdm72: function () {
      let return_data = ClsWdmrForever.turn(this.d_statisticWisdomData, this.option_data['wdm72'], ['name', 'delete']);
      let wdmr_name = return_data.name;
      if (wdmr_name) {
        this.d_statisticWisdomData.label = wdmr_name;
      }
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      if (!only_one_param_is_filled) {
        let modal_data = {
          'type': 'alert',
          'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.',
          'centered': true,
          'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.'
        };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' *' + wdmr_require_alerts[r].label;
        }
        let modal_data = {
          'type': 'alert',
          'text': require_txt,
          'centered': true,
          'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.'
        };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      let data = {};
      data = { 'data': this.d_statisticWisdomData };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kayıt yapılıyor. Lütfen bekleyiniz.' } });
      PoleStarService.save_statistics(data).then(resp => {
        if (resp.data.status === "success") {
          this.f_motherChildren('update_project_data', resp.data.result.project_data);
          this.d_showStatisticsModal = false;
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        } else {
          let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        }
        this.$forceUpdate();
      });

    },
    f_createWdm72ColList: function () {
      let col_list = [];
      for (let i in this.column_list) {
        let lbl = this.column_list[i].label + '( no:' + i + ' ) -' + this.column_list[i].param_type + ' -';
        // We took the column index in value. So when changing we can use value field.
        let x = { 'label': lbl, 'value': i.toString(), 'param_type': this.column_list[i].param_type }
        col_list.push(x);
      }
      this.option_data['wdm72'].general.parameters.analyze_column_list.options = col_list;
      this.option_data['wdm72'].general.parameters.row_parameter_list.options = col_list;
      this.option_data['wdm72'].general.parameters.column_parameter_list.options = col_list;
      this.option_data['wdm72'].general.parameters.before_after.parameters.first_column.options = col_list;
      this.option_data['wdm72'].general.parameters.before_after.parameters.second_column.options = col_list;
    },
    f_prepareWdm72: function (analyze_model, type, column_selection_type) {
      this.analyze_column_list = [];
      this.column_parameter_list = [];
      this.row_parameter_list = [];
      // this.d_selectedAnalyzeModel.analyze_model = analyze_model;
      let pre_data = {
        'id': 'new',
        'data': {},
        'type': 'wdm72',
        'reference_wdm': {},
        'wdm3_id': this.p_selectedProjectData.id
      };
      pre_data['reference_wdm']['version'] = this.option_data['wdm72']['version'];
      pre_data['reference_wdm']['owner_type'] = this.option_data['wdm72']['owner_type'];
      if (this.option_data['wdm72']['owner_type'] === 'wisdom') {
        pre_data['reference_wdm']['key'] = 'wdm_wisdom_wdm72_v' + this.option_data['wdm72'].version;
      }
      for (let i in this.option_data['wdm72'].param_group.param_order) {
        pre_data['data'][this.option_data['wdm72'].param_group.param_order[i]] = {};
      }
      this.d_selectedAnalyzeModel.type = type;
      let analyze_type = '';
      for (let i in this.option_data.wdm72.general.parameters.analyze_type.options) {
        if (this.option_data.wdm72.general.parameters.analyze_type.options[i].value === type) {
          analyze_type = this.option_data.wdm72.general.parameters.analyze_type.options[i];
          break;
        }
      }
      pre_data['data']['general']['wdm3_name'] = { 'val': this.p_selectedProjectData.data.general.name.val };
      pre_data['data']['general']['wdm3_key'] = { 'val': 'wdm3_' + this.p_selectedProjectData.id };
      pre_data['data']['general']['analyze_type'] = { 'val': analyze_type };
      pre_data['data']['general']['analyze_time_type'] = { 'val': { 'value': 'manuel', 'label': 'manuel' } };
      pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'no', 'label': 'hayır' } };
      pre_data['data']['general']['auto_combination_cross_analysis'] = { 'val': { 'value': 'no', 'label': 'hayır' } };
      if (type === 'detailed_descriptive_stats') {
        if (column_selection_type === 'i_will_select_columns') {
          pre_data['data']['general']['column_selection_type'] = { 'val': { 'value': 'manuel_column_selection', 'label': 'manuel kolon seçimi' } };
          pre_data['data']['general']['descriptive_stats'] = {
            'mean': { 'name': { 'value': 'mean', 'label': 'ortalama' }, 'detail': {} },
            'median': { 'name': { 'value': 'median', 'label': 'ortanca (medyan)' }, 'detail': {} },
            'standart_deviation': { 'name': { 'value': 'standart_deviation', 'label': 'standart sapma' }, 'detail': {} },
            'variance': { 'name': { 'value': 'variance', 'label': 'varyans' }, 'detail': {} },
            'min': { 'name': { 'value': 'min', 'label': 'minimum' }, 'detail': {} },
            'max': { 'name': { 'value': 'max', 'label': 'maksimum' }, 'detail': {} },
            'kurtosis': { 'name': { 'value': 'kurtosis', 'label': 'veri dağılımı (basıklık, kurtosis)' }, 'detail': {} },
            'skewness': { 'name': { 'value': 'skewness', 'label': 'veri dağılımı (eğim, skewness)' }, 'detail': {} },
            'frequency': { 'name': { 'value': 'frequency', 'label': 'sıklık' }, 'detail': {} },
            'kolmogorov_smirnov': { 'name': { 'value': 'kolmogorov_smirnov', 'label': 'veri dağılımı (kolmogrov smirnov)' }, 'detail': {} },
            'shapiro': { 'name': { 'value': 'shapiro', 'label': 'veri dağılımı (shapiro)' }, 'detail': {} },
            'outliers_zindex': { 'name': { 'value': 'outliers_zindex', 'label': 'aykırı değer saptama (outliers) z-index' }, 'detail': {} }
          };
        } else if (column_selection_type === 'numeric_columns') {
          // pre_data['data']['general']['selected_columns'] = this.f_addColumnsByParamTypes(['integer', 'float']);
          pre_data['data']['general']['column_selection_type'] = { 'val': { 'value': 'all_numeric_columns', 'label': 'tüm numeric kolonlar' } };
          pre_data['data']['general']['descriptive_stats'] = {
            'mean': { 'name': { 'value': 'mean', 'label': 'ortalama' }, 'detail': {} },
            'median': { 'name': { 'value': 'median', 'label': 'ortanca (medyan)' }, 'detail': {} },
            'standart_deviation': { 'name': { 'value': 'standart_deviation', 'label': 'standart sapma' }, 'detail': {} },
            'variance': { 'name': { 'value': 'variance', 'label': 'varyans' }, 'detail': {} },
            'min': { 'name': { 'value': 'min', 'label': 'minimum' }, 'detail': {} },
            'max': { 'name': { 'value': 'max', 'label': 'maksimum' }, 'detail': {} },
            'kurtosis': { 'name': { 'value': 'kurtosis', 'label': 'veri dağılımı (basıklık, kurtosis)' }, 'detail': {} },
            'skewness': { 'name': { 'value': 'skewness', 'label': 'veri dağılımı (eğim, skewness)' }, 'detail': {} },
            'kolmogorov_smirnov': { 'name': { 'value': 'kolmogorov_smirnov', 'label': 'veri dağılımı (kolmogrov smirnov)' }, 'detail': {} },
            'shapiro': { 'name': { 'value': 'shapiro', 'label': 'veri dağılımı (shapiro)' }, 'detail': {} },
            'outliers_zindex': { 'name': { 'value': 'outliers_zindex', 'label': 'aykırı değer saptama (outliers) z-index' }, 'detail': {} }
          };

        } else if (column_selection_type === 'categorical_columns') {
          pre_data['data']['general']['column_selection_type'] = { 'val': { 'value': 'all_categorical_columns', 'label': 'tüm kategorik kolonlar' } };
          // pre_data['data']['general']['selected_columns'] = this.f_addColumnsByParamTypes(['select', 'radiogroup', 'object_list_options', 'checkbox']);
          pre_data['data']['general']['descriptive_stats'] = {
            'frequency': { 'name': { 'value': 'frequency', 'label': 'sıklık' }, 'detail': {} }
          };
        } else if (column_selection_type === 'all_columns') {
          pre_data['data']['general']['column_selection_type'] = { 'val': { 'value': 'all_columns', 'label': 'tüm kolonlar' } };
          // pre_data['data']['general']['selected_columns'] = this.f_addColumnsByParamTypes('all');
          pre_data['data']['general']['descriptive_stats'] = {
            'mean': { 'name': { 'value': 'mean', 'label': 'ortalama' }, 'detail': {} },
            'median': { 'name': { 'value': 'median', 'label': 'ortanca (medyan)' }, 'detail': {} },
            'standart_deviation': { 'name': { 'value': 'standart_deviation', 'label': 'standart sapma' }, 'detail': {} },
            'variance': { 'name': { 'value': 'variance', 'label': 'varyans' }, 'detail': {} },
            'min': { 'name': { 'value': 'min', 'label': 'minimum' }, 'detail': {} },
            'max': { 'name': { 'value': 'max', 'label': 'maksimum' }, 'detail': {} },
            'kurtosis': { 'name': { 'value': 'kurtosis', 'label': 'veri dağılımı (basıklık, kurtosis)' }, 'detail': {} },
            'skewness': { 'name': { 'value': 'skewness', 'label': 'veri dağılımı (eğim, skewness)' }, 'detail': {} },
            'frequency': { 'name': { 'value': 'frequency', 'label': 'sıklık' }, 'detail': {} },
            'kolmogorov_smirnov': { 'name': { 'value': 'kolmogorov_smirnov', 'label': 'veri dağılımı (kolmogrov smirnov)' }, 'detail': {} },
            'shapiro': { 'name': { 'value': 'shapiro', 'label': 'veri dağılımı (shapiro)' }, 'detail': {} },
            'outliers_zindex': { 'name': { 'value': 'outliers_zindex', 'label': 'aykırı değer saptama (outliers) z-index' }, 'detail': {} }
          };
        }
      }
      this.f_createWdm72ColList();
      this.d_statisticWisdomData = pre_data;
      this.d_showStatisticsModal = true;
      // this.d_showStatsSelect = true;
      this.$store.commit('Loading', { 'status': false, 'data': {} });
    },
    f_selectAnalyze: function (analyze_model, type, column_selection_type) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Analiz Modeliniz hazırlanıyor. Lütfen bekleyiniz.' } });
      setTimeout(function () {
        this.f_prepareWdm72(analyze_model, type, column_selection_type);
      }.bind(this), 500)
    },
    f_motherChildren: function (op, project_data = {}) {
      if (op === 'update_project_data') {
        this.mother_children.d_selectedProjectData = project_data;
      }
      if (this.mother_children[op]) {
        this.mother_children[op] = 0;
      } else {
        this.mother_children[op] = 1;
      }
    },
    f_analyzeProjectCellFillMethods: function () {
      let data = {
        'wdm3_id': this.p_selectedProjectData.id
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Analiz Yapılıyor. Lütfen bekleyiniz.' } });
      PoleStarService.analyze_project_cell_fill_methods(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          let msg = '';
          if (resp.data.status === 'success') {
            msg = 'Analiz işlemleri tamamlandı.';
            this.f_motherChildren('get_project_cases');
            // this.p_selectedProjectData.design.column_list = resp.data.result.column_list;
            // this.column_list.splice(0, this.column_list.length);
          } else {
            msg = resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İfade analiz işlemleri hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        });
    },
    f_selectMenu: function (menu) {
      this.d_selectedMenu = menu.value;
    }
  }
};

</script>

<style type="text/css">
.menusubitem {
  float: left;
  min-width: 50px;
}

.menuitem {
  float: left;
  min-width: 75px;
  height: 100%;
  padding: 3px;
}

</style>

