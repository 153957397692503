import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  get_polestar_project_columns_as_wdm (data) {
    return Vue.http.post(API_BASE_URL + 'polestar/get_polestar_project_columns_as_wdm', data);
  },
  prepare_columns_for_this_operations (data) {
    return Vue.http.post(API_BASE_URL + 'polestar/prepare_columns_for_this_operations', data);
  }
};
