<!-- !!!!!!!!!!!!! RİGHT BETWEEN DİV TAGS TO TEST YOUR RENDERED TEMPLATE THAT YOU LOGGED TO CONSOLE. -->
<!-- 
<template>
  <div></div>
</template>
 -->

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';


var templateRenderFns = [];
export default {
  name: 'TreeLayer',
  render (h) {
    return h('div', [
      (this.template ? this.template() : '')
    ]);
  },
  staticRenderFns: templateRenderFns,
  components: {},
  props: {
    p_treeListData: {
      type: Object,
      required: true
    },
    p_dmpSettings: {
      type: Object,
      required: true
    }
  },
  computed: mapGetters({
    lang: 'lang',
    help: 'help'
  }),
  mounted () {},
  created: function () {
    this.d_selectedItem = this.p_treeListData;
    this.render_template();
  },
  data () {
    return {
      d_selectedItem: { 'list': {} },
      d_selectedDecisionPoint: '',
      d_fullScreen: false,
      template: null,
      d_levelCount: 20, // this is defined as default value, to be in safe.
      d_startFromDragType: '',
      d_level: 0,
      d_icons: {
        '931299': require('../../icon/931299.png'),
        '3039083': require('../../icon/3039083.png'),
        '2960590': require('../../icon/2960590.png'),
        '629153': require('../../icon/629153.png')
      }
    };
  },
  methods: {
    render_template: function () {
      // this.d_level = 0; // When we call this function again, this.d_level must be 0.
      this.d_level = 0;
      this.f_calculateDeepLevel();
      let html = this.build_form();
      // console.log(html);
      let compiled_template = Vue.compile(html);
      this.template = compiled_template.render;
      templateRenderFns.length = 0;
      for (var i in compiled_template.staticRenderFns) {
        templateRenderFns.push(compiled_template.staticRenderFns[i]);
      }
    },
    f_calculateDeepLevel: function () {
      let result = { 'item_index': [], 'max': 0 };
      this.f_calculateMaximumDeepLevelCount(this.p_treeListData.list, result);
      this.d_levelCount = result.max;
      // this.d_levelCount = this.p_treeListData.items.length;
      // console.log('this.d_levelCount: ', this.d_levelCount);
    },
    build_form: function () {
      let html = '';
      let it_style = ' :style="\'margin-bottom: 3px; background-color:\' + p_dmpSettings.style.subBackgroundColor + \'; color:\' + p_dmpSettings.style.subColor" ';
      let it_drag = ' draggable @dragstart="f_startDragRule($event, it_ind, \'rule_add_tree\')" @dragover.prevent @dragenter.prevent ';
      html += '<b-row style="margin: 0px;" :class="[d_fullScreen ? \'full-screen-mode\' : \'normal-mode\']">';
      html += '   <b-col sm="12" md="3" :style="f_calculateRuleTreeStyleLeft()">';
      html += '      <b-card no-body>';
      html += '         <template v-for="(it, it_ind) in p_treeListData.items">';
      html += '            <b-card no-body ' + it_drag + it_style + '>';
      html += '               <b-row style="margin: 0px; min-height: 30px;">';
      html += '                  <b-col>';
      html += '                     <span :title="it.label" style="font-size: 10px;">{{ it_ind + 1 }} - {{ it.label.length > 20 ? it.label.substring(0,20) + "..." : it.label }} <small>({{ it.value }})</small></span>';
      html += '                  </b-col>';
      html += '               </b-row>';
      html += '            </b-card>';
      html += '         </template>';
      html += '      </b-card>';
      html += '   </b-col>';
      html += '   <b-col sm="12" md="9" :style="f_calculateRuleTreeStyleRight()">';
      html += '      <b-row style="margin: 0px;">';
      html += '         <b-col sm="12" md="6">';
      html += '            <div @drop="f_onDropRule($event)" @dragover.prevent @dragenter.prevent style="width: 50px; height: 50px; border-radius: 25px 25px 25px 25px; background-color: #b6f7f6; margin-left: 30px; margin-bottom: 20px; text-align: center;"><span style="color: green;">+</span>';
      html += '            </div>';
      html += '         </b-col>';
      html += '         <b-col sm="12" md="6" style="text-align: right;">';
      html += '            <b-button variant="white" size="md" style="margin-right: 5px; padding: 0px;" @click="f_resetTreeList()">';
      html += '               <img :src="d_icons[\'3039083\']" title="Reset" style="width: 2em;" />';
      html += '            </b-button>';
      html += '            <b-button variant="white" size="md" style="margin-right: 5px; padding: 0px;" @click="f_clearTreeList()">';
      html += '               <img :src="d_icons[\'2960590\']" title="Reset" style="width: 2em;" />';
      html += '            </b-button>';
      html += '            <b-button size="md" :variant="d_fullScreen ? \'warning\' : \'white\'" :style="d_fullScreen ? \'margin-right: 5px; padding: 0px; border: solid 2px green;\' : \'margin-right: 5px; padding: 0px;\'" @click="d_fullScreen ? d_fullScreen = false : d_fullScreen = true">';
      html += '               <img :src="d_icons[\'629153\']" :title="$t(\'wdm16.6589\')" style="width: 2em;" />';
      html += '            </b-button>';
      html += '         </b-col>';
      html += '      </b-row>';
      html += '      <b-row :style="f_calculateRuleTreeStyleBelow()">';
      html += '         <b-col sm="12" md="12">';
      html += this.f_createTree();
      html += '         </b-col>';
      html += '      </b-row>';
      html += '   </b-col>';
      html += '</b-row>';
      return '<div>' + html + '</div>';
    },
    f_resetTreeList: function () {
      this.d_selectedItem = this.p_treeListData;
    },
    f_clearTreeList: function () {
      this.p_treeListData.list = [];
    },
    f_calculateRuleTreeStyleBelow: function () {
      let h = '';
      if (this.d_fullScreen) {
        h = window.innerHeight - 100;
      } else {
        h = window.innerHeight * 40 / 100;
      }
      return "border: solid 0.1em green; overflow-y: auto; overflow-x: auto; height: " + h + "px;";
    },
    f_calculateRuleTreeStyleRight: function () {
      let h = '';
      if (this.d_fullScreen) {
        h = window.innerHeight - 30;
      } else {
        h = window.innerHeight * 50 / 100;
      }
      return "border: dotted 1px black; height: " + h + "px;";
    },
    f_calculateRuleTreeStyleLeft: function () {
      let h = '';
      if (this.d_fullScreen) {
        h = window.innerHeight - 30;
      } else {
        h = window.innerHeight * 50 / 100;
      }
      return "border: dotted 1px black; overflow-y: auto; overflow-x: auto; height: " + h + "px;";
    },
    f_createTree: function () {
      let html = '';
      this.d_level += 1;
      let it_style = ' :style="\'box-shadow: \'+ p_dmpSettings.style.subColor +\' -1px -1px 1px 1px; width: 300px; margin-bottom: 3px; margin-bottom: 3px; background-color:\' + p_dmpSettings.style.subBackgroundColor + \'; color:\' + p_dmpSettings.style.subColor + \'; margin-left:' + (this.d_level * 30).toString() + 'px;\'" ';
      html += '<template v-for="(item' + this.d_level.toString() + ', item_index' + this.d_level.toString() + ') in ' + this.f_treeListData() + '">';
      html += '<b-card no-body ' + it_style + ' @drop="f_onDropRule($event, [' + this.f_itemIndexList() + '], item' + this.d_level.toString() + ')" @dragover.prevent @dragenter.prevent>';
      html += '   <b-row style="margin: 0px; min-height: 20px;">';
      html += '      <b-col sm="12" md="10">';
      html += '         <b-button variant="white" style="padding: 0px;" @click="f_selectItem([' + this.f_itemIndexList() + '], item' + this.d_level.toString() + ')"><i class="fa fa-star"></i></b-button>';
      html += '         <span :title="item' + this.d_level.toString() + '.label" style="font-size: 10px;"> {{ item' + this.d_level.toString() + '.label.length > 20 ? item' + this.d_level.toString() + '.label.substring(0,20) + "..." : item' + this.d_level.toString() + '.label }} </span>';
      html += '         <small>({{ item' + this.d_level.toString() + '.value }})</small>';
      html += '      </b-col>';
      html += '      <b-col sm="12" md="2">';
      html += '         <b-button v-if="item' + this.d_level.toString() + '.list.length === 0" variant="white" style="padding: 0px;" @click="f_deleteItem([' + this.f_itemIndexList() + '], item' + this.d_level.toString() + ')"><i class="fa fa-trash"></i></b-button>';
      html += '      </b-col>';
      html += '   </b-row>';
      html += '</b-card>';
      // html += '<img v-if="item' + this.d_level.toString() + '.list.length !== 0" :src="d_icons[\'931299\']" style="width: ' + (4 / this.d_level) + 'em; margin-left:' + (this.d_level * 30) + 'px;" />';
      html += '<img v-if="item' + this.d_level.toString() + '.list.length !== 0" :src="d_icons[\'931299\']" style="width: 0.7em; margin-left:' + (this.d_level * 30) + 'px;" />';
      html += '<div v-if="item' + this.d_level.toString() + '.list.length === 0" style="height:' + (2 / this.d_level) + 'em;"></div>'
      if (this.d_level <= this.d_levelCount) {
        html += this.f_createTree();
        this.d_level -= 1;
      }
      html += '</template>';
      return html;
    },
    f_deleteItem: function (index_list, item) {
      let list = this.f_getTreeLocation(index_list, 'list');
      list.splice(index_list[index_list.length - 1], 1);
      for (let i in this.p_treeListData.items) {
        if (this.p_treeListData.items[i].value === item.value) {
          this.$delete(this.p_treeListData.items[i], 'is_added');
          break;
        }
      }
      this.p_treeListData.items
      this.$forceUpdate();
    },
    f_selectItem: function (index_list, item) {
      this.d_selectedItem = { 'list': [item] };
      // this.render_template();
      this.$forceUpdate();
    },
    f_startDragRule: function (evt, item_index, type) {
      // console.log('item_index', item_index);
      /*
        if (!this.p_treeListData.items[item_index].is_added) {
        }
      */
      this.d_startFromDragType = type;
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('fromInd', item_index);
    },
    f_onDropRule: function (evt, index_list = [], item_data = '') {
      // console.log('index_list: ', index_list);
      // console.log('this.d_startFromDragTypeDate ', this.d_startFromDragTypeDate);
      let fromInd = evt.dataTransfer.getData('fromInd');
      console.log('fromInd', fromInd);
      if (fromInd !== undefined && this.p_treeListData.items[fromInd]) {
        this.p_treeListData.items[fromInd].is_added = 1;
        let item = { 'value': this.p_treeListData.items[fromInd].value, 'label': this.p_treeListData.items[fromInd].label };
        item.list = [];
        if (index_list.length === 0) {
          this.p_treeListData.list.push(item);
        } else {
          // let item_data = this.f_getTreeLocation(index_list, 'item_data');
          item_data.list.push(item);
        }
        // this.$forceUpdate();
        this.render_template();
        // this.render_template();
        // if (this.d_startFromDragTypeField === 'free_div') {}
        // this.f_controlCompileStrFirstError();
      }
    },
    f_getTreeLocation: function (item_index_list, type) {
      let listLoc = this.d_selectedItem.list;
      for (let i in item_index_list) {
        if (parseInt(i) !== item_index_list.length - 1) {
          listLoc = listLoc[item_index_list[i]].list;
          // console.log('listLoc: ', listLoc);
        } else {
          if (type === 'item_data') {
            return listLoc[item_index_list[i]];
          } else if (type === 'list') {
            // console.log('listLoc:', listLoc);
            return listLoc;
          }
        }
      }
      if (type === 'list') {
        return listLoc;
      } else if (type === 'item_data') {
        return this.d_selectedItem;
      }
    },
    f_calculateMaximumDeepLevelCount: function (list_data, result) {
      for (let i in list_data) {
        if (parseInt(i) === 0) {
          result.item_index.push(i);
        } else {
          result.item_index[result.item_index.length - 1] = i;
        }
        // console.log(result.item_index);
        if (result.item_index.length > result.max) {
          result.max = result.item_index.length;
        }
        list_data[i].index = result.item_index.join(',');
        if ('list' in list_data[i] && list_data[i].list.length > 0) {
          this.f_calculateMaximumDeepLevelCount(list_data[i].list, result);
          result.item_index.splice(result.item_index.length - 1, 1);
        }
      }
    },
    f_itemIndexList: function () {
      let str = '';
      for (let i = 1; i <= this.d_level; i++) {
        str += 'item_index' + i.toString();
        if (i !== this.d_level) {
          str += ', ';
        }
      }
      return str;
    },
    f_treeListData: function () {
      if (this.d_level === 1) {
        return 'd_selectedItem.list';
      } else {
        return 'item' + (this.d_level - 1).toString() + '.list';
      }
    }
  },
  watch: {
    'p_treeListData.list': function () {
      // this.render_template();
    }
  }
};

</script>

<style>
.normal-mode {}

.full-screen-mode {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

</style>

