<template>
  <div>
    <b-card no-body :class="[d_multiWdmrFullScreen ? 'full-screen-mode' : 'normal-mode']">
      <b-row>
        <b-col cols="12">
          <b-card class="card-accent-secondary" no-body>
            <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
              <b-row>
                <b-col v-if="!p_dmpWdmr" sm="12" md="7">
                  <wdmr-schema-multi :p_multi_wdmr_data="d_multiWdmrData" :p_patientData="patient"></wdmr-schema-multi>
                </b-col>
                <b-col sm="12" :md="p_dmpWdmr ? 12 : 5">
                  <b-button v-if="!p_dmpWdmr" class="pull-right" size="md" :title="$t('wdm16.11264')" variant="success" @click="f_saveMultiWdmrList()" style="margin-right: 5px;">
                    <!-- <img src="@/icon/2087726.png" style="width: 2.5em;" /> -->{{ $t('wdm16.4061') }}
                  </b-button>
                  <b-dropdown class="pull-right" variant="secondary" size="md" right style="margin-right: 5px;">
                    <template v-slot:button-content>
                      <img src="@/icon/688807.png" class="img-rounded img-responsive" style="width: 2.2em;" :title="$t('wdm16.11265')" />
                    </template>
                    <b-dropdown-item @click="f_multiWdmrButtonShow()">
                      <i class="fa fa-plus"></i> {{ $t('wdm16.11266') }}
                    </b-dropdown-item>
                    <b-dropdown-item @click="d_showMultiWdmrSettingsModal = true;">
                      <i class="fa fa-plus"></i> {{ $t('wdm16.11267') }}
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-button class="pull-right" :title="$t('wdm16.6589')" :variant="d_multiWdmrFullScreen ? 'warning' : 'secondary'" size="md" style="margin-right: 5px; padding: 0px;" @click="d_multiWdmrFullScreen ? d_multiWdmrFullScreen = false : d_multiWdmrFullScreen = true">
                    <img src="@/icon/629153.png" style="width: 2.5em;" />
                  </b-button>
                  <b-dropdown class="pull-right" variant="secondary" size="md" right style="margin-right: 5px;">
                    <template v-slot:button-content>
                      <img src="@/icon/2766751.png" class="img-rounded img-responsive" style="width: 2.2em;" :title="$t('wdm16.11268')" />
                    </template>
                    <b-dropdown-item v-for="(model, model_ind) in d_eligibleWdmList" :key="'wdm_multi_wdmr_' + model_ind" v-if="f_userHasPermissionToAddThisModel(model.value)" @click="f_addNewWisdomData(model.value, 'multi_wdmr')">
                      <i class="fa fa-plus"></i> {{ model.label }}
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-dropdown v-if="!p_dmpWdmr" class="pull-right" variant="secondary" size="md" right style="margin-right: 5px;">
                    <template v-slot:button-content>
                      <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 2.2em;" />
                    </template>
                    <template v-if="d_multiWdmrData.multi_wdmr_list.length > 0">
                      <b-dropdown-item @click="f_getLastRecordsRelatedWithThisSchema()">
                        <img src="@/icon/2087726.png" style="width: 2.5em;" /> {{ $t('wdm16.11269') }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="f_prepareSchemamWithNewWdmrs()">
                        <img src="@/icon/2087726.png" style="width: 2.5em;" /> {{ $t('wdm16.11270') }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="f_saveMultiWdmrList()">
                        <img src="@/icon/2087726.png" style="width: 2.5em;" /> {{ $t('wdm16.11264') }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="f_saveThisViewToThisMultiWdmrSchema()">
                        <img src="@/icon/1584256.png" style="width: 2.5em;" /> {{ $t('wdm16.11271') }}
                      </b-dropdown-item>
                    </template>
                  </b-dropdown>
                  <template v-if="d_multiWdmrData.multi_wdmr_list.length > 0">
                    <template v-if="d_multiWdmrData.wdmr_settings.header_button === 2">
                      <b-button class="pull-right" size="md" variant="secondary" style="margin-right: 5px; padding: 0px;" @click="f_multiWdmrModeChange()">
                        <img v-if="d_multiWdmrData.wdmr_settings.mode === 'basic'" src="@/icon/665778.png" :title="$t('wdm16.11272')" style="width: 2.5em;" />
                        <img v-else-if="d_multiWdmrData.wdmr_settings.mode === 'left_right'" src="@/icon/3037139.png" :title="$t('wdm16.11273')" style="width: 2.5em;" />
                        <img v-else-if="d_multiWdmrData.wdmr_settings.mode === 'up_down'" src="@/icon/719135.png" :title="$t('wdm16.11274')" style="width: 2.5em;" />
                      </b-button>
                      <template v-if="d_multiWdmrData.wdmr_settings.mode === 'basic'">
                        <b-button class="pull-right" size="md" variant="secondary" style="margin-right: 5px; padding: 0px" :title="$t('wdm16.11275')" @click="f_changeMultiWdmrPageDivide()">
                          <img src="@/icon/2349601.png" style="width: 2.5em;" /> {{ d_multiWdmrData.wdmr_settings.page_divide }}
                        </b-button>
                      </template>
                      <b-button class="pull-right" size="md" variant="secondary" :title="$t('wdm16.11276')" style="margin-right: 5px; padding: 0px" @click="f_changeWdmrSettingsHeaderMode()">
                        <img src="@/icon/1517831.png" style="width: 2.5em;" /> {{ d_multiWdmrData.wdmr_settings.wdmr_header_mode }}
                      </b-button>
                      <b-button v-if="!p_waiTalkAlgorithm" class="pull-right" size="md" style="margin-right: 5px; padding: 0px;" :variant="d_multiWdmrData.wdmr_settings.favorite_show_mode ? 'warning' : 'secondary'" @click="d_multiWdmrData.wdmr_settings.favorite_show_mode ? d_multiWdmrData.wdmr_settings.favorite_show_mode = 0 : d_multiWdmrData.wdmr_settings.favorite_show_mode = 1" :title="$t('wdm16.11277')">
                        <img src="@/icon/1869589.png" style="width: 2.5em;" />
                      </b-button>
                      <b-button class="pull-right" size="md" style="margin-right: 5px; padding: 0px;" variant="secondary" @click="f_changeAnatomyComponentMode()" :title="$t('wdm16.11278')">
                        <img src="@/icon/W3D_humanbody.png" style="width: 2.5em;" /> {{ d_multiWdmrData.wdmr_settings.anatomy_component.mode }}
                      </b-button>
                      <b-button class="pull-right" size="md" variant="secondary" :title="$t('wdm16.11279')" style="margin-right: 5px; padding: 0px" @click="f_anatomyGroupColumnDivide()">
                        <img src="@/icon/W3D_humanbody.png" style="width: 2.5em;" />
                        <img src="@/icon/2349601.png" style="width: 2.5em;" /> {{ d_multiWdmrData.wdmr_settings.anatomy_group.cols }}
                      </b-button>
                      <b-button class="pull-right" size="md" style="margin-right: 5px; padding: 0px;" :variant="d_multiWdmrData.wdmr_settings.anatomy_component.mode ? 'warning' : 'secondary'" @click="d_multiWdmrData.wdmr_settings.anatomy_component.mode ? d_multiWdmrData.wdmr_settings.anatomy_component.mode = 0 : d_multiWdmrData.wdmr_settings.anatomy_component.mode = 1" :title="$t('wdm16.11278')">
                        <img src="@/icon/W3D_humanbody.png" style="width: 2.5em;" />
                      </b-button>
                      <b-button class="pull-right" size="md" style="margin-right: 5px; padding: 0px;" :variant="d_multiWdmrData.wdmr_settings.wdmr_schema.wdm_anatomy.show ? 'warning' : 'secondary'" @click="d_multiWdmrData.wdmr_settings.wdmr_schema.wdm_anatomy.show ? d_multiWdmrData.wdmr_settings.wdmr_schema.wdm_anatomy.show = 0 : d_multiWdmrData.wdmr_settings.wdmr_schema.wdm_anatomy.show = 1" :title="$t('wdm16.11280')">
                        <img src="@/icon/641.png" style="width: 2.5em;" />
                      </b-button>
                      <b-button class="pull-right" size="md" style="margin-right: 5px; padding: 0px;" :variant="d_multiWdmrData.wdmr_settings.group.show ? 'warning' : 'secondary'" @click="d_multiWdmrData.wdmr_settings.group.show ? d_multiWdmrData.wdmr_settings.group.show = 0 : d_multiWdmrData.wdmr_settings.group.show = 1" :title="$t('wdm16.11281')">
                        <img src="@/icon/45774.png" style="width: 2.5em;" />
                      </b-button>
                      <b-button v-if="!p_dmpWdmr" class="pull-right" size="md" style="margin-right: 5px; padding: 0px;" :variant="d_multiWdmrData.wdmr_settings.wdmr_schema.wdm.show ? 'warning' : 'secondary'" @click="d_multiWdmrData.wdmr_settings.wdmr_schema.wdm.show ? d_multiWdmrData.wdmr_settings.wdmr_schema.wdm.show = 0 : d_multiWdmrData.wdmr_settings.wdmr_schema.wdm.show = 1" :title="$t('wdm16.11282')">
                        <img src="@/icon/3082142.png" style="width: 2.5em;" />
                      </b-button>
                      <b-button class="pull-right" size="md" style="margin-right: 5px; padding: 0px;" @click="f_changeGroupMode()" :title="$t('wdm16.11283')">
                        <img src="@/icon/969174.png" style="width: 2.5em;" /> {{ d_multiWdmrData.wdmr_settings.group.mode }}
                      </b-button>
                      <b-button class="pull-right" size="md" variant="secondary" :title="$t('wdm16.11284')" style="margin-right: 5px; padding: 0px" @click="f_groupColumnDivide()">
                        <img src="@/icon/2349601.png" style="width: 2.5em;" /> {{ d_multiWdmrData.wdmr_settings.column_settings.cols }}
                      </b-button>
                      <b-button v-if="!p_waiTalkAlgorithm" class="pull-right" size="md" style="margin-right: 5px; padding: 0px;" :variant="d_multiWdmrData.wdmr_settings.settings.show ? 'warning' : 'secondary'" @click="d_multiWdmrData.wdmr_settings.settings.show ? d_multiWdmrData.wdmr_settings.settings.show = 0 : d_multiWdmrData.wdmr_settings.settings.show = 1" :title="$t('wdm16.11285')">
                        <img src="@/icon/1584256.png" style="width: 2.5em;" />
                      </b-button>
                      <b-button class="pull-right" size="md" variant="secondary" :title="$t('wdm16.11286')" style="margin-right: 5px; padding: 0px" @click="f_changeWisdomParamInputMode()">
                        <img src="@/icon/2063326.png" style="width: 2.5em;" /> {{ d_multiWdmrData.wdmr_settings.wisdom_param.mode }}
                      </b-button>
                      <b-button v-if="!p_waiTalkAlgorithm && (d_multiWdmrData.wdmr_settings.wisdom_param.mode === 2 || d_multiWdmrData.wdmr_settings.wisdom_param.mode === 3)" class="pull-right" size="md" :variant="d_multiWdmrData.wdmr_settings.wisdom_param.settings ? 'warning' : 'secondary'" @click="d_multiWdmrData.wdmr_settings.wisdom_param.settings ? d_multiWdmrData.wdmr_settings.wisdom_param.settings = 0 : d_multiWdmrData.wdmr_settings.wisdom_param.settings = 1" :title="$t('wdm16.11287')" style="margin-right: 5px; padding: 0px">
                        <img src="@/icon/2063326.png" style="width: 2.5em;" />
                        <img src="@/icon/1584256.png" style="width: 2.5em;" />
                      </b-button>
                    </template>
                  </template>
                </b-col>
              </b-row>
            </b-card-header>
            <div v-if="d_multiWdmrData.multi_wdmr_list.length > 0" style="border-radius: 10px 0 0 10px; padding: 3px">
              <!-- SAMI -->
              <template v-if="d_multiWdmrData.wdmr_settings.mode === 'up_down'">
                <template v-for="(multi_wdmr, multi_wdmr_ind) in d_multiWdmrData.multi_wdmr_list">
                  <b-row :class="[d_selectedMultiWdmrIndex === multi_wdmr_ind ? 's_rowSelected' : 's_rowNotSelected']">
                    <b-col cols="1" style="padding: 1px;">
                      <b-button size="md" variant="danger" style="margin-right: 3px;" @click="f_deleteMultiWdmr(multi_wdmr_ind)"><i class="fa fa-trash"></i> </b-button>
                      <b-dropdown variant="primary" size="md" style="margin-right: 3px;">
                        <b-dropdown-item @click="f_listIndexOrder(d_multiWdmrData.multi_wdmr_list, multi_wdmr_ind, 'up')"><i class="fa fa-angle-up"></i>yukarı</b-dropdown-item>
                        <b-dropdown-item @click="f_listIndexOrder(d_multiWdmrData.multi_wdmr_list, multi_wdmr_ind, 'down')"><i class="fa fa-angle-down"></i>aşağı</b-dropdown-item>
                        <b-dropdown-item @click="f_listIndexOrder(d_multiWdmrData.multi_wdmr_list, multi_wdmr_ind, 'top')"><i class="fa fa-angle-double-up"></i>en üste</b-dropdown-item>
                        <b-dropdown-item @click="f_listIndexOrder(d_multiWdmrData.multi_wdmr_list, multi_wdmr_ind, 'bottom')"><i class="fa fa-angle-double-down"></i>en aşağı</b-dropdown-item>
                      </b-dropdown>
                    </b-col>
                    <b-col cols="2" style="padding: 1px;" @click="f_selectMultiWdmr(multi_wdmr_ind)">
                      <i :class="[d_selectedMultiWdmrIndex === multi_wdmr_ind ? 'fa fa-check' : 'fa fa-caret-right']"></i> {{ multi_wdmr.data_type_label }}
                      <template v-if="d_multiWdmrData.multi_wdmr_list[multi_wdmr_ind].option_data[multi_wdmr.data_type].date_ability === 'yes'">
                        <template v-if="multi_wdmr.wisdom_data.date_type === 'datetime'">
                          <datepicker :config="{ enableTime: true, minDate: d_multiWdmrData.multi_wdmr_list[multi_wdmr_ind].option_data[multi_wdmr.data_type].date_ability_limit === 'after' ? f_calculateDateLimit(multi_wdmr.wisdom_data.date_type) : '', maxDate: d_multiWdmrData.multi_wdmr_list[multi_wdmr_ind].option_data[multi_wdmr.data_type].date_ability_limit === 'before' ? f_calculateDateLimit(multi_wdmr.wisdom_data.date_type) : '', time_24hr: true}" class="form-control" v-model="multi_wdmr.wisdom_data.date"></datepicker>
                        </template>
                        <template v-else-if="multi_wdmr.wisdom_data.date_type === 'date'">
                          <b-form-input type="date" :min="d_multiWdmrData.multi_wdmr_list[multi_wdmr_ind].option_data[multi_wdmr.data_type].date_ability_limit === 'after' ? f_calculateDateLimit(multi_wdmr.wisdom_data.date_type) : ''" :max="d_multiWdmrData.multi_wdmr_list[multi_wdmr_ind].option_data[multi_wdmr.data_type].date_ability_limit === 'before' ? f_calculateDateLimit(multi_wdmr.wisdom_data.date_type) : ''" v-model="multi_wdmr.wisdom_data.date"></b-form-input>
                        </template>
                        <template v-else-if="multi_wdmr.wisdom_data.date_type === 'yearmonth'">
                          <input type="month" :min="d_multiWdmrData.multi_wdmr_list[multi_wdmr_ind].option_data[multi_wdmr.data_type].date_ability_limit === 'after' ? f_calculateDateLimit(multi_wdmr.wisdom_data.date_type) : '1000-01'" :max="d_multiWdmrData.multi_wdmr_list[multi_wdmr_ind].option_data[multi_wdmr.data_type].date_ability_limit === 'before' ? f_calculateDateLimit(multi_wdmr.wisdom_data.date_type) : '9999-12'" v-model="multi_wdmr.wisdom_data.date">
                        </template>
                        <template v-else-if="multi_wdmr.wisdom_data.date_type === 'year'">
                          <input type="number" :min="d_multiWdmrData.multi_wdmr_list[multi_wdmr_ind].option_data[multi_wdmr.data_type].date_ability_limit === 'after' ? f_calculateDateLimit(multi_wdmr.wisdom_data.date_type) : '1000'" :max="d_multiWdmrData.multi_wdmr_list[multi_wdmr_ind].option_data[multi_wdmr.data_type].date_ability_limit === 'before' ? f_calculateDateLimit(multi_wdmr.wisdom_data.date_type) : '9999'" step="1" v-model="multi_wdmr.wisdom_data.date">
                        </template>
                      </template>
                    </b-col>
                    <b-col cols="9" style="padding: 1px;">
                      <b-row>
                        <b-col cols="3" @click="f_selectMultiWdmr(multi_wdmr_ind)">Şablon Adı: </b-col>
                        <b-col cols="9">
                          <b-form-input v-model="multi_wdmr.schema_name" style="background-color: white;"></b-form-input>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </template>
              </template>
              <template v-if="d_multiWdmrData.wdmr_settings.mode === 'left_right'">
                <b-row>
                  <b-col cols="3" style="text-align: left;">
                    <b-button variant="outline" :style="f_calculateMultiWdmrButtonColor(d_selectedMultiWdmrIndex - 1)" v-if="d_selectedMultiWdmrIndex !== 0" @click="f_selectMultiWdmr(d_selectedMultiWdmrIndex - 1)">
                      <<< {{ ' ' + d_multiWdmrData.multi_wdmr_list[d_selectedMultiWdmrIndex - 1].data_type_label }} </b-button>
                  </b-col>
                  <b-col cols="6" style="text-align: center;">
                    <b-dropdown variant="outline" :style="f_calculateMultiWdmrButtonColor(d_selectedMultiWdmrIndex)" :text="d_multiWdmrData.multi_wdmr_list[d_selectedMultiWdmrIndex].data_type_label + ' --> ' + d_multiWdmrData.multi_wdmr_list[d_selectedMultiWdmrIndex].schema_name" right>
                      <template v-for="(multi_wdmr, multi_wdmr_ind) in d_multiWdmrData.multi_wdmr_list">
                        <b-dropdown-item :style="f_calculateMultiWdmrButtonColor(multi_wdmr_ind)" @click="f_selectMultiWdmr(multi_wdmr_ind)">
                          <!-- {{ multi_wdmr.data_type_label }} -->
                          {{ multi_wdmr.data_type_label }}
                        </b-dropdown-item>
                      </template>
                    </b-dropdown>
                  </b-col>
                  <b-col cols="3" style="text-align: right;">
                    <b-button variant="outline" :style="f_calculateMultiWdmrButtonColor(d_selectedMultiWdmrIndex + 1)" v-if="d_selectedMultiWdmrIndex < (d_multiWdmrData.multi_wdmr_list.length - 1)" @click="f_selectMultiWdmr(d_selectedMultiWdmrIndex + 1)">
                      {{ d_multiWdmrData.multi_wdmr_list[d_selectedMultiWdmrIndex + 1].data_type_label + '>>>'}}
                    </b-button>
                  </b-col>
                </b-row>
              </template>
              <template v-if="d_multiWdmrData.wdmr_settings.mode === 'basic'">
                <b-row style="margin: 0px;" v-if="d_showMultiWdmrBasicMode">
                  <template v-for="(multi_wdmr, multi_wdmr_ind) in d_multiWdmrData.multi_wdmr_list">
                    <b-col :cols="12 / d_multiWdmrData.wdmr_settings.page_divide" style="padding: 3px;">
                      <!-- First, we are controlling the edit or new wdmr -->
                      <template v-if="multi_wdmr.wisdom_data.id !== undefined && multi_wdmr.wisdom_data.id !== '' && multi_wdmr.wisdom_data.id !== 'new'">
                        <!-- EDITING WDMR -->
                        <!-- Here we understant, we have a wdmr that will be edited. Then we control whether this user can edit? -->
                        <template v-if="f_userHasPermissionToEditThisModel(multi_wdmr.wisdom_data.department, multi_wdmr.wisdom_data.type, multi_wdmr.wisdom_data.hospital_group, multi_wdmr.wisdom_data.hospital_id) && f_calculateWdm10And11And12Edit(multi_wdmr.wisdom_data) && f_checkWdmrWebServiceEditable(multi_wdmr.wisdom_data)">
                          <b-row style="margin: 0px;">
                            <b-col cols="12" style="padding-right: 3px;">
                              <b-dropdown class="pull-right" variant="secondary" size="sm" right style="margin-right: 5px;">
                                <template v-slot:button-content>
                                  <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 1em;" />
                                </template>
                                <template>
                                  <b-dropdown-item @click="f_addNewEmptyWdmr(multi_wdmr_ind)">
                                    <img src="@/icon/2766751.png" style="width: 2.5em;" /> {{ $t('wdm16.6637') }}
                                  </b-dropdown-item>
                                  <b-dropdown-item @click="f_deleteMultiWdmr(multi_wdmr_ind)">
                                    <img src="@/icon/2960590.png" style="width: 2.5em;" /> {{ $t('wdm16.4398') }}
                                  </b-dropdown-item>
                                </template>
                              </b-dropdown>
                            </b-col>
                          </b-row>
                          <wisdom-data-modal :key="'row_multi_wdmr_' + multi_wdmr_ind" :data="multi_wdmr.wisdom_data" :data_type="multi_wdmr.data_type" :option_data="multi_wdmr.option_data" :rule_engine="false" :p_waiTalkAlgorithm="p_waiTalkAlgorithm" :query_list="[]" :p_wdmrSettings="d_multiWdmrData.wdmr_settings" :p_multiWdmrIndex="multi_wdmr_ind" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
                        </template>
                        <template v-else>
                          <!-- If this user can not edit this wdmr -->
                          <wisdom-data-show :change="change" :view_mode="view_mode" :data="multi_wdmr.wisdom_data" :data_type="multi_wdmr.data_type" :option_data="multi_wdmr.option_data" :user_brief_list="user_brief_list[multi_wdmr.wisdom_data.reference_wdm.key]" :brief_mode="brief_mode"></wisdom-data-show>
                        </template>
                      </template>
                      <template v-else>
                        <!-- NEW WDMR -->
                        <!-- Here we understand that, this wdmr is will be added as new -->
                        <template v-if="f_userHasPermissionToAddThisModel(multi_wdmr.data_type)">
                          <!-- When a user has permission to add this model, we open here. -->
                          <b-row style="margin: 0px;">
                            <b-col cols="12" style="padding-right: 3px;">
                              <b-dropdown class="pull-right" variant="secondary" size="sm" right style="margin-right: 5px;">
                                <template v-slot:button-content>
                                  <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 1em;" />
                                </template>
                                <template>
                                  <b-dropdown-item @click="f_addNewEmptyWdmr(multi_wdmr_ind)">
                                    <img src="@/icon/2766751.png" style="width: 2.5em;" /> {{ $t('wdm16.6637') }}
                                  </b-dropdown-item>
                                  <b-dropdown-item @click="f_deleteMultiWdmr(multi_wdmr_ind)">
                                    <img src="@/icon/2960590.png" style="width: 2.5em;" /> {{ $t('wdm16.4398') }}
                                  </b-dropdown-item>
                                </template>
                              </b-dropdown>
                            </b-col>
                          </b-row>
                          <wisdom-data-modal :key="'row_multi_wdmr_' + multi_wdmr_ind" :data="multi_wdmr.wisdom_data" :data_type="multi_wdmr.data_type" :option_data="multi_wdmr.option_data" :rule_engine="false" :query_list="[]" :p_waiTalkAlgorithm="p_waiTalkAlgorithm" :p_wdmrSettings="d_multiWdmrData.wdmr_settings" :p_multiWdmrIndex="multi_wdmr_ind" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
                        </template>
                        <template v-else>
                          <!-- if the user does not have permission -->
                          <span :style="multi_wdmr.option_data[data_type] && multi_wdmr.option_data[data_type].color ? 'margin-left: 0px; padding: 3px; border-radius: 0px 3px 3px 0px; font-size: 10px; background-color: ' + multi_wdmr.option_data[data_type].color : 'margin-left: 0px; padding: 3px; border-radius: 0px 3px 3px 0px; font-size: 10px; background-color: #c8ced3;'">
                            {{ multi_wdmr.option_data[multi_wdmr.data_type] && multi_wdmr.option_data[multi_wdmr.data_type].name ? multi_wdmr.option_data[multi_wdmr.data_type].name.label : '' }} ( v{{ multi_wdmr.option_data[multi_wdmr.data_type] && multi_wdmr.option_data[multi_wdmr.data_type].version ? multi_wdmr.option_data[multi_wdmr.data_type].version : '' }} )
                          </span>
                          <br>
                          <span style="color: red;">
                            {{ $t('wdm16.11288') }}
                          </span>
                        </template>
                      </template>
                    </b-col>
                  </template>
                </b-row>
              </template>
            </div>
            <div v-else>
              <span>{{ $t('wdm16.11289') }}</span>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-card v-if="d_selectedMultiWdmrIndex !== '' && d_multiWdmrData.multi_wdmr_list[d_selectedMultiWdmrIndex]" no-body class="mb-1" header-tag="header">
            <b-row v-if="d_showMultiWdmrField">
              <b-col cols="12">
                <wisdom-data-modal :p_waiTalkAlgorithm="p_waiTalkAlgorithm" :data="d_multiWdmrData.multi_wdmr_list[d_selectedMultiWdmrIndex].wisdom_data" :data_type="d_multiWdmrData.multi_wdmr_list[d_selectedMultiWdmrIndex].data_type" :option_data="d_multiWdmrData.multi_wdmr_list[d_selectedMultiWdmrIndex].option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <modal v-if="d_showMultiWdmrSettingsModal" @close="d_showMultiWdmrSettingsModal = false" :width="'500'">
      <h3 slot="header">{{ $t('wdm16.11290') }}</h3>
      <div slot="body">
        <b-row>
          <b-col cols="2"></b-col>
          <b-col cols="10"></b-col>
        </b-row>
        <b-row v-if="!p_waiTalkAlgorithm && (d_multiWdmrData.wdmr_settings.wisdom_param.mode === 2 || d_multiWdmrData.wdmr_settings.wisdom_param.mode === 3)">
          <b-col cols="2">
            <b-button size="md" :variant="d_multiWdmrData.wdmr_settings.wisdom_param.settings ? 'warning' : 'secondary'" @click="d_multiWdmrData.wdmr_settings.wisdom_param.settings ? d_multiWdmrData.wdmr_settings.wisdom_param.settings = 0 : d_multiWdmrData.wdmr_settings.wisdom_param.settings = 1" :title="$t('wdm16.11287')" style="margin-right: 5px; padding: 0px">
              <img src="@/icon/2063326.png" style="width: 2.5em;" />
              <img src="@/icon/1584256.png" style="width: 2.5em;" />
            </b-button>
          </b-col>
          <b-col cols="10">{{ $t('wdm16.11287') }}</b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <b-button size="md" variant="secondary" :title="$t('wdm16.11286')" style="margin-right: 5px; padding: 0px" @click="f_changeWisdomParamInputMode()">
              <img src="@/icon/2063326.png" style="width: 2.5em;" />
            </b-button>
          </b-col>
          <b-col cols="10"> {{ $t('wdm16.11286') }}: {{ d_multiWdmrData.wdmr_settings.wisdom_param.mode }} </b-col>
        </b-row>
        <b-row v-if="!p_waiTalkAlgorithm">
          <b-col cols="2">
            <b-button size="md" style="margin-right: 5px; padding: 0px;" :variant="d_multiWdmrData.wdmr_settings.settings.show ? 'warning' : 'secondary'" @click="d_multiWdmrData.wdmr_settings.settings.show ? d_multiWdmrData.wdmr_settings.settings.show = 0 : d_multiWdmrData.wdmr_settings.settings.show = 1" :title="$t('wdm16.11285')">
              <img src="@/icon/1584256.png" style="width: 2.5em;" />
            </b-button>
          </b-col>
          <b-col cols="10">{{ $t('wdm16.11285') }}</b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <b-button size="md" variant="secondary" :title="$t('wdm16.11284')" style="margin-right: 5px; padding: 0px" @click="f_groupColumnDivide()">
              <img src="@/icon/2349601.png" style="width: 2.5em;" />
            </b-button>
          </b-col>
          <b-col cols="10">{{ $t('wdm16.11284') }}: {{ d_multiWdmrData.wdmr_settings.column_settings.cols }}</b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <b-button size="md" style="margin-right: 5px; padding: 0px;" @click="f_changeGroupMode()" :title="$t('wdm16.11283')">
              <img src="@/icon/969174.png" style="width: 2.5em;" />
            </b-button>
          </b-col>
          <b-col cols="10">
            {{ $t('wdm16.11283') }}: {{ d_multiWdmrData.wdmr_settings.group.mode }}
          </b-col>
        </b-row>
        <b-row v-if="!p_dmpWdmr">
          <b-col cols="2">
            <b-button size="md" style="margin-right: 5px; padding: 0px;" :variant="d_multiWdmrData.wdmr_settings.wdmr_schema.wdm.show ? 'warning' : 'secondary'" @click="d_multiWdmrData.wdmr_settings.wdmr_schema.wdm.show ? d_multiWdmrData.wdmr_settings.wdmr_schema.wdm.show = 0 : d_multiWdmrData.wdmr_settings.wdmr_schema.wdm.show = 1" :title="$t('wdm16.11282')">
              <img src="@/icon/3082142.png" style="width: 2.5em;" />
            </b-button>
          </b-col>
          <b-col cols="10">
            {{ $t('wdm16.11282') }}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <b-button size="md" style="margin-right: 5px; padding: 0px;" :variant="d_multiWdmrData.wdmr_settings.group.show ? 'warning' : 'secondary'" @click="d_multiWdmrData.wdmr_settings.group.show ? d_multiWdmrData.wdmr_settings.group.show = 0 : d_multiWdmrData.wdmr_settings.group.show = 1" :title="$t('wdm16.11281')">
              <img src="@/icon/45774.png" style="width: 2.5em;" />
            </b-button>
          </b-col>
          <b-col cols="10">
            {{ $t('wdm16.11281') }}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <b-button size="md" style="margin-right: 5px; padding: 0px;" :variant="d_multiWdmrData.wdmr_settings.wdmr_schema.wdm_anatomy.show ? 'warning' : 'secondary'" @click="d_multiWdmrData.wdmr_settings.wdmr_schema.wdm_anatomy.show ? d_multiWdmrData.wdmr_settings.wdmr_schema.wdm_anatomy.show = 0 : d_multiWdmrData.wdmr_settings.wdmr_schema.wdm_anatomy.show = 1" :title="$t('wdm16.11280')">
              <img src="@/icon/641.png" style="width: 2.5em;" />
            </b-button>
          </b-col>
          <b-col cols="10">
            {{ $t('wdm16.11280') }}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <b-button size="md" style="margin-right: 5px; padding: 0px;" :variant="d_multiWdmrData.wdmr_settings.anatomy_component.mode ? 'warning' : 'secondary'" @click="d_multiWdmrData.wdmr_settings.anatomy_component.mode ? d_multiWdmrData.wdmr_settings.anatomy_component.mode = 0 : d_multiWdmrData.wdmr_settings.anatomy_component.mode = 1" :title="$t('wdm16.11278')">
              <img src="@/icon/W3D_humanbody.png" style="width: 2.5em;" />
            </b-button>
          </b-col>
          <b-col cols="10">
            {{ $t('wdm16.11278') }}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <b-button size="md" variant="secondary" :title="$t('wdm16.11279')" style="margin-right: 5px; padding: 0px" @click="f_anatomyGroupColumnDivide()">
              <img src="@/icon/W3D_humanbody.png" style="height: 2.5em; width: 1.25em;" />
              <img src="@/icon/2349601.png" style="height: 2.5em; width: 1.25em;" />
            </b-button>
          </b-col>
          <b-col cols="10">
            {{ $t('wdm16.11279') }}: {{ d_multiWdmrData.wdmr_settings.anatomy_group.cols }}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <b-button size="md" style="margin-right: 5px; padding: 0px;" variant="secondary" @click="f_changeAnatomyComponentMode()" :title="$t('wdm16.11278')">
              <img src="@/icon/W3D_humanbody.png" style="width: 2.5em;" />
            </b-button>
          </b-col>
          <b-col cols="10">
            {{ $t('wdm16.11278') }}: {{ d_multiWdmrData.wdmr_settings.anatomy_component.mode }}
          </b-col>
        </b-row>
        <b-row v-if="!p_waiTalkAlgorithm">
          <b-col cols="2">
            <b-button size="md" style="margin-right: 5px; padding: 0px;" :variant="d_multiWdmrData.wdmr_settings.favorite_show_mode ? 'warning' : 'secondary'" @click="d_multiWdmrData.wdmr_settings.favorite_show_mode ? d_multiWdmrData.wdmr_settings.favorite_show_mode = 0 : d_multiWdmrData.wdmr_settings.favorite_show_mode = 1" :title="$t('wdm16.11277')">
              <img src="@/icon/1869589.png" style="width: 2.5em;" />
            </b-button>
          </b-col>
          <b-col cols="10">
            {{ $t('wdm16.11277') }}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <b-button size="md" variant="secondary" :title="$t('wdm16.11276')" style="margin-right: 5px; padding: 0px" @click="f_changeWdmrSettingsHeaderMode()">
              <img src="@/icon/1517831.png" style="width: 2.5em;" />
            </b-button>
          </b-col>
          <b-col cols="10">
            {{ $t('wdm16.11276') }}: {{ d_multiWdmrData.wdmr_settings.wdmr_header_mode }}
          </b-col>
        </b-row>
        <b-row v-if="d_multiWdmrData.wdmr_settings.mode === 'basic'">
          <b-col cols="2">
            <b-button size="md" variant="secondary" style="margin-right: 5px; padding: 0px" :title="$t('wdm16.11275')" @click="f_changeMultiWdmrPageDivide()">
              <img src="@/icon/2349601.png" style="width: 2.5em;" />
            </b-button>
          </b-col>
          <b-col cols="10">
            {{ $t('wdm16.11275') }}: {{ d_multiWdmrData.wdmr_settings.page_divide }}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <b-button size="md" variant="secondary" style="margin-right: 5px; padding: 0px;" @click="f_multiWdmrModeChange()">
              <img v-if="d_multiWdmrData.wdmr_settings.mode === 'basic'" src="@/icon/665778.png" :title="$t('wdm16.11272')" style="width: 2.5em;" />
              <img v-else-if="d_multiWdmrData.wdmr_settings.mode === 'left_right'" src="@/icon/3037139.png" :title="$t('wdm16.11273')" style="width: 2.5em;" />
              <img v-else-if="d_multiWdmrData.wdmr_settings.mode === 'up_down'" src="@/icon/719135.png" :title="$t('wdm16.11274')" style="width: 2.5em;" />
            </b-button>
          </b-col>
          <b-col cols="10">
            <span v-if="d_multiWdmrData.wdmr_settings.mode === 'basic'">{{ $t('wdm16.11272') }}</span>
            <span v-else-if="d_multiWdmrData.wdmr_settings.mode === 'left_right'">{{ $t('wdm16.11273') }}</span>
            <span v-else-if="d_multiWdmrData.wdmr_settings.mode === 'up_down'">{{ $t('wdm16.11274') }}</span>
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="add_new_copy_parameter()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_showMultiWdmrSettingsModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment";
import WisdomDataService from "@/services/wisdom_data";
import OptionService from "@/services/option";
import {
  default as WdmrSchemaMulti
} from '@/components/widgets/WdmrSchemaMulti';
/*import {
  default as ParameterDesign
} from '@/components/widgets/ParameterDesign';
*/
import {
  default as Modal
} from '@/components/widgets/Modal';
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import { ClsWdm } from "@/services/public/wdm";
import UserService from "@/services/users";
import { ClsUserSettings } from '@/services/public/user';
import { mapGetters } from 'vuex';
import vSelect from "vue-select";
import SchemaService from "@/services/schema";
import GlobalService from '@/services/global';
import { ClsWdmrForever } from "@/services/public/wdm";

export default {
  name: 'MultiWdmr',
  computed: {
    ...mapGetters({
      lang: 'lang',
      help: 'help',
      device: 'device'
    })
  },
  props: {
    p_waiTalkAlgorithm: {
      type: Boolean,
      required: false
    },
    p_eligibleWdmList: {
      type: Array,
      required: false
    },
    p_himsProtocolList: {
      type: Object,
      required: false
    },
    patient: {
      type: Object,
      required: false
    },
    p_screenModeWdmrSettings: {
      type: Object,
      required: false
    },
    p_multiWdmrData: {
      type: Object,
      required: false
    },
    p_dmpWdmr: {
      type: Object,
      required: false
    }
  },
  components: {
    WdmrSchemaMulti,
    vSelect,
    Modal,
    WisdomDataModal
    // ParameterDesign
  },
  data () {
    return {
      d_selectedAnatomySchemaList: [],
      d_eligibleWdmList: [],
      d_showMultiWdmrSettingsModal: false,
      d_showMultiWdmrBasicMode: true,
      d_showMultiWdmrField: true,
      d_selectedMultiWdmrIndex: '',
      user: '',
      d_multiWdmrFullScreen: false,
      d_multiWdmrData: {
        'wdmr_settings': {
          'wisdom_param': {
            'settings': 0,
            'mode': 1, // 1 normal, 2 label value mode up down parameter, 3 label value left to right,
            'user_style': 0,
            'style': {
              'caption': {
                'fontSize': 12,
                'color': 'black',
                'after': '',
                'backgroundColor': 'white',
                'border': {
                  'type': 'solid',
                  'size': 0,
                  'color': 'black'
                },
                'borderRadius': 0
              },
              'value': {
                'fontSize': 10,
                'color': 'black',
                'after': '',
                'backgroundColor': 'white',
                'border': {
                  'type': 'solid',
                  'size': 0,
                  'color': 'black'
                },
                'borderRadius': 0
              }
            }
          },
          'wdm_designer': {
            'show': 0,
            'settings': 0,
            'table': []
          },
          'right_page_mode': {
            'page': ''
          },
          'column_settings': {
            'cols': 1
          },
          'wdmr_schema': {
            'wdm_anatomy': {
              'show': 0,
              'view_mode': 'basic'
            },
            'wdm': {
              'show': 0,
              'view_mode': 'basic'
            }
          },
          'multi_wdmr_schema': {
            'schema_view_mode': 'basic'
          },
          'header_button': 1,
          'mode': 'basic',
          'bmi_gfr_widget': {
            'show_mode': 'left_right'
          },
          'page_divide': 1,
          'settings': {
            'show': 0
          },
          'anatomy_component': {
            'mode': 1
          },
          'anatomy_group': {
            'cols': 1
          },
          'favorite_show_mode': 0,
          'group': {
            'show': 1,
            'mode': 2
          },
          'date': {
            'show_as_row': 0
          },
          'wdmr_header_mode': 3, // 1 or 2 or 3 or 4
          'document_upload': {
            'show': 0
          }
        },
        'multi_wdmr_list': [],
        'selected_multi_wdmr': '',
        'selected_schema_change': 0
      }
    };
  },
  created () {
    if (this.d_multiWdmrData.multi_wdmr_list.length > 10) {
      this.d_multiWdmrData.wdmr_settings.mode = 'up_down';
    }
    if (this.p_eligibleWdmList !== undefined) {
      this.d_eligibleWdmList = this.p_eligibleWdmList;
    }
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.p_screenModeWdmrSettings && Object.keys(this.p_screenModeWdmrSettings).length > 0) {
      this.d_multiWdmrData.wdmr_settings = this.p_screenModeWdmrSettings;
    }
    if (this.p_multiWdmrData) {
      this.d_multiWdmrData = this.p_multiWdmrData;
    }
    if (this.device.isMobile) {
      this.d_multiWdmrData.wdmr_settings.page_divide = 1;
    }
    if (this.p_dmpWdmr) {
      this.f_createEligibleWdmListOfDMP();
    }
  },
  mounted () {},
  methods: {
    f_createEligibleWdmListOfDMP: function () {
      // this.d_multiWdmrData.multi_wdmr_list = [];
      this.d_eligibleWdmList = [];
      let data = {};
      if (this.p_dmpWdmr.data.general.wdm && this.p_dmpWdmr.data.general.wdm.list) {
        for (let i in this.p_dmpWdmr.data.general.wdm.list) {
          let buck = this.p_dmpWdmr.data.general.wdm.list[i].wdm_group.val.value === 'wisdom' ? 'option' : 'hospital';
          if (!data[buck]) {
            data[buck] = { 'keys': [] };
          }
          data[buck].keys.push(this.p_dmpWdmr.data.general.wdm.list[i].wdm_version.val.value);
        }
        if (Object.keys(data).length > 0) {
          data.active_lang = localStorage.getItem('active_lang');
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.13273') } });
          OptionService.get_multi_wdmr_wdm_list(data)
            .then(resp => {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              if (resp.data.status === 'success') {
                let wdm_list = resp.data.result;
                for (let wdm_key in wdm_list) {
                  let option_data = wdm_list[wdm_key];
                  let wdm_info = {
                    'record_count': option_data.record_count,
                    'label': option_data.name.label,
                    'value': option_data.value
                  };
                  this.d_eligibleWdmList.push(wdm_info);
                  this.f_addDmpWisdomData(option_data, wdm_key)
                }
              } else {

              }
            });
        }
      }
    },
    f_addDmpWisdomData: function (option_data, wdm_key) {
      let data_type = option_data.value;
      let det = false;
      for (let i in this.d_multiWdmrData.multi_wdmr_list) {
        if (this.d_multiWdmrData.multi_wdmr_list[i].data_type === data_type) {
          det = true;
          break;
        }
      }
      if (!det) {
        let pre_data = {};
        if (option_data.record_count === 'many_times') {
          pre_data = { 'data': {}, 'date': '', 'type': data_type, 'reference_wdm': {} };
        } else {
          pre_data = { 'data': {}, 'type': data_type, 'reference_wdm': {} };
        }
        for (let i in option_data.param_group.param_order) {
          pre_data['data'][option_data.param_group.param_order[i]] = {};
        }
        pre_data['reference_wdm']['version'] = option_data.version;
        pre_data['reference_wdm']['owner_type'] = option_data['owner_type'];
        pre_data['reference_wdm']['key'] = wdm_key;
        let multi_wdmr_data = {
          'option_data': {},
          'data_type': data_type,
          'data_type_label': option_data.name.label,
          'wisdom_data': pre_data,
          'dr_wisdom': '',
          'schema_name': '',
          'schema_key': ''
        };
        multi_wdmr_data.option_data[data_type] = option_data;
        this.d_multiWdmrData.multi_wdmr_list.push(multi_wdmr_data);
      }
    },
    f_listIndexOrder: function (list, index, direction) {
      // console.log('list:', list, 'index:', index, 'direction:', direction);
      let index_data = list[index];
      let count = list.length;
      let friend = '';
      if (direction === 'up') {
        if (index !== 0) {
          friend = list[index - 1];
          list[index - 1] = index_data;
          list[index] = friend;
        }
      } else if (direction === 'down') {
        if (index !== count - 1) {
          friend = list[index + 1];
          list[index + 1] = index_data;
          list[index] = friend;
        }
      } else if (direction === 'top') {
        list.splice(index, 1);
        list.splice(0, 0, index_data);
      } else if (direction === 'bottom') {
        list.splice(index, 1);
        list.push(index_data);
      }
      this.$forceUpdate();
    },
    f_calculateDateLimit: function (date_type) {
      let today = new Date();
      if (date_type === 'datetime') {
        return moment(today).format("YYYY-MM-DD HH:mm");
      } else if (date_type === 'date') {
        return moment(today).format("YYYY-MM-DD");
      } else if (date_type === 'yearmonth') {
        return moment(today).format("YYYY-MM");
      } else if (date_type === 'year') {
        return moment(today).format("YYYY");
      }
    },
    f_checkWdmrWebServiceEditable: function (wdmr_data) {
      if (wdmr_data['type'] === 'medical_examination' && wdmr_data['status'] === 'webservice') {
        return false;
      }
      return true;
    },
    f_calculateWdm10And11And12Edit: function (wdmr_data) {
      if (wdmr_data['type'] === 'wdm10') {
        try {
          if (wdmr_data['data']['status']['status']['val']['value'] === 'active') {
            return false;
          }
        } catch (err) {
          return true;
        }
      }
      if (wdmr_data['type'] === 'wdm12') {
        return false;
      }
      if (wdmr_data['type'] === 'wdm11') {
        if (wdmr_data.data && wdmr_data.data.approval && wdmr_data.data.approval.doctor && wdmr_data.data.approval.doctor.val && wdmr_data.data.approval.doctor.val.value) {
          if (wdmr_data['data']['general']['model']['val']['value'] === 'intermittently_followup' && ['doctor_3', 'doctor_4', 'doctor_7'].indexOf(wdmr_data.data.approval.doctor.val.value) !== -1) {
            return true;
          }
        }
        return false;
      }
      return true;
    },
    f_userHasPermissionToEditThisModel: function (department_value, data_type, hospital_group, hospital_id) {
      if (this.p_dmpWdmr !== undefined) {
        return true;
      } else {
        if (this.user.hospital_group !== hospital_group || this.user.hospital_id !== hospital_id) {
          return false;
        }
        if (this.user.permissions_result && (this.user.permissions_result.indexOf('wisdom') !== -1 || this.user.permissions_result.indexOf('administrator') !== -1 || this.user.permissions_result.indexOf('administrator_global-wdmr-edit') !== -1)) {
          return true;
        }
        for (let i in this.user.position_department_title) {
          if (this.user.position_department_title[i].active && this.user.position_department_title[i].department.value === department_value && this.user.position_department_title[i].wisdom_data_model && this.user.position_department_title[i].wisdom_data_model[data_type] && this.user.position_department_title[i].wisdom_data_model[data_type].permission && this.user.position_department_title[i].wisdom_data_model[data_type].permission.indexOf('record_add_edit') !== -1) {
            return true;
          }
        }
        return false;
      }
    },
    f_userHasPermissionToAddThisModel: function (data_type) {
      if (this.p_dmpWdmr !== undefined) {
        return true;
      } else {
        if (!this.patient.hospitals || !this.patient.hospitals[this.user.hospital_group] || (this.patient.hospitals[this.user.hospital_group] && !this.patient.hospitals[this.user.hospital_group][this.user.hospital_id])) {
          return false;
        }
        if (this.user.permissions_result && this.user.permissions_result.indexOf('administrator') !== -1) {
          return true;
        }
        for (let i in this.user.position_department_title) {
          if (this.user.position_department_title[i].active && this.user.position_department_title[i].wisdom_data_model && this.user.position_department_title[i].wisdom_data_model[data_type] && this.user.position_department_title[i].wisdom_data_model[data_type].permission && this.user.position_department_title[i].wisdom_data_model[data_type].permission.indexOf('record_add_edit') !== -1) {
            return true;
          }
        }
        return false;
      }
    },
    f_deleteMultiWdmr: function (multi_wdmr_ind) {
      this.d_multiWdmrData.multi_wdmr_list.splice(multi_wdmr_ind, 1);
      this.d_selectedMultiWdmrIndex = '';
      this.f_showMultiWdmrBasicMode();
      this.$forceUpdate();
    },
    f_calculateMultiWdmrButtonColor: function (source_index) {
      let color = '#c8ced3';
      if (this.d_multiWdmrData.multi_wdmr_list[source_index].option_data[this.d_multiWdmrData.multi_wdmr_list[source_index].data_type].color) {
        color = this.d_multiWdmrData.multi_wdmr_list[source_index].option_data[this.d_multiWdmrData.multi_wdmr_list[source_index].data_type].color;
      }
      return 'background-color:' + color;
    },
    f_selectMultiWdmr: function (model_ind) {
      this.d_showMultiWdmrField = false;
      this.d_selectedMultiWdmrIndex = model_ind;
      this.$forceUpdate();
      setTimeout(function () {
          if (this.d_selectedMultiWdmrIndex === model_ind) {
            this.d_showMultiWdmrField = true;
          }
          this.$forceUpdate();
        }.bind(this), 1000)
        // this.f_showTrueFalseMultiWdmrField();
    },
    f_showMultiWdmrBasicMode: function (model_ind) {
      this.d_showMultiWdmrBasicMode = false;
      setTimeout(function () {
          this.d_showMultiWdmrBasicMode = true;
          this.$forceUpdate();
        }.bind(this), 200)
        // this.f_showTrueFalseMultiWdmrField();
    },
    f_groupColumnDivide: function () {
      if (this.d_multiWdmrData.wdmr_settings.column_settings.cols === 1) {
        this.d_multiWdmrData.wdmr_settings.column_settings.cols = 2;
      } else if (this.d_multiWdmrData.wdmr_settings.column_settings.cols === 2) {
        this.d_multiWdmrData.wdmr_settings.column_settings.cols = 3;
      } else if (this.d_multiWdmrData.wdmr_settings.column_settings.cols === 3) {
        this.d_multiWdmrData.wdmr_settings.column_settings.cols = 4;
      } else if (this.d_multiWdmrData.wdmr_settings.column_settings.cols === 4) {
        this.d_multiWdmrData.wdmr_settings.column_settings.cols = 6;
      } else if (this.d_multiWdmrData.wdmr_settings.column_settings.cols === 6) {
        this.d_multiWdmrData.wdmr_settings.column_settings.cols = 12;
      } else if (this.d_multiWdmrData.wdmr_settings.column_settings.cols === 12) {
        this.d_multiWdmrData.wdmr_settings.column_settings.cols = 1;
      }
      this.$forceUpdate();
    },
    f_anatomyGroupColumnDivide: function () {
      if (this.d_multiWdmrData.wdmr_settings.anatomy_group.cols === 1) {
        this.d_multiWdmrData.wdmr_settings.anatomy_group.cols = 2;
      } else if (this.d_multiWdmrData.wdmr_settings.anatomy_group.cols === 2) {
        this.d_multiWdmrData.wdmr_settings.anatomy_group.cols = 3;
      } else if (this.d_multiWdmrData.wdmr_settings.anatomy_group.cols === 3) {
        this.d_multiWdmrData.wdmr_settings.anatomy_group.cols = 4;
      } else if (this.d_multiWdmrData.wdmr_settings.anatomy_group.cols === 4) {
        this.d_multiWdmrData.wdmr_settings.anatomy_group.cols = 6;
      } else if (this.d_multiWdmrData.wdmr_settings.anatomy_group.cols === 6) {
        this.d_multiWdmrData.wdmr_settings.anatomy_group.cols = 12;
      } else if (this.d_multiWdmrData.wdmr_settings.anatomy_group.cols === 12) {
        this.d_multiWdmrData.wdmr_settings.anatomy_group.cols = 1;
      }
      this.$forceUpdate();
    },
    f_changeAnatomyComponentMode: function () {
      if (this.d_multiWdmrData.wdmr_settings.anatomy_component.mode === 1) {
        this.d_multiWdmrData.wdmr_settings.anatomy_component.mode = 2;
      } else if (this.d_multiWdmrData.wdmr_settings.anatomy_component.mode === 2) {
        this.d_multiWdmrData.wdmr_settings.anatomy_component.mode = 3;
      } else if (this.d_multiWdmrData.wdmr_settings.anatomy_component.mode === 3) {
        this.d_multiWdmrData.wdmr_settings.anatomy_component.mode = 1;
      }
      this.$forceUpdate();
    },
    f_addNewEmptyWdmr: function (wdmr_index) {
      let multi_wdmr_list = JSON.parse(JSON.stringify(this.d_multiWdmrData.multi_wdmr_list));
      let new_empty_wdmr = JSON.parse(JSON.stringify(this.d_multiWdmrData.multi_wdmr_list[wdmr_index]));
      this.d_multiWdmrData.multi_wdmr_list = [];
      setTimeout(function () {
        let pre_data = {};
        if (new_empty_wdmr.wisdom_data.date !== undefined) {
          pre_data = {
            'data': {},
            'date': '',
            'type': new_empty_wdmr.data_type,
            'reference_wdm': new_empty_wdmr.wisdom_data.reference_wdm
          };
        } else {
          pre_data = {
            'data': {},
            'type': new_empty_wdmr.data_type,
            'reference_wdm': new_empty_wdmr.wisdom_data.reference_wdm
          };
        }
        let option_data = new_empty_wdmr.option_data[new_empty_wdmr.data_type];
        for (let i in option_data.param_group.param_order) {
          pre_data['data'][option_data.param_group.param_order[i]] = {};
        }
        new_empty_wdmr.wisdom_data = pre_data;
        if (wdmr_index === this.d_multiWdmrData.multi_wdmr_list.length - 1) {
          multi_wdmr_list.push(new_empty_wdmr);
        } else {
          multi_wdmr_list.splice(wdmr_index + 1, 0, new_empty_wdmr);
        }
        this.d_multiWdmrData.multi_wdmr_list = multi_wdmr_list;
        this.f_showMultiWdmrBasicMode();
      }.bind(this), 100);
    },
    f_changeWisdomParamInputMode: function () {
      if (this.d_multiWdmrData.wdmr_settings.wisdom_param.mode === 1) {
        this.d_multiWdmrData.wdmr_settings.wisdom_param.mode = 2;
      } else if (this.d_multiWdmrData.wdmr_settings.wisdom_param.mode === 2) {
        this.d_multiWdmrData.wdmr_settings.wisdom_param.mode = 3;
      } else if (this.d_multiWdmrData.wdmr_settings.wisdom_param.mode === 3) {
        this.d_multiWdmrData.wdmr_settings.wisdom_param.mode = 1;
      }
      this.$forceUpdate();
    },
    f_prepareSchemamWithNewWdmrs: function () {
      let multi_wdmr_list = JSON.parse(JSON.stringify(this.d_multiWdmrData.multi_wdmr_list));
      this.d_multiWdmrData.multi_wdmr_list = [];
      setTimeout(function () {
        for (let i in multi_wdmr_list) {
          let pre_data = {};
          let data_record_count_type = '';
          if (multi_wdmr_list[i].wisdom_data.date !== undefined) {
            pre_data = {
              'data': {},
              'date': '',
              'type': multi_wdmr_list[i].data_type,
              'reference_wdm': multi_wdmr_list[i].wisdom_data.reference_wdm
            };
          } else {
            pre_data = {
              'data': {},
              'type': multi_wdmr_list[i].data_type,
              'reference_wdm': multi_wdmr_list[i].wisdom_data.reference_wdm
            };
          }
          let option_data = multi_wdmr_list[i].option_data[multi_wdmr_list[i].data_type];
          for (let i in option_data.param_group.param_order) {
            pre_data['data'][option_data.param_group.param_order[i]] = {};
          }
          multi_wdmr_list[i].wisdom_data = pre_data;
        }
        this.d_multiWdmrData.multi_wdmr_list = multi_wdmr_list;
        this.$forceUpdate();
      }.bind(this), 100);
    },
    f_changeGroupMode: function () {
      if (this.d_multiWdmrData.wdmr_settings.group.mode === 1) {
        this.d_multiWdmrData.wdmr_settings.group.mode = 2;
      } else if (this.d_multiWdmrData.wdmr_settings.group.mode === 2) {
        this.d_multiWdmrData.wdmr_settings.group.mode = 3;
      } else if (this.d_multiWdmrData.wdmr_settings.group.mode === 3) {
        this.d_multiWdmrData.wdmr_settings.group.mode = 1;
      }
      this.$forceUpdate();
    },
    f_saveThisViewToThisMultiWdmrSchema: function () {
      let user_data = JSON.parse(localStorage.getItem('user'));
      ClsUserSettings.free_structure(user_data);
      let schema_data = this.d_multiWdmrData.wdmr_settings.multi_wdmr_schema.d_selectedSchema;
      user_data.settings.wdmr_record.settings.multi_wdmr_schema.settings[schema_data.value] = {
        'schema': schema_data,
        'wdmr_settings': this.d_multiWdmrData.wdmr_settings
      };
      let data = {
        'schema': schema_data,
        'wdmr_settings': this.d_multiWdmrData.wdmr_settings
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Form görünümünüz güncelleniyor. Lütfen bekleyiniz.' } });
      UserService.update_multi_wdmr_schema_settings(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            alert(this.$t('wdm16.12713'));
            localStorage.setItem('user', JSON.stringify(user_data));
          } else {
            alert(this.$t('wdm16.12714'), resp.data.message);
          }
        });
      // localStorage.user = JSON.stringify(user_data);
    },
    f_getLastRecordsRelatedWithThisSchema: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Forma ait son kayıtlı veriler getiriliyor. Lütfen bekleyiniz.' } });
      let data = {
        'patient_id': this.patient.id,
        'multi_wdmr_list': this.d_multiWdmrData.multi_wdmr_list
      };
      WisdomDataService.get_last_records_related_with_this_schema(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_multiWdmrData.multi_wdmr_list = [];
            setTimeout(function () {
              this.d_multiWdmrData.multi_wdmr_list = resp.data.result.multi_wdmr_list;
            }.bind(this), 100);
            this.$forceUpdate();
            this.$store.commit('Loading', { 'status': false, 'data': {} });
          } else {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            alert('error:' + resp.data.message);
          }
        });
    },
    f_addNewWisdomData: function (data_type) {
      let pre_data = {};
      let data_record_count_type = '';
      for (let i in this.d_eligibleWdmList) {
        if (this.d_eligibleWdmList[i].value === data_type) {
          data_record_count_type = this.d_eligibleWdmList[i].record_count;
          break;
        }
      }
      if (data_record_count_type === 'many_times') {
        pre_data = { 'data': {}, 'date': '', 'type': data_type, 'reference_wdm': {} };
      } else {
        pre_data = { 'data': {}, 'type': data_type, 'reference_wdm': {} };
      }
      let wdm_version = JSON.parse(localStorage.getItem('wdm_version'));
      let doc_key = '';
      let doc_version = '';
      let bucket = '';
      let data_type_label = '';
      if (wdm_version.wisdom.all[data_type]) {
        data_type_label = wdm_version.wisdom.all[data_type].name.label;
      }
      if (wdm_version.user.all[data_type]) {
        data_type_label = wdm_version.user.all[data_type].name.label;
      }
      if (wdm_version.hospital.all[data_type]) {
        data_type_label = wdm_version.hospital.all[data_type].name.label;
      }
      if (wdm_version.wisdom.last[data_type]) {
        doc_key = wdm_version.wisdom.last[data_type].key;
        doc_version = wdm_version.wisdom.last[data_type].version;
        bucket = 'option';
      }
      if (wdm_version.user.last[data_type]) {
        doc_key = wdm_version.user.last[data_type].key;
        doc_version = wdm_version.user.last[data_type].version;
        bucket = 'hospital';
      }
      // If data_type defined in also hospital it will be selected. Then this below code added to future.
      if (wdm_version.hospital.last[data_type]) {
        doc_key = wdm_version.hospital.last[data_type].key;
        doc_version = wdm_version.hospital.last[data_type].version;
        bucket = 'hospital';
      }
      GlobalService.get_one_cb_document({ 'key': doc_key, 'bucket': bucket })
        .then(resp => {
          if (resp.data.status === 'success') {
            let option_data = resp.data.result;
            for (let i in option_data.param_group.param_order) {
              pre_data['data'][option_data.param_group.param_order[i]] = {};
            }
            pre_data['reference_wdm']['version'] = doc_version;
            pre_data['reference_wdm']['owner_type'] = option_data['owner_type'];
            pre_data['reference_wdm']['key'] = doc_key;
            let multi_wdmr_data = {
              'option_data': {},
              'data_type': data_type,
              'data_type_label': data_type_label,
              'wisdom_data': pre_data,
              'dr_wisdom': '',
              'schema_name': '',
              'schema_key': ''
            };
            multi_wdmr_data.option_data[data_type] = option_data;
            this.d_multiWdmrData.multi_wdmr_list.push(multi_wdmr_data);
            this.$forceUpdate();
          } else {
            alert('error ', resp.data.message);
          }
        });
    },
    f_multiWdmrButtonShow: function () {
      if (this.d_multiWdmrData.wdmr_settings.header_button === 1) {
        this.d_multiWdmrData.wdmr_settings.header_button = 2;
      } else if (this.d_multiWdmrData.wdmr_settings.header_button === 2) {
        this.d_multiWdmrData.wdmr_settings.header_button = 1;
      }
      this.$forceUpdate();
    },
    f_changeWdmrSettingsHeaderMode: function () {
      if (this.d_multiWdmrData.wdmr_settings.wdmr_header_mode === 1) {
        this.d_multiWdmrData.wdmr_settings.wdmr_header_mode = 2;
      } else if (this.d_multiWdmrData.wdmr_settings.wdmr_header_mode === 2) {
        this.d_multiWdmrData.wdmr_settings.wdmr_header_mode = 3;
      } else if (this.d_multiWdmrData.wdmr_settings.wdmr_header_mode === 3) {
        this.d_multiWdmrData.wdmr_settings.wdmr_header_mode = 4;
      } else if (this.d_multiWdmrData.wdmr_settings.wdmr_header_mode === 4) {
        this.d_multiWdmrData.wdmr_settings.wdmr_header_mode = 1;
      }
      this.$forceUpdate();
    },
    f_changeMultiWdmrPageDivide: function () {
      if (this.d_multiWdmrData.wdmr_settings.page_divide === 2) {
        this.d_multiWdmrData.wdmr_settings.bmi_gfr_widget.show_mode = 'top_down';
        this.d_multiWdmrData.wdmr_settings.page_divide = 3;
      } else if (this.d_multiWdmrData.wdmr_settings.page_divide === 3) {
        this.d_multiWdmrData.wdmr_settings.bmi_gfr_widget.show_mode = 'top_down';
        this.d_multiWdmrData.wdmr_settings.page_divide = 4;
      } else if (this.d_multiWdmrData.wdmr_settings.page_divide === 4) {
        this.d_multiWdmrData.wdmr_settings.bmi_gfr_widget.show_mode = 'left_right';
        this.d_multiWdmrData.wdmr_settings.page_divide = 1;
      } else if (this.d_multiWdmrData.wdmr_settings.page_divide === 1) {
        this.d_multiWdmrData.wdmr_settings.bmi_gfr_widget.show_mode = 'top_down';
        this.d_multiWdmrData.wdmr_settings.page_divide = 2;
      }
      this.$forceUpdate();
    },
    f_multiWdmrModeChange: function () {
      if (this.d_multiWdmrData.wdmr_settings.mode === 'basic') {
        if (this.d_selectedMultiWdmrIndex === '') {
          this.f_selectMultiWdmr(0);
        }
        this.d_multiWdmrData.wdmr_settings.mode = 'left_right';
      } else if (this.d_multiWdmrData.wdmr_settings.mode === 'left_right') {
        this.d_multiWdmrData.wdmr_settings.mode = 'up_down';
      } else if (this.d_multiWdmrData.wdmr_settings.mode === 'up_down') {
        this.d_selectedMultiWdmrIndex = '';
        this.d_multiWdmrData.wdmr_settings.mode = 'basic';
      }
      this.$forceUpdate();
    },
    f_saveMultiWdmrList: function () {
      // First we control requirements and creating label for every wisdom_data
      let tmp_d_multiWdmr = JSON.parse(JSON.stringify(this.d_multiWdmrData.multi_wdmr_list));
      let any_param_field = false;
      /*
        We prepare an object. Its keys are the indexes of this.d_multiWdmrData.multi_wdmr_list. Values are the all data of its item data in multi_wdmr_list.
        save_elibile_wdmr_list = {
          '1': {},
          '3': {}
        }
      */
      let save_elibile_wdmr_list = {};
      for (let i in tmp_d_multiWdmr) {
        let x = tmp_d_multiWdmr[i];
        let wisdom_data = x['wisdom_data'];
        let return_data = ClsWdmrForever.turn(wisdom_data, x.option_data[x.data_type], ['require', 'name']);
        let wdmr_name = return_data.name;
        wisdom_data['label'] = wdmr_name;
        let wdmr_require_alerts = return_data.wdmr_require_alerts;
        let only_one_param_is_filled = return_data.only_one_param_is_filled;
        if (only_one_param_is_filled) {
          any_param_field = true;
        }
        if (x['wisdom_data'].id && x['wisdom_data'].id !== '' && x['wisdom_data'].id !== 'new') {
          x.save_ability = 1;
          any_param_field = true;
          save_elibile_wdmr_list[i] = x;
        } else {
          x['wisdom_data'] = JSON.parse(JSON.stringify(return_data.wdmr));
          if (only_one_param_is_filled && wdmr_require_alerts.length === 0) {
            x.save_ability = 1;
            save_elibile_wdmr_list[i] = x;
          } else {
            x.save_ability = 0;
          }
        }
        /*
          // çoklu formlarda bir parametrenin dolu olması ve zorunlu alan problemi olmaması durumunda kaydedilecek şeklinde bir parametre true olarak gönderiliyor
          if (!only_one_param_is_filled) {
            let modal_data = { 'type': 'alert', 'text': (parseInt(i) + 1).toString() + ' nolu veri modelini, ' + x.data_type_label + '\n, kaydedebilmek için en az bir veriniz dolu olmalıdır.', 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
            return;
          }
          if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
            let require_txt = '';
            for (let r in wdmr_require_alerts) {
              require_txt += ' * ' + wdmr_require_alerts[r].label;
            }
            let modal_data = { 'type': 'alert', 'text': (parseInt(i) + 1).toString() + ' nolu veri modeli, ' + x.data_type_label + ',\n' + require_txt, 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
            return;
          }
        */
      }
      // Bir wdmr kaydı yapabilmek için en az bir parametre dolu olması gerekiyor.
      if (!any_param_field) {
        let modal_data = { 'type': 'alert', 'text': 'Formu kaydedebilmek için en az bir modeldeki zorunlu alanlar dolu olmalıdır.', 'centered': true, 'title': 'Veri modeli kayıt işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      // this.d_multiWdmrData.multi_wdmr_list = JSON.parse(JSON.stringify(tmp_d_multiWdmr));
      let data = {
        'webservice': {
          'hims_protocol_list': this.p_himsProtocolList,
          'hospitalGroup': this.patient.hospital_group,
          'hospitalCode': this.patient.hospital_id
        },
        'save_elibile_wdmr_list': save_elibile_wdmr_list
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kayıt işlemi yapılıyor. Lütfen bekleyiniz.' } });
      WisdomDataService.save_multi_wdmr_list(this.patient.id, data).then(resp => {
        // console.log(resp.data);
        this.$store.commit('Loading', { 'status': false, 'data': {} });
        if (resp.data.status === "success") {
          // If we turn to wisdom data show mode we use this.f_turnWisdomDataShowMode(); function
          // this.f_turnWisdomDataShowMode(resp.data.result.wdmr_list);
          // If we want to be same view in edit mode, we use the below function.
          // We add response wdmr_list then, when we save it edits and updates saved wdmrs. It does not add new wdmrs.
          for (let i in resp.data.result.wdmr_list) {
            this.d_multiWdmrData.multi_wdmr_list[i] = resp.data.result.wdmr_list[i];
          }
          this.$forceUpdate();
        } else {
          alert('error: ', resp.data.message);
        }
      });
    },
    f_showTrueFalseMultiWdmrField: function () {
      this.d_showMultiWdmrField = false;
      setTimeout(function () {
        this.d_showMultiWdmrField = true;
        this.$forceUpdate();
      }.bind(this), 50)
    }
  },
  watch: {
    'd_multiWdmrData.selected_schema_change': function () {
      this.d_selectedMultiWdmrIndex = '';
      this.f_showTrueFalseMultiWdmrField();
    }
  }
};

</script>

<style type="text/css">


</style>

