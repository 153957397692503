<template>
  <div>
    <!-- 
      <div @click="d_showQuickIcon ? d_showQuickIcon = false : d_showQuickIcon = true" :class="[d_showQuickIcon ? 's_showQuickIcon' : 's_showQuickIconNot']">
        <i :class="['fa fa-2x', d_showQuickIcon ? 'fa-arrow-right' : 'fa-arrow-left']" style="margin-top: 22px; margin-left: 5px"></i>&nbsp;
      </div>
      <div class="animated fadeIn s_edict" v-if="d_showQuickIcon">
        <b-card no-body style="border: 0px; margin-bottom: 0px; border-radius: 0px 20px 20px 20px;" bg-variant="danger">
          <b-card-header style="background-color: red" class="p-1 text-center">
            <i class="fa fa-list-ol"></i> Hızlı Sayfa Geçişi
          </b-card-header>
          <router-link active :to="'/rule-engine/decision-making-packages'" :exact="true" style="color: white;">
            <b-row class="s_edictList">
              <b-col cols="12">
                <i class="fa fa-link"></i> Karar Destek Paketleri
              </b-col>
            </b-row>
          </router-link>
          <router-link :to="'/rule-engine/rule-test'" :exact="true" style="color: white;">
            <b-row class="s_edictList">
              <b-col cols="12">
                <i class="fa fa-link"></i> Kural Test Ekranı
              </b-col>
            </b-row>
          </router-link>
        </b-card>
      </div>
     -->
    <b-card no-body :style="'color:' + d_dmpSettings.style.color + '; background-color: ' + d_dmpSettings.style.backgroundColor" :class="[d_fullScreenGeneral ?'full-screen-mode' :'normal-mode']">
      <b-card-header class="p-1" :style="'color:' + d_dmpSettings.style.dmp_header.color + '; background-color: ' + d_dmpSettings.style.dmp_header.backgroundColor">
        <b-row>
          <!-- <b-col cols="1"> </b-col> -->
          <b-col cols="9">
            <img src="@/icon/2933568.png" style="width: 2em; margin-right: 5px; padding: 0px;" />
            <template v-if="d_selectedDmpWdmrData && d_selectedDmpWdmrData.id">
              <strong :title="d_selectedDmpWdmrData.label" style="margin-right: 10px;">{{ d_selectedDmpWdmrData.label }} </strong>
              <span style="color: red;">[{{ d_selectedDmpWdmrData.id }}] </span>,
              <i>{{ d_selectedDmpWdmrData.owner }} - </i>
              <i>{{ DateFormat(d_selectedDmpWdmrData.created_at) }}</i>
            </template>
          </b-col>
          <b-col cols="2" style="text-align: center;">
            <strong style="color: #50afc9; text-align: right"> Walgorithm <img src="@/icon/1430869.png" style="width: 2em;" /> </strong>
          </b-col>
          <b-col cols="1">
            <b-button class="pull-right" size="md" :variant="d_fullScreenGeneral ? 'warning' : 'white'" :style="d_fullScreenGeneral ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreenGeneral ? d_fullScreenGeneral = false : d_fullScreenGeneral = true">
              <img src="@/icon/629153.png" title="Tam ekran" style="width: 2em;" />
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <dmp-menu :p_dmpSettings="d_dmpSettings" :p_selectedDmpWdmrData="d_selectedDmpWdmrData" :f_addAlgorithmsFromExcel="f_addAlgorithmsFromExcel" :f_addNewWisdomData="f_addNewWisdomData" :f_editSelectedWdmr="f_editSelectedWdmr" :f_publishDmp="f_publishDmp" :f_getDmpRuleAlgorithmList="f_getDmpRuleAlgorithmList" :f_openDmpList="f_openDmpList" :f_deleteSelectedChildWdmr="f_deleteSelectedChildWdmr" :f_ruleEngineTest="f_ruleEngineTest" :f_analyzeDmpTest="f_analyzeDmpTest" :f_analyzeDmpApiServiceTest="f_analyzeDmpApiServiceTest" :f_copyRuleAlgorithm="f_copyRuleAlgorithm" :f_openRuleAlgoritm="f_openRuleAlgoritm" :f_drawAlgorithm="f_drawAlgorithm" :f_saveDmp="f_saveDmp" :f_copyDmp="f_copyDmp" :f_openGetNewModels="f_openGetNewModels" :f_openChatBotModal="f_openChatBotModal" :f_openChatBotDiagramModal="f_openChatBotDiagramModal" :f_goToChatBotIframe="f_goToChatBotIframe" :f_getChatbotIframeGlobalUrl="f_getChatbotIframeGlobalUrl" :f_openNewPageWithChatbotGlobalIframe="f_openNewPageWithChatbotGlobalIframe" :f_addEditApiResultDocument="f_addEditApiResultDocument"></dmp-menu>
      <b-tabs>
        <b-tab active @click="d_selectedTab = 'algorithmtab'">
          <template slot="title">
            <img src="@/icon/665778.png" style="width: 1.5em;">
            <span :style="'color:' + d_dmpSettings.style.tab_header.color">Algorithm</span>
          </template>
          <template v-if="d_selectedTab === 'algorithmtab'">
            <b-card no-body>
              <b-row>
                <b-col sm="12" md="4" v-if="d_dmpRulesList.list && d_dmpRulesList.list.length > 0">
                  <b-card no-body>
                    <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_dmpSettings.style.dmp_header.color + '; background-color: ' + d_dmpSettings.style.dmp_header.backgroundColor">
                      <strong :title="$t('wdm16.11146')">{{ $t('wdm16.11147') }} </strong>
                      <small> ( {{ $t('wdm16.11148') }} ) </small>
                      <b-button class="pull-right" variant="white" size="sm" @click="f_selectAllRules()">
                        <img src="@/icon/2147852.png" style="width: 2em;" />
                      </b-button>
                    </b-card-header>
                    <div style="overflow-y: auto; overflow-x: hidden; height: 350px;">
                      <template v-for="(x, x_ind) in d_dmpRulesList.list">
                        <b-card :style="d_dmpRulesList.selected.indexOf(x_ind) !== -1 ? 'margin-bottom: 3px; min-height: 30px; cursor: pointer; background: #e1f86b;' : 'margin-bottom: 3px; min-height: 30px; cursor: pointer;'" no-body>
                          <b-row style="margin: 0px;">
                            <b-col sm="12" md="10" @click="f_selectWdmr('dmp_rule', x_ind, x.key)">
                              <span :title="x.label">
                                {{ x_ind + 1 }} - {{ x.label.length > 50 ? x.label.substring(0,50) + '...' : x.label }}
                              </span>
                              <small style="color: #ff5722;">[ {{ x.key.split('_')[1] }} ]</small>
                            </b-col>
                            <b-col sm="12" md="2" style="text-align: right;" @click="f_getWdmrData('dmp_rule', x.key, 'wisdom'); d_selectedWdmrIndex_dmp_rule = x_ind;">
                              <template v-if="'rule_' + d_selectedRuleWdmrData.id === x.key">
                                <b-dropdown class="pull-right" variant="white" size="md" right style="margin-right: 5px;">
                                  <template v-slot:button-content>
                                    <img src="@/icon/2940638.png" class="img-rounded img-responsive" style="width: 1em;" />
                                  </template>
                                  <template>
                                    <b-dropdown-item @click="f_copyRuleAlgorithm()">
                                      <img src="@/icon/2176401.png" style="width: 2.5em;" /> {{ $t('wdm16.11149') }}
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="f_editSelectedWdmr('rule')">
                                      <img src="@/icon/2930471.png" style="width: 2.5em;" /> {{ $t('wdm16.3897') }}
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="f_deleteSelectedChildWdmr('rule')">
                                      <img src="@/icon/2960590.png" style="width: 2.5em;" /> {{ $t('wdm16.4398') }}
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="f_openRuleAlgoritm()">
                                      <img src="@/icon/2324511.png" style="width: 2.5em;" /> Algoritma tasarımı
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="f_drawAlgorithm()">
                                      <img src="@/icon/2324511.png" style="width: 2.5em;" /> {{ $t('wdm16.11189') }}
                                    </b-dropdown-item>
                                    <b-dropdown-item v-if="d_userDmpPermissions.indexOf('sign_algorithm_rule') !== -1 || d_userDmpPermissions.indexOf('owner') !== -1" @click="f_signAlgorithm()">
                                      <img src="@/icon/2324511.png" style="width: 2.5em;" /> imzala
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="f_changeRuleAlgorithmStatus('active')">
                                      <img src="@/icon/2324511.png" style="width: 2.5em;" /> algoritmayı aktif yap
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="f_changeRuleAlgorithmStatus('passive')">
                                      <img src="@/icon/2324511.png" style="width: 2.5em;" /> algoritmayı pasif yap
                                    </b-dropdown-item>
                                  </template>
                                </b-dropdown>
                              </template>
                              <template v-else>
                                .....
                              </template>
                            </b-col>
                          </b-row>
                          <b-row v-if="d_ruleResult[x.key]" style="text-align: right;">
                            <b-col cols="12">
                              <b-badge v-if="d_ruleResult[x.key].result" variant="success">{{ $t('wdm16.10899') }}</b-badge>
                              <b-badge v-else variant="danger">{{ $t('wdm16.10898') }}</b-badge>
                            </b-col>
                          </b-row>
                          <!-- <b-card-footer footer-bg-variant="primary" header-text-variant="dark" class="p-1" style="background-color: #5db5bd!important;"></b-card-footer> -->
                        </b-card>
                      </template>
                    </div>
                  </b-card>
                </b-col>
                <b-col sm="12" md="8" style="padding-left: 5px;">
                  <template v-if="d_selectedWdmrIndex_dmp_rule !== '' && d_selectedRuleWdmrData">
                    <b-card no-body>
                      <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_dmpSettings.style.dmp_header.color + '; background-color: ' + d_dmpSettings.style.dmp_header.backgroundColor">
                        {{ d_selectedRuleWdmrData.label }}
                        <b-dropdown class="pull-right" variant="white" size="md" right style="margin-right: 5px;">
                          <template v-slot:button-content>
                            <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 1em;" />
                          </template>
                          <template>
                            <b-dropdown-item @click="f_editSelectedWdmr('rule')">
                              <img src="@/icon/2087726.png" style="width: 2.5em;" /> {{ $t('wdm16.3897') }}
                            </b-dropdown-item>
                            <b-dropdown-item @click="f_deleteSelectedChildWdmr('rule', d_selectedWdmrIndex_dmp_rule)">
                              <img src="@/icon/2960590.png" style="width: 2.5em;" /> {{ $t('wdm16.4398') }}
                            </b-dropdown-item>
                            <b-dropdown-item @click="f_openRuleAlgoritm()">
                              <img src="@/icon/2324511.png" style="width: 2.5em;" /> Algoritma tasarımı
                            </b-dropdown-item>
                          </template>
                        </b-dropdown>
                      </b-card-header>
                      <div style="overflow-y: auto; overflow-x: auto; height: 350px;">
                        <wisdom-data-show :change="change" :view_mode="'table'" :data="d_selectedRuleWdmrData" :data_type="'rule'" :option_data="option_data['rule']"></wisdom-data-show>
                      </div>
                    </b-card>
                  </template>
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-tab>
        <b-tab @click="d_selectedTab = 'multi_wdmr_data'">
          <template slot="title">
            <img src="@/icon/665778.png" style="width: 1.5em;">
            <span :style="'color:' + d_dmpSettings.style.tab_header.color">{{ $t('wdm16.11190') }}</span>
          </template>
          <template v-if="d_showMultiWdmr && d_selectedTab === 'multi_wdmr_data' && f_controlMultiWdmrShow()">
            <multi-wdmr :p_dmpWdmr="d_selectedDmpWdmrData" :p_multiWdmrData="d_multiWdmrData"></multi-wdmr>
          </template>
        </b-tab>
        <!-- <b-tab style="background-color: black;" @click="d_selectedTab = 'case_by_analyze'">
          <template slot="title">
            <img src="@/icon/9370.png" style="width: 1.5em;"> {{ $t('wdm16.11191') }}
          </template>
          <b-row v-if="d_selectedTab === 'case_by_analyze' && d_dmpWdm7List.list && d_dmpWdm7List.list.length > 0" style="margin: 5px 0px 0px 0px; border: solid 1px #d8f6d8; padding: 3px; border-radius: 5px;">
            <b-col sm="12" md="3">
              <strong style="color: white;">{{ $t('wdm16.11192') }}</strong> <small style="color: #d8f6d8;"> ( {{ $t('wdm16.11148') }} ) </small>
              <div style="overflow-y: auto; overflow-x: hidden; height: 250px;">
                <template v-for="(x, x_ind) in d_dmpWdm7List.list">
                  <b-card :style="d_selectedWdmrIndex_dmp_wdm7 === x_ind ? 'margin-bottom: 3px; cursor: pointer; background: #9adcd3;' : 'margin-bottom: 3px; cursor: pointer;'" no-body @click="f_selectWdmr('dmp_wdm7', x_ind, x.key)">
                    <b-col cols="12">
                      {{ x_ind + 1 }} - {{ x.label }} <small>[ {{ x.key }} ]</small>
                    </b-col>
                  </b-card>
                </template>
              </div>
            </b-col>
            <b-col sm="12" md="9">
              <b-row style="margin: 0px;">
                <template v-for="(wdmstore, wdmstore_ind) in d_wdmStoreDataList">
                  <b-col sm="12" md="4">
                    <strong style="color: white;">{{ wdmstore.label }} </strong> <small style="color: #d8f6d8;"> ( {{ $t('wdm16.11148') }} ) </small>
                    <div style="overflow-y: auto; overflow-x: hidden; height: 250px;">
                      <template v-for="(x, x_ind) in wdmstore.list">
                        <b-card :style="wdmstore.selected.indexOf(x_ind) !== -1 ? 'margin-bottom: 3px; cursor: pointer; background: #e1f86b;' : 'margin-bottom: 3px; cursor: pointer;'" no-body @click="f_selectWdmr('wdmstore_data', x_ind, x.key, wdmstore_ind)">
                          <b-col cols="12">
                            {{ x_ind + 1 }} - {{ x.label }} <small>[ {{ x.key }} ]</small>
                          </b-col>
                        </b-card>
                      </template>
                    </div>
                  </b-col>
                </template>
              </b-row>
            </b-col>
          </b-row>
        </b-tab> -->
        <b-tab v-if="d_selectedDmpWdmrData && d_selectedDmpWdmrData.id" style="background-color: white;" @click="d_selectedTab = 'diagram_model'">
          <template slot="title">
            <img src="@/icon/2448909.png" style="width: 1.5em;">
            <span :style="'color:' + d_dmpSettings.style.tab_header.color">Diagram</span>
          </template>
          <template v-if="d_selectedTab === 'diagram_model'">
            <!-- <diagram :model="diagramModel"></diagram> -->
            <b-card no-body :class="[d_fullScreen ? 'full-screen-mode' : 'normal-mode']">
              <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_dmpSettings.style.dmp_header.color + '; background-color: ' + d_dmpSettings.style.dmp_header.backgroundColor">
                <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Diyagram Ağacı
                <!-- <b-button class="pull-right" size="md" :variant="d_fullScreen ? 'warning' : 'white'" :style="d_fullScreen ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreen ? d_fullScreen = false : d_fullScreen = true">
                  <img src="@/icon/629153.png" :title="$t('wdm16.6589')" style="width: 2em;" />
                </b-button> -->
                <b-dropdown class="pull-right" variant="white" size="md" right style="margin-right: 5px;">
                  <template v-slot:button-content>
                    <img src="@/icon/2940638.png" class="img-rounded img-responsive" style="width: 1.5em;" />
                  </template>
                  <template>
                    <b-dropdown-item @click="f_saveDmp()">
                      <img src="@/icon/2087726.png" style="width: 2.5em;" /> {{ $t('wdm16.3833') }}
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_editDiagramBranch()">
                      <img src="@/icon/2087726.png" style="width: 2.5em;" /> Diyagram Dallarını Düzenle
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
                <b-button class="pull-right" size="md" :variant="d_showRuleTreeList ? 'warning' : 'white'" :style="d_showRuleTreeList ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_showRuleTreeList ? d_showRuleTreeList = false : d_showRuleTreeList = true">
                  <img src="@/icon/629153.png" title="Diyagram Modelini Göster" style="width: 2em;" /> Diyagramı Göster
                </b-button>
              </b-card-header>
              <b-row>
                <b-col sm="12" md="12">
                  <div>
                    <template v-if="d_showRuleTreeList && d_ruleTreeList && d_ruleTreeList.items && d_ruleTreeList.items.length > 0">
                      <rule-tree-list :p_treeListData="d_ruleTreeList" :p_dmpSettings="d_dmpSettings"></rule-tree-list>
                    </template>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-tab>
        <b-tab v-if="d_selectedDmpWdmrData && d_selectedDmpWdmrData.id" style="background-color: white;" @click="d_selectedTab = 'dmp_details'">
          <template slot="title">
            <img src="@/icon/2448909.png" style="width: 1.5em;">
            <span :style="'color:' + d_dmpSettings.style.tab_header.color">Karar Destek Paketi Detaylar</span>
          </template>
          <template v-if="d_selectedTab === 'dmp_details'">
            <!-- <diagram :model="diagramModel"></diagram> -->
            <b-card no-body>
              <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_dmpSettings.style.dmp_header.color + '; background-color: ' + d_dmpSettings.style.dmp_header.backgroundColor">
                <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Karar Destek Paketi
              </b-card-header>
              <b-row>
                <b-col sm="12" md="12">
                  <div style="overflow-y: auto; overflow-x: auto; height: 250px;">
                    <wisdom-data-show :change="change" :view_mode="'table'" :data="d_selectedDmpWdmrData" :data_type="'wdm24'" :option_data="option_data['wdm24']"></wisdom-data-show>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-tab>
        <b-tab v-if="d_selectedWdm308ApiDocument && d_selectedWdm308ApiDocument.id" style="background-color: white;" @click="d_selectedTab = 'api_wdm308_details'">
          <template slot="title">
            <img src="@/icon/2448909.png" style="width: 1.5em;">
            <span :style="'color:' + d_dmpSettings.style.tab_header.color">Karar Destek Paketi Api Detaylar</span>
          </template>
          <template v-if="d_selectedTab === 'api_wdm308_details'">
            <!-- <diagram :model="diagramModel"></diagram> -->
            <b-card no-body>
              <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_dmpSettings.style.dmp_header.color + '; background-color: ' + d_dmpSettings.style.dmp_header.backgroundColor">
                <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Api Bilgileri [{{ d_selectedWdm308ApiDocument.id }}]
              </b-card-header>
              <b-row>
                <b-col sm="12" md="12">
                  <div style="overflow-y: auto; overflow-x: auto; height: 250px;">
                    <wisdom-data-show :change="change" :view_mode="'table'" :data="d_selectedWdm308ApiDocument" :data_type="'wdm308'" :option_data="option_data['wdm308']"></wisdom-data-show>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-tab>
        <b-tab v-if="d_selectedDmpWdmrData && d_selectedDmpWdmrData.id" style="background-color: white;" @click="d_selectedTab = 'datamodels'">
          <template slot="title">
            <img src="@/icon/2448909.png" style="width: 1.5em;">
            <span :style="'color:' + d_dmpSettings.style.tab_header.color">Veri modelleri</span>
          </template>
          <template v-if="d_selectedTab === 'datamodels'">
            <!-- <diagram :model="diagramModel"></diagram> -->
            <b-card no-body>
              <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_dmpSettings.style.dmp_header.color + '; background-color: ' + d_dmpSettings.style.dmp_header.backgroundColor">
                <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Veri modelleri listesi
                <!-- <b-dropdown class="pull-right" variant="white" size="md" right style="margin-right: 5px;">
                  <template v-slot:button-content>
                    <img src="@/icon/2940638.png" class="img-rounded img-responsive" style="width: 1.5em;" />
                  </template>
                  <template>
                    <b-dropdown-item v-if="d_wisdomeraSystem.system_type !== 'mainserver'" @click="f_openGetNewModels()">
                      <img src="@/icon/2176401.png" style="width: 2.5em;" /> Veri modeli indir
                    </b-dropdown-item>
                  </template>
                </b-dropdown> -->
              </b-card-header>
              <template v-if="d_selectedDmpWdmrData.data.general.wdm && d_selectedDmpWdmrData.data.general.wdm.list">
                <template v-for="(wdm, wdm_ind) in d_selectedDmpWdmrData.data.general.wdm.list">
                  <b-card style="margin-bottom: 3px; min-height: 50px; cursor: pointer;" no-body>
                    <b-row>
                      <b-col sm="12" md="10">
                        {{ wdm.wdm_type.val.label }} <small style="color: #ff5722;">[ {{ wdm.wdm_type.val.value }} ]</small>
                      </b-col>
                      <b-col sm="12" md="2" style="text-align: right;">
                        <b-dropdown class="pull-right" variant="white" size="md" right style="margin-right: 5px;">
                          <template v-slot:button-content>
                            <img src="@/icon/2940638.png" class="img-rounded img-responsive" style="width: 1.5em;" />
                          </template>
                          <template>
                            <b-dropdown-item @click="f_goToDataFormApp(wdm.wdm_type.val.value, wdm.wdm_version.val.label, wdm.wdm_group.val.value)">
                              <img src="@/icon/2176401.png" style="width: 2.5em;" /> Dataform uygulamasında veri modeline git
                            </b-dropdown-item>
                            <b-dropdown-item v-if="d_wisdomeraSystem.system_type !== 'mainserver'" @click="f_openUpdateData(wdm.wdm_group.val.value, wdm.wdm_type.val.value, wdm.wdm_version.val.value, wdm.wdm_type.val.label)">
                              <img src="@/icon/3530181.png" style="width: 2.5em;" /> Veri modelini indir
                            </b-dropdown-item>
                          </template>
                        </b-dropdown>
                      </b-col>
                    </b-row>
                  </b-card>
                </template>
              </template>
            </b-card>
          </template>
        </b-tab>
      </b-tabs>
    </b-card>
    <modal v-if="d_showWisdomDataModal" @close="d_showWisdomDataModal = false" :width="'750'">
      <h3 slot="header"> {{ option_data[data_type].name.label }} </h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="wisdom_data" :data_type="data_type" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <b-button variant="danger" @click="d_showWisdomDataModal = false">{{ $t('wdm16.3944') }}</b-button>
        <b-button variant="success" @click="f_saveWdm()">{{ $t('wdm16.3833') }}</b-button>
      </div>
    </modal>
    <modal v-if="d_showRuleAlgoritmModal" @close="d_showRuleAlgoritmModal = false" :width="'1500'">
      <h3 slot="header">Algoritma Tasarım</h3>
      <div slot="body">
        <wdm-rule-a :data_type="d_algorithmRule.data_type" :option_data="d_algorithmRule.option_data" :prepare_data="d_algorithmRule.prepare_data" :query_list="d_algorithmRule.query_list" :compile_str_array="d_algorithmRule.compile_str_array" :compile_str_date="d_algorithmRule.compile_str_date" :wdmr_change="d_algorithmRule.wdmr_change" :p_wdmList="d_algorithmRule.wdm_list" :mother_children="mother_children" :p_wdmParameterSelectData="d_algorithmRule.wps_data" :p_wdmRuleAsettings="d_algorithmRule.wra_settings" :compile_str_text="d_algorithmRule.compile_str_text"></wdm-rule-a>
      </div>
      <div slot="footer">
        <b-button variant="danger" @click="d_showRuleAlgoritmModal = false">{{ $t('wdm16.3944') }}</b-button>
        <b-button variant="success" @click="f_saveRuleAlgoritm()">{{ $t('wdm16.3833') }}</b-button>
      </div>
    </modal>
    <modal v-if="d_dmpServiceTestResult.show" @close="d_dmpServiceTestResult.show = false" :width="'1500'">
      <h3 slot="header">Servis test işlemi</h3>
      <div slot="body">
        <b-card no-body>
          <b-card-header class="p-1">
            <i class="fa fa-list-ol"></i> Servis Bilgileri
          </b-card-header>
          <b-row style="margin: 0px;">
            <b-col cols="3">Uri</b-col>
            <b-col cols="9">{{ d_dmpServiceTestResult.uri }}</b-col>
          </b-row>
          <b-row style="margin: 0px;">
            <b-col cols="3">Metod</b-col>
            <b-col cols="9">{{ d_dmpServiceTestResult.method }}</b-col>
          </b-row>
        </b-card>
        <b-row>
          <b-col sm="12" lg="6">
            <b-card no-body bg-variant="secondary">
              <b-card-header class="p-1">
                <i class="fa fa-list-ol"></i> Request (istem)
              </b-card-header>
              <div style="overflow-y: auto; height: 350px; white-space: pre; color: #2aab8b; background: black; " contenteditable="true">
                {{ f_getJsonStringify(d_dmpServiceTestResult.request) }}
              </div>
            </b-card>
          </b-col>
          <b-col sm="12" lg="6">
            <b-card no-body bg-variant="secondary">
              <b-card-header class="p-1">
                <i class="fa fa-list-ol"></i> Response (yanıt)
              </b-card-header>
              <div style="overflow-y: auto; height: 350px; white-space: pre; color: red; background: black;" contenteditable="true">
                {{ f_getJsonStringify(d_dmpServiceTestResult.response) }}
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <b-button variant="danger" @click="d_dmpServiceTestResult.show = false">{{ $t('wdm16.3944') }}</b-button>
      </div>
    </modal>
    <b-modal v-if="d_dmpShowModal" :title="$t('wdm16.11137')" centered class="modal-success" v-model="d_dmpShowModal" @ok="d_dmpShowModal = false" ok-variant="success" hide-footer>
      <b-row>
        <b-col cols="12">
          <template v-for="(x, x_ind) in d_dmpList.list">
            <b-card :style="d_selectedWdmrIndex_dmp === x_ind ? 'margin-bottom: 3px; cursor: pointer; background: #9adcd3;' : 'margin-bottom: 3px; cursor: pointer;'" no-body @click="f_selectWdmr('dmp', x_ind, x.key)">
              <b-col cols="12">
                {{ x_ind + 1 }} - {{ x.label }} <small>[ {{ x.key }} ]</small>
              </b-col>
              <!-- <b-card-footer footer-bg-variant="primary" header-text-variant="dark" class="p-1" style="background-color: #5db5bd!important;"></b-card-footer> -->
            </b-card>
          </template>
        </b-col>
      </b-row>
    </b-modal>
    <modal v-if="d_dataModalUpdate.show" @close="d_dataModalUpdate.show = false" :width="'500'">
      <h3 slot="header">Veri modelleri indirme & güncelleme</h3>
      <div slot="body">
        <b-card>
          <b-row style="margin: 0px;">
            <b-col cols="6"><strong> WisdomDataForm kullanıcı adı </strong></b-col>
            <b-col cols="6">
              <b-form-input v-model="d_dataModalUpdate.username"></b-form-input>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-row style="margin: 0px;">
            <b-col cols="6"><strong> WisdomDataForm şifre </strong></b-col>
            <b-col cols="6">
              <b-form-input v-model="d_dataModalUpdate.pass"></b-form-input>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-row style="margin: 0px;">
            <b-col cols="6"><strong> Wdm id (wdm7, wdm245, v.b) </strong></b-col>
            <b-col cols="6">
              <b-form-input v-model="d_dataModelAdd.wdm_type"></b-form-input>
            </b-col>
          </b-row>
          <b-row style="margin: 0px;">
            <b-col cols="6"><strong> Wdm versiyon (2.0, 3.0 v.b) </strong></b-col>
            <b-col cols="6">
              <b-form-input v-model="d_dataModelAdd.wdm_version"></b-form-input>
            </b-col>
          </b-row>
          <b-row style="margin: 0px;">
            <b-col cols="12">
              <b-button variant="success" @click="f_addToDataModalUpdate()">veri modellerine ekle</b-button>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <template v-for="(x, x_ind) in d_dataModalUpdate.wdm_list">
            <b-card no-body>
              <b-row style="margin: 0px;">
                <b-col cols="10">
                  <strong>Ad:</strong> {{ x.wdm_name }} <strong>ID:</strong> {{ x.wdm_type }} <strong>Version:</strong> {{ x.wdm_version }}
                </b-col>
                <b-col cols="2">
                  <b-button variant="danger" @click="d_dataModalUpdate.wdm_list.splice(x_ind, 1); $forceUpdate();">-</b-button>
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-card>
      </div>
      <div slot="footer">
        <b-button variant="danger" @click="d_dataModalUpdate.show = false">{{ $t('wdm16.3944') }}</b-button>
        <b-button variant="success" @click="f_updateDataModelFromRemoteServer()">seçili veri modellerini güncelle</b-button>
      </div>
    </modal>
    <b-modal v-if="d_ruleAnalyzeData.show" v-model="d_ruleAnalyzeData.show" size="lg" :title="$t('wdm16.11193')" centered hide-footer body-bg-variant="secondary" header-bg-variant="secondary">
      <rule-engine :rule_results="d_ruleAnalyzeData.results"></rule-engine>
    </b-modal>
    <b-modal v-if="d_algorithmData.show" v-model="d_algorithmData.show" size="lg" :title="$t('wdm16.11189')" centered hide-footer body-bg-variant="secondary" header-bg-variant="secondary">
      <draw-algorithm :p_algorithmData="d_algorithmData"></draw-algorithm>
    </b-modal>
    <b-modal v-if="d_signWdmr.show" v-model="d_signWdmr.show" size="xl" title="Algoritma Kuralı İmza" centered hide-footer body-bg-variant="secondary" header-bg-variant="secondary">
      <b-row style="min-height: 350px;">
        <b-col cols="12">
          <template v-for="(x, x_ind) in d_signWdmr.user_list">
            <b-card v-if="user.username === x.username" style="margin-bottom: 3px; cursor: pointer;" no-body>
              <b-row :style="f_calculateApproveRowStyle(x)">
                <b-col cols="2">
                  Kullanıcı: {{ x.username }}
                </b-col>
                <b-col cols="2">
                  <template v-if="x.approve === ''">imzalanmadı</template>
                  <template v-else-if="x.approve === '1'"><strong style="color: green;">onaylandı / imzalandı.</strong> {{ DateFormatWithTime(x.date) }}</template>
                  <template v-else-if="x.approve === '0'"><strong style="color: red;">onaylanmadı / imzalandı.</strong> {{ DateFormatWithTime(x.date) }}</template>
                </b-col>
                <b-col cols="6">
                  <template v-if="x.log && x.log.length > 0">
                    <div>
                      <div><strong>Loglar:</strong></div>
                      <b-row v-for="(log, log_index) in x.log">
                        <b-col cols="12">
                          <template v-if="log.approve === ''">imzalanmadı</template>
                          <template v-else-if="log.approve === '1'"><span style="color: green;">onaylandı / imzalandı.</span> {{ DateFormatWithTime(log.date) }}</template>
                          <template v-else-if="log.approve === '0'"><span style="color: red;">onaylanmadı / imzalandı.</span> {{ DateFormatWithTime(log.date) }}</template>
                        </b-col>
                      </b-row>
                    </div>
                  </template>
                  <template v-if="x.mail_log && x.mail_log.length > 0">
                    <div style="overflow-y: auto; overflow-x: hidden; height: 300px; background: #e5fff5;">
                      <div><strong>Mail Loglar:</strong></div>
                      <template v-for="(log, log_index) in x.mail_log">
                        <b-row>
                          <b-col cols="6">
                            {{ DateFormatWithTime(log.date) }}
                          </b-col>
                          <b-col cols="6">
                            {{ log.sender_user }}
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12">
                            <div v-html="log.mail_text"> </div>
                          </b-col>
                        </b-row>
                      </template>
                    </div>
                  </template>
                </b-col>
                <b-col cols="2">
                  <b-button class="pull-right" title="onaylama / değerlendirme maili gönder" size="md" variant="white" @click="f_sendAlgorithmApproveMailThisUserList([x.username])" style="margin-right: 5px; padding: 0px">
                    <img src="@/icon/110598.png" style="width: 2.5em;" title="onaylama / değerlendirme maili gönder" alt="onaylama / değerlendirme maili gönder" />
                  </b-button>
                  <template v-if="user.username === x.username">
                    <b-button class="pull-right" title="algoritmayı reddet" size="md" variant="white" @click="f_signApproveAlgorithm('0', x_ind)" style="margin-right: 5px; padding: 0px">
                      <img src="@/icon/2120432_red.png" style="width: 2.5em;" title="algoritmayı reddet" alt="algoritmayı reddet" />
                    </b-button>
                    <b-button class="pull-right" title="algoritmayı onayla" size="md" variant="white" @click="f_signApproveAlgorithm('1', x_ind)" style="margin-right: 5px; padding: 0px">
                      <img src="@/icon/2147852_green.png" style="width: 2.5em;" title="algoritmayı onayla" alt="algoritmayı onayla" />
                    </b-button>
                  </template>
                </b-col>
              </b-row>
            </b-card>
          </template>
          <template v-for="(x, x_ind) in d_signWdmr.user_list">
            <b-card v-if="!d_signWdmr.only_me && user.username !== x.username" style="margin-bottom: 3px; cursor: pointer;" no-body>
              <b-row :style="f_calculateApproveRowStyle(x)">
                <b-col cols="2">
                  Kullanıcı: {{ x.username }}
                </b-col>
                <b-col cols="2">
                  <template v-if="x.approve === ''">imzalanmadı</template>
                  <template v-else-if="x.approve === '1'"><strong style="color: green;">onaylandı / imzalandı.</strong> {{ DateFormatWithTime(x.date) }}</template>
                  <template v-else-if="x.approve === '0'"><strong style="color: red;">onaylanmadı / imzalandı.</strong> {{ DateFormatWithTime(x.date) }}</template>
                </b-col>
                <b-col cols="6">
                  <template v-if="x.log && x.log.length > 0">
                    <div>
                      <div><strong>Loglar:</strong></div>
                      <b-row v-for="(log, log_index) in x.log">
                        <b-col cols="12">
                          <template v-if="log.approve === ''">imzalanmadı</template>
                          <template v-else-if="log.approve === '1'"><span style="color: green;">onaylandı / imzalandı.</span> {{ DateFormatWithTime(log.date) }}</template>
                          <template v-else-if="log.approve === '0'"><span style="color: red;">onaylanmadı / imzalandı.</span> {{ DateFormatWithTime(log.date) }}</template>
                        </b-col>
                      </b-row>
                    </div>
                  </template>
                  <template v-if="x.mail_log && x.mail_log.length > 0">
                    <div style="overflow-y: auto; overflow-x: hidden; height: 300px; background: #e5fff5;">
                      <div><strong>Mail Loglar:</strong></div>
                      <template v-for="(log, log_index) in x.mail_log">
                        <b-row>
                          <b-col cols="6">
                            {{ DateFormatWithTime(log.date) }}
                          </b-col>
                          <b-col cols="6">
                            {{ log.sender_user }}
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12">
                            <div v-html="log.mail_text"> </div>
                          </b-col>
                        </b-row>
                      </template>
                    </div>
                  </template>
                </b-col>
                <b-col cols="2">
                  <b-button class="pull-right" title="onaylama / değerlendirme maili gönder" size="md" variant="white" @click="f_sendAlgorithmApproveMailThisUserList([x.username])" style="margin-right: 5px; padding: 0px">
                    <img src="@/icon/110598.png" style="width: 2.5em;" title="onaylama / değerlendirme maili gönder" alt="onaylama / değerlendirme maili gönder" />
                  </b-button>
                  <template v-if="user.username === x.username">
                    <b-button class="pull-right" title="algoritmayı reddet" size="md" variant="white" @click="f_signApproveAlgorithm('0', x_ind)" style="margin-right: 5px; padding: 0px">
                      <img src="@/icon/2120432_red.png" style="width: 2.5em;" title="algoritmayı reddet" alt="algoritmayı reddet" />
                    </b-button>
                    <b-button class="pull-right" title="algoritmayı onayla" size="md" variant="white" @click="f_signApproveAlgorithm('1', x_ind)" style="margin-right: 5px; padding: 0px">
                      <img src="@/icon/2147852_green.png" style="width: 2.5em;" title="algoritmayı onayla" alt="algoritmayı onayla" />
                    </b-button>
                  </template>
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_algorithmMail.show" v-model="d_algorithmMail.show" size="lg" title="Algoritma Kuralı İmza / Onay Mail İşlemi" centered hide-footer body-bg-variant="white" header-bg-variant="secondary" no-close-on-esc no-close-on-backdrop>
      <b-row style="margin: 0px;">
        <b-col cols="2">
          Kullanıcılar
        </b-col>
        <b-col cols="10">
          <template v-for="(x, x_ind) in d_algorithmMail.username_list">
            {{ x }}
          </template>
        </b-col>
      </b-row>
      <b-row style="margin: 0px;">
        <b-col cols="2">
          Ek bilgi
        </b-col>
        <b-col cols="10">
          <b-textarea v-model="d_algorithmMail.additional_text"></b-textarea>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <b-button variant="danger" @click="f_sendRuleAlgorithmApproveMail()">mail gönder</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_chatBot.show" v-model="d_chatBot.show" size="xl" title="ChatBot Ekranı" centered hide-footer body-bg-variant="white" header-bg-variant="secondary" no-close-on-esc no-close-on-backdrop>
      <b-row style="margin: 0px;">
        <b-col sm="12" lg="6">
          <b-row style="margin: 0px;">
            <b-col cols="12"> <strong>Veri Modelleri</strong> </b-col>
          </b-row>
          <div style="max-height: 500px; overflow-y: auto; overflow-x: hidden;">
            <template v-for="(wdm, wdm_ind) in d_chatBot.wdm_list">
              <b-card no-body :style="'color:' + d_dmpSettings.style.color + '; background-color: ' + d_dmpSettings.style.backgroundColor" :class="[d_fullScreenGeneral ?'full-screen-mode' :'normal-mode']">
                <b-card-header class="p-1" :style="'color:' + d_dmpSettings.style.dmp_header.color + '; background-color: ' + d_dmpSettings.style.dmp_header.backgroundColor">
                  <strong> * {{ wdm.label }}</strong>
                </b-card-header>
                <b-row style="margin: 0px;">
                  <b-col cols="12"> {{wdm.info}} </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" lg="12">
                    <template v-for="(param, param_ind) in wdm.params">
                      <b-card no-body :style="'min-height: 30px; color:' + d_dmpSettings.style.color + '; background-color: ' + d_dmpSettings.style.backgroundColor">
                        <b-card-header class="p-1" :style="'color:' + d_dmpSettings.style.dmp_header.color + '; background-color: ' + d_dmpSettings.style.dmp_header.backgroundColor">
                          {{ param.label }} ({{ param.type }})
                        </b-card-header>
                        <template v-if="param.type === 'text'">
                          <input v-model="d_chatBot.wdm_list[wdm_ind].params[param_ind].val" class="form-control" type="text" :key="param.id">
                        </template>
                        <template v-if="param.type === 'plain_text'">
                          <template v-if="d_chatBot.wdm_list[wdm_ind].params[param_ind].val !== ''">
                            {{ d_chatBot.wdm_list[wdm_ind].params[param_ind].val }}
                          </template>
                          <template v-else>
                            ...
                          </template>
                        </template>
                        <template v-if="param.type === 'textarea'">
                          <b-textarea v-model="d_chatBot.wdm_list[wdm_ind].params[param_ind].val" class="form-control" :key="param.id"></b-textarea>
                        </template>
                        <template v-if="param.type === 'integer'">
                          <input v-model="d_chatBot.wdm_list[wdm_ind].params[param_ind].val" class="form-control" type="number" :min="param.min !== undefined ? param.min : ''" :max="param.max !== undefined && param.max > 0 ? param.max : ''" :key="param.id">
                        </template>
                        <template v-if="param.type === 'float'">
                          <input v-model="d_chatBot.wdm_list[wdm_ind].params[param_ind].val" class="form-control" type="number" :min="param.min !== undefined ? param.min : ''" :max="param.max !== undefined && param.max > 0 ? param.max : ''" :key="param.id">
                        </template>
                        <template v-if="param.type === 'select'">
                          <b-form-select v-model="d_chatBot.wdm_list[wdm_ind].params[param_ind].val" :select-size="4" :key="param.id">
                            <template v-for="opt in param.options">
                              <option :value="opt.value">{{ opt.label }}</option>
                            </template>
                          </b-form-select>
                        </template>
                        <template v-if="param.type === 'radiogroup'">
                          <b-form-radio-group v-model="d_chatBot.wdm_list[wdm_ind].params[param_ind].val">
                            <template v-for="(op, op_ind) in param.options">
                              <b-row>
                                <b-col cols="12">
                                  <b-form-radio :key="'radiogroup_' + op_ind.toString()" :value="op.value">
                                    <template v-if="op.image_info && op.image_info.data">
                                      <template v-if="op.image_info.side === 'icon'">
                                        <img :src="op.image_info.data" style="cursor: zoom-in;width: 30px;" @click="f_previewImage(op.image_info.data)">
                                      </template>
                                    </template>
                                    {{ op.label }}
                                  </b-form-radio>
                                </b-col>
                              </b-row>
                              <template v-if="op.image_info && op.image_info.data">
                                <template v-if="op.image_info.side === 'bottom'">
                                  <b-row style="margin: 0px;">
                                    <b-col cols="12">
                                      <img :src="op.image_info.data" style="cursor: zoom-in;width: 100px;" @click="f_previewImage(op.image_info.data)">
                                    </b-col>
                                  </b-row>
                                </template>
                              </template>
                            </template>
                          </b-form-radio-group>
                        </template>
                      </b-card>
                    </template>
                  </b-col>
                </b-row>
              </b-card>
            </template>
          </div>
        </b-col>
        <b-col sm="12" lg="6">
          <b-row style="margin: 5px;">
            <b-col cols="6"> <strong>Analiz Sonuçları</strong> </b-col>
            <b-col cols="6" style="text-align: right;">
              <b-button variant="white" @click="f_chatBotAnalyze()">
                <img src="@/icon/2024270.png" style="width: 5em;">
              </b-button>
            </b-col>
          </b-row>
          <template v-if="d_chatBot.results.length === 0">
            <span>Pozitif algoritma sonucu yok</span>
          </template>
          <template v-else>
            <b-row style="margin: 5px;">
              <b-col cols="12">
                <strong>{{ d_chatBot.results.length }}</strong> adet pozitif algoritma sonucu mevcut.
              </b-col>
            </b-row>
            <div style="max-height: 500px; overflow-y: auto; overflow-x: hidden;">
              <template v-for="(res, res_ind) in d_chatBot.results">
                <b-card :style="'color:' + d_dmpSettings.style.color + '; background-color: ' + d_dmpSettings.style.backgroundColor" :class="[d_fullScreenGeneral ?'full-screen-mode' :'normal-mode']">
                  <!-- <b-card-header class="p-1" :style="'color:' + d_dmpSettings.style.dmp_header.color + '; background-color: ' + d_dmpSettings.style.dmp_header.backgroundColor">
                          </b-card-header> -->
                  <b-row style="margin: 5px;">
                    <b-col sm="12" lg="4">ID</b-col>
                    <b-col sm="12" lg="8"> {{ res.id }} </b-col>
                  </b-row>
                  <b-row style="margin: 5px;">
                    <b-col sm="12" lg="4">Adı</b-col>
                    <b-col sm="12" lg="8"> {{ res.name }} </b-col>
                  </b-row>
                  <b-row style="margin: 5px;">
                    <b-col sm="12" lg="4">Mesaj</b-col>
                    <b-col sm="12" lg="8"> {{ res.message }} </b-col>
                  </b-row>
                  <b-row style="margin: 5px;">
                    <b-col sm="12" lg="4">Operasyon ID</b-col>
                    <b-col sm="12" lg="8"> {{ res.operation_id }} </b-col>
                  </b-row>
                  <b-row style="margin: 5px;" v-if="res.message_image">
                    <b-col sm="12" lg="12" style="text-align: center;">
                      <img :src="res.message_image" style="width: 300px;">
                    </b-col>
                  </b-row>
                </b-card>
              </template>
            </div>
          </template>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_chatBotDiagram.show" v-model="d_chatBotDiagram.show" size="xl" title="ChatBot Diagram Ekranı" centered hide-footer body-bg-variant="white" header-bg-variant="secondary" no-close-on-esc no-close-on-backdrop>
      <b-row style="margin: 0px;">
        <b-col sm="12" lg="12">
          <b-row style="margin: 5px;">
            <b-col cols="6"></b-col>
            <b-col cols="6" style="text-align: right;">
              <b-button title="Iframe ekranı" variant="white" @click="f_goToChatBotIframe()">
                <img src="@/icon/201295.png" style="width: 3em;">
              </b-button>
              <b-button v-if="d_chatBotDiagram.last_diagram_point" title="Geri" variant="white" @click="f_chatBotDiagramBack()">
                <img src="@/icon/2457527.png" style="width: 3em;">
              </b-button>
              <b-button variant="white" @click="f_chatBotDiagramAnalyze()" title="Diyagram Analizi">
                <img src="@/icon/2024270.png" style="width: 5em;">
              </b-button>
            </b-col>
          </b-row>
          <div style="max-height: 500px; overflow-y: auto; overflow-x: hidden;">
            <template v-for="(algoritm, algoritm_ind) in d_chatBotDiagram.algorithm_list">
              <b-card no-body @click="f_selectDiagramPoint(algoritm.point)" @dblclick="f_selectDiagramPointAndGo(algoritm.point)" :style="algoritm.point === d_chatBotDiagram.diagram_point ? 'background: #d1f5f9; cursor: pointer;' : 'cursor: pointer;'">
                <!-- <b-card-header class="p-1" :style="'color:' + d_dmpSettings.style.dmp_header.color + '; background-color: ' + d_dmpSettings.style.dmp_header.backgroundColor">
                          </b-card-header> -->
                <b-row style="margin: 0px;">
                  <b-col sm="12" lg="8">
                    <b-row style="margin: 0px;">
                      <b-col sm="12" lg="4">ID</b-col>
                      <b-col sm="12" lg="8"> {{ algoritm.id }} </b-col>
                    </b-row>
                    <b-row style="margin: 0px;">
                      <b-col sm="12" lg="4">Adı</b-col>
                      <b-col sm="12" lg="8"> {{ algoritm.name }} </b-col>
                    </b-row>
                    <b-row style="margin: 0px;">
                      <b-col sm="12" lg="4">Mesaj</b-col>
                      <b-col sm="12" lg="8"> {{ algoritm.message }} </b-col>
                    </b-row>
                    <b-row style="margin: 0px;">
                      <b-col sm="12" lg="4">Operasyon ID</b-col>
                      <b-col sm="12" lg="8"> {{ algoritm.operation_id }} </b-col>
                    </b-row>
                    <b-row style="margin: 0px;">
                      <b-col sm="12" lg="4">Devamı Var mı ?</b-col>
                      <b-col sm="12" lg="8"> {{ algoritm.next ? 'evet' : 'hayır' }} </b-col>
                    </b-row>
                  </b-col>
                  <b-col sm="12" lg="4">
                    <img v-if="algoritm.message_image" :src="algoritm.message_image" style="height: 100%;">
                  </b-col>
                </b-row>
              </b-card>
            </template>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_excelAlgorithms.show" v-model="d_excelAlgorithms.show" size="xl" title="Excelden Algoritma Ekleme Ekranı" centered hide-footer body-bg-variant="white" header-bg-variant="secondary" no-close-on-esc no-close-on-backdrop>
      <b-row style="margin: 10px;">
        <b-col cols="12">
          <table id="algorithm_excel_table" style="border: solid 1px black; width: 100%;">
            <thead>
              <tr>
                <th>name</th>
                <th>note</th>
                <th>message</th>
                <th>message_image_link</th>
                <th>reference_type</th>
                <th>reference_source</th>
                <th>reference_name</th>
                <th>reference_link</th>
                <th>reference_note</th>
                <th>rule_type</th>
                <th>operation_no</th>
                <th>operation_info</th>
                <th>operation_text</th>
                <th>status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
              </tr>
              <tr>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      <b-row style="margin: 10px;">
        <b-col cols="12" style="text-align: right;">
          <input class="pull-right" id="excel_algorithm_document" title="Excel dökümanı yükle" type="file" ref="fileinput" @change="f_selectExcelDocument('excel_algorithm_document')" accept=".xls,.xlsx">
        </b-col>
      </b-row>
      <b-row style="margin: 10px;">
        <b-col cols="12" style="text-align: right;">
          <button type="button" class="btn btn-success" @click="f_addExcelAlgorithmsToDmp()">Algoritmaları Ekle</button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_excelDiagramRows.show" v-model="d_excelDiagramRows.show" size="xl" title="Excelden Diyagram Oluşturma Ekranı" centered hide-footer body-bg-variant="white" header-bg-variant="secondary" no-close-on-esc no-close-on-backdrop>
      <b-row style="margin: 10px;">
        <b-col cols="12">
          <table id="diagram_algorithm_excel_table" style="border: solid 1px black; width: 100%;">
            <thead>
              <tr>
                <th>a</th>
                <th>b</th>
                <th>c</th>
                <th>...</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
              </tr>
              <tr>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      <b-row style="margin: 10px;">
        <b-col cols="12" style="text-align: right;">
          <input class="pull-right" id="excel_diagram_algorithm_document" title="Excel dökümanı yükle" type="file" ref="fileinput" @change="f_selectExcelDocument('excel_diagram_algorithm_document')" accept=".xls,.xlsx">
        </b-col>
      </b-row>
      <b-row style="margin: 10px;">
        <b-col cols="12" style="text-align: right;">
          <button type="button" class="btn btn-success" @click="f_addExcelDiagramAlgorithmsToDmp('add')">Dalları Oluştur (ekleyerek)</button>
          <button type="button" class="btn btn-success" @click="f_addExcelDiagramAlgorithmsToDmp('reset')">Dalları Oluştur (resetleyerek)</button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_diagramBranchData.show" v-model="d_diagramBranchData.show" size="xl" title="Diyagram Karar Ağacı Dallarını Düzenleme Ekranı" centered hide-footer body-bg-variant="white" header-bg-variant="secondary" no-close-on-esc no-close-on-backdrop>
      <b-card no-body :style="'color:' + d_dmpSettings.style.color + '; background-color: ' + d_dmpSettings.style.backgroundColor" :class="[d_diagramBranchData.fullscreen ?'full-screen-mode' :'normal-mode']">
        <b-card-header class="p-1" :style="'color:' + d_dmpSettings.style.dmp_header.color + '; background-color: ' + d_dmpSettings.style.dmp_header.backgroundColor">
          <b-row>
            <b-col cols="2">
              <strong>Karar Ağacı Diyagramı Satırları</strong>
              <div>
                <span style="color: red;" v-if="d_diagramBranchData.search_text">( {{ d_diagramBranchData.search_index_list.length }} / {{ d_diagramBranchData.list.length }} )</span>
                <span style="color: red;" v-else>( {{ d_diagramBranchData.list.length }} / {{ d_diagramBranchData.list.length }} )</span>
              </div>
            </b-col>
            <b-col cols="2">
              <b-form-input v-model="d_diagramBranchData.search_text" placeholder="filtrelemek için yazınız"></b-form-input>
            </b-col>
            <b-col cols="2">
              <b-form-select v-model="d_diagramBranchData.search_type" title="Hangi Dal Noktalarında Aransın">
                <option value="all_branch_points">tüm dal noktalarında</option>
                <option value="last_branch_points">son dal noktalarında</option>
                <option value="first_branch_points">ilk dal noktalarında</option>
                <option value="penultimate_branch_points">sondan bir önceki dal noktalarında</option>
              </b-form-select>
            </b-col>
            <b-col cols="3" style="text-align: right;">
              <b-pagination v-if="d_diagramBranchData.search_text" size="sm" class="pull-right" style="margin-bottom: 0px;" aria-controls="column_pagination" v-model="d_diagramBranchData.pagination.current" :total-rows="d_diagramBranchData.search_index_list.length" :per-page="d_diagramBranchData.pagination.perpage"></b-pagination>
              <b-pagination v-else size="sm" class="pull-right" style="margin-bottom: 0px;" aria-controls="column_pagination" v-model="d_diagramBranchData.pagination.current" :total-rows="d_diagramBranchData.list.length" :per-page="d_diagramBranchData.pagination.perpage"></b-pagination>
            </b-col>
            <b-col cols="2" style="text-align: right;">
              <b-dropdown variant="success" style="margin-right: 5px;">
                <template slot="button-content"> işlemler </template>
                <b-dropdown-item @click="f_updateDiagramBranchs(point_ind)"> Diyagramı Güncelle </b-dropdown-item>
                <b-dropdown-item @click="f_deleteLastDiagramBranchPoints()"> Diyagram Dalı Son Noktalarını Sil </b-dropdown-item>
                <b-dropdown-item @click="f_addDiagramAlgorithmsFromExcel()"> Diyagram Algoritmalarını excelden ekle </b-dropdown-item>
              </b-dropdown>
            </b-col>
            <b-col cols="1" style="text-align: right;">
              <b-button size="md" :variant="d_diagramBranchData.fullscreen ? 'warning' : 'white'" :style="d_diagramBranchData.fullscreen ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_diagramBranchData.fullscreen ? d_diagramBranchData.fullscreen = false : d_diagramBranchData.fullscreen = true">
                <img src="@/icon/629153.png" title="Tam ekran" style="width: 2em;" />
              </b-button>
            </b-col>
          </b-row>
        </b-card-header>
        <b-row style="margin: 10px;">
          <b-col cols="12">
            <div :style="f_calculteStyleDiagramEditBranch()">
              <table id="diagram_branch_table" style="border: solid 1px black;">
                <tbody>
                  <template v-if="d_diagramBranchData.list.length > 0">
                    <tr>
                      <td style="background-color: #2fffc1; box-shadow: seagreen 2px 4px 13px;"></td>
                      <td v-for="(count, point_ind) in d_diagramBranchData.column_count">
                        <b-dropdown variant="success">
                          <template slot="button-content"> </template>
                          <b-dropdown-item @click="f_addNewColumn(point_ind, 'right')">Sağına 1 adet Algoritma Ekle</b-dropdown-item>
                          <b-dropdown-item @click="f_addNewColumn(point_ind, 'right', 5)">Sağına 5 adet Algoritma Ekle</b-dropdown-item>
                          <b-dropdown-item @click="f_addNewColumn(point_ind, 'left')">Soluna 1 adet Algoritma Ekle</b-dropdown-item>
                          <b-dropdown-item @click="f_addNewColumn(point_ind, 'left', 5)">Soluna 5 adet Algoritma Ekle</b-dropdown-item>
                          <b-dropdown-item @click="f_deleteColumn(point_ind)">Sil </b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td>
                        <b-dropdown variant="success">
                          <template slot="button-content"> </template>
                          <b-dropdown-item @click="f_addNewRow(branch_ind, 'down')">1 adet Dal Ekle</b-dropdown-item>
                          <b-dropdown-item @click="f_addNewRow(branch_ind, 'down', 5)">5 adet Dal Ekle</b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                  </template>
                  <template v-if="d_diagramBranchData.search_text">
                    <template v-for="(search_index, search_index_ind) in d_diagramBranchData.search_index_list">
                      <tr v-if="search_index_ind > d_diagramBranchData.pagination.start && search_index_ind < d_diagramBranchData.pagination.end">
                        <td>
                          <b-dropdown variant="primary">
                            <template slot="button-content"> </template>
                            <b-dropdown-item @click="f_addNewRow(search_index, 'up')">Üstüne 1 adet Dal Ekle</b-dropdown-item>
                            <b-dropdown-item @click="f_addNewRow(search_index, 'up', 1, 'copy')">Üstüne 1 adet Dal Kopyalayarak Ekle</b-dropdown-item>
                            <b-dropdown-item @click="f_addNewRow(search_index, 'up', 5)">Üstüne 5 adet Dal Ekle</b-dropdown-item>
                            <b-dropdown-item @click="f_addNewRow(search_index, 'up', 5, 'copy')">Üstüne 5 adet Dal Kopyalayarak Ekle</b-dropdown-item>
                            <b-dropdown-item @click="f_addNewRow(search_index, 'down')">Altına 1 adet Dal Ekle</b-dropdown-item>
                            <b-dropdown-item @click="f_addNewRow(search_index, 'down', 1, 'copy')">Altına 1 adet Dal Kopyalayarak Ekle</b-dropdown-item>
                            <b-dropdown-item @click="f_addNewRow(search_index, 'down', 5)">Altına 5 adet Dal Ekle</b-dropdown-item>
                            <b-dropdown-item @click="f_addNewRow(search_index, 'down', 5, 'copy')">Altına 5 adet Dal Kopyalayarak Ekle</b-dropdown-item>
                            <b-dropdown-item @click="f_deleteRow(search_index)">Dalı Sil</b-dropdown-item>
                          </b-dropdown>
                        </td>
                        <template v-for="(point, point_ind) in d_diagramBranchData.list[search_index]">
                          <td style="min-width: 50px;;">
                            <div @click="f_openAlgorithmSelectionModalForBranch(search_index, point_ind)" style="cursor: pointer; min-width: 50px; min-height: 20px; margin-right: 5px">
                              <template v-if="point && point.label">
                                {{ point.label }}
                              </template>
                            </div>
                          </td>
                        </template>
                        <td style="width: 200px;">
                          <div @click="f_openAlgorithmSelectionModalForBranch(search_index, d_diagramBranchData.list[search_index].length)" style="cursor: pointer; width: 200px; min-height: 30px;">
                          </div>
                        </td>
                      </tr>
                    </template>
                  </template>
                  <template v-else>
                    <template v-for="(branch, branch_ind) in d_diagramBranchData.list">
                      <tr v-if="f_filterBranchControl(branch_ind)">
                        <td>
                          <b-dropdown variant="primary">
                            <template slot="button-content"> </template>
                            <b-dropdown-item @click="f_addNewRow(branch_ind, 'up')">Üstüne 1 adet Dal Ekle</b-dropdown-item>
                            <b-dropdown-item @click="f_addNewRow(branch_ind, 'up', 1, 'copy')">Üstüne 1 adet Dal Kopyalayarak Ekle</b-dropdown-item>
                            <b-dropdown-item @click="f_addNewRow(branch_ind, 'up', 5)">Üstüne 5 adet Dal Ekle</b-dropdown-item>
                            <b-dropdown-item @click="f_addNewRow(branch_ind, 'up', 5, 'copy')">Üstüne 5 adet Dal Kopyalayarak Ekle</b-dropdown-item>
                            <b-dropdown-item @click="f_addNewRow(branch_ind, 'down')">Altına 1 adet Dal Ekle</b-dropdown-item>
                            <b-dropdown-item @click="f_addNewRow(branch_ind, 'down', 1, 'copy')">Altına 1 adet Dal Kopyalayarak Ekle</b-dropdown-item>
                            <b-dropdown-item @click="f_addNewRow(branch_ind, 'down', 5)">Altına 5 adet Dal Ekle</b-dropdown-item>
                            <b-dropdown-item @click="f_addNewRow(branch_ind, 'down', 5, 'copy')">Altına 5 adet Dal Kopyalayarak Ekle</b-dropdown-item>
                            <b-dropdown-item @click="f_deleteRow(branch_ind)">Dalı Sil</b-dropdown-item>
                          </b-dropdown>
                        </td>
                        <template v-for="(point, point_ind) in branch">
                          <td style="min-width: 50px;;">
                            <div @click="f_openAlgorithmSelectionModalForBranch(branch_ind, point_ind)" style="cursor: pointer; min-width: 50px; min-height: 20px; margin-right: 5px">
                              <template v-if="point && point.label">
                                {{ point.label }}
                              </template>
                            </div>
                          </td>
                        </template>
                        <td style="width: 200px;">
                          <div @click="f_openAlgorithmSelectionModalForBranch(branch_ind, branch.length)" style="cursor: pointer; width: 200px; min-height: 30px;">
                          </div>
                        </td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
    <b-modal v-if="d_selectAlgorithmForDiagramBranch.show" title="Algoritma Listesi" centered class="modal-success" v-model="d_selectAlgorithmForDiagramBranch.show" @ok="d_selectAlgorithmForDiagramBranch.show = false" ok-variant="success" hide-footer>
      <b-row>
        <b-col cols="12">
          <b-form-input id="select_algorithm_for_diagram_branch" type="text" placeholder="Filtrelemek için lütfen yazınız." v-model="d_selectAlgorithmForDiagramBranch.search_text"></b-form-input>
        </b-col>
      </b-row>
      <b-row style="overflow-y: auto; overflow-x: hidden; height: 500px;">
        <b-col cols="12">
          <template v-for="(x, x_ind) in d_ruleTreeList.items">
            <template v-if="d_selectAlgorithmForDiagramBranch.search_text === '' || (d_selectAlgorithmForDiagramBranch.search_text !== '' && x.label.toLowerCase().indexOf(d_selectAlgorithmForDiagramBranch.search_text.toLowerCase()) !== -1)">
              <b-card :style="d_selectAlgorithmForDiagramBranch.selected_index === x_ind ? 'margin-bottom: 3px; cursor: pointer; background: #9adcd3;' : 'margin-bottom: 3px; cursor: pointer;'" no-body @click="f_selectAlgorithmForBranch(x_ind, x)" @dblclick="d_selectAlgorithmForDiagramBranch.show = false">
                <b-col cols="12">
                  {{ x_ind + 1 }} - {{ x.label }} <small>[ {{ x.value }} ]</small>
                </b-col>
                <!-- <b-card-footer footer-bg-variant="primary" header-text-variant="dark" class="p-1" style="background-color: #5db5bd!important;"></b-card-footer> -->
              </b-card>
            </template>
          </template>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { API_FRONTEND_URL } from '@/config';
import {
  default as DmpMenu
} from '@/components/widgets/DmpMenu';
import {
  default as WdmRuleA
} from '@/components/widgets/WdmRuleA';
import {
  default as RuleTreeList
} from '@/components/widgets/RuleTreeList';
import {
  default as DrawAlgorithm
} from '@/components/widgets/DrawAlgorithm';
import {
  default as RuleEngine
} from '@/components/widgets/RuleEngine';
import {
  default as Modal
} from "@/components/widgets/Modal";
import {
  default as WisdomDataShow
} from '@/components/widgets/WisdomDataShow';
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import {
  default as MultiWdmr
} from '@/components/widgets/MultiWdmr';
import { ClsRule } from '@/services/public/rule';
import { ClsWdmrForever, ClsWdmrSave } from "@/services/public/wdm";
import ChatBotService from '@/services/chatbot';
import WdmService from '@/services/wdm';
import RuleEngineService from "@/services/rule_engine";
import WaiService from "@/services/wai";
import GlobalService from "@/services/global";
import DataformService from "@/services/dataform";
import PolestarWebService from "@/services/polestarservice";
import OptionService from "@/services/option";
import WmanagerService from "@/services/wmanager";
import DmpService from "@/services/dmp";
import moment from 'moment';
import { mapGetters } from 'vuex';
export default {
  name: 'Algorithm',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {
    RuleTreeList,
    DrawAlgorithm,
    Modal,
    MultiWdmr,
    RuleEngine,
    WdmRuleA,
    WisdomDataModal,
    WisdomDataShow,
    DmpMenu
  },
  props: {
    talk_algorithm: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      d_layerOptionDataList: {},
      d_layerWdmList: [],
      d_showRuleTreeList: false,
      d_selectAlgorithmForDiagramBranch: {
        'search_text': '',
        'show': false,
        'branch_ind': '',
        'selected_index': '',
        'point_ind': ''
      },
      d_diagramBranchData: {
        'pagination': {
          'start': 0,
          'end': 15,
          'current': 1,
          'perpage': 15,
          'all_count': 0
        },
        'timeout': 0,
        'column_count': 0,
        'fullscreen': false,
        'show': false,
        'search_text': '',
        'search_type': 'all_branch_points',
        'list': [],
        'search_index_list': []
      },
      d_excelAlgorithms: {
        'show': false,
        'excel_file': ''
      },
      d_excelDiagramRows: {
        'show': false,
        'excel_file': ''
      },
      d_selectedWdm308ApiDocument: '',
      d_chatBotDiagram: {
        'show': false,
        'diagram_point': '',
        'last_diagram_point': '',
        'algorithm_list': []
      },
      d_chatBot: {
        'show': false,
        'results': [],
        'possible_results': [],
        'wdm_list': []
      },
      d_signWdmr: {
        'user_list': [],
        'only_me': false,
        'show': false,
        'wdmr': ''
      },
      d_dataModelAdd: {
        'wdm_type': '',
        'wdm_version': ''
      },
      d_fullScreenGeneral: false,
      d_dmpSettings: {
        'style': {
          'backgroundColor': '#FFFFFF',
          'subBackgroundColor': '#FFFFFF',
          'subColor': '#000000',
          'color': '#000000',
          'dmp_header': {
            'backgroundColor': '#FFFFFF',
            'color': '#000000'
          },
          'tab_header': {
            'backgroundColor': '#FFFFFF',
            'color': '#9e9e9e'
          }
        }
      },
      d_dmpSettings_default: {
        'style': {
          'backgroundColor': '#FFFFFF',
          'subBackgroundColor': '#FFFFFF',
          'subColor': '#000000',
          'color': '#000000',
          'dmp_header': {
            'backgroundColor': '#FFFFFF',
            'color': '#000000'
          },
          'tab_header': {
            'backgroundColor': '#FFFFFF',
            'color': '#9e9e9e'
          }
        }
      },
      d_userDmpPermissions: [],
      d_dmpServiceTestResult: {
        'show': false,
        'method': 'POST',
        'uri': '',
        'response': '',
        'request': ''
      },
      d_fullScreen: false,
      d_ruleTreeList: { 'list': [], 'items': [] },
      d_diagramGraph: {
        show: true,
        width: 800,
        height: 600,
        background: "#f0f0f0",
        nodes: [],
        links: []
      },
      d_diagramData: {
        'backGround': 'white',
        'fluid': false,
        'show': true,
        'editable': true,
        'labels': {
          'edit': ' ',
          'remove': 'Kaldır',
          'link': 'Bağlantı',
          'select': 'Seç',
          'cancel': 'İptal',
          'copy': ' '
        },
        'links': [],
        'nodes': []
      },
      d_algorithmData: { 'show': false, 'data': [] },
      d_selectedAnatomySchemaList: [],
      d_dmpShowModal: false,
      d_showMultiWdmr: true,
      d_algorithmRuleNew: {
        'date_ability': '',
        'wdmr_change': { 'rule': 0 },
        'data_type': '',
        'option_data': {},
        'prepare_data': {},
        'query_list': [],
        'compile_str_text': [],
        'compile_str_array': [],
        'compile_str_date': [],
        'wra_settings': {
          'mode': 'param',
          'options': [
            { 'label': 'WDM & QUERY', 'value': 'wdm_query' },
            { 'label': 'PARAM', 'value': 'param' },
            { 'label': 'PARAM & QUERY', 'value': 'param_query' }
          ]
        },
        'wps_data': [{
          'operation': '',
          'selected_value': '',
          'loc': [],
          'val_list': [],
          'selected_wdm': '',
          'd_level': 0,
          'd_level_count': 0,
          'significant': 'true'
        }],
        'wdm_list': []
      },
      d_algorithmRule: {},
      d_showWisdomDataModal: false,
      d_ruleAnalyzeData: { 'results': {}, 'show': false },
      d_selectedTab: 'algorithmtab',
      d_eligibleWdmList: [],
      d_showQuickIcon: false,
      change: '0',
      option_data: {},
      d_selectedRuleWdmrData: '',
      d_selectedDmpWdmrData: '',
      d_selectedWdmrIndex_dmp_wdm7: '',
      d_selectedWdmrIndex_dmp_rule: '',
      d_selectedWdmrIndex_dmp: '',
      d_dmpList: { 'list': [] },
      d_dmpRulesList: { 'list': [], 'selected': [] },
      d_dmpWdm7List: {}, // test patient list
      // if we add wdm24_settings it means that, we want to create new wdmr-manage-a with the wdmstore list selected in wdmr 24. 
      // Please be careful to add this setttings field to child_wdm parameter.
      wdm22: {},
      d_wdmStoreDataList: {},
      d_selectedWdm7Data: {},
      d_ruleResult: {},
      user: {},
      d_multiWdmrData: {
        'wdmr_settings': {
          'wisdom_param': {
            'settings': 0,
            'mode': 1, // 1 normal, 2 label value mode up down parameter, 3 label value left to right,
            'style': {
              'caption': {
                'fontSize': 12,
                'color': 'black',
                'after': '',
                'backgroundColor': 'white',
                'border': {
                  'type': 'solid',
                  'size': 0,
                  'color': 'black'
                },
                'borderRadius': 0
              },
              'value': {
                'fontSize': 10,
                'color': 'black',
                'after': '',
                'backgroundColor': 'white',
                'border': {
                  'type': 'solid',
                  'size': 0,
                  'color': 'black'
                },
                'borderRadius': 0
              }
            }
          },
          'wdm_designer': {
            'show': 0,
            'settings': 0,
            'table': []
          },
          'right_page_mode': {
            'page': ''
          },
          'column_settings': {
            'cols': 1
          },
          'wdmr_schema': {
            'wdm_anatomy': {
              'show': 0,
              'view_mode': 'basic'
            },
            'wdm': {
              'show': 0,
              'view_mode': 'basic'
            }
          },
          'multi_wdmr_schema': {
            'schema_view_mode': 'basic'
          },
          'header_button': 1,
          'mode': 'basic',
          'bmi_gfr_widget': {
            'show_mode': 'left_right'
          },
          'page_divide': 3,
          'settings': {
            'show': 0
          },
          'anatomy_component': {
            'mode': 2
          },
          'anatomy_group': {
            'cols': 1
          },
          'favorite_show_mode': 0,
          'group': {
            'show': 1,
            'mode': 1
          },
          'date': {
            'show_as_row': 0
          },
          'wdmr_header_mode': 2, // 1 or 2 or 3 or 4
          'document_upload': {
            'show': 0
          }
        },
        'multi_wdmr_list': [],
        'selected_multi_wdmr': '',
        'selected_schema_change': 0
      },
      wisdom_op: 'new',
      data_type: '',
      wisdom_data: {},
      d_decisionPackageWdmList: [],
      query_list: [],
      compile_str_array: [],
      d_showRuleAlgoritmModal: false,
      mother_children: {},
      diagramModel: '',
      d_polestarProjectRule: { 'option_data': {}, 'wdm_list': [] },
      d_oldSelectedRuleWdmrData: {},
      d_wisdomeraSystem: {},
      d_dataModalUpdate: {
        'show': 0,
        'username': '',
        'pass': '',
        'wdm_list': []
      },
      d_algorithmMail: {
        'show': false,
        'username_list': [],
        'additional_text': ''
      }
    };
  },
  created: function () {
    this.d_algorithmRule = JSON.parse(JSON.stringify(this.d_algorithmRuleNew));
    this.d_wisdomeraSystem = JSON.parse(localStorage.getItem('wisdomera_system'));
    this.user = JSON.parse(localStorage.getItem('user'));
    this.wdm22 = JSON.parse(localStorage.getItem('wdm22'));
    let parent_wdm = { 'label': this.wdm22.label, 'key': 'wdm22_' + this.wdm22.id, 'bucket': 'wisdom', 'type': 'wdm22' };
    let child_wdm = { 'bucket': 'wisdom', 'type': 'wdm24' };
    let relation_type = 'keys';
    this.f_getChildList('dmp', parent_wdm, child_wdm, relation_type);
    this.f_getOptionDataList();
    // this.f_createDiagramModel();
  },
  mounted: function () {},
  methods: {
    f_filterBranchControl: function (branch_ind) {
      let res = false;
      if (this.d_diagramBranchData.search_text) {
        if (this.d_diagramBranchData.search_index_list.indexOf(branch_ind) !== -1) {
          res = true;
        }
      } else {
        if (branch_ind > this.d_diagramBranchData.pagination.start && branch_ind < this.d_diagramBranchData.pagination.end) {
          res = true;
        }
      }
      return res;
    },
    f_getChatbotIframeGlobalUrl: function () {
      try {
        let can_use = this.d_selectedDmpWdmrData.data.general.can_use_chatbot_by_token.val.value;
        let chatbot_token = this.d_selectedDmpWdmrData.data.general.chatbot_token.val;
        if (can_use === 'yes' && chatbot_token) {
          let msg = API_FRONTEND_URL + '#/chatbotiframeglobal?dmp_id=' + this.$route.query.dmp_id + '&chatbot_token=' + chatbot_token;
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Chatbot global iframe url' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          this.$forceUpdate();
        }
      } catch (err) {}
    },
    f_openNewPageWithChatbotGlobalIframe: function () {
      try {
        let can_use = this.d_selectedDmpWdmrData.data.general.can_use_chatbot_by_token.val.value;
        let chatbot_token = this.d_selectedDmpWdmrData.data.general.chatbot_token.val;
        if (can_use === 'yes' && chatbot_token) {
          let uri = API_FRONTEND_URL + '#/chatbotiframeglobal?dmp_id=' + this.$route.query.dmp_id + '&chatbot_token=' + chatbot_token;
          window.open(uri, '_blank');
        }
      } catch (err) {}
    },
    f_updateDiagramBranchs: function () {
      this.d_ruleTreeList.list = [];
      let query = 'dmp_id=' + this.d_selectedDmpWdmrData.id;
      let data = {
        'branch_list': this.d_diagramBranchData.list
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Diyagram karar ağacı hazırlanıyor. Lütfen bekleyiniz' } });
      DmpService.create_diagram_from_branch_list(query, data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '1000') {
            this.d_ruleTreeList.list = resp.data.d_ruleTreeList.list;
          } else {
            let msg = '';
            msg = resp.data.status_message;
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
            this.$forceUpdate();
          }
        });
    },
    f_calculteStyleDiagramEditBranch: function () {
      let height = 500;
      if (this.d_diagramBranchData.fullscreen) {
        height = window.innerHeight - 90;
      }
      return 'overflow-y: auto; height:' + height.toString() + 'px; overflow-x: auto; margin-bottom: 0px;';
    },
    f_deleteColumn: function (column_index) {
      for (let row_ind in this.d_diagramBranchData.list) {
        this.d_diagramBranchData.list[row_ind].splice(column_index, 1);
      }
      this.$forceUpdate();
    },
    f_deleteRow: function (row_ind) {
      this.d_diagramBranchData.list.splice(row_ind, 1);
      this.$forceUpdate();
    },
    f_addNewColumn: function (column_index, direction, count = 1) {
      for (let i = 0; i < count; i++) {
        if (direction === 'right') {
          for (let row_ind in this.d_diagramBranchData.list) {
            if (column_index === this.d_diagramBranchData.list[row_ind].length - 1) {
              this.d_diagramBranchData.list[row_ind].push('');
            } else {
              this.d_diagramBranchData.list[row_ind].splice(column_index + 1, 0, '');
            }
          }
        } else if (direction === 'left') {
          for (let row_ind in this.d_diagramBranchData.list) {
            if (column_index === 0) {
              this.d_diagramBranchData.list[row_ind].splice(0, 0, '');
            } else {
              this.d_diagramBranchData.list[row_ind].splice(column_index - 1, 0, '');
            }
          }
        }
      }
      this.$forceUpdate();
    },
    f_addNewRow: function (row_ind, direction, count = 1, operation_type = '') {
      for (let i = 0; i < count; i++) {
        let new_row = [];
        if (this.d_diagramBranchData.list.length > 0) {
          if (operation_type === 'copy') {
            for (let i in this.d_diagramBranchData.list[row_ind]) {
              let cell_data = this.d_diagramBranchData.list[row_ind][i];
              new_row.push(cell_data);
            }
          } else {
            for (let i in this.d_diagramBranchData.list[0]) {
              new_row.push('');
            }
          }
          if (direction === 'down') {
            if (row_ind === this.d_diagramBranchData.list.length - 1) {
              this.d_diagramBranchData.list.push(new_row);

            } else {
              this.d_diagramBranchData.list.splice(row_ind + 1, 0, new_row);
            }
          } else {
            if (row_ind === 0) {
              this.d_diagramBranchData.list.splice(0, 0, new_row);
            } else {
              this.d_diagramBranchData.list.splice(row_ind, 0, new_row);
            }
          }
        } else {
          this.d_diagramBranchData.list.push([
            ['']
          ]);
        }
      }
      this.$forceUpdate();
    },
    f_openAlgorithmSelectionModalForBranch: function (branch_ind, point_ind) {
      this.d_selectAlgorithmForDiagramBranch.search_text = '';
      this.d_selectAlgorithmForDiagramBranch.branch_ind = branch_ind;
      this.d_selectAlgorithmForDiagramBranch.point_ind = point_ind;
      this.d_selectAlgorithmForDiagramBranch.show = true;
      this.d_selectAlgorithmForDiagramBranch.selected_index = '';
      setTimeout(function () {
        let x = document.getElementById('select_algorithm_for_diagram_branch');
        if (x) {
          x.focus();
        }
      }.bind(this), 500);
    },
    f_selectAlgorithmForBranch: function (x_ind, x) {
      this.d_selectAlgorithmForDiagramBranch.selected_index = x_ind;
      this.d_diagramBranchData.list[this.d_selectAlgorithmForDiagramBranch.branch_ind][this.d_selectAlgorithmForDiagramBranch.point_ind] = x;
      this.$forceUpdate();
    },
    f_editDiagramBranch: function () {
      let query = 'dmp_id=' + this.d_selectedDmpWdmrData.id;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Diyagram karar ağacı dalları hazırlanıyor. Lütfen bekleyiniz' } });
      DmpService.create_diagram_branchs_as_rows(query)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '1000') {
            this.d_diagramBranchData.list = resp.data.list;
            for (let i in this.d_diagramBranchData.list) {
              if (this.d_diagramBranchData.list[i].length > this.d_diagramBranchData.column_count) {
                this.d_diagramBranchData.column_count = this.d_diagramBranchData.list[i].length;
              }
            }
            this.d_diagramBranchData.show = true;
          } else {
            let msg = '';
            msg = resp.data.status_message;
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
            this.$forceUpdate();
          }
        });
    },
    f_addAlgorithmsFromExcel: function () {
      this.d_excelAlgorithms.file = '';
      this.d_excelAlgorithms.show = true;
    },
    f_addDiagramAlgorithmsFromExcel: function () {
      this.d_excelDiagramRows.file = '';
      this.d_excelDiagramRows.show = true;
    },
    f_addExcelDiagramAlgorithmsToDmp: function (type) {
      if (this.d_excelDiagramRows.file) {
        let on_screen_merge_rule_list = [];
        if (type  === 'add') {
          for (let i in this.d_diagramBranchData.list) {
            let merged_rule_id_list = '';
            for (let k in this.d_diagramBranchData.list[i]) {
              merged_rule_id_list += this.d_diagramBranchData.list[i][k]['value'] + '_';
            }
            if (merged_rule_id_list) {
              on_screen_merge_rule_list.push(merged_rule_id_list);
            }
          }
        } else if (type === 'reset') {
          this.d_diagramBranchData.list = [];
        }
        this.d_diagramBranchData.show = false;
        let query = 'dmp_id=' + this.d_selectedDmpWdmrData.id;
        let data = {
          'file': this.d_excelDiagramRows.file
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Excel dökümanı analizi başlatıldı. Lütfen bekleyiniz.' } });
        DmpService.diagram_create_from_excel(query, data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            console.log(resp.data);
            if (resp.data.status_code === '1000') {
              if (type === 'reset') {
                this.d_diagramBranchData.list = resp.data.diagram_rows;
              } else if (type === 'add') {
                for (let i in resp.data.diagram_rows) {
                  let merged_rule_id_list = '';
                  for (let k in resp.data.diagram_rows[i]) {
                    merged_rule_id_list += resp.data.diagram_rows[i][k]['value'] + '_';
                  }
                  if (on_screen_merge_rule_list.indexOf(merged_rule_id_list) === -1) {
                    this.d_diagramBranchData.list.push(resp.data.diagram_rows[i]);
                  }
                }
              }
              for (let i in this.d_diagramBranchData.list) {
                if (this.d_diagramBranchData.list[i].length > this.d_diagramBranchData.column_count) {
                  this.d_diagramBranchData.column_count = this.d_diagramBranchData.list[i].length;
                }
              }
              this.d_diagramBranchData.show = true;
            } else {
              alert('Error :' + resp.data.status_message);
            }
          });
      } else {
        alert('Lütfen öncelikle excel dökümanı seçimi yapınnız');
      }
    },
    f_selectExcelDocument: function (div_id) {
      if (document.getElementById(div_id).value) {
        let arr = document.getElementById(div_id).value.split('.');
        let fileExtension = arr[arr.length - 1];
        if (['xlsx', 'xls'].indexOf(fileExtension.toLowerCase()) === -1) {
          document.getElementById(div_id).value = '';
          alert('Lütfen xlsx, xls formatını kullanınız');
          return;
        }
        var files = document.getElementById(div_id).files;
        if (!files.length) {
          return;
        }
        var reader = new FileReader();
        let vm = this;
        reader.onload = (e) => {
          if (div_id === 'excel_algorithm_document') {
            vm.d_excelAlgorithms.file = e.target.result;
          } else if (div_id === 'excel_diagram_algorithm_document') {
            vm.d_excelDiagramRows.file = e.target.result;
          }
        };
        reader.readAsDataURL(files[0]);
        this.$forceUpdate();
      }
    },
    f_addExcelAlgorithmsToDmp: function () {
      if (this.d_excelAlgorithms.file) {
        let query = 'dmp_id=' + this.d_selectedDmpWdmrData.id;
        let data = {
          'file': this.d_excelAlgorithms.file
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Excel dökümanı analizi başlatıldı. Lütfen bekleyiniz.' } });
        DmpService.add_algorithms_from_excel(query, data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            console.log(resp.data);
            if (resp.data.status_code === '1000') {
              let wdm24_key = 'wdm24_' + this.d_selectedDmpWdmrData.id;
              let parent_wdm = {
                'key': wdm24_key,
                'bucket': 'wisdom',
                'type': 'wdm24'
              };
              let child_wdm = {
                'bucket': 'wisdom',
                'type': 'rule'
              };
              let relation_type = 'keys';
              this.f_getChildList('dmp_rule', parent_wdm, child_wdm, relation_type);
            } else {
              alert('Error :' + resp.data.status_message);
            }
          });
      } else {
        alert('Lütfen öncelikle excel dökümanı seçimi yapınnız');
      }
    },
    f_goToChatBotIframe: function () {
      /*
      let route_data = {
        'name': 'chatbotiframe',
        'path': '/chatbotiframe',
        'query': {
          'dmp_id': this.$route.query.dmp_id
        }
      };
      this.$router.push(route_data);
      window.open(uri, '_blank');
      */
      let uri = API_FRONTEND_URL + '#/chatbotiframe?dmp_id=' + this.$route.query.dmp_id;
      window.open(uri, '_blank');
    },
    f_chatBotDiagramBack: function () {
      let point_list = this.d_chatBotDiagram.last_diagram_point.split(',');
      // console.log(point_list);
      if (point_list.length === 1) {
        this.d_chatBotDiagram.diagram_point = '';
      } else {
        point_list.splice(point_list.length - 1, 1);
        this.d_chatBotDiagram.diagram_point = point_list.join(',');
        // console.log(this.d_chatBotDiagram.diagram_point);
      }
      this.f_chatBotDiagramAnalyze();
    },
    f_selectDiagramPoint: function (diagram_point) {
      this.d_chatBotDiagram.diagram_point = diagram_point;
      this.$forceUpdate();
    },
    f_selectDiagramPointAndGo: function (diagram_point) {
      this.d_chatBotDiagram.diagram_point = diagram_point;
      this.$forceUpdate();
      this.f_chatBotDiagramAnalyze();
    },
    f_calculateApproveRowStyle: function (x) {
      if (x.approve === '') {
        return 'margin: 0px;'
      } else if (x.approve === '1') {
        return 'margin: 0px; background-color: #afffac;'
      } else if (x.approve === '0') {
        return 'margin: 0px; background-color: #ffebce;'
      }
    },
    f_openChatBotDiagramModal: function () {
      this.d_chatBotDiagram.diagram_point = '';
      this.d_chatBotDiagram.algorithm_list = [];
      this.f_chatBotDiagramAnalyze();
    },
    f_chatBotDiagramAnalyze: function () {
      let query = "dmp_id=" + this.d_selectedDmpWdmrData.id;
      if (this.d_chatBotDiagram.diagram_point) {
        query += '&diagram_point=' + this.d_chatBotDiagram.diagram_point;
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz' } });
      ChatBotService.chatbot_talk_diagram(query)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '1000') {
            this.d_chatBotDiagram.last_diagram_point = this.d_chatBotDiagram.diagram_point;
            this.d_chatBotDiagram.algorithm_list = resp.data.algorithm_list;
            this.d_chatBotDiagram.show = true;
          } else {
            let msg = '';
            msg = resp.data.status_message;
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': '' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
            this.$forceUpdate();
          }
        });
    },
    f_openChatBotModal: function () {
      this.d_chatBot.wdm_list = [];
      this.f_chatBotAnalyze();
    },
    f_chatBotAnalyze: function () {
      let query = "dmp_id=" + this.d_selectedDmpWdmrData.id;
      let data = {
        'wdm_list': this.d_chatBot.wdm_list
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz' } });
      ChatBotService.chatbot_talk(query, data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '1000') {
            this.d_chatBot.wdm_list = resp.data.wdm_list;
            for (let i in this.d_chatBot.wdm_list) {
              for (let p in this.d_chatBot.wdm_list[i].params) {
                if (this.d_chatBot.wdm_list[i].params[p].val === undefined) {
                  if (['checkbox'].indexOf(this.d_chatBot.wdm_list[i].params[p].type) !== -1) {
                    this.d_chatBot.wdm_list[i].params[p].val = [];
                  } else {
                    this.d_chatBot.wdm_list[i].params[p].val = "";
                  }
                }
              }
            }
            this.d_chatBot.results = resp.data.results;
            this.d_chatBot.possible_results = resp.data.possible_results;
            this.d_chatBot.show = true;
          } else {
            let msg = '';
            msg = resp.data.status_message;
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': '' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
            this.$forceUpdate();
          }
        });
    },
    f_sendAlgorithmApproveMailThisUserList: function (username_list) {
      this.d_algorithmMail.additional_text = '';
      this.d_algorithmMail.username_list = username_list;
      this.d_algorithmMail.show = true;
    },
    f_sendRuleAlgorithmApproveMail: function () {
      let query = 'dmp_id=' + this.d_selectedDmpWdmrData.id;
      query += '&rule_id=' + this.d_selectedRuleWdmrData.id;
      query += '&username_list=' + this.d_algorithmMail.username_list.join(',');
      let data = {
        'additional_text': this.d_algorithmMail.additional_text
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Veri modeli güncelleme işlemleri kontrol ediliyor. Lütfen bekleyiniz' } });
      DmpService.send_rule_algorithm_approve_mail(query, data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          let msg = '';
          if (resp.data.status_code === '1000') {
            this.d_selectedRuleWdmrData.sign = resp.data.result.sign;
            this.d_algorithmMail.show = false;
            this.d_signWdmr.show = false;
            msg = 'Başarıyla tamamlandı.';
          } else {
            msg = resp.data.status_message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          this.$forceUpdate();
        });
    },
    f_changeRuleAlgorithmStatus: function (status) {
      if (this.d_userDmpPermissions.indexOf('owner') !== -1) {
        if (status === 'active') {
          this.d_selectedRuleWdmrData.data.general.status = { 'val': { 'value': 'active', 'label': 'aktif' } };
        } else if (status === 'passive') {
          this.d_selectedRuleWdmrData.data.general.status = { 'val': { 'value': 'passive', 'label': 'pasif' } };
        }
        let parent_wdm = { 'label': this.d_selectedDmpWdmrData.label, 'key': 'wdm24_' + this.d_selectedDmpWdmrData.id, 'bucket': 'wisdom', 'type': 'wdm24' };
        let child_wdm = { 'bucket': 'wisdom', 'type': 'rule' };
        let relation_type = 'keys';
        let data = {
          'data': this.d_selectedRuleWdmrData,
          'child_wdm': child_wdm,
          'parent_wdm': parent_wdm,
          'relation_type': relation_type
        };
        WdmService.save_child_wdmr(data)
          .then(resp => {
            let msg = '';
            if (resp.data.status === "success") {
              this.d_selectedRuleWdmrData = resp.data.result.wdmr;
              this.f_wisdomShowUpdate();
              msg = this.$t('wdm16.13275');
            } else {
              msg = 'error: ' + resp.data.message;
            }
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
            this.$forceUpdate();
          });
      } else {
        let msg = 'Yetkiniz bulunmamaktadır. Lütfen paket yetkilisi ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar destek paketi durum değiştirme işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_signApproveAlgorithm: function (approve_type, user_index) {
      let user_data = this.d_signWdmr.user_list[user_index];
      /*
        approve_type = "0" or "1"
      */
      if (!this.d_signWdmr.wdmr.sign) {
        this.d_signWdmr.wdmr.sign = { 'list': [] };
      }
      let dt = new Date();
      let new_sign_data = {
        'id': user_data.id,
        'username': user_data.username,
        'date': dt.toISOString(),
        'approve': approve_type,
        'note': ''
      };
      let sign_index = '';
      for (let i in this.d_signWdmr.wdmr.sign.list) {
        if (this.d_signWdmr.wdmr.sign.list[i].username === user_data.username) {
          sign_index = parseInt(i);
          break;
        }
      }
      if (sign_index !== '') {
        this.d_signWdmr.wdmr.sign.list[sign_index]['id'] = new_sign_data.id;
        this.d_signWdmr.wdmr.sign.list[sign_index]['username'] = new_sign_data.username;
        this.d_signWdmr.wdmr.sign.list[sign_index]['approve'] = new_sign_data.approve;
        this.d_signWdmr.wdmr.sign.list[sign_index]['date'] = new_sign_data.date;
      } else {
        this.d_signWdmr.wdmr.sign.list.push(new_sign_data);
        sign_index = this.d_signWdmr.wdmr.sign.list.length - 1;
      }
      if (!this.d_signWdmr.wdmr.sign.list[sign_index].log) {
        this.d_signWdmr.wdmr.sign.list[sign_index].log = [];
      }
      let log_data = {
        'date': new_sign_data.date,
        'approve': new_sign_data.approve,
        'note': new_sign_data.note
      };
      this.d_signWdmr.wdmr.sign.list[sign_index].log.push(log_data);
      let status_sign_approve_type = '';
      /*
        status_cannot_be_changed_by_sign,
        status_can_be_changed_to_active_by_one_signer,
        status_can_be_changed_to_active_by_all_signers
      */
      try {
        status_sign_approve_type = this.d_selectedDmpWdmrData.data.general.status_sign_approve_type.val.value;
      } catch (err) {}
      let approved_count = 0;
      for (let i in this.d_signWdmr.wdmr.sign.list) {
        if (this.d_signWdmr.wdmr.sign.list[i].approve === '1') {
          approved_count += 1;
        }
      }
      if (['status_can_be_changed_to_active_by_one_signer', 'status_can_be_changed_to_active_by_all_signers'].indexOf(status_sign_approve_type) !== -1) {
        this.d_signWdmr.wdmr.data.general.status = { 'val': { 'value': 'passive', 'label': 'pasif' } };
      }
      if (status_sign_approve_type === 'status_can_be_changed_to_active_by_one_signer') {
        if (approved_count > 0) {
          this.d_signWdmr.wdmr.data.general.status = { 'val': { 'value': 'active', 'label': 'aktif' } };
        }
      } else if (status_sign_approve_type === 'status_can_be_changed_to_active_by_all_signers') {
        if (approved_count === this.d_signWdmr.wdmr.sign.list.length) {
          this.d_signWdmr.wdmr.data.general.status = { 'val': { 'value': 'active', 'label': 'aktif' } };
        }
      }
      let parent_wdm = { 'label': this.d_selectedDmpWdmrData.label, 'key': 'wdm24_' + this.d_selectedDmpWdmrData.id, 'bucket': 'wisdom', 'type': 'wdm24' };
      let child_wdm = { 'bucket': 'wisdom', 'type': 'rule' };
      let relation_type = 'keys';
      let data = {
        'data': this.d_signWdmr.wdmr,
        'child_wdm': child_wdm,
        'parent_wdm': parent_wdm,
        'relation_type': relation_type
      };
      WdmService.save_child_wdmr(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === "success") {
            this.d_selectedRuleWdmrData = resp.data.result.wdmr;
            this.f_wisdomShowUpdate();
            this.f_createSignerUsers();
            if (approve_type === '0') {
              msg = "Algoritmaya yapmış olduğunuz red işlemi kaydedilmiştir";
            } else if (approve_type === '1') {
              msg = "Algoritmaya yapmış olduğunuz onay işlemi kaydedilmiştir";
            } else {
              msg = this.$t('wdm16.13275');
            }
          } else {
            msg = 'error: ' + resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          this.$forceUpdate();
        });
    },
    f_signAlgorithm: function () {
      this.d_signWdmr.wdmr = JSON.parse(JSON.stringify(this.d_selectedRuleWdmrData));
      this.d_signWdmr.only_me = false;
      this.d_signWdmr.show = true;
    },
    f_showOnlyMeSignModal: function () {
      if (this.d_userDmpPermissions.indexOf('sign_algorithm_rule') !== -1 || this.d_userDmpPermissions.indexOf('owner') !== -1) {
        this.d_signWdmr.wdmr = JSON.parse(JSON.stringify(this.d_selectedRuleWdmrData));
        this.d_signWdmr.only_me = true;
        this.d_signWdmr.show = true;
      }
    },
    f_createSignerUsers: function () {
      this.d_signWdmr.user_list = [];
      try {
        for (let i in this.d_selectedDmpWdmrData.data.general.who.list) {
          for (let u in this.d_selectedDmpWdmrData.data.general.who.list[i].selected_users) {
            let user_data = {
              'id': '',
              'username': u,
              'approve': '',
              'date': '',
              'log': [],
              'mail_log': []
            }
            let det = false;
            for (let p in this.d_selectedDmpWdmrData.data.general.who.list[i].selected_users[u].detail.user_permissions.val) {
              if (this.d_selectedDmpWdmrData.data.general.who.list[i].selected_users[u].detail.user_permissions.val[p].value === 'sign_algorithm_rule') {
                det = true;
                break;
              }
            }
            if (det) {
              if (this.d_selectedRuleWdmrData && this.d_selectedRuleWdmrData.sign && this.d_selectedRuleWdmrData.sign.list && this.d_selectedRuleWdmrData.sign.list.length > 0) {
                for (let s in this.d_selectedRuleWdmrData.sign.list) {
                  if (this.d_selectedRuleWdmrData.sign.list[s].username === user_data.username) {
                    user_data.approve = this.d_selectedRuleWdmrData.sign.list[s].approve;
                    user_data.log = this.d_selectedRuleWdmrData.sign.list[s].log;
                    user_data.mail_log = this.d_selectedRuleWdmrData.sign.list[s].mail_log;
                    break;
                  }
                }
              }
              this.d_signWdmr.user_list.push(user_data);
            }
          }
        }
      } catch (err) {}
      if (this.$route.query.show_sign) {
        this.f_showOnlyMeSignModal();
      }
    },
    f_addToDataModalUpdate: function () {
      if (this.d_dataModelAdd.wdm_type && this.d_dataModelAdd.wdm_version) {
        let wdm_data = {
          'type': 'manuel',
          'location': '',
          'wdm_type': this.d_dataModelAdd.wdm_type,
          'wdm_version': this.d_dataModelAdd.wdm_version,
          'wdm_name': this.d_dataModelAdd.wdm_type
        };
        this.d_dataModalUpdate.wdm_list.push(wdm_data);
        this.$forceUpdate();
      } else {
        let msg = 'Veri modeline ait id ve versiyon bilgisini lütfen giriniz.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Veri modeli güncelleme & indirme işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_openGetNewModels: function () {
      this.d_dataModalUpdate = {
        'show': 1,
        'username': '',
        'pass': '',
        'wdm_list': []
      };
    },
    f_openUpdateData: function (location, wdm_type, wdm_version, wdm_name) {
      this.d_dataModalUpdate = {
        'show': 1,
        'username': '',
        'pass': '',
        'wdm_list': []
      };
      this.d_dataModalUpdate.wdm_list.push({ 'location': location, 'wdm_type': wdm_type, 'wdm_version': wdm_version, 'wdm_name': wdm_name });
    },
    f_updateDataModelFromRemoteServer: function () {
      if (this.d_dataModalUpdate.username && this.d_dataModalUpdate.pass && this.d_dataModalUpdate.wdm_list.length > 0) {
        let data = {
          'username': this.d_dataModalUpdate.username,
          'pass': this.d_dataModalUpdate.pass,
          'wdm_list': this.d_dataModalUpdate.wdm_list
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Veri modeli güncelleme işlemleri kontrol ediliyor. Lütfen bekleyiniz' } });
        DataformService.update_data_model_from_remote_server(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.result.result && resp.data.result.result.wdm_list) {
              for (let i in resp.data.result.result.wdm_list) {
                let x = resp.data.result.result.wdm_list[i];
                msg += x.wdm_name + ', ' + x.wdm_type + ', ' + x.wdm_version + '....... => ' + x.status + '\n';
              }
            }
            if (resp.data.status === 'success') {
              if (resp.data.result.status === 'success') {
                // this.d_dataModalUpdate.show = false;
                msg = 'İşlem tamamlandı\n' + msg;
                let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Veri modeli güncelleme & indirme işlemi hakkında' };
                this.$store.commit('modal', modal_data);
                this.$store.commit('modal_show', true);
              } else {
                msg = resp.data.result.message + ' ' + resp.data.webservice_message + '\n' + msg;
                let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Veri modeli güncelleme & indirme işlemi hakkında' };
                this.$store.commit('modal', modal_data);
                this.$store.commit('modal_show', true);
              }
            } else {
              msg = resp.data.message + ' ' + resp.data.webservice_message + '\n' + msg;
              let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Veri modeli güncelleme & indirme işlemi hakkında' };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
          });
      } else {
        let msg = 'Veri modellerinizi güncellemek için lütfen dataform uygulamasına ait kullanıcı adı ve şifrenizi giriniz ve en az bir adet veri modeli ekleyiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Veri modeli güncelleme & indirme işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_goToDataFormApp: function (w, v, l) {
      // let system_uri = 'http://localhost:8086/';
      let system_uri = 'https://dataform.wisdomera.io/';
      let uri = system_uri + '#/dataform?w=' + w + '&v=' + v + '&l=' + l;
      window.open(uri, '_blank');
    },
    DateFormat: function (date) {
      if (date) {
        return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
      } else {
        return '';
      }
    },
    DateFormatWithTime: function (date) {
      if (date) {
        return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm');
      } else {
        return '';
      }
    },
    f_openDmpList: function () {
      this.d_dmpShowModal = true;
    },
    f_controlMultiWdmrShow: function () {
      if (this.d_multiWdmrData.multi_wdmr_list.length > 0) {
        return true;
      }
      try {
        if (this.d_selectedDmpWdmrData.data.general.wdm.list.length > 0) {
          return true;
        }
      } catch (err) {}
      try {
        if (this.d_selectedDmpWdmrData.data.general.polestar_project_id.val) {
          return true;
        }
      } catch (err) {}
      return false;
    },
    f_updateDmpRuleNames: function () {
      if (this.d_selectedRuleWdmrData && this.d_selectedRuleWdmrData.id) {
        for (let i in this.d_dmpRulesList.list) {
          if (this.d_dmpRulesList.list[i].key === 'rule_' + this.d_selectedRuleWdmrData.id) {
            this.d_dmpRulesList.list[i].label = this.d_selectedRuleWdmrData.label ? this.d_selectedRuleWdmrData.label : '';
            break;
          }
        }
        if (this.d_selectedDmpWdmrData && this.d_selectedDmpWdmrData.d_ruleTreeList && this.d_selectedDmpWdmrData.d_ruleTreeList.items) {
          for (let i in this.d_selectedDmpWdmrData.d_ruleTreeList.items) {
            if (this.d_selectedDmpWdmrData.d_ruleTreeList.items[i].value === 'rule_' + this.d_selectedRuleWdmrData.id) {
              this.d_selectedDmpWdmrData.d_ruleTreeList.items[i].label = this.d_selectedRuleWdmrData.label ? this.d_selectedRuleWdmrData.label : '';
              break;
            }
          }
        }
        if (this.d_selectedDmpWdmrData && this.d_selectedDmpWdmrData.d_ruleTreeList && this.d_selectedDmpWdmrData.d_ruleTreeList.list) {
          this.f_updateRuleTreeNames(this.d_selectedDmpWdmrData.d_ruleTreeList.list);
        }
        this.f_saveDmp();
      }
    },
    f_updateRuleTreeNames: function (rule_tree_list) {
      for (let i in rule_tree_list) {
        if (rule_tree_list[i].value === 'rule_' + this.d_selectedRuleWdmrData.id) {
          rule_tree_list[i].label = this.d_selectedRuleWdmrData.label ? this.d_selectedRuleWdmrData.label : '';
        }
        if (rule_tree_list[i].list && rule_tree_list[i].list.length > 0) {
          this.f_updateRuleTreeNames(rule_tree_list[i].list);
        }
      }
    },
    f_getJsonStringify: function (data) {
      return JSON.stringify(data, null, 4);
    },
    f_selectAllRules: function () {
      if (this.d_dmpRulesList.selected.length > 0) {
        this.d_dmpRulesList.selected = [];
      } else {
        this.d_dmpRulesList.selected = [];
        for (let i in this.d_dmpRulesList.list) {
          this.d_dmpRulesList.selected.push(parseInt(i));
        }
      }
    },
    f_addToDiagramNodes: function (rule_key) {
      let last_node = false;
      if (this.d_diagramData.nodes.length >= 1) {
        last_node = this.d_diagramData.nodes[this.d_diagramData.nodes.length - 1];
      }
      let new_node = {
        'id': rule_key,
        'content': {
          'text': rule_key,
          // 'url': 'www.wisdomera.io',
          'color': 'red'
        },
        'width': 100,
        'height': 50,
        'shape': 'ellipse', // ellipse or rectangle
        'point': {
          'x': 20,
          'y': 20
        }
      };
      if (this.d_diagramData.nodes.length >= 1) {
        let new_link = {
          'id': rule_key,
          'source': last_node.id,
          'destination': rule_key,
          'color': 'blue',
          'pattern': 'dash',
          'arrow': 'dest',
          'point': {
            'x': last_node && last_node.point ? last_node.point.x += 50 : 50,
            'y': 30
          }
        };
        this.d_diagramData.links.push(new_link);
      }
      this.d_diagramData.nodes.push(new_node);
      // this.d_diagramShowFunc();
    },
    d_diagramShowFunc: function () {
      this.d_diagramData.show = false;
      setTimeout(function () {
        this.d_diagramData.show = true;
      }.bind(this), 500);
    },
    editNode (node) {
      /* selected node */
      /* event handler */
      // this.d_diagramShowFunc();
      // console.log('editNode: ', node);
    },
    editLink (link) {
      /* selected link */
      /* event handler */
      // this.d_diagramShowFunc();
    },
    nodeChanged (obj) {
      /* array of nodes */
      /* event handler */
      const nodes = obj.nodes
        // console.log('nodeChanged: ', obj);
      this.d_diagramData.nodes = obj.nodes;
      // this.d_diagramShowFunc();
    },
    linkChanged (obj) {
      /* array of links */
      /* event handler */
      const links = obj.links
        // console.log('linkChanged: ', obj);
      this.d_diagramData.links = obj.links;
      // this.d_diagramShowFunc();
    },
    f_createDiagramModel: function () {
      this.diagramModel = new Diagram.Model();
      const node1 = this.diagramModel.addNode("test2", 300, 200);
      const inPort = node1.addInPort("test");
      const node2 = this.diagramModel.addNode("test", 10, 300, 144, 80);
      const node2OutPort = node2.addOutPort("testOut");
      node2.addOutPort("testOut2");
      node2.color = "#00cc66";
      const node3 = this.diagramModel.addNode("test3", 10, 100, 72, 100);
      const node3OutPort = node3.addOutPort("testOut3");
      node3.color = "#cc6600";
      this.diagramModel.addLink(node2OutPort, inPort);
      this.diagramModel.addLink(node3OutPort, inPort);
    },
    f_getDmpRuleAlgorithmList: function () {
      if (this.user && this.user.permissions_result && this.user.permissions_result.indexOf('decision-making-system_rule-engine_create-dmp-algorithm-show') !== -1) {
        if (this.d_userDmpPermissions.indexOf('owner') !== -1 || this.d_userDmpPermissions.indexOf('show_algorithm_show') !== -1) {
          let data = {
              'bucket': 'wisdom',
              'key': 'wdm24_' + this.d_selectedDmpWdmrData.id
            }
            // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Karar destek paketleri algoritmaları getiriliyor. Lütfen bekleyiniz' } });
          RuleEngineService.get_dmp_rule_algorithm_list(data)
            .then(resp => {
              // this.$store.commit('Loading', { 'status': false, 'data': {} });
              if (resp.data.status === 'success') {
                // console.log(resp.data.result);
                this.d_algorithmData.data = resp.data.result.list;
                this.d_algorithmData.show = true;
              } else {
                alert('Error ', resp.data.message);
              }
            });
        } else {
          let msg = 'Karar destek paketinde algoritma show yetkiniz bulunmamaktadır. Lütfen paket yetkilisi ile temasa geçiniz';
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar destek paketi algoritma show işlemi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        }
      } else {
        let msg = 'Karar destek paketi algoritma show yapma yetkiniz bulunmamaktadır. Lütfen sistem yetkilisi ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar destek paketi algoritma show işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_drawAlgorithm: function () {
      if (this.user && this.user.permissions_result && this.user.permissions_result.indexOf('decision-making-system_rule-engine_create-dmp-algorithm-show') !== -1) {
        if (this.d_userDmpPermissions.indexOf('owner') !== -1 || this.d_userDmpPermissions.indexOf('show_algorithm_show') !== -1) {
          if (this.d_selectedRuleWdmrData && this.d_selectedRuleWdmrData.rule_algoritm) {
            this.d_algorithmData.data = [];
            this.d_algorithmData.data.push(JSON.parse(JSON.stringify(this.d_selectedRuleWdmrData.rule_algoritm)));
            this.d_algorithmData.show = true;
          } else {
            let msg = 'Algoritma show işlemi için öncelikle algoritma seçmeniz gerekmektedir.';
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar destek paketi algoritma show işlemi hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
        } else {
          let msg = 'Karar destek paketinde algoritma show yetkiniz bulunmamaktadır. Lütfen paket yetkilisi ile temasa geçiniz';
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar destek paketi algoritma show işlemi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        }
      } else {
        let msg = 'Karar destek paketi algoritma show yapma yetkiniz bulunmamaktadır. Lütfen sistem yetkilisi ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar destek paketi algoritma show işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_copyRuleAlgorithm: function () {
      this.data_type = 'rule';
      this.wisdom_op = "new";
      let pre_data = {
        'id': 'new',
        'data': JSON.parse(JSON.stringify(this.d_selectedRuleWdmrData.data)),
        'type': 'rule',
        'reference_wdm': {},
        'parent': this.data_type
      };
      if (this.d_selectedRuleWdmrData.rule_algoritm) {
        pre_data['rule_algoritm'] = this.d_selectedRuleWdmrData.rule_algoritm;
      }
      pre_data['reference_wdm']['version'] = this.option_data[this.data_type]['version'];
      pre_data['reference_wdm']['owner_type'] = this.option_data[this.data_type]['owner_type'];
      if (this.option_data[this.data_type]['owner_type'] === 'wisdom') {
        pre_data['reference_wdm']['key'] = 'wdm_wisdom_' + this.data_type + '_v' + this.option_data[this.data_type].version;
      }
      this.wisdom_data = pre_data;
      this.d_showWisdomDataModal = true;
    },
    f_showMultiWdmr: function () {
      this.d_showMultiWdmr = false;
      setTimeout(function () {
        this.d_showMultiWdmr = true;
      }.bind(this), 500);
    },
    f_deleteSelectedChildWdmr: function (type, ind = '') {
      if (type === 'wdm24') {
        ind = this.d_selectedWdmrIndex_dmp;
        if (this.d_userDmpPermissions.indexOf('owner') !== -1) {
          //
        } else {
          let msg = 'Karar destek paketini sadece paketin sahibi silebilir. Lütfen paket yetkilisi ile temasa geçiniz';
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar destek paketi silme işlemi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          return;
        }
      }
      if (type === 'rule') {
        ind = this.d_selectedWdmrIndex_dmp_rule;
        if (this.d_userDmpPermissions.indexOf('owner') !== -1 || this.d_userDmpPermissions.indexOf('delete_rule') !== -1) {
          //
        } else {
          let msg = 'Karar destek paketindeki kuralları silme yetkiniz bulunmamaktadır. Lütfen paket yetkilisi ile temasa geçiniz';
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar destek paketi kuralı silme işlemi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          return;
        }
      }
      if (confirm('Silme işlemini gerçekleştirmek istediğinize emin misiniz?')) {
        let parent_wdm = '';
        let child_wdm = '';
        let relation_type = '';
        if (type === 'wdm24') {
          parent_wdm = { 'label': this.wdm22.label, 'key': 'wdm22_' + this.wdm22.id, 'bucket': 'wisdom', 'type': 'wdm22' };
          child_wdm = { 'bucket': 'wisdom', 'type': 'wdm24', 'key': 'wdm24_' + this.d_selectedDmpWdmrData.id };
          relation_type = 'keys';
        } else if (type === 'rule') {
          parent_wdm = { 'label': this.d_selectedDmpWdmrData.label, 'key': 'wdm24_' + this.d_selectedDmpWdmrData.id, 'bucket': 'wisdom', 'type': 'wdm24' };
          child_wdm = { 'bucket': 'wisdom', 'type': 'rule', 'key': 'rule_' + this.d_selectedRuleWdmrData.id };
          relation_type = 'keys';
        }
        let data = {
          'child_wdm': child_wdm,
          'parent_wdm': parent_wdm,
          'relation_type': relation_type
        };
        WdmService.delete_child_wdmr(data)
          .then(resp => {
            let msg = 'Silme işlemi gerçekleştirildi.';
            if (resp.data.status === "success") {
              if (type === 'rule') {
                this.d_dmpRulesList.list.splice(this.d_selectedWdmrIndex_dmp_rule, 1);
                this.d_selectedWdmrIndex_dmp_rule = '';
                this.d_selectedRuleWdmrData = {};
              } else if (type === 'wdm24') {
                this.d_dmpList.list.splice(this.d_selectedWdmrIndex_dmp, 1);
                this.d_selectedWdmrIndex_dmp = '';
                this.d_selectedDmpWdmrData = {};
              }
            } else {
              msg = 'error: ' + resp.data.message;
            }
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
            this.$forceUpdate();
          });
      }
    },
    f_saveRuleAlgoritm: function () {
      if (this.d_algorithmRule.wra_settings.mode === 'param') {
        ClsRule.f_paramToQuery(this.d_algorithmRule.query_list, this.d_algorithmRule.compile_str_array, this.d_algorithmRule.wps_data);
      }
      this.d_selectedRuleWdmrData.rule_algoritm = {
        'compile_str_array': this.d_algorithmRule.compile_str_array,
        'compile_str_date': this.d_algorithmRule.compile_str_date,
        'compile_str_text': this.d_algorithmRule.compile_str_text,
        'query_list': this.d_algorithmRule.query_list
      };
      let parent_wdm = { 'label': this.d_selectedDmpWdmrData.label, 'key': 'wdm24_' + this.d_selectedDmpWdmrData.id, 'bucket': 'wisdom', 'type': 'wdm24' };
      let child_wdm = { 'bucket': 'wisdom', 'type': 'rule' };
      let relation_type = 'keys';
      let data = {
        'data': this.d_selectedRuleWdmrData,
        'child_wdm': child_wdm,
        'parent_wdm': parent_wdm,
        'relation_type': relation_type
      };
      WdmService.save_child_wdmr(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === "success") {
            this.d_showRuleAlgoritmModal = false;
            this.d_selectedRuleWdmrData = resp.data.result.wdmr;
            this.f_wisdomShowUpdate();
            msg = this.$t('wdm16.13275');
          } else {
            msg = 'error: ' + resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          this.$forceUpdate();
        });
    },
    f_openRuleAlgoritm: function (type = '') {
      let standart_wdm_list = [];
      let main_layer_id = '';
      let package_type = '';
      try {
        main_layer_id = this.d_selectedDmpWdmrData.data.general.main_layer_id.val;
      } catch (err) {}
      try {
        package_type = this.d_selectedDmpWdmrData.data.general.package_type.val.value;
      } catch (err) {}
      // console.log('package_type ', package_type);
      // console.log('polestar_project_id ', polestar_project_id);
      if (this.d_userDmpPermissions.indexOf('owner') !== -1 || this.d_userDmpPermissions.indexOf('edit_rule_algorithm') !== -1) {
        this.d_algorithmRule = JSON.parse(JSON.stringify(this.d_algorithmRuleNew));
        if (package_type === 'layer_wdm') {
          this.d_algorithmRule.wdm_list = this.d_layerWdmList;
          this.d_algorithmRule.option_data = this.d_layerOptionDataList;
          this.d_showRuleAlgoritmModal = true;
        } else if (package_type === 'polestar_project') {
          this.d_algorithmRule.wdm_list = this.d_polestarProjectRule.wdm_list;
          this.d_algorithmRule.option_data = this.d_polestarProjectRule.option_data;
          this.d_showRuleAlgoritmModal = true;
        } else {
          try {
            standart_wdm_list = this.d_selectedDmpWdmrData.data.general.wdm.list;
            if (standart_wdm_list.length > 0) {
              for (let i in standart_wdm_list) {
                let wdm_bucket = '';
                if (standart_wdm_list[i].wdm_group.val.value === 'wisdom') {
                  wdm_bucket = 'option';
                } else {
                  wdm_bucket = 'hospital';
                }
                let y = {
                  'label': standart_wdm_list[i].wdm_type.val.label + ' ( ' + standart_wdm_list[i].wdm_version.val.label + ')',
                  'value': standart_wdm_list[i].wdm_version.val.key,
                  'bucket': wdm_bucket,
                  'type': standart_wdm_list[i].wdm_type.val.value
                };
                this.d_algorithmRule.wdm_list.push(y);
              }
              this.d_showRuleAlgoritmModal = true;
            }
          } catch (err) {}
        }
        if (this.d_selectedRuleWdmrData.rule_algoritm) {
          if (this.d_selectedRuleWdmrData.rule_algoritm.query_list) {
            this.d_algorithmRule.query_list = JSON.parse(JSON.stringify(this.d_selectedRuleWdmrData.rule_algoritm.query_list));
          }
          if (this.d_selectedRuleWdmrData.rule_algoritm.compile_str_array) {
            this.d_algorithmRule.compile_str_array = JSON.parse(JSON.stringify(this.d_selectedRuleWdmrData.rule_algoritm.compile_str_array));
          }
          if (this.d_selectedRuleWdmrData.rule_algoritm.compile_str_date) {
            this.d_algorithmRule.compile_str_date = JSON.parse(JSON.stringify(this.d_selectedRuleWdmrData.rule_algoritm.compile_str_date));
          }
          if (this.d_selectedRuleWdmrData.rule_algoritm.compile_str_text) {
            this.d_algorithmRule.compile_str_text = JSON.parse(JSON.stringify(this.d_selectedRuleWdmrData.rule_algoritm.compile_str_text));
          }
        }
      } else {
        let msg = 'Karar destek paketinde waide yayınlama yetkiniz bulunmamaktadır. Lütfen paket yetkilisi ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar destek paketi wai de yayınlama işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }

    },
    f_saveDmp: function () {
      this.d_selectedDmpWdmrData.d_ruleTreeList = this.d_ruleTreeList;
      let parent_wdm = '';
      let child_wdm = '';
      let relation_type = '';
      parent_wdm = {
        'label': this.wdm22.label,
        'key': 'wdm22_' + this.wdm22.id,
        'bucket': 'wisdom',
        'type': 'wdm22'
      };
      child_wdm = {
        'bucket': 'wisdom',
        'type': 'wdm24'
      };
      relation_type = 'keys';
      let data = {
        'data': this.d_selectedDmpWdmrData,
        'child_wdm': child_wdm,
        'parent_wdm': parent_wdm,
        'relation_type': relation_type
      };
      // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kayıt işleminiz yapılıyor. Lütfen bekleyiniz.' } });
      WdmService.save_child_wdmr(data)
        .then(resp => {
          let msg = '';
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === "success") {
            this.f_createSignerUsers();
            msg = 'Kayıt işleminiz tamamlandı';
          } else {
            msg = 'error: ' + resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          this.$forceUpdate();
        });
    },
    f_copyDmp: function () {
      let msg = '';
      if (this.d_selectedDmpWdmrData && this.d_selectedDmpWdmrData.id) {
        if (confirm('Karar Destek Paketi Kopyalama işlemine devam etmek istediğinizden emin misiniz ?')) {
          let query = 'dmp_id=' + this.d_selectedDmpWdmrData.id;
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Karar destek paketi kopyalama işlemi gerçekleştiriliyor. Lütfen bekleyiniz.' } });
          DmpService.dmp_copy(query)
            .then(resp => {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              if (resp.data.status_code === "1000") {
                msg = 'Kayıt işleminiz tamamlandı';
                let url = API_FRONTEND_URL + '#/algorithm?dmp_id=' + resp.data.dmp_id;
                window.open(url, '_blank');
              } else {
                msg = 'error: ' + resp.data.status_message;
              }
              let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
              this.$forceUpdate();
            });
        }
      } else {
        msg = 'Lütfen karar destek paketi seçimi yapınız.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_saveWdm: function () {
      let return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data[this.data_type], ['name', 'required']);
      this.wisdom_data.label = return_data.name;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      if (!only_one_param_is_filled) {
        let modal_data = { 'type': 'alert', 'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.', 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' * ' + wdmr_require_alerts[r].label;
        }
        let modal_data = { 'type': 'alert', 'text': require_txt, 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data[this.data_type], ['delete']);
      let parent_wdm = '';
      let child_wdm = '';
      let relation_type = '';
      if (this.data_type === 'wdm24') {
        parent_wdm = { 'label': this.wdm22.label, 'key': 'wdm22_' + this.wdm22.id, 'bucket': 'wisdom', 'type': 'wdm22' };
        child_wdm = { 'bucket': 'wisdom', 'type': 'wdm24' };
        relation_type = 'keys';
      } else if (this.data_type === 'rule') {
        parent_wdm = {
          'label': this.d_selectedDmpWdmrData.label,
          'key': 'wdm24_' + this.d_selectedDmpWdmrData.id,
          'bucket': 'wisdom',
          'type': 'wdm24'
        };
        child_wdm = {
          'bucket': 'wisdom',
          'type': 'rule'
        };
        relation_type = 'keys';
      } else if (this.data_type === 'wdm308') {
        parent_wdm = {
          'label': this.d_selectedDmpWdmrData.label,
          'key': 'wdm24_' + this.d_selectedDmpWdmrData.id,
          'bucket': 'wisdom',
          'type': 'wdm24'
        };
        child_wdm = {
          'bucket': 'wisdom',
          'type': 'wdm308'
        };
        relation_type = 'keys';
      }
      if (this.data_type === 'wdm24' && !this.wisdom_data.owner) {
        this.wisdom_data.owner = this.user.username;
      }
      let data = {
        'data': this.wisdom_data,
        'child_wdm': child_wdm,
        'parent_wdm': parent_wdm,
        'relation_type': relation_type
      };
      if (this.data_type === 'wdm24' && (!this.wisdom_data.id || this.wisdom_data.id === 'new')) {
        // UNCOMMENT FOR PERMISSION
        data.permission_control = { 'value': 'decision-making-system_create-dmp', 'i_used_this_permission': 1 };
      } else if (this.data_type === 'rule' && (!this.wisdom_data.id || this.wisdom_data.id === 'new')) {
        data.permission_control = { 'value': 'walgorithm_create-rule', 'i_used_this_permission': 1 };
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kayıt işleminiz yapılıyor. Lütfen bekleyiniz.' } });
      WdmService.save_child_wdmr(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === "success") {
            if (this.data_type !== 'rule') {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
            } else {
              if (this.wisdom_op === 'edit') {
                this.$store.commit('Loading', { 'status': false, 'data': {} });
              }
            }
            // console.log(resp.data.result);
            if (resp.data.result.user_dmp_data) {
              if (Object.keys(resp.data.result.user_dmp_data).length > 0) {
                if (!this.user.decision_making_packages) {
                  this.user.decision_making_packages = {};
                }
              }
              for (let i in resp.data.result.user_dmp_data) {
                this.user.decision_making_packages[i] = resp.data.result.user_dmp_data[i];
              }
              localStorage.setItem('user', JSON.stringify(this.user));
            }
            this.d_showWisdomDataModal = false;
            if (this.wisdom_op === 'edit') {
              if (this.data_type === 'wdm24') {
                this.d_selectedDmpWdmrData = resp.data.result.wdmr;
              } else if (this.data_type === 'rule') {
                let will_update_rule_name_locations = this.d_selectedRuleWdmrData.label !== resp.data.result.wdmr.label;
                console.log('will_update_rule_name_locations : ', will_update_rule_name_locations);
                this.d_selectedRuleWdmrData = resp.data.result.wdmr;
                if (will_update_rule_name_locations) {
                  this.f_updateDmpRuleNames();
                }
              } else if (this.data_type === 'wdm308') {
                this.d_selectedWdm308ApiDocument = resp.data.result.wdmr;
              }
              this.f_wisdomShowUpdate();
            } else {
              if (this.data_type === 'wdm24') {
                let x = {
                  'key': 'wdm24_' + resp.data.result.wdmr.id,
                  'label': resp.data.result.wdmr.label
                };
                this.d_dmpList.list.push(x);
              } else if (this.data_type === 'rule') {
                let x = {
                  'key': 'rule_' + resp.data.result.wdmr.id,
                  'label': resp.data.result.wdmr.label
                };
                if (!this.d_dmpRulesList.list) {
                  this.d_dmpRulesList.list = [];
                }
                this.d_dmpRulesList.list.push(x);
              }
            }
            if (this.data_type === 'rule') {
              this.d_selectedRuleWdmrData = resp.data.result.wdmr;
              this.f_controlOperationColumnsForPolestarProject();
            }
            msg = this.$t('wdm16.13275');
          } else {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            msg = resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          this.$forceUpdate();
        });
    },
    f_controlOperationColumnsForPolestarProject: function () {
      let polestar_project_id = '';
      let new_operations = [];
      let old_operations = [];
      try {
        polestar_project_id = this.d_selectedDmpWdmrData.data.general.polestar_project_id.val;
      } catch (err) {}
      // console.log(polestar_project_id);
      if (polestar_project_id) {

        let new_op_push_data = {
          'operation_no': { 'val': '' },
          'operation_info': { 'val': '' },
          'operation_text': { 'val': '' }
        };
        try {
          if (this.d_selectedRuleWdmrData.data.general.rule_type.val.value === 'operation') {
            new_op_push_data.operation_no = this.d_selectedRuleWdmrData.data.general.operation_no;
            new_op_push_data.operation_info = this.d_selectedRuleWdmrData.data.general.operation_info;
            new_op_push_data.operation_text = this.d_selectedRuleWdmrData.data.general.operation_text;
          }
        } catch (err) {}
        new_operations.push(new_op_push_data);

        if (this.wisdom_op === 'edit') {
          let old_op_push_data = {
            'operation_no': { 'val': '' },
            'operation_info': { 'val': '' },
            'operation_text': { 'val': '' }
          };
          try {
            if (this.d_oldSelectedRuleWdmrData.data.general.rule_type.val.value === 'operation') {
              old_op_push_data.operation_no = this.d_oldSelectedRuleWdmrData.data.general.operation_no;
              old_op_push_data.operation_info = this.d_oldSelectedRuleWdmrData.data.general.operation_info;
              old_op_push_data.operation_text = this.d_oldSelectedRuleWdmrData.data.general.operation_text;
            }
          } catch (err) {}
          old_operations.push(old_op_push_data);
        }

        let prepare_columns_for_this_operations = this.f_prepareColumnsWithOldNewOperationControl(new_operations, old_operations);
        // console.log('prepare_columns_for_this_operations: ', prepare_columns_for_this_operations);
        if (prepare_columns_for_this_operations.length > 0) {
          let data = {
            'rule_id': this.d_selectedRuleWdmrData.id,
            'wdm3_id': polestar_project_id,
            'wdmr24_id': this.d_selectedDmpWdmrData.id,
            'operations': prepare_columns_for_this_operations
          };
          PolestarWebService.prepare_columns_for_this_operations(data)
            .then(resp => {
              if (resp.data.status === 'success') {
                if (resp.data.result.status === 'success') {
                  this.d_polestarProjectRule.option_data = resp.data.result.result.option_data;
                  this.d_polestarProjectRule.wdm_list = resp.data.result.result.wdm_list;
                  this.f_createMultiWdmrList(resp.data.result.result);
                }
              } else {
                this.$store.commit('Loading', { 'status': false, 'data': {} });
                alert('Error: ', resp.data.message);
              }
            });
        }
      } else {
        this.$store.commit('Loading', { 'status': false, 'data': {} });
      }
    },
    f_prepareColumnsWithOldNewOperationControl: function (new_operations, old_operations) {
      // console.log('new_operations: ', JSON.stringify(new_operations));
      // console.log('old_operations: ', JSON.stringify(old_operations));
      let prepare_columns_for_this_operations = [];
      // First loop over new_operations
      for (let i in new_operations) {
        let full_match = false;
        let operation_no_match = false;
        for (let k in old_operations) {
          if (new_operations[i].operation_no.val === old_operations[k].operation_no.val) {
            operation_no_match = true;
            if (new_operations[i].operation_text.val === old_operations[k].operation_text.val) {
              if (new_operations[i].operation_info.val === old_operations[k].operation_info.val) {
                full_match = true;
                break;
              }
            }
          }
        }
        if (full_match) {
          // No need to do something
        } else {
          if (operation_no_match) {
            //
          } else {
            new_operations[i].column_operation = 'new';
            prepare_columns_for_this_operations.push(new_operations[i]);
          }
        }
      }
      // Then loop over old_operations
      for (let i in old_operations) {
        let full_match = false;
        let operation_no_match = false;
        let edit_op = '';
        for (let k in new_operations) {
          if (old_operations[i].operation_no.val === new_operations[k].operation_no.val) {
            operation_no_match = true;
            edit_op = new_operations[k];
            if (old_operations[i].operation_text.val === new_operations[k].operation_text.val) {
              if (old_operations[i].operation_info.val === new_operations[k].operation_info.val) {
                full_match = true;
                break;
              }
            }
          }
        }
        if (full_match) {
          // No need to do something
        } else {
          if (operation_no_match) {
            // console.log('edit_op', JSON.stringify(edit_op));
            edit_op.column_operation = 'edit';
            prepare_columns_for_this_operations.push(edit_op);
          } else {
            old_operations[i].column_operation = 'deletion';
            prepare_columns_for_this_operations.push(old_operations[i]);
          }
        }
      }
      // console.log('prepare_columns_for_this_operations: ', prepare_columns_for_this_operations);
      return prepare_columns_for_this_operations;
    },
    f_wisdomShowUpdate: function () {
      if (this.change === "0") {
        this.change = "1";
      } else {
        this.change = "0";
      }
      this.$forceUpdate();
    },
    f_getOptionDataList: function () {
      let query = 'data_type=wdm24&location=wisdom&active_lang=' + localStorage.getItem('active_lang');
      OptionService.get_last_wdm_of_this_type(query)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.option_data['wdm24'] = resp.data.result;
            let query = 'data_type=rule&location=wisdom&active_lang=' + localStorage.getItem('active_lang');
            OptionService.get_last_wdm_of_this_type(query)
              .then(resp => {
                if (resp.data.status === 'success') {
                  this.option_data['rule'] = resp.data.result;
                  let query = 'data_type=wdm308&location=wisdom&active_lang=' + localStorage.getItem('active_lang');
                  OptionService.get_last_wdm_of_this_type(query)
                    .then(resp => {
                      if (resp.data.status === 'success') {
                        this.option_data['wdm308'] = resp.data.result;
                      } else {
                        // console.log('error ', resp.data.message);
                      }
                    });

                } else {
                  // console.log('error ', resp.data.message);
                }
              });
          } else {
            alert('error ', resp.data.message);
          }
        });
    },
    f_editSelectedWdmr: function (data_type) {
      this.wisdom_op = "edit";
      this.data_type = data_type;
      if (this.data_type === 'rule') {
        if (this.d_userDmpPermissions.indexOf('owner') !== -1 || this.d_userDmpPermissions.indexOf('add_edit_rule') !== -1) {
          this.wisdom_data = JSON.parse(JSON.stringify(this.d_selectedRuleWdmrData));
          this.d_oldSelectedRuleWdmrData = JSON.parse(JSON.stringify(this.d_selectedRuleWdmrData));
          this.d_showWisdomDataModal = true;
        } else {
          let msg = 'Karar destek paketini düzenleme yetkiniz bulunmamaktadır. Lütfen karar destek paketi yetkilisi ile temasa geçiniz';
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar destek paketi düzenleme işlemi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        }
      } else if (this.data_type === 'wdm24') {
        if (this.d_userDmpPermissions.indexOf('owner') !== -1 || this.d_userDmpPermissions.indexOf('edit_dmp') !== -1) {
          this.wisdom_data = JSON.parse(JSON.stringify(this.d_selectedDmpWdmrData));
          this.d_showWisdomDataModal = true;
        } else {
          let msg = 'Karar destek paketini düzenleme yetkiniz bulunmamaktadır. Lütfen karar destek paketi yetkilisi ile temasa geçiniz';
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar destek paketi düzenleme işlemi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        }
      }
    },
    f_addEditApiResultDocument: function () {
      // if (this.d_userDmpPermissions.indexOf('owner') !== -1 || this.d_userDmpPermissions.indexOf('publish_operations_for_wai') !== -1) {
      if (this.d_userDmpPermissions.indexOf('owner') !== -1 || this.d_userDmpPermissions.indexOf('create_api_response_document') !== -1) {
        if (this.d_selectedWdm308ApiDocument) {
          this.data_type = 'wdm308';
          this.wisdom_op = "edit";
          this.wisdom_data = this.d_selectedWdm308ApiDocument;
          this.d_showWisdomDataModal = true;
        } else {
          this.data_type = 'wdm308';
          this.wisdom_op = "new";
          let pre_data = {
            'id': 'new',
            'data': {},
            'type': 'wdm308',
            'reference_wdm': {},
            'parent': this.data_type
          };
          pre_data['reference_wdm']['version'] = this.option_data[this.data_type]['version'];
          pre_data['reference_wdm']['owner_type'] = this.option_data[this.data_type]['owner_type'];
          if (this.option_data[this.data_type]['owner_type'] === 'wisdom') {
            pre_data['reference_wdm']['key'] = 'wdm_wisdom_' + this.data_type + '_v' + this.option_data[this.data_type].version;
          }
          for (let i in this.option_data[this.data_type].param_group.param_order) {
            pre_data['data'][this.option_data[this.data_type].param_group.param_order[i]] = {};
          }
          this.wisdom_data = pre_data;
          this.d_showWisdomDataModal = true;
        }
      } else {
        let msg = 'Karar destek paketi oluşturma yetkiniz bulunmamaktadır. Lütfen sistem yetkilisi ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar destek paketi oluşturma işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
    },
    f_addNewWisdomData: function (data_type) {
      if (data_type === 'wdm24') {
        if (this.user && this.user.permissions_result && this.user.permissions_result.indexOf('decision-making-system_create-dmp') !== -1) {
          //
        } else {
          let msg = 'Karar destek paketi oluşturma yetkiniz bulunmamaktadır. Lütfen sistem yetkilisi ile temasa geçiniz';
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar destek paketi oluşturma işlemi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          return;
        }
      }
      if (data_type === 'rule') {
        if (!this.d_selectedDmpWdmrData || (this.d_selectedDmpWdmrData && !this.d_selectedDmpWdmrData.id)) {
          let msg = 'Algoritma kuralı oluşturmak için lütfen öncelikle bir karar destek paketi seçiniz.';
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Algoritma kuralı oluşturma işlemi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          return;
        }
        if (this.d_userDmpPermissions.indexOf('owner') !== -1 || this.d_userDmpPermissions.indexOf('add_edit_rule') !== -1) {
          //
        } else {
          let msg = 'Karar destek paketine algoritma kuralı ekleme yetkiniz bulunmamaktadır. Lütfen paket yetkilisi ile temasa geçiniz';
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar destek paketine algoritma kuralı ekleme işlemi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          return;
        }
      }

      this.data_type = data_type;
      this.wisdom_op = "new";
      let pre_data = {
        'id': 'new',
        'data': {},
        'type': data_type,
        'reference_wdm': {},
        'parent': data_type
      };
      pre_data['reference_wdm']['version'] = this.option_data[this.data_type]['version'];
      pre_data['reference_wdm']['owner_type'] = this.option_data[this.data_type]['owner_type'];
      if (this.option_data[this.data_type]['owner_type'] === 'wisdom') {
        pre_data['reference_wdm']['key'] = 'wdm_wisdom_' + this.data_type + '_v' + this.option_data[this.data_type].version;
      }
      for (let i in this.option_data[this.data_type].param_group.param_order) {
        pre_data['data'][this.option_data[this.data_type].param_group.param_order[i]] = {};
      }
      this.wisdom_data = pre_data;
      this.d_showWisdomDataModal = true;
    },
    f_analyzeDmpApiServiceTest: function (type, only_last_true_rule = 1) {
      if (this.user && this.user.permissions_result && this.user.permissions_result.indexOf('decision-making-system_make-api-test') !== -1) {
        let data = {
          'wdmr': {},
          'dmp': {},
          'only_last_true_rule': only_last_true_rule
        };
        let dmp_id = this.d_selectedDmpWdmrData.id;
        data.dmp[dmp_id] = {
          'type': type,
          'list': []
        };
        if (type === 'selected') {
          for (let i in this.d_dmpRulesList.selected) {
            let ind = this.d_dmpRulesList.selected[i];
            let rule_id = this.d_dmpRulesList.list[ind].key.split('_')[1];
            data.dmp[dmp_id].list.push(parseInt(rule_id));
          }
          if (data.dmp[dmp_id].list.length === 0) {
            alert('Please select your rule algorithms');
            return;
          }
        }
        for (let i in this.d_multiWdmrData.multi_wdmr_list) {
          let data_type = this.d_multiWdmrData.multi_wdmr_list[i].data_type;
          if (!data.wdmr[data_type]) {
            data.wdmr[data_type] = [];
          }
          let wisdom_data = JSON.parse(JSON.stringify(this.d_multiWdmrData.multi_wdmr_list[i].wisdom_data));
          let option_data = this.d_multiWdmrData.multi_wdmr_list[i].option_data[data_type];
          // let return_data = ClsWdmrForever.turn(wisdom_data, option_data, ['delete', 'lang_id_deletion', 'val_label_deletion']);
          let return_data = ClsWdmrForever.turn(wisdom_data, option_data, ['delete', 'lang_id_deletion']);
          if (return_data.wdmr['date_type'] !== undefined) {
            this.$delete(return_data.wdmr, 'date_type');
          }
          if (return_data.wdmr['ending_date_type'] !== undefined) {
            this.$delete(return_data.wdmr, 'ending_date_type');
          }
          if (return_data.wdmr['reference_wdm'] !== undefined) {
            this.$delete(return_data.wdmr, 'reference_wdm');
          }
          data.wdmr[data_type].push(return_data.wdmr);
        }
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Analizleriniz yapılıyor bekleyiniz.' } });
        WaiService.analyze_dmp(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status === 'success') {
              // console.log(resp.data.result);
            } else {
              // alert('Error ', resp.data.message);
            }
            if (type === 'all') {
              this.d_dmpServiceTestResult.uri = 'https://walgorithm-api.your_company.com/v1/dmp/analyze?dmp_id=' + dmp_id + '&only_last_true_rule=' + only_last_true_rule;
            } else {
              this.d_dmpServiceTestResult.uri = 'https://walgorithm-api.your_company.com/v1/dmp/analyze?dmp_id=' + dmp_id + '&only_last_true_rule=' + only_last_true_rule + '&algorithm_id=' + data.dmp[dmp_id].list.join(',');
            }
            let response = {};
            response.status_code = '1000',
              response.status_message = 'Successfully responded',
              response.dmp = {};
            response.dmp[dmp_id] = {};
            response.dmp[dmp_id].status = resp.data.result.dmp[dmp_id].status;
            response.dmp[dmp_id].true = [];

            for (let r in resp.data.result.dmp[dmp_id].true_rules) {
              let resp_data = resp.data.result.dmp[dmp_id].true_rules[r];
              let push_data = {};
              push_data.id = resp_data.id;
              push_data.operation_id = '';
              try {
                push_data.operation_id = resp_data.operation_id;
              } catch (err) {}
              push_data.name = resp_data.label;
              push_data.message = resp_data.message;
              push_data.message_image = resp_data.message_image;
              response.dmp[dmp_id].true.push(push_data);
            }

            // "operation_list": [],
            //         "message_image": "",
            //         "combine_text_result": [],
            //         "label": "vat, ",
            //         "true_wdmr_list": [],
            //         "message": "vat",
            //         "id": "33"

            this.d_dmpServiceTestResult.request = data;
            this.$delete(this.d_dmpServiceTestResult.request, 'dmp');
            this.$delete(this.d_dmpServiceTestResult.request, 'only_last_true_rule');
            this.d_dmpServiceTestResult.response = response;
            this.d_dmpServiceTestResult.show = true;
          });
      } else {
        let msg = 'Karar destek paketi api ile test yetkiniz bulunmamaktadır. Lütfen sistem yetkilisi ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar destek paketi api ile test işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }

    },
    f_analyzeDmpTest: function (type, only_last_true_rule = 1) {
      let data = {
        'wdmr': {},
        'dmp': {},
        'only_last_true_rule': only_last_true_rule
      };
      let dmp_id = this.d_selectedDmpWdmrData.id;
      data.dmp[dmp_id] = {
        'type': type,
        'list': []
      };
      if (type === 'selected') {
        for (let i in this.d_dmpRulesList.selected) {
          let ind = this.d_dmpRulesList.selected[i];
          let rule_id = this.d_dmpRulesList.list[ind].key.split('_')[1];
          data.dmp[dmp_id].list.push(parseInt(rule_id));
        }
        if (data.dmp[dmp_id].list.length === 0) {
          alert('Please select your rule algorithms');
          return;
        }
      }
      for (let i in this.d_multiWdmrData.multi_wdmr_list) {
        let data_type = this.d_multiWdmrData.multi_wdmr_list[i].data_type;
        if (!data.wdmr[data_type]) {
          data.wdmr[data_type] = [];
        }
        let wisdom_data = JSON.parse(JSON.stringify(this.d_multiWdmrData.multi_wdmr_list[i].wisdom_data));
        let option_data = this.d_multiWdmrData.multi_wdmr_list[i].option_data[data_type];
        // let return_data = ClsWdmrForever.turn(wisdom_data, option_data, ['delete', 'lang_id_deletion', 'val_label_deletion']);
        // when we clean something below code, we post a small request data.
        let return_data = ClsWdmrForever.turn(wisdom_data, option_data, ['delete', 'lang_id_deletion']);
        // console.log(JSON.stringify(return_data));
        // console.log(JSON.stringify(return_data.wdmr));
        if (return_data.wdmr['date_type'] !== undefined) {
          this.$delete(return_data.wdmr, 'date_type');
        }
        if (return_data.wdmr['ending_date_type'] !== undefined) {
          this.$delete(return_data.wdmr, 'ending_date_type');
        }
        if (return_data.wdmr['reference_wdm'] !== undefined) {
          this.$delete(return_data.wdmr, 'reference_wdm');
        }
        data.wdmr[data_type].push(return_data.wdmr);
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Analizleriniz yapılıyor bekleyiniz.' } });
      // console.log(data);
      WaiService.analyze_dmp_test(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            // console.log(resp.data.result);
            this.d_ruleAnalyzeData.results = { 'list': resp.data.result.dmp };
            if (Object.keys(this.d_ruleAnalyzeData.results.list).length > 0) {
              this.d_ruleAnalyzeData.show = true;
            }
            this.d_ruleResult = {};
            for (let i in this.d_ruleAnalyzeData.results.list) {
              if (this.d_ruleAnalyzeData.results.list[i].true_rules && this.d_ruleAnalyzeData.results.list[i].true_rules.length > 0) {
                for (let true_rule_ind in this.d_ruleAnalyzeData.results.list[i].true_rules) {
                  let rule_data = this.d_ruleAnalyzeData.results.list[i].true_rules[true_rule_ind];
                  let rule_key = 'rule_' + rule_data.id;
                  this.d_ruleResult[rule_key] = { 'result': 1 };
                }
              }
            }
          } else {
            // alert('Error ', resp.data.message);
          }
        });
    },
    f_ruleEngineTest: function () {
      if (this.d_dmpRulesList.selected.length > 0) {
        if (this.d_selectedTab === 'case_by_analyze') {
          let data = { 'case_wdmr_list': [], 'rules_list': [] }
          for (let i in this.d_wdmStoreDataList) {
            for (let s in this.d_wdmStoreDataList[i].selected) {
              data.case_wdmr_list.push(this.d_wdmStoreDataList[i].list[this.d_wdmStoreDataList[i].selected[s]].key);
            }
          }
          for (let s in this.d_dmpRulesList.selected) {
            data.rules_list.push(this.d_dmpRulesList.list[this.d_dmpRulesList.selected[s]].key);
          }
          let msg = '';
          if (data.case_wdmr_list.length === 0) {
            msg += 'Lütfen test vakasına ait analiz edilecek verileri seçiniz\n';
          }
          if (data.case_wdmr_list.length === 0) {
            msg += 'Lütfen test vakasına ait analiz edilecek verileri seçiniz\n';
          }
          if (msg) {
            let modal_data = {
              'type': 'alert',
              'header_bg_variant': 'info',
              'header_text_variant': 'dark',
              'text': msg,
              'centered': true,
              'title': 'Kural Motoru Analiz İşlemi Hakkında'
            };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          } else {
            //
            RuleEngineService.rule_engine_test(data)
              .then(resp => {
                if (resp.data.status === 'success') {
                  this.d_ruleResult = resp.data.result;
                  this.$forceUpdate();
                  // console.log(resp.data.result);
                } else {
                  // console.log('Error: ' + resp.data.message);
                }
              });
          }
        } else if (this.d_selectedTab === 'multi_wdmr_data') {
          this.f_analyzeDmpTest('selected');
        }
      } else {
        alert('Lütfen algoritma kuralları seçiminizi öncelikle yapınız.');
      }
    },
    f_publishDmp: function () {
      if (this.user && this.user.permissions_result && this.user.permissions_result.indexOf('decision-making-system_rule-engine_publish-dmp-as-wai') !== -1) {
        if (this.d_userDmpPermissions.indexOf('owner') !== -1 || this.d_userDmpPermissions.indexOf('publish_operations_for_wai') !== -1) {
          if (confirm('Karar destek paketini WAI\'de yayınlamak istediğinize emin misiniz?')) {
            let data = {
              'wdm24_id': this.d_selectedDmpWdmrData.id
            };
            this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Karar Destek Paketiniz yayınlanıyor. Lütfen bekleyiniz.' } });
            RuleEngineService.publish_decision_making_package(data)
              .then(resp => {
                this.$store.commit('Loading', { 'status': false, 'data': {} });
                if (resp.data.status === 'success') {
                  let msg = 'Yayınlanma işlemi başarıyla tamamlandı';
                  let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar destek paketi yayınlama işlemi hakkında' };
                  this.$store.commit('modal', modal_data);
                  this.$store.commit('modal_show', true);
                } else {
                  // console.log('Error: ' + resp.data.message);
                }
              });
          }
        } else {
          let msg = 'Karar destek paketinde waide yayınlama yetkiniz bulunmamaktadır. Lütfen paket yetkilisi ile temasa geçiniz';
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar destek paketi wai de yayınlama işlemi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        }
      } else {
        let msg = 'Karar destek paketini yayınlama yetkiniz bulunmamaktadır. Lütfen sistem yetkilisi ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar destek paketi yayınlama işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_getOptionData: function (data_type, location) {
      let query = 'data_type=' + data_type + '&location=' + location;
      OptionService.get_last_wdm_of_this_type(query)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.option_data[data_type] = resp.data.result;
          } else {
            // console.log('error ', resp.data.message);
          }
        });
    },
    f_selectWdmr: function (type, x_ind, x_key, wdmstore_ind = '') {
      if (type === 'dmp') {
        if (!this.d_selectedDmpWdmrData || (this.d_selectedDmpWdmrData && 'wdm24_' + this.d_selectedDmpWdmrData.id !== x_key)) {
          // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
          if (this.$route.query && this.$route.query.dmp_id !== x_key.split('_')[1]) {
            let route_data = {
              // 'name': 'algorithmdevelopment',
              // 'path': '/rule-engine/algorithm',
              'query': {
                'dmp_id': x_key.split('_')[1]
              }
            };
            this.$router.push(route_data);
          }
          this.d_multiWdmrData.multi_wdmr_list = [];
          this.d_ruleTreeList = { 'list': [], 'items': [] };
          this.d_wdmStoreDataList = {};
          this.d_selectedWdmrIndex_dmp_wdm7 = '';
          this.d_selectedWdmrIndex_dmp_rule = '';
          this.d_selectedRuleWdmrData = '';
          this.d_selectedWdmrIndex_dmp = x_ind;
          let parent_wdm = {
            'label': this.d_dmpList.list[x_ind].label,
            'key': x_key,
            'bucket': 'wisdom',
            'type': 'wdm24'
          };
          let child_wdm = {
            'bucket': 'wisdom',
            'type': 'rule'
          };
          let relation_type = 'keys';
          let secondService = {
            'type': 'dmp_wdm7',
            'parent_wdm': parent_wdm,
            'child_wdm': child_wdm,
            'relation_type': relation_type
          };
          let getWdmrData = {
            'type': type,
            'wdmr_key': x_key,
            'bucket': 'wisdom'
          };
          this.f_getChildList('dmp_rule', parent_wdm, child_wdm, relation_type, secondService, getWdmrData);
          child_wdm = {
            'bucket': 'wisdom',
            'type': 'wdm308'
          };
          this.f_getChildList('wdm308', parent_wdm, child_wdm, relation_type, false, false);
          // wdm7 list, test patients
          this.d_dmpShowModal = false;
        }
      } else if (type === 'dmp_rule') {
        this.d_selectedWdmrIndex_dmp_rule = x_ind;
        let ind = this.d_dmpRulesList.selected.indexOf(x_ind);
        if (ind === -1) {
          this.d_dmpRulesList.selected.push(x_ind);
        } else {
          this.d_dmpRulesList.selected.splice(ind, 1);
        }
        /*
        if (!this.d_selectedRuleWdmrData || (this.d_selectedRuleWdmrData && 'rule_' + this.d_selectedRuleWdmrData.id !== x_key)) {
          this.f_getWdmrData(type, x_key, 'wisdom');
        }
        */
      } else if (type === 'dmp_wdm7') {
        this.d_selectedWdmrIndex_dmp_wdm7 = x_ind;
        this.f_getWdmrData(type, x_key, 'wisdom');
      } else if (type === 'wdmstore_data') {
        let ind = this.d_wdmStoreDataList[wdmstore_ind].selected.indexOf(x_ind);
        if (ind === -1) {
          this.d_wdmStoreDataList[wdmstore_ind].selected.push(x_ind);
        } else {
          this.d_wdmStoreDataList[wdmstore_ind].selected.splice(ind, 1);
        }
        // this.f_getWdmrData(type, x_key, 'wisdom');
      }
      this.$forceUpdate();
    },
    f_getWdmrData: function (type, wdmr_key, bucket, f_showMultiWdmr = false) {
      let data = { 'key': wdmr_key, 'bucket': bucket };
      // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
      GlobalService.get_one_cb_document(data)
        .then(resp => {
          let msg = '';
          // this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === "success") {
            if (type === 'dmp_rule') {
              this.d_selectedRuleWdmrData = resp.data.result;
              if (this.$route.query && this.$route.query.algorithm_id !== wdmr_key.split('_')[1]) {
                let route_data = {
                  // 'name': 'algorithmdevelopment',
                  // 'path': '/rule-engine/algorithm',
                  'query': {
                    'dmp_id': this.$route.query.dmp_id,
                    'algorithm_id': wdmr_key.split('_')[1]
                  }
                };
                this.$router.push(route_data);
              }
              this.f_wisdomShowUpdate();
              this.f_createSignerUsers();
              this.$forceUpdate();
            } else if (type === 'dmp') {
              this.d_selectedDmpWdmrData = resp.data.result;
              this.f_getPolestarProjectColumnsAsWdm();
              this.f_getLayerWdmListOfDecisionMaking();
              if (this.d_selectedDmpWdmrData.d_ruleTreeList) {
                this.d_ruleTreeList.list = this.d_selectedDmpWdmrData.d_ruleTreeList.list;
              }
              this.f_createUserDmpPermissions();
              this.f_createSignerUsers();
              this.d_dmpSettings = this.f_getDmpSettings();
            } else if (type === 'dmp_wdm7') {
              this.d_selectedWdm7Data = resp.data.result;
              this.f_getWdmStoreDataList();
            } else if (type === 'wdm308') {
              this.d_selectedWdm308ApiDocument = resp.data.result;
              this.f_wisdomShowUpdate();
            }
          } else {
            if (type === 'dmp_rule') {
              this.d_selectedRuleWdmrData = '';
            }
            // console.log('errorr f_getWdmrData : ', resp.data.message);
          }
          if (f_showMultiWdmr) {
            this.f_showMultiWdmr();
          }
        });
    },
    f_getDmpSettings: function () {
      let default_json = JSON.parse(JSON.stringify(this.d_dmpSettings_default));
      let target_json = '';
      if (this.d_selectedDmpWdmrData.settings && this.d_selectedDmpWdmrData.settings[this.user.username]) {
        target_json = JSON.parse(JSON.stringify(this.d_selectedDmpWdmrData.settings[this.user.username]));
        this.f_controlTwoJsonAndCompleteWithDefault(default_json, target_json);
      } else {
        target_json = JSON.parse(JSON.stringify(default_json));
      }
      return target_json;
    },
    f_controlTwoJsonAndCompleteWithDefault: function (default_json, target_json) {
      for (let i in default_json) {
        // console.log('typeof default_json[i] ', typeof default_json[i]);
        // If target json does not include default json data, we add it to target from default json.
        if (!target_json[i]) {
          target_json[i] = default_json[i];
        }
        if (typeof default_json[i] === 'object' || typeof default_json[i] === 'array') {
          this.f_controlTwoJsonAndCompleteWithDefault(default_json[i], target_json[i]);
        }
      }
    },
    f_createUserDmpPermissions: function () {
      this.d_userDmpPermissions = [];
      if (this.user.id === this.d_selectedDmpWdmrData.created_by) {
        this.d_userDmpPermissions.push('owner');
      }
      if (this.d_selectedDmpWdmrData.data && this.d_selectedDmpWdmrData.data.general && this.d_selectedDmpWdmrData.data.general.who && this.d_selectedDmpWdmrData.data.general.who.list && this.d_selectedDmpWdmrData.data.general.who.list.length > 0) {
        for (let i in this.d_selectedDmpWdmrData.data.general.who.list) {
          let who_data = this.d_selectedDmpWdmrData.data.general.who.list[i];
          if (who_data.selected_users && who_data.selected_users[this.user.username] && who_data.selected_users[this.user.username].detail && who_data.selected_users[this.user.username].detail.user_permissions && who_data.selected_users[this.user.username].detail.user_permissions.val && who_data.selected_users[this.user.username].detail.user_permissions.val.length > 0) {
            for (let p in who_data.selected_users[this.user.username].detail.user_permissions.val) {
              this.d_userDmpPermissions.push(who_data.selected_users[this.user.username].detail.user_permissions.val[p].value);
            }
          }
        }
      }
    },
    f_getLayerWdmListOfDecisionMaking: function () {
      let main_layer_id = '';
      let package_type = '';
      try {
        main_layer_id = this.d_selectedDmpWdmrData.data.general.main_layer_id.val;
      } catch (err) {}
      try {
        package_type = this.d_selectedDmpWdmrData.data.general.package_type.val.value;
      } catch (err) {}
      // console.log('package_type ', package_type);
      // console.log('polestar_project_id ', polestar_project_id);
      if (package_type === 'layer_wdm' && main_layer_id) {
        let query = 'layer_id=' + main_layer_id;
        WmanagerService.get_sub_layer_wdm_list(query)
          .then(resp => {
            if (resp.data.status_code === '1000') {
              this.d_layerOptionDataList = {};
              this.d_multiWdmrData.multi_wdmr_list = [];
              for (let layer_index in resp.data.list) {
                let new_layer_item = {
                  'label': resp.data.list[layer_index].label,
                  'value': resp.data.list[layer_index].value,
                  'bucket': resp.data.list[layer_index].bucket,
                  'type': resp.data.list[layer_index].type
                }
                let data_type = resp.data.list[layer_index].type;
                this.d_layerWdmList.push(new_layer_item);
                this.d_layerOptionDataList[data_type] = resp.data.list[layer_index].wdm_data;
                let option_data = resp.data.list[layer_index].wdm_data;
                let pre_data = {};
                // console.log(option_data);
                if (option_data.record_count === 'many_times') {
                  pre_data = { 'data': {}, 'date': '', 'type': data_type, 'reference_wdm': {} };
                } else {
                  pre_data = { 'data': {}, 'type': data_type, 'reference_wdm': {} };
                }
                for (let i in option_data.param_group.param_order) {
                  pre_data['data'][option_data.param_group.param_order[i]] = {};
                }
                pre_data['reference_wdm']['version'] = 'layer';
                pre_data['reference_wdm']['owner_type'] = 'layer';
                pre_data['reference_wdm']['key'] = 'layer';
                let multi_wdmr_data = {
                  'option_data': {},
                  'data_type': data_type,
                  'data_type_label': option_data.name.label,
                  'wisdom_data': pre_data,
                  'dr_wisdom': '',
                  'schema_name': '',
                  'schema_key': ''
                };
                multi_wdmr_data.option_data[data_type] = option_data;
                this.d_multiWdmrData.multi_wdmr_list.push(multi_wdmr_data);
              }
              this.d_showMultiWdmr = true;
            } else {
              let msg = resp.data.message;
              let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'error' };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
              this.$forceUpdate();
            }
          });
      }
    },
    f_getPolestarProjectColumnsAsWdm: function () {
      let package_type = '';
      let polestar_project_id = '';
      try {
        package_type = this.d_selectedDmpWdmrData.data.general.package_type.val.value;
        polestar_project_id = this.d_selectedDmpWdmrData.data.general.polestar_project_id.val;
      } catch (err) {}
      // console.log('package_type ', package_type);
      // console.log('polestar_project_id ', polestar_project_id);
      if (package_type === 'polestar_project' && polestar_project_id) {
        let data = {
          'wdm3_id': polestar_project_id,
          'wdmr24_id': this.d_selectedDmpWdmrData.id
        };
        PolestarWebService.get_polestar_project_columns_as_wdm(data)
          .then(resp => {
            if (resp.data.status === 'success') {
              if (resp.data.result.status === 'success') {
                this.d_polestarProjectRule.option_data = resp.data.result.result.option_data;
                this.d_polestarProjectRule.wdm_list = resp.data.result.result.wdm_list;
                this.f_createMultiWdmrList(resp.data.result.result);
              }
            } else {
              let msg = resp.data.message;
              let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'error' };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
              this.$forceUpdate();
            }
          });
      }
    },
    f_createMultiWdmrList: function (res) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Veri modelleri hazırlanıyor. Lütfen bekleyiniz.' } });
      this.d_multiWdmrData.multi_wdmr_list = [];
      let option_data_list = res['option_data'];
      let wdm_list = res['wdm_list'];
      for (let i in wdm_list) {
        let data_type = wdm_list[i].type;
        let option_data = option_data_list[data_type];
        let pre_data = {};
        // console.log(option_data);
        if (option_data.record_count === 'many_times') {
          pre_data = { 'data': {}, 'date': '', 'type': data_type, 'reference_wdm': {} };
        } else {
          pre_data = { 'data': {}, 'type': data_type, 'reference_wdm': {} };
        }
        for (let i in option_data.param_group.param_order) {
          pre_data['data'][option_data.param_group.param_order[i]] = {};
        }
        pre_data['reference_wdm']['version'] = 'polestar';
        pre_data['reference_wdm']['owner_type'] = 'polestar';
        pre_data['reference_wdm']['key'] = 'polestar';
        let multi_wdmr_data = {
          'option_data': {},
          'data_type': data_type,
          'data_type_label': option_data.name.label,
          'wisdom_data': pre_data,
          'dr_wisdom': '',
          'schema_name': '',
          'schema_key': ''
        };
        multi_wdmr_data.option_data[data_type] = option_data;
        this.d_multiWdmrData.multi_wdmr_list.push(multi_wdmr_data);
      }
      setTimeout(function () {
        this.$store.commit('Loading', { 'status': false, 'data': {} });
      }.bind(this), 500);
      // this.f_showMultiWdmr();
    },
    f_getWdmStoreDataList: function () {
      this.d_wdmStoreDataList = {};
      try {
        for (let i in this.d_selectedDmpWdmrData.data.general.wdm.list) {
          let parent_key = 'wdm7_' + this.d_selectedWdm7Data.id;
          let parent_wdm = { 'label': '', 'key': parent_key, 'bucket': 'wisdom', 'type': 'wdm7' };
          let child_wdm = {
            'bucket': 'wisdom',
            'type': this.d_selectedDmpWdmrData.data.general.wdm.list[i].wdm_type.val.value,
            'label': this.d_selectedDmpWdmrData.data.general.wdm.list[i].wdm_type.val.label
          };
          let relation_type = 'keys';
          this.f_getChildList('wdmstore_data', parent_wdm, child_wdm, relation_type);
        }
      } catch (err) {}

    },
    f_deleteLastDiagramBranchPoints: function () {
      for (let branch_ind in this.d_diagramBranchData.list) {
        if (this.d_diagramBranchData.list[branch_ind].length > 0) {
          this.d_diagramBranchData.list[branch_ind].splice(this.d_diagramBranchData.list[branch_ind].length - 1, 1);
        }
      }
      this.$forceUpdate();
    },
    f_getChildList: function (type, parent_wdm, child_wdm, relation_type, secondService = false, getWdmrData = false) {
      let data = {
        'parent_wdm': parent_wdm,
        'child_wdm': child_wdm,
        'relation_type': relation_type
      };
      // console.log(data);
      WdmService.get_related_wdmr_list(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            if (type === 'dmp') {
              this.d_dmpList = resp.data.result;
              if (!this.d_dmpList.list) {
                this.d_dmpList.list = [];
              }
              // console.log('this.$route', this.$route);
              if (this.$route.query && this.$route.query.dmp_id !== undefined) {
                for (let i in this.d_dmpList.list) {
                  if (this.d_dmpList.list[i].key === 'wdm24_' + this.$route.query.dmp_id) {
                    this.f_selectWdmr('dmp', parseInt(i), 'wdm24_' + this.$route.query.dmp_id);
                    break;
                  }
                }
              }
              this.d_dmpRulesList.list = [];
              this.d_dmpRulesList.selected = [];
            } else if (type === 'dmp_rule') {
              this.d_dmpRulesList.list = resp.data.result.list;
              if (this.$route.query && this.$route.query.algorithm_id !== undefined) {
                for (let i in this.d_dmpRulesList.list) {
                  if (this.d_dmpRulesList.list[i].key === 'rule_' + this.$route.query.algorithm_id) {
                    this.f_getWdmrData('dmp_rule', this.d_dmpRulesList.list[i].key, 'wisdom');
                    this.d_selectedWdmrIndex_dmp_rule = parseInt(i);
                    break;
                  }
                }
              }
            } else if (type === 'wdm308') {
              try {
                if (resp.data.result.list.length > 0) {
                  let wdm308_key = resp.data.result.list[0].key;
                  this.f_getWdmrData('wdm308', wdm308_key, 'wisdom', true);
                }
              } catch (err) {}
            } else if (type === 'dmp_wdm7') {
              this.d_dmpWdm7List = resp.data.result;
            } else if (type === 'wdmstore_data') {
              this.d_wdmStoreDataList[child_wdm.type] = resp.data.result;
              this.d_wdmStoreDataList[child_wdm.type].selected = [];
              this.d_wdmStoreDataList[child_wdm.type].label = child_wdm.label;
            }
          } else {
            alert('errorr \n', resp.data.message);
          }
          if (secondService) {
            data = {
              'parent_wdm': secondService.parent_wdm,
              'child_wdm': secondService.child_wdm,
              'relation_type': secondService.relation_type
            };
            WdmService.get_related_wdmr_list(data)
              .then(resp => {
                if (resp.data.status === 'success') {
                  if (secondService.type === 'dmp') {
                    this.d_dmpList = resp.data.result;
                    if (!this.d_dmpList.list) {
                      this.d_dmpList.list = [];
                    }
                    this.d_dmpRulesList.list = [];
                    this.d_dmpRulesList.selected = [];
                  } else if (secondService.type === 'dmp_rule') {
                    this.d_dmpRulesList.list = resp.data.result.list;
                  } else if (secondService.type === 'dmp_wdm7') {
                    this.d_dmpWdm7List = resp.data.result;
                  } else if (secondService.type === 'wdmstore_data') {
                    this.d_wdmStoreDataList[child_wdm.type] = resp.data.result;
                    this.d_wdmStoreDataList[child_wdm.type].selected = [];
                    this.d_wdmStoreDataList[child_wdm.type].label = child_wdm.label;
                  }
                } else {
                  alert('errorr \n', resp.data.message);
                }
                if (getWdmrData) {
                  this.f_getWdmrData(getWdmrData.type, getWdmrData.wdmr_key, getWdmrData.bucket, true);
                }
                this.$forceUpdate();
              });
          } else {
            if (getWdmrData) {
              this.f_getWdmrData(getWdmrData.type, getWdmrData.wdmr_key, getWdmrData.bucket, true);
            }
            this.$forceUpdate();
          }
        });
    }
  },
  watch: {
    'd_diagramBranchData.search_text': function () {
      this.d_diagramBranchData.search_index_list = [];
      this.d_diagramBranchData.pagination = {
        'start': 0,
        'end': 15,
        'current': 1,
        'perpage': 15,
        'all_count': 0
      };
      if (this.d_diagramBranchData.search_text) {
        if (this.d_diagramBranchData.timeout) {
          clearTimeout(this.d_diagramBranchData.timeout);
          this.d_diagramBranchData.timeout = 0;
        }
        this.d_diagramBranchData.timeout = setTimeout(function () {
          for (let branch_ind in this.d_diagramBranchData.list) {
            if (this.d_diagramBranchData.list[branch_ind].length > 0) {
              if (this.d_diagramBranchData.search_type === 'all_branch_points') {
                for (let point_ind in this.d_diagramBranchData.list[branch_ind]) {
                  let point_data = this.d_diagramBranchData.list[branch_ind][point_ind];
                  if (point_data.label && point_data.label.toLocaleLowerCase().indexOf(this.d_diagramBranchData.search_text.toLocaleLowerCase()) !== -1) {
                    this.d_diagramBranchData.search_index_list.push(parseInt(branch_ind));
                    break;
                  }
                }
              } else if (this.d_diagramBranchData.search_type === 'last_branch_points') {
                let point_data = this.d_diagramBranchData.list[branch_ind][this.d_diagramBranchData.list[branch_ind].length - 1];
                if (point_data.label && point_data.label.toLocaleLowerCase().indexOf(this.d_diagramBranchData.search_text.toLocaleLowerCase()) !== -1) {
                  this.d_diagramBranchData.search_index_list.push(parseInt(branch_ind));
                }
              } else if (this.d_diagramBranchData.search_type === 'penultimate_branch_points') {
                if (this.d_diagramBranchData.list[branch_ind].length >= 2) {
                  let point_data = this.d_diagramBranchData.list[branch_ind][this.d_diagramBranchData.list[branch_ind].length - 2];
                  if (point_data.label && point_data.label.toLocaleLowerCase().indexOf(this.d_diagramBranchData.search_text.toLocaleLowerCase()) !== -1) {
                    this.d_diagramBranchData.search_index_list.push(parseInt(branch_ind));
                  }
                }
              } else if (this.d_diagramBranchData.search_type === 'first_branch_points') {
                let point_data = this.d_diagramBranchData.list[branch_ind][0];
                if (point_data.label && point_data.label.toLocaleLowerCase().indexOf(this.d_diagramBranchData.search_text.toLocaleLowerCase()) !== -1) {
                  this.d_diagramBranchData.search_index_list.push(parseInt(branch_ind));
                }
              }
            }
          }
        }.bind(this), 1000);
      }
    },
    'd_diagramBranchData.pagination.current': function () {
      this.d_diagramBranchData.pagination.start = (this.d_diagramBranchData.pagination.current - 1) * this.d_diagramBranchData.pagination.perpage;
      this.d_diagramBranchData.pagination.end = (this.d_diagramBranchData.pagination.perpage * this.d_diagramBranchData.pagination.current);
    },
    'd_dmpRulesList.list': function () {
      this.d_ruleTreeList.items = [];
      for (let i in this.d_dmpRulesList.list) {
        let item = { 'value': this.d_dmpRulesList.list[i].key, 'label': this.d_dmpRulesList.list[i].label };
        this.d_ruleTreeList.items.push(item);
      }
      if (this.d_selectedDmpWdmrData && this.d_selectedDmpWdmrData.d_ruleTreeList) {
        this.f_saveDmp();
      }
    },
    'd_diagramData.nodes': function () {
      // console.log('d_diagramData.nodes', this.d_diagramData.nodes);
    }
  }
}

</script>

<style>
.normal-mode {}

.full-screen-mode {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

#algorithm_excel_table td,
th {
  border: solid 1px #acdbdb;
  padding: 3px;
}

#diagram_branch_table td,
th {
  border: solid 1px #acdbdb;
  padding: 3px;
}

.s_showQuickIcon {
  height: 75px;
  width: 35px;
  border-radius: 50px 0 0 50px;
  background-color: rgba(255, 0, 0, 0.9);
  box-shadow: -4px -1px 6px 2px #716b6b;
  border: solid 1px 0px 1px 1px #716b6b;
  position: fixed;
  z-index: 1000;
  top: 100px;
  right: 250px;
  cursor: pointer;
}

.s_showQuickIconNot {
  height: 75px;
  width: 35px;
  border-radius: 50px 0 0 50px;
  background-color: rgba(255, 0, 0, 0.9);
  box-shadow: -4px -1px 6px 2px #716b6b;
  border: solid 1px 0px 1px 1px #716b6b;
  position: fixed;
  z-index: 1000;
  top: 100px;
  right: 0px;
  cursor: pointer;
}

.s_edict {
  position: fixed;
  z-index: 1000;
  top: 100px;
  right: 0px;
  width: 250px;
  background-color: #f36666;
  box-shadow: 4px -1px 6px 4px #716b6b;
  border-radius: 0px 20px 20px 20px;
}

.s_edictList {
  padding: 5px;
  border-top: solid 0.5px #716b6b;
  margin: 0px;
}

.s_edictList:hover {
  padding: 5px;
  border-top: solid 0.5px #716b6b;
  margin: 0px;
  background-color: red;
  cursor: pointer;
}

</style>

