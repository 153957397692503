<template>
  <div v-if="option_data">
    <!-- for cycle in groups -->
    <template v-if="data.document_list">
      <b-row style="padding-left: 10px; margin-left: 9px;">
        <b-col sm="12" lg="6">{{ $t('wdm16.11214') }}</b-col>
        <b-col sm="12" lg="6" style="padding-left: 0px;">
          <template v-for="(doc, doc_ind) in data.document_list">
            <b-row>
              <b-col cols="6">
                {{ doc_ind + 1 }} ) {{ doc.file_name }}
              </b-col>
              <b-col cols="6">
                <b-button style="margin-right: 5px;" size="sm" v-if="['jpeg', 'png', 'jpg'].indexOf(doc.fileExtension.toLowerCase()) !== -1" variant="primary" @click="PreviewImage(doc_ind, doc.file_name)">{{ $t('wdm16.11213') }}</b-button>
                <b-button style="margin-right: 5px;" size="sm" variant="secondary" @click="f_downloadDocument(doc_ind, doc.file_name)">indir</b-button>
              </b-col>
            </b-row>
          </template>
        </b-col>
      </b-row>
    </template>
    <template v-if="data.webservice_text">
      <b-row style="padding-left: 10px; margin-left: 9px; white-space: pre-line;">
        <b-col sm="12" lg="12" style="padding-left: 0px;">
          {{ data.webservice_text.replace(/\n\n/g, '') }}
        </b-col>
      </b-row>
    </template>
    <template v-for="(group, group_ind) in option_data.param_group.param_order">
      <template v-if="f_briefControl(group)">
        <div v-if="group !== 'anatomy' && data['data'][group] && Object.keys(data['data'][group]).length > 0">
          <b-card-header header-bg-variant="white" header-text-variant="dark" class="p-1">
            <strong> {{ option_data.param_group.parameters[group].label }} </strong>
          </b-card-header>
          <wisdom-show :change="change" :relation="'mother'" :view_mode="view_mode" :data="data['data'][group]" :group_parameters="option_data[group]" :brief_mode="brief_mode" :user_brief_list="user_brief_list" :param_group="group"></wisdom-show>
          <div class="row" v-if="data_type === 'status' && data.relations">
            <div class="col-2">
              <strong> {{ $t('wdm16.11216') }} </strong>
            </div>
            <div class="col-10">
              <div class="row" v-for="(rel, rel_ind) in data.relations">
                <div class="col-12">
                  <span v-if="rel.date"> {{ DateFormat(rel.date) }}, </span>
                  <span style="color: #187fa3;"> {{ filter_group[rel.type].name.translation[lang] }} => </span> {{ rel.translation[lang] }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <template v-if="group === 'anatomy' && data['data'].anatomy && Object.keys(data['data'].anatomy).length > 0">
          <b-card-header header-bg-variant="white" header-text-variant="dark" class="p-1">
            <strong> {{ option_data.param_group.parameters[group].label }} </strong>
          </b-card-header>
          <div v-for="(sys, sys_ind) in data['data'].anatomy">
            <template v-if="view_mode === 'table'">
              <div style="padding-left: 10px; color: #a73030;"> <strong> {{ sys.name.label }}: </strong> </div>
            </template>
            <template v-else>
              <span style="padding-left: 10px; color: #a73030;"><strong> {{ sys.name.label }}: </strong></span>
            </template>
            <wisdom-show :change="change" :relation="'mother'" :view_mode="view_mode" :data="sys.signs" :group_parameters="option_data[group]" :brief_mode="brief_mode" :user_brief_list="user_brief_list" :param_group="group"></wisdom-show>
          </div>
        </template>
      </template>
    </template>
    <template v-if="data.data_not_match">
      <b-row style="padding-left: 10px; margin-left: 9px;" v-for="(x, x_ind) in data.data_not_match" :key="'data.data_not_match_' + x_ind">
        <b-col sm="12" lg="6"> {{ x.test_name }} </b-col>
        <b-col sm="12" lg="6" style="padding-left: 0px;">
          {{ x.val }}
        </b-col>
      </b-row>
    </template>
    <template v-if="data.rule_algoritm">
      <b-row style="margin-top: 10px;">
        <b-col sm="12" lg="12" style="text-align: center;"> <strong>{{ $t('wdm16.11217') }}</strong> </b-col>
      </b-row>
      <b-row style="margin-top: 10px;">
        <b-col sm="12" lg="12">
          <rule-query :query_list="data.rule_algoritm.query_list" :compile_str="data.rule_algoritm.compile_str_array" :mode="'view'" :compile_str_date="data.rule_algoritm.compile_str_date" :compile_str_text="data.rule_algoritm.compile_str_text"></rule-query>
          <!-- <rule-compile-str :compile_str_array="data.rule_algoritm.compile_str_array" :mode="'view'" :show_caption="'sorgu'" :acordion_index="'rule-1'"></rule-compile-str> -->
        </b-col>
      </b-row>
    </template>
    <!-- "data.rule.main_rule" this structure is defined in wdm3 polestar -->
    <template v-if="data.rule && data.rule.main_rule && data.rule.main_rule.query_list && data.rule.main_rule.query_list.length > 0">
      <b-row style="margin-top: 10px;">
        <b-col sm="12" lg="12" style="text-align: center;"> <strong>{{ $t('wdm16.11217') }}</strong> </b-col>
      </b-row>
      <b-row style="margin-top: 10px;">
        <b-col sm="12" lg="12">
          <rule-query :query_list="data.rule.main_rule.query_list" :compile_str="data.rule.main_rule.compile_str_array" :compile_str_date="data.rule.main_rule.compile_str_date" :mode="'view'" :compile_str_text="data.rule.main_rule.compile_str_text"></rule-query>
          <!-- <rule-compile-str :compile_str_array="data.rule.main_rule.compile_str_array" :mode="'view'" :show_caption="'sorgu'" :acordion_index="'rule-1'"></rule-compile-str> -->
        </b-col>
      </b-row>
    </template>
    <template v-if="data.tables && data.tables.length > 0">
      <b-card no-body header-tag="header">
        <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark"><strong>{{ $t('wdm16.11218') }}</strong></b-card-header>
        <template v-for="(table, table_ind) in data.tables">
          <wisdom-table :key="'wisdom_table_' + table_ind.toString()" :table_data="table" :table_no="table_ind + 1" :wdmr_data="{'data_type': data_type, 'id': data.id, 'table_list': data.tables}"></wisdom-table>
        </template>
      </b-card>
    </template>
    <template v-if="data.figures && data.figures.length > 0">
      <b-card no-body header-tag="header">
        <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark">
          <strong>{{ $t('wdm16.11219') }}</strong>
        </b-card-header>
        <template v-for="(figure, figure_ind) in data.figures">
          <b-card no-body header-tag="header" style="margin-bottom: 3px;">
            <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
              <b-row>
                <b-col cols="6">
                  <strong>{{ figure_ind + 1 }} -</strong> {{ figure.figure_name }}
                </b-col>
                <b-col cols="6" style="text-align: right;">
                  <b-button size="md" variant="secondary" style="margin-right: 5px; padding: 0px;" :title="$t('wdm16.11215')" @click="f_showFigure(figure.key)">
                    <img src="@/icon/824448.png" style="width: 1.5em;" />
                  </b-button>
                </b-col>
              </b-row>
            </b-card-header>
            <template v-if="d_figureList[figure.key]">
              <img :src="d_figureList[figure.key].data" style="width: 100%;">
            </template>
          </b-card>
        </template>
      </b-card>
    </template>
    <template v-if="data.sign && data.sign.list && data.sign.list.length > 0">
      <b-card no-body header-tag="header">
        <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark">
          <strong>Onay & İmza</strong>
        </b-card-header>
        <template v-for="(sign, sign_ind) in data.sign.list">
          <b-card no-body header-tag="header" style="margin-bottom: 3px;">
            <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
              <b-row>
                <b-col cols="6">
                  <strong>{{ sign_ind + 1 }} - </strong> {{ sign.username }}
                </b-col>
                <b-col cols="6" style="text-align: right;"></b-col>
              </b-row>
            </b-card-header>
            <b-row>
              <b-col cols="2">
                Kullanıcı: {{ sign.username }}
              </b-col>
              <b-col cols="3">
                <template v-if="sign.approve === ''">imzalanmadı</template>
                <template v-else-if="sign.approve === '1'">onaylandı / imzalandı. {{ DateFormatWithTime(sign.date) }}</template>
                <template v-else-if="sign.approve === '0'">onaylanmadı / imzalandı. {{ DateFormatWithTime(sign.date) }}</template>
              </b-col>
              <b-col cols="3">
                <div><strong>Loglar:</strong></div>
                <template v-if="sign.log && sign.log.length > 0">
                  <b-row v-for="(log, log_index) in sign.log">
                    <b-col cols="12">
                      <template v-if="log.approve === ''">imzalanmadı</template>
                      <template v-else-if="log.approve === '1'">onaylandı / imzalandı. {{ DateFormatWithTime(log.date) }}</template>
                      <template v-else-if="log.approve === '0'">onaylanmadı / imzalandı. {{ DateFormatWithTime(log.date) }}</template>
                    </b-col>
                  </b-row>
                </template>
              </b-col>
            </b-row>
          </b-card>
        </template>
      </b-card>
    </template>
    <b-modal id="image-show-modal" v-model="show_image_show_modal" :title="document_show_data.file_name" centered header-bg-variant="success" header-text-variant="dark">
      <div> <img :src="document_show_data.file" style="width: 100%;"> </div>
      <template slot="modal-footer">
        <b-button @click="show_image_show_modal = false">{{ $t('wdm16.11212') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import GlobalService from '@/services/global';
import FileSaver from 'file-saver';
import {
  default as RuleQuery
} from '@/components/widgets/RuleQuery';
import {
  default as WisdomTable
} from '@/components/widgets/WisdomTable';
import {
  default as RuleCompileStr
} from '@/components/widgets/RuleCompileStr';

import { mapGetters } from 'vuex';
import moment from 'moment';
import {
  default as WisdomShow
} from './WisdomShow';
import FileTransferService from '@/services/file_transfer';

export default {
  name: 'WisdomDataShow',
  props: {
    data: {
      type: Object,
      required: false,
      default: {}
    },
    option_data: {
      type: Object,
      required: false
    },
    data_type: {
      type: String,
      required: true
    },
    view_mode: {
      type: String,
      required: false,
      default: 'table'
    },
    change: {
      type: String,
      required: true,
      default: '0'
    },
    user_brief_list: {
      type: Array,
      required: false
    },
    brief_mode: {
      type: Number,
      required: false
    }
  },
  mounted () {},
  computed: mapGetters({
    lang: 'lang'
  }),
  data () {
    return {
      d_figureList: {},
      document_show_data: {},
      show_image_show_modal: false,
      select_types: ['select', 'checkbox', 'object_list']
    };
  },
  methods: {
    DateFormat: function (date) {
      if (date) {
        return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
      } else {
        return '';
      }
    },
    DateFormatWithTime: function (date) {
      if (date) {
        return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm');
      } else {
        return '';
      }
    },
    f_showFigure: function (figure_key) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Figür getiriliyor. Lütfen bekleyiniz.' } });
      GlobalService.get_one_cb_document({ 'key': figure_key, 'bucket': 'uploads' })
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.d_figureList[figure_key] = resp.data.result;
            this.$forceUpdate();
          } else {
            alert('error', resp.data.message);
          }
        });
    },
    f_briefControl: function (param_group) {
      // console.log(param_group);
      if (this.brief_mode) {
        if (this.user_brief_list && this.user_brief_list.length > 0) {
          for (let i in this.user_brief_list) {
            if (this.user_brief_list[i].indexOf(param_group) !== -1) {
              return true;
            }
          }
          return false;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    PreviewImage: function (doc_ind, file_name) {
      if (this.data.document_list[doc_ind].document) {
        this.document_show_data.file_name = this.data.document_list[doc_ind].file_name;
        this.document_show_data.file = this.data.document_list[doc_ind].document;
        this.show_image_show_modal = true;
      } else {
        let data = { 'key_list': [] };
        let key = this.data_type + '_' + this.data.id + '_doc' + doc_ind.toString();
        data.key_list.push(key);
        FileTransferService.get_file_with_key(data)
          .then(resp => {
            console.log(resp.data.result);
            if (resp.data.status === 'success') {
              this.document_show_data.file_name = file_name;
              this.document_show_data.file = resp.data.result[key].file;
              this.show_image_show_modal = true;
            } else {
              alert('error: ', resp.data.message);
            }
          });
      }
    },
    f_downloadDocument: function (doc_ind, file_name) {
      if (this.data.document_list[doc_ind].document) {
        FileSaver.saveAs(this.data.document_list[doc_ind].document, this.data.document_list[doc_ind].file_name);
      } else {
        let data = { 'key_list': [] };
        let key = this.data_type + '_' + this.data.id + '_doc' + doc_ind.toString();
        data.key_list.push(key);
        FileTransferService.get_file_with_key(data)
          .then(resp => {
            if (resp.data.status === 'success') {
              FileSaver.saveAs(resp.data.result[key].file, file_name);
            } else {
              alert('error: ', resp.data.message);
            }
          });
      }
    }
  },
  watch: {
    view_mode: function () {
      this.$forceUpdate();
    }
  },
  components: {
    WisdomShow,
    RuleQuery,
    RuleCompileStr,
    WisdomTable
  }
};

</script>

<style>


</style>

