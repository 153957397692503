<template>
  <div>
    <b-card no-body :class="[d_fullScreen ? 'full-screen-mode' : 'normal-mode']">
      <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
        <img src="@/icon/1430869.png" style="width: 2em;" /> {{ $t('wdm16.11147') }}
        <b-button class="pull-right" size="md" :variant="d_fullScreen ? 'warning' : 'secondary'" :style="d_fullScreen ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreen ? d_fullScreen = false : d_fullScreen = true">
          <img src="@/icon/629153.png" :title="$t('wdm16.6589')" style="width: 2em;" />
        </b-button>
      </b-card-header>
      <b-card no-body style="margin: 5px;">
        <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
          <b-row style="margin: 0px;">
            <b-col sm="12" md="6">
              <img src="@/icon/2956643.png" style="width: 2em;" /> {{ $t('wdm16.13044') }}
              <template v-if="mode === 'edit'">
                <b-dropdown v-if="query_list.length > 0" variant="secondary" size="sm" style="margin: 3px; width: 50px;" right>
                  <b-dropdown-item @click="add_key_compile_str('query', 'and')"> {{ $t('wdm16.13045') }} </b-dropdown-item>
                  <b-dropdown-item @click="add_key_compile_str('query', 'or')"> {{ $t('wdm16.13046') }} </b-dropdown-item>
                  <b-dropdown-item @click="f_selectQueryAndAdd()">{{ $t('wdm16.13047') }}</b-dropdown-item>
                  <b-dropdown-item @click="f_deleteQueryList()">{{ $t('wdm16.13048') }}</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-col>
            <b-col sm="12" md="6">
            </b-col>
          </b-row>
        </b-card-header>
        <template v-if="mode === 'edit'">
          <b-row style="margin: 3px;">
            <b-col cols="12">
              <small v-if="query_list.length > 0" style="color: green; cursor: pointer;" @click="f_selectQueryAndAdd()">{{ $t('wdm16.13049') }} = {{ query_list.length }} & {{ $t('wdm16.6381') }}</small>
              <small v-else style="color: red; cursor: pointer;">{{ $t('wdm16.13050') }}</small>
            </b-col>
          </b-row>
        </template>
        <div :style="f_calculateStyleAlgoritm()">
          <table>
            <tbody>
              <tr>
                <template v-for="(com, com_ind) in compile_str">
                  <template v-if="com === ')' || com === '('">
                    <td class="query-compilestr-parenthesis"> {{ com }} </td>
                  </template>
                  <template v-else-if="com === 'and'">
                    <td class="query-compilestr-operation-and"> {{ com }} </td>
                  </template>
                  <template v-else-if="com === 'or'">
                    <td class="query-compilestr-operation-or"> {{ com }} </td>
                  </template>
                  <template v-else-if="com.toString().indexOf('text[') !== -1">
                    <td class="query-compilestr-operation-or"> {{ com }} </td>
                  </template>
                  <template v-else>
                    <td>
                      <b-card no-body style="height: 100%; width: 200px; margin: 3px; background: #eaf6f6;">
                        <b-card-header :header-bg-variant="query_list[com].significant === 'true' ? 'secondary' : 'danger'" header-text-variant="dark" class="p-1">
                          <b-row>
                            <b-col sm="12" md="10" :title="query_list[com].type.label">
                              <b>{{ com + 1 }}- </b> {{ f_showLastWdmOfLayerAndThisChars(query_list[com].type.label, 200) }}
                            </b-col>
                            <b-col sm="12" md="2">
                              <b-button class="pull-right" size="sm" variant="secondary" style="margin-right: 5px; padding: 0px;" @click="f_showQuery(com)">
                                <img src="@/icon/629153.png" :title="$t('wdm16.3897')" style="width: 1.4em;" />
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-card-header>
                        <template v-for="(k, k_ind) in query_list[com].compile_str_array">
                          <template v-if="k === ')' || k === '('">
                            <span class="compilestr-parenthesis"> {{ k }} </span>
                          </template>
                          <template v-else-if="k === 'and'">
                            <span class="compilestr-operation-and"> {{ k }} </span>
                          </template>
                          <template v-else-if="k === 'or'">
                            <span class="compilestr-operation-or"> {{ k }} </span>
                          </template>
                          <template v-else>
                            <b-card no-body :style="query_list[com].fields[k].significant === 'true' ? 'margin: 3px;' : 'margin: 3px; background: #f86c6b;'">
                              <template v-for="(loc, loc_ind) in query_list[com].fields[k].loc">
                                <template> {{ loc.label }}. </template>
                                <template v-if="loc.type === 'list_parameter'">
                                  <template v-if="loc.i_type === 'any'">{{ $t('wdm16.13074') }}</template>
                                  <template v-else-if="loc.i_type === 'this_group'">[{{ 'G-' + loc.i_group }}].</template>
                                </template>
                                <template v-if="loc.value === 'anatomy'">
                                  <template v-if="query_list[com].fields[k].anatomy_use === 'this'">
                                    <template v-if="query_list[com].fields[k].this_anatomy && query_list[com].fields[k].this_anatomy.label">
                                      {{ query_list[com].fields[k].this_anatomy.label }}.
                                    </template>
                                    <template v-else style="color: red;">
                                      {{ $t('wdm16.11247') }}
                                    </template>
                                  </template>
                                  <template v-else-if="query_list[com].fields[k].anatomy_use === 'parent'">
                                    <template v-if="query_list[com].fields[k].this_parent && query_list[com].fields[k].this_parent.label">
                                      {{ query_list[com].fields[k].this_parent.label }}.
                                    </template>
                                    <template v-else style="color: red;">
                                      {{ $t('wdm16.11248') }}
                                    </template>
                                  </template>
                                  <template v-else-if="query_list[com].fields[k].anatomy_use === 'any'">
                                    <template> {{ $t('wdm16.13075') }} </template>
                                  </template>
                                </template>
                              </template>
                              <template v-if="query_list[com].fields[k].val_list && query_list[com].fields[k].val_list.length > 0">
                                <b>{{ query_list[com].fields[k].operation }}</b>
                                <span v-for="(val, val_ind) in query_list[com].fields[k].val_list">
                                  <i style="color: #0091d7;">
                                    <b v-if="typeof val === 'object'"> {{ val.label }} </b>
                                    <b v-else> {{ val }} </b>
                                    <template v-if="val_ind !== query_list[com].fields[k].val_list.length - 1">
                                      ;
                                    </template>
                                  </i>
                                </span>
                              </template>
                            </b-card>
                          </template>
                        </template>
                      </b-card>
                    </td>
                  </template>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card>
      <!-- *** DATE ALGIRITHM *** DATE DATE *** DATE ALGIRITHM *** *** DATE ALGIRITHM *** *** DATE ALGIRITHM *** *** DATE ALGIRITHM *** -->
      <b-card no-body v-if="f_controlWdmrDateAlgorithmEligibility()" style="margin: 5px;">
        <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
          <b-row style="margin: 0px;">
            <b-col sm="12" md="6">
              <img src="@/icon/2301012.png" style="width: 2em;" /> {{ $t('wdm16.13051') }}
              <template v-if="mode === 'edit'">
                <b-dropdown v-if="query_list.length > 0" variant="secondary" size="sm" style="margin: 7px; width: 50px;" right>
                  <b-dropdown-item @click="d_showQueryListForDateAlgorithm = true">{{ $t('wdm16.13052') }}</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-col>
            <b-col sm="12" md="6">
            </b-col>
          </b-row>
        </b-card-header>
        <template v-if="mode === 'edit'">
          <b-row style="margin: 3px;">
            <b-col cols="12">
              <small v-if="query_list.length > 0" style="color: green; cursor: pointer;" @click="d_showQueryListForDateAlgorithm = true">{{ $t('wdm16.13049') }} = {{ query_list.length }}</small>
              <small v-else style="color: red; cursor: pointer;">{{ $t('wdm16.13050') }}</small>
            </b-col>
          </b-row>
        </template>
        <div :style="f_calculateStyleAlgoritm()">
          <table>
            <tbody>
              <tr>
                <template v-for="(com, com_ind) in compile_str_date">
                  <template v-if="com === ')' || com === '('">
                    <td class="query-compilestr-parenthesis"> {{ com }} </td>
                  </template>
                  <template v-else-if="com === 'and'">
                    <td class="query-compilestr-operation-and"> {{ com }} </td>
                  </template>
                  <template v-else-if="com === 'or'">
                    <td class="query-compilestr-operation-or"> {{ com }} </td>
                  </template>
                  <template v-else-if="com === '<'">
                    <td class="query-compilestr-operation-and"> {{ com }} </td>
                  </template>
                  <template v-else-if="com === '<='">
                    <td class="query-compilestr-operation-and"> {{ com }} </td>
                  </template>
                  <template v-else-if="com === '>'">
                    <td class="query-compilestr-operation-and"> {{ com }} </td>
                  </template>
                  <template v-else-if="com === '>='">
                    <td class="query-compilestr-operation-and"> {{ com }} </td>
                  </template>
                  <template v-else-if="com === '='">
                    <td class="query-compilestr-operation-and"> {{ com }} </td>
                  </template>
                  <template v-else-if="com === '+'">
                    <td class="query-compilestr-operation-and"> {{ com }} </td>
                  </template>
                  <template v-else-if="com === '-'">
                    <td class="query-compilestr-operation-and"> {{ com }} </td>
                  </template>
                  <template v-else-if="com.toString().indexOf('date') !== -1">
                    <td class="query-compilestr-operation-and"> {{ com }} </td>
                  </template>
                  <template v-else-if="com.toString().indexOf('days') !== -1">
                    <td class="query-compilestr-operation-and"> {{ com }} </td>
                  </template>
                  <template v-else-if="com.toString().indexOf('phrase') !== -1">
                    <td class="query-compilestr-operation-and"> {{ com }} </td>
                  </template>
                  <template v-else>
                    <td>
                      <b-card no-body style="height: 100%; width: 200px; margin: 3px; background: #eaf6f6;">
                        <b-card-header :header-bg-variant="query_list[com].significant === 'true' ? 'secondary' : 'danger'" header-text-variant="dark" class="p-1">
                          <b-row>
                            <b-col sm="12" md="10">
                              <b>{{ com + 1 }}- </b> {{ query_list[com].type.label }}
                            </b-col>
                            <b-col sm="12" md="2">
                              <b-button class="pull-right" size="sm" variant="secondary" style="margin-right: 5px; padding: 0px;" @click="f_showQuery(com)">
                                <img src="@/icon/629153.png" :title="$t('wdm16.3897')" style="width: 1.4em;" />
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-card-header>
                        <template v-for="(k, k_ind) in query_list[com].compile_str_array">
                          <template v-if="k === ')' || k === '('">
                            <span class="compilestr-parenthesis"> {{ k }} </span>
                          </template>
                          <template v-else-if="k === 'and'">
                            <span class="compilestr-operation-and"> {{ k }} </span>
                          </template>
                          <template v-else-if="k === 'or'">
                            <span class="compilestr-operation-or"> {{ k }} </span>
                          </template>
                          <template v-else>
                            <b-card no-body :style="query_list[com].fields[k].significant === 'true' ? 'margin: 3px;' : 'margin: 3px; background: #f86c6b;'">
                              <template v-for="(loc, loc_ind) in query_list[com].fields[k].loc">
                                <template> {{ loc.label }}. </template>
                                <template v-if="loc.type === 'list_parameter'">
                                  <template v-if="loc.i_type === 'any'">{{ $t('wdm16.13074') }}</template>
                                  <template v-else-if="loc.i_type === 'this_group'">[{{ 'G-' + loc.i_group }}].</template>
                                </template>
                                <template v-if="loc.value === 'anatomy'">
                                  <template v-if="query_list[com].fields[k].anatomy_use === 'this'">
                                    <template v-if="query_list[com].fields[k].this_anatomy && query_list[com].fields[k].this_anatomy.label">
                                      {{ query_list[com].fields[k].this_anatomy.label }}.
                                    </template>
                                    <template v-else style="color: red;">
                                      {{ $t('wdm16.11247') }}
                                    </template>
                                  </template>
                                  <template v-else-if="query_list[com].fields[k].anatomy_use === 'parent'">
                                    <template v-if="query_list[com].fields[k].this_parent && query_list[com].fields[k].this_parent.label">
                                      {{ query_list[com].fields[k].this_parent.label }}.
                                    </template>
                                    <template v-else style="color: red;">
                                      {{ $t('wdm16.11248') }}
                                    </template>
                                  </template>
                                  <template v-else-if="query_list[com].fields[k].anatomy_use === 'any'">
                                    <template> {{ $t('wdm16.13075') }} </template>
                                  </template>
                                </template>
                              </template>
                              <template v-if="query_list[com].fields[k].val_list && query_list[com].fields[k].val_list.length > 0">
                                <b>{{ query_list[com].fields[k].operation }}</b>
                                <span v-for="(val, val_ind) in query_list[com].fields[k].val_list">
                                  <i style="color: #0091d7;">
                                    <b v-if="typeof val === 'object'"> {{ val.label }} </b>
                                    <b v-else> {{ val }} </b>
                                    <template v-if="val_ind !== query_list[com].fields[k].val_list.length - 1">
                                      ;
                                    </template>
                                  </i>
                                </span>
                              </template>
                            </b-card>
                          </template>
                        </template>
                      </b-card>
                    </td>
                  </template>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card>
      <!-- *** TEXT algorithm *** TEXT algorithm *** TEXT algorithm *** *** TEXT algorithm *** *** TEXT algorithm *** *** TEXT algorithm *** -->
      <b-card no-body style="margin: 5px;" v-if="f_showTextAlgorithm()">
        <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
          <b-row style="margin: 0px;">
            <b-col sm="12" md="6">
              <img src="@/icon/2301012.png" style="width: 2em;" /> {{ $t('wdm16.13055') }}
              <template v-if="mode === 'edit'">
                <b-dropdown v-if="query_list.length > 0" variant="secondary" size="sm" style="margin: 7px; width: 50px;" right>
                  <b-dropdown-item @click="d_showQueryListForTextAlgorithm = true">{{ $t('wdm16.13052') }}</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-col>
            <b-col sm="12" md="6">
            </b-col>
          </b-row>
        </b-card-header>
        <template v-if="mode === 'edit'">
          <b-row style="margin: 3px;">
            <b-col cols="12">
              <small v-if="query_list.length > 0" style="color: green; cursor: pointer;" @click="d_showQueryListForTextAlgorithm = true">{{ $t('wdm16.13049') }} = {{ query_list.length }}</small>
              <small v-else style="color: red; cursor: pointer;">{{ $t('wdm16.13050') }}</small>
            </b-col>
          </b-row>
        </template>
        <div :style="f_calculateStyleAlgoritm()">
          <table>
            <tbody>
              <tr>
                <template v-for="(com, com_ind) in compile_str_text">
                  <template v-if="com.toString().indexOf('text[') !== -1">
                    <template v-if="mode === 'edit'">
                      <td class="query-compilestr-text" draggable @dragstart='f_startDragText($event, com_ind, "own_list")' @drop='f_onDropCompileStrText($event, com_ind)' @dragover.prevent @dragenter.prevent> {{ com }} </td>
                    </template>
                    <template v-if="mode === 'view'">
                      <td class="query-compilestr-text"> {{ com }} </td>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="mode === 'edit'">
                      <td draggable @dragstart='f_startDragText($event, com_ind, "own_list")' @drop='f_onDropCompileStrText($event, com_ind)' @dragover.prevent @dragenter.prevent>
                        <b-card no-body style="height: 100%; width: 200px; margin: 3px; background: #eaf6f6;">
                          <b-card-header :header-bg-variant="query_list[com.split('_')[0]].significant === 'true' ? 'secondary' : 'danger'" header-text-variant="dark" class="p-1">
                            <b-row>
                              <b-col sm="12" md="10">
                                <b>{{ parseInt(com.split('_')[0]) + 1 }}- </b> {{ query_list[com.split('_')[0]].type.label }}
                              </b-col>
                              <b-col sm="12" md="2">
                                <b-button class="pull-right" size="sm" variant="secondary" style="margin-right: 5px; padding: 0px;" @click="f_showQuery(com.split('_')[0])">
                                  <img src="@/icon/629153.png" :title="$t('wdm16.3897')" style="width: 1.4em;" />
                                </b-button>
                              </b-col>
                            </b-row>
                          </b-card-header>
                          <b-card no-body :style="query_list[com.split('_')[0]].fields[com.split('_')[1]].significant === 'true' ? 'margin: 3px;' : 'margin: 3px; background: #f86c6b;'">
                            <template v-for="(loc, loc_ind) in query_list[com.split('_')[0]].fields[com.split('_')[1]].loc">
                              <template> {{ loc.label }}. </template>
                              <template v-if="loc.type === 'list_parameter'">
                                <template v-if="loc.i_type === 'any'">{{ $t('wdm16.13074') }}</template>
                                <template v-else-if="loc.i_type === 'this_group'">[{{ 'G-' + loc.i_group }}].</template>
                              </template>
                              <template v-if="loc.value === 'anatomy'">
                                <template v-if="query_list[com.split('_')[0]].fields[com.split('_')[1]].anatomy_use === 'this'">
                                  <template v-if="query_list[com.split('_')[0]].fields[com.split('_')[1]].this_anatomy && query_list[com.split('_')[0]].fields[com.split('_')[1]].this_anatomy.label">
                                    {{ query_list[com.split('_')[0]].fields[com.split('_')[1]].this_anatomy.label }}.
                                  </template>
                                  <template v-else style="color: red;">
                                    {{ $t('wdm16.11247') }}
                                  </template>
                                </template>
                                <template v-else-if="query_list[com.split('_')[0]].fields[com.split('_')[1]].anatomy_use === 'parent'">
                                  <template v-if="query_list[com.split('_')[0]].fields[com.split('_')[1]].this_parent && query_list[com.split('_')[0]].fields[com.split('_')[1]].this_parent.label">
                                    {{ query_list[com.split('_')[0]].fields[com.split('_')[1]].this_parent.label }}.
                                  </template>
                                  <template v-else style="color: red;">
                                    {{ $t('wdm16.11248') }}
                                  </template>
                                </template>
                                <template v-else-if="query_list[com.split('_')[0]].fields[com.split('_')[1]].anatomy_use === 'any'">
                                  <template> {{ $t('wdm16.13075') }} </template>
                                </template>
                              </template>
                            </template>
                          </b-card>
                        </b-card>
                      </td>
                    </template>
                    <template v-if="mode === 'view'">
                      <td>
                        <b-card no-body style="height: 100%; width: 200px; margin: 3px; background: #eaf6f6;">
                          <b-card-header :header-bg-variant="query_list[com.split('_')[0]].significant === 'true' ? 'secondary' : 'danger'" header-text-variant="dark" class="p-1">
                            <b-row>
                              <b-col sm="12" md="10">
                                <b>{{ parseInt(com.split('_')[0]) + 1 }}- </b> {{ query_list[com.split('_')[0]].type.label }}
                              </b-col>
                              <b-col sm="12" md="2">
                                <b-button class="pull-right" size="sm" variant="secondary" style="margin-right: 5px; padding: 0px;" @click="f_showQuery(com.split('_')[0])">
                                  <img src="@/icon/629153.png" :title="$t('wdm16.3897')" style="width: 1.4em;" />
                                </b-button>
                              </b-col>
                            </b-row>
                          </b-card-header>
                          <b-card no-body :style="query_list[com.split('_')[0]].fields[com.split('_')[1]].significant === 'true' ? 'margin: 3px;' : 'margin: 3px; background: #f86c6b;'">
                            <template v-for="(loc, loc_ind) in query_list[com.split('_')[0]].fields[com.split('_')[1]].loc">
                              <template> {{ loc.label }}. </template>
                              <template v-if="loc.type === 'list_parameter'">
                                <template v-if="loc.i_type === 'any'">{{ $t('wdm16.13074') }}</template>
                                <template v-else-if="loc.i_type === 'this_group'">[{{ 'G-' + loc.i_group }}].</template>
                              </template>
                              <template v-if="loc.value === 'anatomy'">
                                <template v-if="query_list[com.split('_')[0]].fields[com.split('_')[1]].anatomy_use === 'this'">
                                  <template v-if="query_list[com.split('_')[0]].fields[com.split('_')[1]].this_anatomy && query_list[com.split('_')[0]].fields[com.split('_')[1]].this_anatomy.label">
                                    {{ query_list[com.split('_')[0]].fields[com.split('_')[1]].this_anatomy.label }}.
                                  </template>
                                  <template v-else style="color: red;">
                                    {{ $t('wdm16.11247') }}
                                  </template>
                                </template>
                                <template v-else-if="query_list[com.split('_')[0]].fields[com.split('_')[1]].anatomy_use === 'parent'">
                                  <template v-if="query_list[com.split('_')[0]].fields[com.split('_')[1]].this_parent && query_list[com.split('_')[0]].fields[com.split('_')[1]].this_parent.label">
                                    {{ query_list[com.split('_')[0]].fields[com.split('_')[1]].this_parent.label }}.
                                  </template>
                                  <template v-else style="color: red;">
                                    {{ $t('wdm16.11248') }}
                                  </template>
                                </template>
                                <template v-else-if="query_list[com.split('_')[0]].fields[com.split('_')[1]].anatomy_use === 'any'">
                                  <template> {{ $t('wdm16.13075') }} </template>
                                </template>
                              </template>
                            </template>
                          </b-card>
                        </b-card>
                      </td>
                    </template>
                  </template>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card>
    </b-card>
    <!-- RULE ALGORITHM MODAL & RULE ALGORITHM MODAL & RULE ALGORITHM MODAL & RULE ALGORITHM MODAL & RULE ALGORITHM MODAL -->
    <modal v-if="d_showQueryList" @close="d_showQueryList = false" :width="'1500'">
      <h3 slot="header">{{ $t('wdm16.13066') }}</h3>
      <div slot="body">
        <template v-if="mode === 'edit'">
          <b-row style="margin: 3px;" @drop="f_onDropDeleteCompileIndex($event, 'operation_list')" @dragover.prevent @dragenter.prevent>
            <b-col cols="12" style="text-align: center;">
              <b-dropdown v-if="query_list.length > 0" variant="secondary" size="md" style="margin: 3px; width: 50px;" right>
                <b-dropdown-item @click="add_key_compile_str('query', 'and')"> {{ $t('wdm16.13045') }} </b-dropdown-item>
                <b-dropdown-item @click="add_key_compile_str('query', 'or')"> {{ $t('wdm16.13046') }} </b-dropdown-item>
              </b-dropdown>
              <b-button draggable @dragstart="f_startDrag($event, '(', 'operation')" size="md" @click="f_addToCompileStr(compile_str, '(')" style="margin: 3px; width: 50px;">(</b-button>
              <b-button draggable @dragstart="f_startDrag($event, ')', 'operation')" size="md" @click="f_addToCompileStr(compile_str, ')')" style="margin: 3px; width: 50px;">)</b-button>
              <b-button draggable @dragstart="f_startDrag($event, 'and', 'operation')" size="md" @click="f_addToCompileStr(compile_str, 'and')" style="margin: 3px; width: 50px;">{{ $t('wdm16.3969') }}</b-button>
              <b-button draggable @dragstart="f_startDrag($event, 'or', 'operation')" size="md" @click="f_addToCompileStr(compile_str, 'or')" style="margin: 3px; width: 50px;">{{ $t('wdm16.4254') }}</b-button>
              <b-button size="md" @click="BackInCompileStr()" style="margin: 3px; width: 50px;">geri</b-button>
              <b-button size="md" @click="ClearCompileStr()" :title="$t('wdm16.13061')" style="margin: 3px; width: 50px;"><i class="fa fa-trash"></i></b-button>
            </b-col>
          </b-row>
        </template>
        <b-row>
          <b-col sm="12" :md="mode === 'edit' ? 3 : 12">
            <div @drop="f_onDropDeleteCompileIndex($event, 'query_list')" @dragover.prevent @dragenter.prevent>
              <template v-for="(query, query_ind) in query_list">
                <b-card no-body style="margin: 5px; background: #eaf6f6;" draggable @dragstart="f_startDrag($event, query_ind, 'query')">
                  <b-card-header :header-bg-variant="query.significant === 'true' ? 'secondary' : 'danger'" header-text-variant="dark" class="p-1">
                    <b-row>
                      <b-col sm="12" md="9">
                        <b>{{ query_ind + 1 }}- </b> {{ query.type.label }}
                      </b-col>
                      <b-col sm="12" md="3">
                        <b-dropdown v-if="mode === 'edit'" variant="secondary" size="md">
                          <template slot="button-content">
                          </template>
                          <b-dropdown-item @click="add_to_model(compile_str, query_ind)">
                            :<img src="@/icon/2766751.png" title="$t('wdm16.13062')" style="width: 1.4em;" /> {{ $t('wdm16.13063') }}
                          </b-dropdown-item>
                          <b-dropdown-item @click="f_showQuery(query_ind)">
                            <img src="@/icon/629153.png" :title="$t('wdm16.13062')" style="width: 1.4em;" /> {{ $t('wdm16.3897') }}
                          </b-dropdown-item>
                          <b-dropdown-item @click="f_deleteThisQuery(query_ind)">
                            <img src="@/icon/2960590.png" :title="$t('wdm16.13062')" style="width: 1.4em;" /> {{ $t('wdm16.4398') }}
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-col>
                    </b-row>
                  </b-card-header>
                  <template v-for="(k, k_ind) in query.compile_str_array">
                    <template v-if="k === ')' || k === '('">
                      <span class="compilestr-parenthesis"> {{ k }} </span>
                    </template>
                    <template v-else-if="k === 'and'">
                      <span class="compilestr-operation-and"> {{ k }} </span>
                    </template>
                    <template v-else-if="k === 'or'">
                      <span class="compilestr-operation-or"> {{ k }} </span>
                    </template>
                    <template v-else-if="k.toString().indexOf('text[') !== -1">
                      {{ k }}
                    </template>
                    <template v-else>
                      <b-card no-body :style="query.fields[k].significant === 'true' ? 'margin: 3px;' : 'margin: 3px; background: #f86c6b;'">
                        <template v-for="(loc, loc_ind) in query.fields[k].loc">
                          <template> {{ loc.label }}. </template>
                          <template v-if="loc.type === 'list_parameter'">
                            <template v-if="loc.i_type === 'any'">{{ $t('wdm16.13074') }}</template>
                            <template v-else-if="loc.i_type === 'this_group'">[{{ 'G-' + loc.i_group }}].</template>
                          </template>
                          <template v-if="loc.value === 'anatomy'">
                            <template v-if="query.fields[k].anatomy_use === 'this'">
                              <template v-if="query.fields[k].this_anatomy && query.fields[k].this_anatomy.label">
                                {{ query.fields[k].this_anatomy.label }}.
                              </template>
                              <template v-else style="color: red;">
                                {{ $t('wdm16.11247') }}
                              </template>
                            </template>
                            <template v-else-if="query.fields[k].anatomy_use === 'parent'">
                              <template v-if="query.fields[k].this_parent && query.fields[k].this_parent.label">
                                {{ query.fields[k].this_parent.label }}.
                              </template>
                              <template v-else style="color: red;">
                                {{ $t('wdm16.11248') }}
                              </template>
                            </template>
                            <template v-else-if="query.fields[k].anatomy_use === 'any'">
                              <template> {{ $t('wdm16.13075') }} </template>
                            </template>
                          </template>
                        </template>
                        <template v-if="query.fields[k].val_list && query.fields[k].val_list.length > 0">
                          <b>{{ query.fields[k].operation }}</b>
                          <span v-for="(val, val_ind) in query.fields[k].val_list">
                            <i style="color: #0091d7;">
                              <b v-if="typeof val === 'object'"> {{ val.label }} </b>
                              <b v-else> {{ val }} </b>
                              <template v-if="val_ind !== query.fields[k].val_list.length - 1">
                                ;
                              </template>
                            </i>
                          </span>
                        </template>
                      </b-card>
                    </template>
                  </template>
                </b-card>
              </template>
            </div>
          </b-col>
          <b-col v-if="mode === 'edit'" sm="12" md="9">
            <div id="rule-algorithm" style="overflow-y: auto; overflow-x: auto; height: 500px;">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div @drop="f_onDropCompileStr($event, 'compile_str_array_left')" @dragover.prevent @dragenter.prevent style="min-height: 100px; border-radius: 50px 5px 5px 50px; background-color: #b6f7f6; width: 50px; margin-right: 30px;">
                      </div>
                    </td>
                    <td v-if="compile_str.length === 0">
                      Algoritmanız boş lütfen ekleyiniz
                    </td>
                    <template v-for="(com, com_ind) in compile_str">
                      <td v-if="com === ')' || com === '('" class="query-compilestr-parenthesis" draggable @dragstart='f_startDrag($event, com_ind, "own_list")' @drop='f_onDropCompileStr($event, com_ind)' @dragover.prevent @dragenter.prevent> {{ com }}
                        <div v-if="d_errorCompileStr === com_ind" style="color: red;">!!!</div>
                      </td>
                      <td v-else-if="com === 'and'" class="query-compilestr-operation-and" draggable @dragstart='f_startDrag($event, com_ind, "own_list")' @drop='f_onDropCompileStr($event, com_ind)' @dragover.prevent @dragenter.prevent> {{ com }}
                        <div v-if="d_errorCompileStr === com_ind" style="color: red;">!!!</div>
                      </td>
                      <td v-else-if="com === 'or'" class="query-compilestr-operation-or" draggable @dragstart='f_startDrag($event, com_ind, "own_list")' @drop='f_onDropCompileStr($event, com_ind)' @dragover.prevent @dragenter.prevent> {{ com }}
                        <div v-if="d_errorCompileStr === com_ind" style="color: red;">!!!</div>
                      </td>
                      <td v-else-if="com.toString().indexOf('text[') !== -1" class="query-compilestr-operation-text" draggable @dragstart='f_startDrag($event, com_ind, "own_list")' @drop='f_onDropCompileStr($event, com_ind)' @dragover.prevent @dragenter.prevent> {{ com }}
                        <div v-if="d_errorCompileStr === com_ind" style="color: red;">!!!</div>
                      </td>
                      <td v-else draggable @dragstart='f_startDrag($event, com_ind, "own_list")' @drop='f_onDropCompileStr($event, com_ind)' @dragover.prevent @dragenter.prevent>
                        <b-card no-body style="height: 100%; width: 200px; margin: 3px; background: #eaf6f6;">
                          <b-card-header :header-bg-variant="query_list[com].significant === 'true' ? 'secondary' : 'danger'" header-text-variant="dark" class="p-1">
                            <b-row>
                              <b-col sm="12" md="10">
                                <b>{{ com + 1 }}- </b> {{ query_list[com].type.label }}
                              </b-col>
                              <b-col sm="12" md="2">
                                <b-button class="pull-right" size="sm" variant="secondary" style="margin-right: 5px; padding: 0px;" @click="f_showQuery(com)">
                                  <img src="@/icon/629153.png" :title="$t('wdm16.3897')" style="width: 1.4em;" />
                                </b-button>
                              </b-col>
                            </b-row>
                          </b-card-header>
                          <template v-for="(k, k_ind) in query_list[com].compile_str_array">
                            <template v-if="k === ')' || k === '('">
                              <span class="compilestr-parenthesis"> {{ k }} </span>
                            </template>
                            <template v-else-if="k === 'and'">
                              <span class="compilestr-operation-and"> {{ k }} </span>
                            </template>
                            <template v-else-if="k === 'or'">
                              <span class="compilestr-operation-or"> {{ k }} </span>
                            </template>
                            <template v-else>
                              <b-card no-body :style="query_list[com].fields[k].significant === 'true' ? 'margin: 3px;' : 'margin: 3px; background: #f86c6b;'">
                                <template v-for="(loc, loc_ind) in query_list[com].fields[k].loc">
                                  <template> {{ loc.label }}. </template>
                                  <template v-if="loc.type === 'list_parameter'">
                                    <template v-if="loc.i_type === 'any'">{{ $t('wdm16.13074') }}</template>
                                    <template v-else-if="loc.i_type === 'this_group'">[{{ 'G-' + loc.i_group }}].</template>
                                  </template>
                                  <template v-if="loc.value === 'anatomy'">
                                    <template v-if="query_list[com].fields[k].anatomy_use === 'this'">
                                      <template v-if="query_list[com].fields[k].this_anatomy && query_list[com].fields[k].this_anatomy.label">
                                        {{ query_list[com].fields[k].this_anatomy.label }}.
                                      </template>
                                      <template v-else style="color: red;">
                                        {{ $t('wdm16.11247') }}
                                      </template>
                                    </template>
                                    <template v-else-if="query_list[com].fields[k].anatomy_use === 'parent'">
                                      <template v-if="query_list[com].fields[k].this_parent && query_list[com].fields[k].this_parent.label">
                                        {{ query_list[com].fields[k].this_parent.label }}.
                                      </template>
                                      <template v-else style="color: red;">
                                        {{ $t('wdm16.11248') }}
                                      </template>
                                    </template>
                                    <template v-else-if="query_list[com].fields[k].anatomy_use === 'any'">
                                      <template> {{ $t('wdm16.13075') }} </template>
                                    </template>
                                  </template>
                                </template>
                                <template v-if="query_list[com].fields[k].val_list && query_list[com].fields[k].val_list.length > 0">
                                  <b>{{ query_list[com].fields[k].operation }}</b>
                                  <span v-for="(val, val_ind) in query_list[com].fields[k].val_list">
                                    <i style="color: #0091d7;">
                                      <b v-if="typeof val === 'object'"> {{ val.label }} </b>
                                      <b v-else> {{ val }} </b>
                                      <template v-if="val_ind !== query_list[com].fields[k].val_list.length - 1">
                                        ;
                                      </template>
                                    </i>
                                  </span>
                                </template>
                              </b-card>
                            </template>
                          </template>
                        </b-card>
                        <div v-if="d_errorCompileStr === com_ind" style="color: red;">!!!</div>
                      </td>
                    </template>
                    <td>
                      <div @drop="f_onDropCompileStr($event, 'compile_str_array_right')" @dragover.prevent @dragenter.prevent style="min-height: 100px; border-radius:  5px 50px 50px 5px; background-color: #b6f7f6; width: 50px; margin-left: 30px;">
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showQueryList = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <!-- Date edit algorithm modal -->
    <modal v-if="d_showQueryListForDateAlgorithm" @close="d_showQueryListForDateAlgorithm = false" :width="'1500'">
      <h3 slot="header">{{ $t('wdm16.13066') }}</h3>
      <div slot="body">
        <b-row style="margin: 3px;" @drop="f_onDropDeleteCompileIndexDate($event, 'operation_list')" @dragover.prevent @dragenter.prevent>
          <b-col cols="12" style="text-align: center;">
            <b-button size="md" draggable @dragstart="f_startDragDate($event, '(', 'operation')" @click="f_addCompileStrDate('(')" style="margin: 3px; width: 50px;"> ( </b-button>
            <b-button size="md" draggable @dragstart="f_startDragDate($event, ')', 'operation')" @click="f_addCompileStrDate(')')" style="margin: 3px; width: 50px;"> ) </b-button>
            <b-button size="md" draggable @dragstart="f_startDragDate($event, 'and', 'operation')" @click="f_addCompileStrDate('and')" style="margin: 3px; width: 50px;"> {{ $t('wdm16.3969') }} </b-button>
            <b-button size="md" draggable @dragstart="f_startDragDate($event, 'or', 'operation')" @click="f_addCompileStrDate('or')" style="margin: 3px; width: 50px;"> {{ $t('wdm16.4254') }} </b-button>
            <b-button size="md" draggable @dragstart="f_startDragDate($event, '<', 'operation')" @click="f_addCompileStrDate('<')" style="margin: 3px; width: 50px;">
              {{ f_showOperator('low') }}
            </b-button>
            <b-button size="md" draggable @dragstart="f_startDragDate($event, '<=', 'operation')" @click="f_addCompileStrDate('<=')" style="margin: 3px; width: 50px;">
              {{ f_showOperator('low_or_equal') }}
            </b-button>
            <b-button size="md" draggable @dragstart="f_startDragDate($event, '>', 'operation')" @click="f_addCompileStrDate('>')" style="margin: 3px; width: 50px;">
              {{ f_showOperator('high') }}
            </b-button>
            <b-button size="md" draggable @dragstart="f_startDragDate($event, '>=', 'operation')" @click="f_addCompileStrDate('>=')" style="margin: 3px; width: 50px;">
              {{ f_showOperator('high_or_equal') }}
            </b-button>
            <b-button size="md" draggable @dragstart="f_startDragDate($event, '=', 'operation')" @click="f_addCompileStrDate('=')" style="margin: 3px; width: 50px;"> = </b-button>
            <b-button size="md" draggable @dragstart="f_startDragDate($event, '+', 'operation')" @click="f_addCompileStrDate('+')" style="margin: 3px; width: 50px;"> + </b-button>
            <b-button size="md" draggable @dragstart="f_startDragDate($event, '-', 'operation')" @click="f_addCompileStrDate('-')" style="margin: 3px; width: 50px;"> - </b-button>
            <b-button size="md" @click="f_backInCompileStrDate()" style="margin: 3px; width: 50px;">geri</b-button>
            <b-button size="md" @click="f_addPhraseToDateAlgorithm('today')" title="Algoritmaya manuel tarih eklenmesini sağlar." style="margin: 3px;">bugün</b-button>
            <b-button size="md" @click="f_addManuelDate()" title="Algoritmaya manuel tarih eklenmesini sağlar." style="margin: 3px;">tarih</b-button>
            <b-button size="md" @click="f_addDayDifference()" title="Algoritmaya gün farkı eklenmesini sağlar." style="margin: 3px;">gün farkı</b-button>
            <b-button size="md" @click="f_clearDateCompileStr()" :title="$t('wdm16.13061')" style="margin: 3px; width: 50px;"><i class="fa fa-trash"></i></b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="3">
            <div @drop="f_onDropDeleteCompileIndexDate($event, 'query_list')" @dragover.prevent @dragenter.prevent>
              <template v-for="(query, query_ind) in query_list">
                <b-card no-body style="margin: 5px; background: #eaf6f6;" draggable @dragstart="f_startDragDate($event, query_ind, 'query')">
                  <b-card-header :header-bg-variant="query.significant === 'true' ? 'secondary' : 'danger'" header-text-variant="dark" class="p-1">
                    <b-row>
                      <b-col sm="12" md="9">
                        <b>{{ query_ind + 1 }}- </b> {{ query.type.label }}
                      </b-col>
                      <b-col sm="12" md="3">
                        <b-dropdown v-if="mode === 'edit'" variant="secondary" size="md">
                          <template slot="button-content">
                          </template>
                          <b-dropdown-item @click="f_showQuery(query_ind)">
                            <img src="@/icon/629153.png" :title="$t('wdm16.13062')" style="width: 1.4em;" /> {{ $t('wdm16.3897') }}
                          </b-dropdown-item>
                          <b-dropdown-item @click="f_addCompileStrDate(query_ind)">
                            <img src="@/icon/2301012.png" :title="$t('wdm16.13062')" style="width: 1.4em;" /> {{ $t('wdm16.13068') }}
                          </b-dropdown-item>
                          <b-dropdown-item v-if="compile_str.indexOf(query_ind) === -1" @click="f_deleteThisQuery(query_ind)">
                            <img src="@/icon/2960590.png" :title="$t('wdm16.13062')" style="width: 1.4em;" /> {{ $t('wdm16.4398') }}
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-col>
                    </b-row>
                  </b-card-header>
                  <template v-for="(k, k_ind) in query.compile_str_array">
                    <template v-if="k === ')' || k === '('">
                      <span class="compilestr-parenthesis"> {{ k }} </span>
                    </template>
                    <template v-else-if="k === 'and'">
                      <span class="compilestr-operation-and"> {{ k }} </span>
                    </template>
                    <template v-else-if="k === 'or'">
                      <span class="compilestr-operation-or"> {{ k }} </span>
                    </template>
                    <template v-else>
                      <b-card no-body :style="query.fields[k].significant === 'true' ? 'margin: 3px;' : 'margin: 3px; background: #f86c6b;'">
                        <template v-for="(loc, loc_ind) in query.fields[k].loc">
                          <template> {{ loc.label }}. </template>
                          <template v-if="loc.type === 'list_parameter'">
                            <template v-if="loc.i_type === 'any'">{{ $t('wdm16.13074') }}</template>
                            <template v-else-if="loc.i_type === 'this_group'">[{{ 'G-' + loc.i_group }}].</template>
                          </template>
                          <template v-if="loc.value === 'anatomy'">
                            <template v-if="query.fields[k].anatomy_use === 'this'">
                              <template v-if="query.fields[k].this_anatomy && query.fields[k].this_anatomy.label">
                                {{ query.fields[k].this_anatomy.label }}.
                              </template>
                              <template v-else style="color: red;">
                                {{ $t('wdm16.11247') }}
                              </template>
                            </template>
                            <template v-else-if="query.fields[k].anatomy_use === 'parent'">
                              <template v-if="query.fields[k].this_parent && query.fields[k].this_parent.label">
                                {{ query.fields[k].this_parent.label }}.
                              </template>
                              <template v-else style="color: red;">
                                {{ $t('wdm16.11248') }}
                              </template>
                            </template>
                            <template v-else-if="query.fields[k].anatomy_use === 'any'">
                              <template> {{ $t('wdm16.13075') }} </template>
                            </template>
                          </template>
                        </template>
                        <template v-if="query.fields[k].val_list && query.fields[k].val_list.length > 0">
                          <b>{{ query.fields[k].operation }}</b>
                          <span v-for="(val, val_ind) in query.fields[k].val_list">
                            <i style="color: #0091d7;">
                              <b v-if="typeof val === 'object'"> {{ val.label }} </b>
                              <b v-else> {{ val }} </b>
                              <template v-if="val_ind !== query.fields[k].val_list.length - 1">
                                ;
                              </template>
                            </i>
                          </span>
                        </template>
                      </b-card>
                    </template>
                  </template>
                </b-card>
              </template>
            </div>
          </b-col>
          <b-col sm="12" md="9">
            <div id="rule-algorithm" style="overflow-y: auto; overflow-x: auto; height: 500px;">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div @drop="f_onDropCompileStrDate($event, 'compile_str_date_left')" @dragover.prevent @dragenter.prevent style="min-height: 100px; border-radius:  50px 5px 5px 50px; background-color: #b6f7f6; width: 50px; margin-left: 30px;">
                      </div>
                    </td>
                    <td v-if="compile_str_date.length === 0">
                      Algoritmanız boş lütfen ekleyiniz
                    </td>
                    <template v-for="(com, com_ind) in compile_str_date">
                      <td v-if="com === ')' || com === '('" class="query-compilestr-parenthesis" draggable @dragstart='f_startDragDate($event, com_ind, "own_list")' @drop='f_onDropCompileStrDate($event, com_ind)' @dragover.prevent @dragenter.prevent> {{ com }} </td>
                      <td v-else-if="com === 'and'" class="query-compilestr-operation-and" draggable @dragstart='f_startDragDate($event, com_ind, "own_list")' @drop='f_onDropCompileStrDate($event, com_ind)' @dragover.prevent @dragenter.prevent> {{ com }} </td>
                      <td v-else-if="com === 'or'" class="query-compilestr-operation-or" draggable @dragstart='f_startDragDate($event, com_ind, "own_list")' @drop='f_onDropCompileStrDate($event, com_ind)' @dragover.prevent @dragenter.prevent> {{ com }} </td>
                      <td v-else-if="com === '<'" class="query-compilestr-operation-or" draggable @dragstart='f_startDragDate($event, com_ind, "own_list")' @drop='f_onDropCompileStrDate($event, com_ind)' @dragover.prevent @dragenter.prevent> {{ com }} </td>
                      <td v-else-if="com === '<='" class="query-compilestr-operation-or" draggable @dragstart='f_startDragDate($event, com_ind, "own_list")' @drop='f_onDropCompileStrDate($event, com_ind)' @dragover.prevent @dragenter.prevent> {{ com }} </td>
                      <td v-else-if="com === '>'" class="query-compilestr-operation-or" draggable @dragstart='f_startDragDate($event, com_ind, "own_list")' @drop='f_onDropCompileStrDate($event, com_ind)' @dragover.prevent @dragenter.prevent> {{ com }} </td>
                      <td v-else-if="com === '>='" class="query-compilestr-operation-or" draggable @dragstart='f_startDragDate($event, com_ind, "own_list")' @drop='f_onDropCompileStrDate($event, com_ind)' @dragover.prevent @dragenter.prevent> {{ com }} </td>
                      <td v-else-if="com === '='" class="query-compilestr-operation-or" draggable @dragstart='f_startDragDate($event, com_ind, "own_list")' @drop='f_onDropCompileStrDate($event, com_ind)' @dragover.prevent @dragenter.prevent> {{ com }} </td>
                      <td v-else-if="com === '+'" class="query-compilestr-operation-or" draggable @dragstart='f_startDragDate($event, com_ind, "own_list")' @drop='f_onDropCompileStrDate($event, com_ind)' @dragover.prevent @dragenter.prevent> {{ com }} </td>
                      <td v-else-if="com === '-'" class="query-compilestr-operation-or" draggable @dragstart='f_startDragDate($event, com_ind, "own_list")' @drop='f_onDropCompileStrDate($event, com_ind)' @dragover.prevent @dragenter.prevent> {{ com }} </td>
                      <td v-else-if="com.toString().indexOf('date') !== -1" class="query-compilestr-operation-or" draggable @dragstart='f_startDragDate($event, com_ind, "own_list")' @drop='f_onDropCompileStrDate($event, com_ind)' @dragover.prevent @dragenter.prevent> {{ com }} </td>
                      <td v-else-if="com.toString().indexOf('days') !== -1" class="query-compilestr-operation-or" draggable @dragstart='f_startDragDate($event, com_ind, "own_list")' @drop='f_onDropCompileStrDate($event, com_ind)' @dragover.prevent @dragenter.prevent> {{ com }} </td>
                      <td v-else-if="com.toString().indexOf('phrase') !== -1" class="query-compilestr-operation-or" draggable @dragstart='f_startDragDate($event, com_ind, "own_list")' @drop='f_onDropCompileStrDate($event, com_ind)' @dragover.prevent @dragenter.prevent> {{ com }} </td>
                      <td v-else draggable @dragstart='f_startDragDate($event, com_ind, "own_list")' @drop='f_onDropCompileStrDate($event, com_ind)' @dragover.prevent @dragenter.prevent>
                        <b-card no-body style="height: 100%; width: 200px; margin: 3px; background: #eaf6f6;">
                          <b-card-header :header-bg-variant="query_list[com].significant === 'true' ? 'secondary' : 'danger'" header-text-variant="dark" class="p-1">
                            <b-row>
                              <b-col sm="12" md="10">
                                <b>{{ com + 1 }}- </b> {{ query_list[com].type.label }}
                              </b-col>
                              <b-col sm="12" md="2">
                                <b-button class="pull-right" size="sm" variant="secondary" style="margin-right: 5px; padding: 0px;" @click="f_showQuery(com)">
                                  <img src="@/icon/629153.png" :title="$t('wdm16.3897')" style="width: 1.4em;" />
                                </b-button>
                              </b-col>
                            </b-row>
                          </b-card-header>
                          <template v-for="(k, k_ind) in query_list[com].compile_str_array">
                            <template v-if="k === ')' || k === '('">
                              <span class="compilestr-parenthesis"> {{ k }} </span>
                            </template>
                            <template v-else-if="k === 'and'">
                              <span class="compilestr-operation-and"> {{ k }} </span>
                            </template>
                            <template v-else-if="k === 'or'">
                              <span class="compilestr-operation-or"> {{ k }} </span>
                            </template>
                            <template v-else>
                              <b-card no-body :style="query_list[com].fields[k].significant === 'true' ? 'margin: 3px;' : 'margin: 3px; background: #f86c6b;'">
                                <template v-for="(loc, loc_ind) in query_list[com].fields[k].loc">
                                  <template> {{ loc.label }}. </template>
                                  <template v-if="loc.type === 'list_parameter'">
                                    <template v-if="loc.i_type === 'any'">{{ $t('wdm16.13074') }}</template>
                                    <template v-else-if="loc.i_type === 'this_group'">[{{ 'G-' + loc.i_group }}].</template>
                                  </template>
                                  <template v-if="loc.value === 'anatomy'">
                                    <template v-if="query_list[com].fields[k].anatomy_use === 'this'">
                                      <template v-if="query_list[com].fields[k].this_anatomy && query_list[com].fields[k].this_anatomy.label">
                                        {{ query_list[com].fields[k].this_anatomy.label }}.
                                      </template>
                                      <template v-else style="color: red;">
                                        {{ $t('wdm16.11247') }}
                                      </template>
                                    </template>
                                    <template v-else-if="query_list[com].fields[k].anatomy_use === 'parent'">
                                      <template v-if="query_list[com].fields[k].this_parent && query_list[com].fields[k].this_parent.label">
                                        {{ query_list[com].fields[k].this_parent.label }}.
                                      </template>
                                      <template v-else style="color: red;">
                                        {{ $t('wdm16.11248') }}
                                      </template>
                                    </template>
                                    <template v-else-if="query_list[com].fields[k].anatomy_use === 'any'">
                                      <template> {{ $t('wdm16.13075') }} </template>
                                    </template>
                                  </template>
                                </template>
                                <template v-if="query_list[com].fields[k].val_list && query_list[com].fields[k].val_list.length > 0">
                                  <b>{{ query_list[com].fields[k].operation }}</b>
                                  <span v-for="(val, val_ind) in query_list[com].fields[k].val_list">
                                    <i style="color: #0091d7;">
                                      <b v-if="typeof val === 'object'"> {{ val.label }} </b>
                                      <b v-else> {{ val }} </b>
                                      <template v-if="val_ind !== query_list[com].fields[k].val_list.length - 1">
                                        ;
                                      </template>
                                    </i>
                                  </span>
                                </template>
                              </b-card>
                            </template>
                          </template>
                        </b-card>
                      </td>
                    </template>
                    <td>
                      <div @drop="f_onDropCompileStrDate($event, 'compile_str_date_right')" @dragover.prevent @dragenter.prevent style="min-height: 100px; border-radius:  5px 50px 50px 5px; background-color: #b6f7f6; width: 50px; margin-left: 30px;">
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showQueryListForDateAlgorithm = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <!-- TEXT ALGORITHM MODAL & TEXT ALGORITHM MODAL & TEXT ALGORITHM MODAL & TEXT ALGORITHM MODAL & TEXT ALGORITHM MODAL -->
    <modal v-if="d_showQueryListForTextAlgorithm" @close="d_showQueryListForTextAlgorithm = false" :width="'1500'">
      <h3 slot="header">{{ $t('wdm16.13066') }}</h3>
      <div slot="body">
        <b-row style="margin: 3px;" @drop="f_onDropDeleteCompileIndexText($event, 'operation_list')" @dragover.prevent @dragenter.prevent>
          <b-col cols="12" style="text-align: center;">
            <b-button size="md" @click="f_addTextToAlgoritm()" :title="$t('wdm16.13071')" style="margin: 3px;">ifade</b-button>
            <b-button size="md" @click="f_backInCompileStrText()" style="margin: 3px; width: 50px;">{{ $t('wdm16.13072') }}</b-button>
            <b-button size="md" @click="f_clearCompileStrText()" :title="$t('wdm16.13061')" style="margin: 3px; width: 50px;"><i class="fa fa-trash"></i></b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="3">
            <div @drop="f_onDropDeleteCompileIndexText($event, 'query_list')" @dragover.prevent @dragenter.prevent>
              <template v-for="(query, query_ind) in query_list">
                <template v-for="(field, field_ind) in query.fields">
                  <b-card no-body style="margin: 5px; background: #eaf6f6;" draggable @dragstart="f_startDragText($event, query_ind.toString() + '_' + field_ind.toString(), 'query_field')">
                    <b-card-header :header-bg-variant="query.significant === 'true' ? 'secondary' : 'danger'" header-text-variant="dark" class="p-1">
                      <b-row>
                        <b-col sm="12" md="9">
                          <b>{{ query_ind + 1 }}- </b> {{ query.type.label }}
                        </b-col>
                        <b-col sm="12" md="3"></b-col>
                      </b-row>
                    </b-card-header>
                    <b-card no-body :style="field.significant === 'true' ? 'margin: 3px;' : 'margin: 3px; background: #f86c6b;'">
                      <template v-for="(loc, loc_ind) in field.loc">
                        <template> {{ loc.label }}. </template>
                        <template v-if="loc.type === 'list_parameter'">
                          <template v-if="loc.i_type === 'any'">{{ $t('wdm16.13074') }}</template>
                          <template v-else-if="loc.i_type === 'this_group'">[{{ 'G-' + loc.i_group }}].</template>
                        </template>
                        <template v-if="loc.value === 'anatomy'">
                          <template v-if="field.anatomy_use === 'this'">
                            <template v-if="field.this_anatomy && field.this_anatomy.label">
                              {{ field.this_anatomy.label }}.
                            </template>
                            <template v-else style="color: red;">
                              {{ $t('wdm16.11247') }}
                            </template>
                          </template>
                          <template v-else-if="field.anatomy_use === 'parent'">
                            <template v-if="field.this_parent && field.this_parent.label">
                              {{ field.this_parent.label }}.
                            </template>
                            <template v-else style="color: red;">
                              {{ $t('wdm16.11248') }}
                            </template>
                          </template>
                          <template v-else-if="field.anatomy_use === 'any'">
                            <template> {{ $t('wdm16.13075') }} </template>
                          </template>
                        </template>
                      </template>
                    </b-card>
                  </b-card>
                </template>
              </template>
            </div>
          </b-col>
          <b-col sm="12" md="9">
            <div id="rule-algorithm" style="overflow-y: auto; overflow-x: auto; height: 500px;">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div @drop="f_onDropCompileStrText($event, 'compile_str_text_left')" @dragover.prevent @dragenter.prevent style="min-height: 100px; border-radius:  50px 5px 5px 50px; background-color: #b6f7f6; width: 50px; margin-left: 30px;">
                      </div>
                    </td>
                    <td v-if="compile_str_text.length === 0">
                      Algoritmanız boş lütfen ekleyiniz
                    </td>
                    <template v-for="(com, com_ind) in compile_str_text">
                      <template v-if="com.toString().indexOf('text[') !== -1">
                        <td class="query-compilestr-parenthesis" draggable @dragstart='f_startDragText($event, com_ind, "own_list")' @drop='f_onDropCompileStrText($event, com_ind)' @dragover.prevent @dragenter.prevent> {{ com }} </td>
                      </template>
                      <template v-else>
                        <td draggable @dragstart='f_startDragText($event, com_ind, "own_list")' @drop='f_onDropCompileStrText($event, com_ind)' @dragover.prevent @dragenter.prevent>
                          <b-card no-body style="height: 100%; width: 200px; margin: 3px; background: #eaf6f6;">
                            <b-card-header :header-bg-variant="query_list[com.split('_')[0]].significant === 'true' ? 'secondary' : 'danger'" header-text-variant="dark" class="p-1">
                              <b-row>
                                <b-col sm="12" md="10">
                                  <b>{{ parseInt(com.split('_')[0]) + 1 }}- </b> {{ query_list[com.split('_')[0]].type.label }}
                                </b-col>
                                <b-col sm="12" md="2">
                                  <b-button class="pull-right" size="sm" variant="secondary" style="margin-right: 5px; padding: 0px;" @click="f_showQuery(com.split('_')[0])">
                                    <img src="@/icon/629153.png" :title="$t('wdm16.3897')" style="width: 1.4em;" />
                                  </b-button>
                                </b-col>
                              </b-row>
                            </b-card-header>
                            <b-card no-body :style="query_list[com.split('_')[0]].fields[com.split('_')[1]].significant === 'true' ? 'margin: 3px;' : 'margin: 3px; background: #f86c6b;'">
                              <template v-for="(loc, loc_ind) in query_list[com.split('_')[0]].fields[com.split('_')[1]].loc">
                                <template> {{ loc.label }}. </template>
                                <template v-if="loc.type === 'list_parameter'">
                                  <template v-if="loc.i_type === 'any'">{{ $t('wdm16.13074') }}</template>
                                  <template v-else-if="loc.i_type === 'this_group'">[{{ 'G-' + loc.i_group }}].</template>
                                </template>
                                <template v-if="loc.value === 'anatomy'">
                                  <template v-if="query_list[com.split('_')[0]].fields[com.split('_')[1]].anatomy_use === 'this'">
                                    <template v-if="query_list[com.split('_')[0]].fields[com.split('_')[1]].this_anatomy && query_list[com.split('_')[0]].fields[com.split('_')[1]].this_anatomy.label">
                                      {{ query_list[com.split('_')[0]].fields[com.split('_')[1]].this_anatomy.label }}.
                                    </template>
                                    <template v-else style="color: red;">
                                      {{ $t('wdm16.11247') }}
                                    </template>
                                  </template>
                                  <template v-else-if="query_list[com.split('_')[0]].fields[com.split('_')[1]].anatomy_use === 'parent'">
                                    <template v-if="query_list[com.split('_')[0]].fields[com.split('_')[1]].this_parent && query_list[com.split('_')[0]].fields[com.split('_')[1]].this_parent.label">
                                      {{ query_list[com.split('_')[0]].fields[com.split('_')[1]].this_parent.label }}.
                                    </template>
                                    <template v-else style="color: red;">
                                      {{ $t('wdm16.11248') }}
                                    </template>
                                  </template>
                                  <template v-else-if="query_list[com.split('_')[0]].fields[com.split('_')[1]].anatomy_use === 'any'">
                                    <template> {{ $t('wdm16.13075') }} </template>
                                  </template>
                                </template>
                              </template>
                            </b-card>
                          </b-card>
                        </td>
                      </template>
                    </template>
                    <td>
                      <div @drop="f_onDropCompileStrText($event, 'compile_str_text_right')" @dragover.prevent @dragenter.prevent style="min-height: 100px; border-radius:  5px 50px 50px 5px; background-color: #b6f7f6; width: 50px; margin-left: 30px;">
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showQueryListForTextAlgorithm = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showQueryModal" @close="d_showQueryModal = false" :width="'1500'">
      <h3 slot="header">{{ query_list[d_selectedQueryIndex].type.label }}</h3>
      <div slot="body">
        <b-row v-if="query_list[d_selectedQueryIndex]">
          <b-col sm="12">
            <div class="row" v-if="mode === 'edit'" :style="query_list[d_selectedQueryIndex].significant === 'true' ? 'padding: 3px; margin: 0px;' : 'background-color: #f86c6b; padding: 3px; margin: 0px;'">
              <div class="col-sm-2">
                <strong> sorgu {{ d_selectedQueryIndex + 1 }} </strong>
                <!--           <b-dropdown id="rule_queries" v-if="mode === 'edit'" variant="success" size="sm">
            <template slot="button-content">
              sorgu {{ d_selectedQueryIndex + 1 }}
            </template>
            <b-dropdown-item v-if="compile_str.length === 0" @click="f_deleteThisQuery(d_selectedQueryIndex)"> {{ $t('wdm16.4398') }} </b-dropdown-item>
            <b-dropdown-item @click="add_to_model(compile_str, d_selectedQueryIndex)"> {{ $t('wdm16.13063') }} </b-dropdown-item>
            <b-dropdown-item v-if="query_list[d_selectedQueryIndex].date === '1'" @click="AddThisQueryToOrder(d_selectedQueryIndex)">{{ $t('wdm16.13080') }}</b-dropdown-item>
            <b-dropdown-item @click="add_key_compile_str('field', 'and', d_selectedQueryIndex)">{{ $t('wdm16.13081') }}</b-dropdown-item>
          </b-dropdown> -->
              </div>
              <div class="col-sm-10" v-if="!p_isPolestarColumn">
                <!--           <b-row>
            <b-col cols="12">
              <b-form-select v-model="query_list[d_selectedQueryIndex].data_type">
                <option value="requesting_data">{{ $t('wdm16.12812') }}</option>
                <option value="old_recorded_data">{{ $t('wdm16.12813') }}</option>
              </b-form-select>
            </b-col>
          </b-row>
 -->
                <b-row>
                  <b-col cols="12">
                    <b-form-select v-model="query_list[d_selectedQueryIndex].time_of_value">
                      <option value="last_value">{{ $t('wdm16.12819') }}</option>
                      <option value="last_days">{{ $t('wdm16.12820') }}</option>
                      <option value="any_value">{{ $t('wdm16.12821') }}</option>
                    </b-form-select>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-select v-model="query_list[d_selectedQueryIndex].significant">
                      <option value="true">{{ $t('wdm16.13087') }}</option>
                      <option value="false">{{ $t('wdm16.13088') }}</option>
                    </b-form-select>
                  </b-col>
                </b-row>
                <b-row v-if="query_list[d_selectedQueryIndex].time_of_value === 'last_days'">
                  <b-col cols="12">
                    <input type="number" name="last_xxx_days" :placeholder="$t('wdm16.13089')" class="form-control" :value="query_list[d_selectedQueryIndex].days">
                  </b-col>
                </b-row>
              </div>
            </div>
            <div class="row" v-if="mode === 'view'" :style="query_list[d_selectedQueryIndex].significant === 'true' ? 'background-color: #20a8d8; padding: 3px; margin: 0px;' : 'background-color: #f86c6b; padding: 3px; margin: 0px;'">
              <div class="col-sm-12">
                <strong style="color: blue;"> {{ d_selectedQueryIndex + 1 }}. {{ $t('wdm16.13090') }} </strong>
                <b-badge variant="warning">, {{ query_list[d_selectedQueryIndex].type.label }} </b-badge>
                <!-- <span v-if="query_list[d_selectedQueryIndex].data_type === 'requesting_data'">, {{ $t('wdm16.12812') }} </span> -->
                <!-- <span v-if="query_list[d_selectedQueryIndex].data_type === 'old_recorded_data'">, {{ $t('wdm16.12813') }} </span> -->
                <span v-if="query_list[d_selectedQueryIndex].time_of_value === 'any_value'">, {{ $t('wdm16.12821') }} </span>
                <span v-if="query_list[d_selectedQueryIndex].time_of_value === 'last_days'">, {{ $t('wdm16.12819') }} </span>
                <span v-if="query_list[d_selectedQueryIndex].time_of_value === 'last_value'">, {{ $t('wdm16.7168') }} {{ query_list[d_selectedQueryIndex].days }} {{ $t('wdm16.13095') }}</span>
                <span v-if="query_list[d_selectedQueryIndex].significant === 'true'">, {{ $t('wdm16.13096') }}</span>
                <span v-if="query_list[d_selectedQueryIndex].significant === 'false'">, {{ $t('wdm16.13097') }}</span>
              </div>
            </div>
            <b-row>
              <b-col sm="12" md="4">
                <b-card no-body v-if="mode === 'edit'">
                  <!--               <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
                <i class="fa fa-building-o"></i> {{ $t('wdm16.13098') }}
              </b-card-header> -->
                  <div @drop="f_onDropDeleteField($event, 'field')" @dragover.prevent @dragenter.prevent>
                    <template v-for="(f, f_ind) in query_list[d_selectedQueryIndex].fields">
                      <b-card no-body draggable @dragstart="f_startDragField($event, f_ind, 'field')" :style="f.significant === 'true' ? 'background-color: white;' : 'background-color: #f86c6b;'">
                        <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
                          <i class="fa fa-building-o"></i> param {{ f_ind + 1 }}
                          <b-dropdown id="field_queries" variant="primary" size="sm" class="pull-right" v-if="mode === 'edit'">
                            <template slot="button-content"> </template>
                            <b-dropdown-item @click="add_to_model(query_list[d_selectedQueryIndex].compile_str_array, f_ind)"> {{ $t('wdm16.13063') }} </b-dropdown-item>
                            <b-dropdown-item v-if="query_list[d_selectedQueryIndex].compile_str_array.length === 0" @click="delete_field_in_query(f_ind, query_list[d_selectedQueryIndex])"> {{ $t('wdm16.4398') }} </b-dropdown-item>
                          </b-dropdown>
                        </b-card-header>
                        <b-row>
                          <b-col sm="12">
                            <template v-for="(loc, loc_ind) in f.loc">
                              <span> {{ loc.label }}. </span>
                              <template v-if="loc.type === 'list_parameter'">
                                <select v-model="loc.i_type" @change="$forceUpdate()">
                                  <option value="any">{{ $t('wdm16.13074') }}</option>
                                  <option value="this_group">{{ $t('wdm16.13101') }}</option>
                                </select>
                                .
                                <template v-if="loc.i_type === 'this_group'">
                                  <select v-model="loc.i_group">
                                    <option v-for="(g, g_ind) in query_list[d_selectedQueryIndex].list_parameter_groups[loc.value]" :value="g"> G-{{ g }}</option>
                                  </select>
                                  .
                                </template>
                              </template>
                              <template v-if="loc.value === 'anatomy'">
                                <span v-if="f.anatomy_use === 'this'">
                                  <span v-if="f.this_anatomy && f.this_anatomy.label">
                                    {{ f.this_anatomy.label }}.
                                  </span>
                                <span v-else style="color: red;">
                                    {{ $t('wdm16.11247') }}
                                  </span>
                                </span>
                                <span v-else-if="f.anatomy_use === 'parent'">
                                  <span v-if="f.this_parent && f.this_parent.label">
                                    {{ f.this_parent.label }}.
                                  </span>
                                <span v-else style="color: red;">
                                    {{ $t('wdm16.11248') }}
                                  </span>
                                </span>
                                <span v-else-if="f.anatomy_use === 'any'">
                                  <span> {{ $t('wdm16.13075') }} </span>
                                </span>
                              </template>
                            </template>
                            <strong v-if="f.val_list && f.val_list.length > 0">{{ f.operation }}</strong>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12">
                            <ul>
                              <li v-for="(val, val_ind) in f.val_list">
                                <template v-if="['select', 'object_list_options', 'checkbox', 'radiogroup'].indexOf(f.value_type) !== -1">
                                  <i style="color: #0091d7;"> <b> {{ val.label }} </b></i>
                                  <br>
                                </template>
                                <template v-else>
                                  <i style="color: #0091d7;"> <b>{{ val }}</b> </i>
                                  <br>
                                </template>
                              </li>
                            </ul>
                          </b-col>
                        </b-row>
                        <template v-if="f.is_column">
                          <b-row>
                            <b-col cols="12">
                              <strong style="background-color: #ffd2d2;">{{ $t('wdm16.13103') }}</strong>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12">
                              <b-form-select v-model="f.column_val_analyze_type">
                                <option value="sum">{{ $t('wdm16.13104') }}</option>
                                <option value="count">{{ $t('wdm16.13105') }}</option>
                                <option v-if="f.val_list && f.val_list.length > 0" value="yesno">{{ $t('wdm16.13106') }}</option>
                              </b-form-select>
                            </b-col>
                          </b-row>
                        </template>
                        <template v-else>
                          <b-row>
                            <b-col cols="12">
                              <b-form-select v-model="f.significant">
                                <option value="true">{{ $t('wdm16.13087') }}</option>
                                <option value="false">{{ $t('wdm16.13108') }}</option>
                                <option value="false_reached">{{ $t('wdm16.13109') }}</option>
                              </b-form-select>
                            </b-col>
                          </b-row>
                        </template>
                        <b-row v-if="f_doesFieldIncludeAnatomy(f)">
                          <b-col cols="12">
                            <b-form-select v-model="f.anatomy_use">
                              <option value="any">{{ $t('wdm16.13075') }}</option>
                              <option value="this">{{ $t('wdm16.13111') }}</option>
                              <option value="parent">{{ $t('wdm16.13112') }}</option>
                            </b-form-select>
                          </b-col>
                        </b-row>
                        <b-row v-if="f.anatomy_use && f.anatomy_use !== 'any'" style="margin: 0px;">
                          <b-col cols="12" :style="d_showAnatomy.indexOf(f_ind) !== -1 ? 'background-color: #ffc107; cursor: pointer;' : 'cursor: pointer;'">
                            <span @click="d_showAnatomy.indexOf(f_ind) === -1 ? d_showAnatomy.push(f_ind) : d_showAnatomy.splice(d_showAnatomy.indexOf(f_ind), 1)">{{ $t('wdm16.13113') }}</span>
                          </b-col>
                        </b-row>
                        <b-row v-if="f_doesFieldIncludeAnatomy(f) && d_showAnatomy.indexOf(f_ind) !== -1 && f.anatomy_use !== 'any'">
                          <b-col cols="12">
                            <anatomy :key="'anatomy_' + f_ind" :selected_anatomy_region="selected_anatomy_region" :selected_anatomy_parent="selected_anatomy_parent" :mother_children="mother_children"></anatomy>
                          </b-col>
                        </b-row>
                        <b-row v-if="f_doesFieldIncludeAnatomy(f) && d_showAnatomy.indexOf(f_ind) !== -1 && f.anatomy_use !== 'any'">
                          <b-col cols="12">
                            <b-button @click="f_addAnatomyInfoToField(f)">{{ $t('wdm16.13114') }}</b-button>
                          </b-col>
                        </b-row>
                      </b-card>
                    </template>
                  </div>
                </b-card>
                <b-card no-body v-if="mode === 'view'">
                  <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
                    <i class="fa fa-building-o"></i> {{ $t('wdm16.13098') }}
                  </b-card-header>
                  <template v-for="(f, f_ind) in query_list[d_selectedQueryIndex].fields">
                    <b-card no-body v-if="mode === 'view'">
                      <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                        <i class="fa fa-building-o"></i> param {{ f_ind + 1 }}
                      </b-card-header>
                      <b-row>
                        <b-col cols="12">
                          <template v-if="f.column_val_analyze_type">
                            {{ f.column_val_analyze_type }}
                          </template>
                        </b-col>
                      </b-row>
                      <div class="row" style="margin: 0px;">
                        <div class="col-sm-12" style="padding-left: 0px;">
                          <span class="query-param"></span>
                          <strong> {{ f_ind + 1 }}. param = </strong>
                          <span v-if="f.significant === 'true'">{{ $t('wdm16.13087') }}</span>
                          <span v-if="f.significant === 'false'">{{ $t('wdm16.13108') }}</span>
                          <span v-if="f.significant === 'false_reached'">{{ $t('wdm16.13109') }}</span>
                        </div>
                      </div>
                      <div class="row" style="margin: 0px;">
                        <div class="col-sm-1"> </div>
                        <div class="col-sm-10">
                          <template v-for="(loc, loc_ind) in f.loc">
                            <span> {{ loc.label }}. </span>
                            <template v-if="loc.type === 'list_parameter'">
                              <span v-if="loc.i_type === 'any'">{{ $t('wdm16.13074') }}</span>
                              <span v-else-if="loc.i_type === 'this_group'">[{{ 'G-' + loc.i_group }}].</span>
                            </template>
                            <template v-if="loc.value === 'anatomy'">
                              <span v-if="f.anatomy_use === 'this'">
                                <span v-if="f.this_anatomy && f.this_anatomy.label">
                                  {{ f.this_anatomy.label }}.
                                </span>
                              <span v-else style="color: red;">
                                  {{ $t('wdm16.11247') }}
                                </span>
                              </span>
                              <span v-else-if="f.anatomy_use === 'parent'">
                                <span v-if="f.this_parent && f.this_parent.label">
                                  {{ f.this_parent.label }}.
                                </span>
                              <span v-else style="color: red;">
                                  {{ $t('wdm16.11248') }}
                                </span>
                              </span>
                              <span v-else-if="f.anatomy_use === 'any'">
                                <span> {{ $t('wdm16.13075') }} </span>
                              </span>
                            </template>
                          </template>
                          <strong v-if="f.val_list && f.val_list.length > 0">{{ f.operation }}</strong>
                          <div class="row">
                            <div class="col-sm-12">
                              <ul>
                                <li v-for="(val, val_ind) in f.val_list">
                                  <template v-if="['select', 'object_list_options', 'checkbox', 'radiogroup'].indexOf(f.value_type) !== -1">
                                    <i style="color: #0091d7;"> <b> {{ val.label }} </b></i>
                                    <br>
                                  </template>
                                  <template v-else>
                                    <i style="color: #0091d7;"> <b>{{ val }}</b> </i>
                                    <br>
                                  </template>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card>
                  </template>
                </b-card>
              </b-col>
              <b-col sm="12" md="8">
                <b-row v-if="mode === 'edit'" style="margin: 3px;" @drop="f_onDropDeleteField($event, 'operation_list')" @dragover.prevent @dragenter.prevent>
                  <b-col cols="12" style="text-align: center;">
                    <b-button size="md" draggable @dragstart="f_startDragField($event, '(', 'operation')" @click="f_addField('(')" style="margin: 3px; width: 50px;"> ( </b-button>
                    <b-button size="md" draggable @dragstart="f_startDragField($event, ')', 'operation')" @click="f_addField(')')" style="margin: 3px; width: 50px;"> ) </b-button>
                    <b-button size="md" draggable @dragstart="f_startDragField($event, 'and', 'operation')" @click="f_addField('and')" style="margin: 3px; width: 50px;"> {{ $t('wdm16.3969') }} </b-button>
                    <b-button size="md" draggable @dragstart="f_startDragField($event, 'or', 'operation')" @click="f_addField('or')" style="margin: 3px; width: 50px;"> {{ $t('wdm16.4254') }} </b-button>
                    <b-button size="md" @click="f_backInCompileStrField()" style="margin: 3px; width: 50px;">geri</b-button>
                    <b-button size="md" @click="f_clearField()" style="margin: 3px; width: 50px;"><i class="fa fa-trash"></i></b-button>
                  </b-col>
                </b-row>
                <div v-if="mode === 'edit'" id="rule-algorithm" style="overflow-y: auto; overflow-x: auto; height: 300px;">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div @drop="f_onDropField($event, 'field_left')" @dragover.prevent @dragenter.prevent style="min-height: 100px; border-radius:  50px 5px 5px 50px; background-color: #b6f7f6; width: 50px; margin-left: 30px;">
                          </div>
                        </td>
                        <td v-if="query_list[d_selectedQueryIndex].length === 0">
                          Algoritmanız boş lütfen ekleyiniz
                        </td>
                        <template v-for="(com, com_ind) in query_list[d_selectedQueryIndex].compile_str_array">
                          <td v-if="com === ')' || com === '('" class="query-compilestr-parenthesis" draggable @dragstart='f_startDragField($event, com_ind, "own_list")' @drop='f_onDropField($event, com_ind)' @dragover.prevent @dragenter.prevent> {{ com }} </td>
                          <td v-else-if="com === 'and'" class="query-compilestr-operation-and" draggable @dragstart='f_startDragField($event, com_ind, "own_list")' @drop='f_onDropField($event, com_ind)' @dragover.prevent @dragenter.prevent> {{ com }} </td>
                          <td v-else-if="com === 'or'" class="query-compilestr-operation-or" draggable @dragstart='f_startDragField($event, com_ind, "own_list")' @drop='f_onDropField($event, com_ind)' @dragover.prevent @dragenter.prevent> {{ com }} </td>
                          <td v-else draggable @dragstart='f_startDragField($event, com_ind, "own_list")' @drop='f_onDropField($event, com_ind)' @dragover.prevent @dragenter.prevent>
                            <b-card no-body :style="query_list[d_selectedQueryIndex].fields[com].significant === 'true' ? 'background-color: white; width: 200px;' : 'background-color: #f86c6b; width: 200px;'">
                              <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
                                <i class="fa fa-building-o"></i> param {{ parseInt(com) + 1 }}
                              </b-card-header>
                              <b-row v-if="query_list[d_selectedQueryIndex].fields[com].column_val_analyze_type">
                                <b-col cols="12">
                                  {{ query_list[d_selectedQueryIndex].fields[com].column_val_analyze_type }}
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col cols="12">
                                  <template v-for="(loc, loc_ind) in query_list[d_selectedQueryIndex].fields[com].loc">
                                    <span> {{ loc.label }}. </span>
                                    <template v-if="loc.type === 'list_parameter'">
                                      <span v-if="loc.i_type === 'any'">{{ $t('wdm16.13074') }}</span>
                                      <span v-else-if="loc.i_type === 'this_group'">[{{ 'G-' + loc.i_group }}].</span>
                                    </template>
                                    <template v-if="loc.value === 'anatomy'">
                                      <template v-if="query_list[d_selectedQueryIndex].fields[com].anatomy_use === 'this'">
                                        <span v-if="query_list[d_selectedQueryIndex].fields[com].this_anatomy && query_list[d_selectedQueryIndex].fields[com].this_anatomy.label">
                                          {{ query_list[d_selectedQueryIndex].fields[com].this_anatomy.label }}.
                                        </span>
                                        <span v-else style="color: red;"> {{ $t('wdm16.11247') }} </span>
                                      </template>
                                      <template v-else-if="query_list[d_selectedQueryIndex].fields[com].anatomy_use === 'parent'">
                                        <span v-if="query_list[d_selectedQueryIndex].fields[com].this_parent && query_list[d_selectedQueryIndex].fields[com].this_parent.label"> {{ query_list[d_selectedQueryIndex].fields[com].this_parent.label }}. </span>
                                        <span v-else style="color: red;"> {{ $t('wdm16.11248') }} </span>
                                      </template>
                                      <template v-else-if="query_list[d_selectedQueryIndex].fields[com].anatomy_use === 'any'">
                                        <span> {{ $t('wdm16.13075') }} </span>
                                      </template>
                                    </template>
                                  </template>
                                  <strong v-if="query_list[d_selectedQueryIndex].fields[com].val_list && query_list[d_selectedQueryIndex].fields[com].val_list.length > 0">{{ query_list[d_selectedQueryIndex].fields[com].operation }}</strong>
                                  <div class="row">
                                    <div class="col-sm-12">
                                      <ul>
                                        <li v-for="(val, val_ind) in query_list[d_selectedQueryIndex].fields[com].val_list">
                                          <template v-if="['select', 'object_list_options', 'checkbox', 'radiogroup'].indexOf(query_list[d_selectedQueryIndex].fields[com].value_type) !== -1">
                                            <i style="color: #0091d7;"> <b> {{ val.label }} </b></i>
                                            <br>
                                          </template>
                                          <template v-else>
                                            <i style="color: #0091d7;"> <b>{{ val }}</b> </i>
                                            <br>
                                          </template>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </b-col>
                              </b-row>
                              <div class="row" style="margin: 0px;">
                                <div class="col-sm-12" style="padding-left: 0px;">
                                  <!-- <strong> {{ com + 1 }}. {{ $t('wdm16.13125') }} = </strong> -->
                                  *
                                  <small v-if="query_list[d_selectedQueryIndex].fields[com].significant === 'true'">{{ $t('wdm16.13087') }}</small>
                                  <small v-if="query_list[d_selectedQueryIndex].fields[com].significant === 'false'">{{ $t('wdm16.13108') }}</small>
                                  <small v-if="query_list[d_selectedQueryIndex].fields[com].significant === 'false_reached'">{{ $t('wdm16.13109') }}</small>
                                </div>
                              </div>
                            </b-card>
                          </td>
                        </template>
                        <td>
                          <div @drop="f_onDropField($event, 'field_right')" @dragover.prevent @dragenter.prevent style="min-height: 100px; border-radius:  5px 50px 50px 5px; background-color: #b6f7f6; width: 50px; margin-left: 30px;">
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-if="mode === 'view'" id="rule-algorithm" style="overflow-y: auto; overflow-x: auto; height: 300px;">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div style="min-height: 100px; border-radius:  50px 5px 5px 50px; background-color: #b6f7f6; width: 50px; margin-left: 30px;">
                          </div>
                        </td>
                        <template v-for="(com, com_ind) in query_list[d_selectedQueryIndex].compile_str_array">
                          <td v-if="com === ')' || com === '('" class="query-compilestr-parenthesis"> {{ com }} </td>
                          <td v-else-if="com === 'and'" class="query-compilestr-operation-and"> {{ com }} </td>
                          <td v-else-if="com === 'or'" class="query-compilestr-operation-or"> {{ com }} </td>
                          <td v-else>
                            <b-card no-body :style="query_list[d_selectedQueryIndex].fields[com].significant === 'true' ? 'background-color: white; width: 200px;' : 'background-color: #f86c6b; width: 200px;'">
                              <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
                                <i class="fa fa-building-o"></i> param {{ parseInt(com) + 1 }}
                              </b-card-header>
                              <b-row v-if="query_list[d_selectedQueryIndex].fields[com].column_val_analyze_type">
                                <b-col cols="12">
                                  {{ query_list[d_selectedQueryIndex].fields[com].column_val_analyze_type }}
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col cols="12">
                                  <template v-for="(loc, loc_ind) in query_list[d_selectedQueryIndex].fields[com].loc">
                                    <span> {{ loc.label }}. </span>
                                    <template v-if="loc.type === 'list_parameter'">
                                      <span v-if="loc.i_type === 'any'">{{ $t('wdm16.13074') }}</span>
                                      <span v-else-if="loc.i_type === 'this_group'">[{{ 'G-' + loc.i_group }}].</span>
                                    </template>
                                    <template v-if="loc.value === 'anatomy'">
                                      <template v-if="query_list[d_selectedQueryIndex].fields[com].anatomy_use === 'this'">
                                        <span v-if="query_list[d_selectedQueryIndex].fields[com].this_anatomy && query_list[d_selectedQueryIndex].fields[com].this_anatomy.label">
                                          {{ query_list[d_selectedQueryIndex].fields[com].this_anatomy.label }}.
                                        </span>
                                        <span v-else style="color: red;"> {{ $t('wdm16.11247') }} </span>
                                      </template>
                                      <template v-else-if="query_list[d_selectedQueryIndex].fields[com].anatomy_use === 'parent'">
                                        <span v-if="query_list[d_selectedQueryIndex].fields[com].this_parent && query_list[d_selectedQueryIndex].fields[com].this_parent.label"> {{ query_list[d_selectedQueryIndex].fields[com].this_parent.label }}. </span>
                                        <span v-else style="color: red;"> {{ $t('wdm16.11248') }} </span>
                                      </template>
                                      <template v-else-if="query_list[d_selectedQueryIndex].fields[com].anatomy_use === 'any'">
                                        <span> {{ $t('wdm16.13075') }} </span>
                                      </template>
                                    </template>
                                  </template>
                                  <strong v-if="query_list[d_selectedQueryIndex].fields[com].val_list && query_list[d_selectedQueryIndex].fields[com].val_list.length > 0">{{ query_list[d_selectedQueryIndex].fields[com].operation }}</strong>
                                  <div class="row">
                                    <div class="col-sm-12">
                                      <ul>
                                        <li v-for="(val, val_ind) in query_list[d_selectedQueryIndex].fields[com].val_list">
                                          <template v-if="['select', 'object_list_options', 'checkbox', 'radiogroup'].indexOf(query_list[d_selectedQueryIndex].fields[com].value_type) !== -1">
                                            <i style="color: #0091d7;"> <b> {{ val.label }} </b></i>
                                            <br>
                                          </template>
                                          <template v-else>
                                            <i style="color: #0091d7;"> <b>{{ val }}</b> </i>
                                            <br>
                                          </template>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </b-col>
                              </b-row>
                              <div class="row" style="margin: 0px;">
                                <div class="col-sm-12" style="padding-left: 0px;">
                                  <!-- <strong> {{ com + 1 }}. {{ $t('wdm16.13125') }} = </strong> -->
                                  *
                                  <small v-if="query_list[d_selectedQueryIndex].fields[com].significant === 'true'">{{ $t('wdm16.13087') }}</small>
                                  <small v-if="query_list[d_selectedQueryIndex].fields[com].significant === 'false'">{{ $t('wdm16.13108') }}</small>
                                  <small v-if="query_list[d_selectedQueryIndex].fields[com].significant === 'false_reached'">{{ $t('wdm16.13109') }}</small>
                                </div>
                              </div>
                            </b-card>
                          </td>
                        </template>
                        <td>
                          <div style="min-height: 100px; border-radius:  5px 50px 50px 5px; background-color: #b6f7f6; width: 50px; margin-left: 30px;">
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showQueryModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <b-modal v-if="d_dateAlgorightm.show" title="Tarih düzenleme ekranı" centered class="modal-success" v-model="d_dateAlgorightm.show" @ok="d_dateAlgorightm.show = false" ok-variant="success" hide-footer>
      <b-row style="margin: 2px;">
        <b-col sm="12" lg="12">
          <datepicker class="form-control" v-model="d_dateAlgorightm.date"></datepicker>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" lg="12">
          <b-button v-if="d_dateAlgorightm.date" class="pull-right" variant="success" size="md" @click="f_addManuelDateToCompileStr()">{{ $t('wdm16.13134') }}</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_dayDifference.show" title="Tarihe gün farkı ekleme alanı" centered class="modal-success" v-model="d_dayDifference.show" @ok="d_dayDifference.show = false" ok-variant="success" hide-footer>
      <b-row style="margin: 2px;">
        <b-col sm="12" lg="6">
          Gün farkı giriniz
        </b-col>
        <b-col sm="12" lg="6">
          <b-form-input type="number" v-model="d_dayDifference.day"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" lg="12">
          <b-button v-if="d_dayDifference.day" class="pull-right" variant="success" size="md" @click="f_addDayDifferenceToCompileStr()">{{ $t('wdm16.13135') }}</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_algorithmText.show" :title="$t('wdm16.13137')" centered class="modal-success" v-model="d_algorithmText.show" @ok="d_algorithmText.show = false" ok-variant="success" hide-footer>
      <b-row style="margin: 2px;">
        <b-col sm="12" lg="6">
          İfadenizi giriniz
        </b-col>
        <b-col sm="12" lg="6">
          <b-textarea v-model="d_algorithmText.text"></b-textarea>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" lg="12">
          <b-button v-if="d_algorithmText.text" class="pull-right" variant="success" size="md" @click="f_addTextToAlgoritmOperation()">{{ $t('wdm16.13139') }}</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import Datepicker from "wisdom-vue-datepicker";
import { ClsRule } from '@/services/public/rule';
import {
  default as Anatomy
} from '@/components/widgets/Anatomy';
import { mapGetters } from 'vuex';
import { RuleEngine } from '@/services/public/functions';
import {
  default as RuleCompileStr
} from './RuleCompileStr';
import {
  default as Modal
} from './Modal';

export default {
  name: 'RuleQuery',
  mounted () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  computed: {
    ...mapGetters({
      lang: 'lang'
    })
  },
  components: {
    RuleCompileStr,
    Anatomy,
    Datepicker,
    Modal: () =>
      import ('@/components/widgets/Modal'),
  },
  props: {
    mode: {
      type: String,
      default: 'edit'
    },
    query_list: {
      type: Array,
      required: true
    },
    compile_str: {
      type: Array,
      required: false
    },
    compile_str_date: {
      type: Array,
      required: false
    },
    compile_str_text: {
      type: Array,
      required: false
    },
    p_isPolestarColumn: {
      type: Boolean,
      required: false
    },
    algoritm_height: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      d_showQueryListForTextAlgorithm: false,
      d_showQueryListForDateAlgorithm: false,
      d_errorCompileStr: '',
      d_algorithmText: { 'show': false, 'text': '' },
      d_dayDifference: { 'show': false, 'day': 0 },
      d_dateAlgorightm: { 'show': false, 'date': '' },
      d_showQueryList: false,
      d_fullScreen: false,
      d_showQueryModal: false,
      d_selectedQueryIndex: '',
      d_showMode: 'left_to_right',
      mother_children: { 'forceUpdate': 0 },
      d_showAnatomy: [],
      selected_anatomy_parent: { 'value': '' },
      selected_anatomy_region: { 'value': '' },
      d_startFromDragType: '',
      d_startFromDragTypeDate: '',
      d_startFromDragTypeText: '',
      d_startFromDragTypeField: ''
    };
  },
  created () {},
  methods: {
    f_showLastWdmOfLayerAndThisChars: function (text, count) {
      let str = '';
      try {
        text = text.toString();
        let text_arr = text.split('->');
        text = text_arr[text_arr.length - 1].split(']')[0] + '] ...';
        let x = text.length > count ? count : text.length;
        for (let i = 0; i < x; i++) {
          if (text[i]) {
            str += text[i];
          } else {
            str += '.';
          }
        }
        if (text.length > count) {
          str += '...';
        }
      } catch (err) {}
      return str;
    },
    f_addField: function () {},
    f_onDropDeleteField: function (evt, type) {
      let fromInd = evt.dataTransfer.getData('fromInd');
      this.query_list[this.d_selectedQueryIndex].compile_str_array.splice(fromInd, 1);
    },
    f_backInCompileStrField: function () {
      this.query_list[this.d_selectedQueryIndex].compile_str_array.splice(this.query_list[this.d_selectedQueryIndex].compile_str_array.length - 1, 1);
    },
    f_clearField: function () {
      this.query_list[this.d_selectedQueryIndex].compile_str_array = [];
    },
    f_startDragField: function (evt, ind, type) {
      this.d_startFromDragTypeField = type;
      // console.log('evt ', evt);
      // console.log('com_ind ', com_ind);
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('fromInd', ind);
    },
    f_onDropField: function (evt, toComInd) {
      // console.log(com_ind);
      // console.log('this.d_startFromDragTypeDate ', this.d_startFromDragTypeDate);
      let fromInd = evt.dataTransfer.getData('fromInd');
      // console.log('fromInd', fromInd);
      // console.log('toComInd', toComInd);
      let item = '';
      if (this.d_startFromDragTypeField === 'field') {
        item = fromInd;
        if (toComInd === 'field_right') {
          this.query_list[this.d_selectedQueryIndex].compile_str_array.push(item);
        } else if (toComInd === 'field_left') {
          this.query_list[this.d_selectedQueryIndex].compile_str_array.splice(0, 0, item);
        } else {
          if (toComInd === this.query_list[this.d_selectedQueryIndex].compile_str_array.length - 1) {
            this.query_list[this.d_selectedQueryIndex].compile_str_array.push(item);
          } else {
            this.query_list[this.d_selectedQueryIndex].compile_str_array.splice(toComInd + 1, 0, item);
          }
        }
      } else if (this.d_startFromDragTypeField === 'operation') {
        item = fromInd;
        if (toComInd === 'field_right') {
          this.query_list[this.d_selectedQueryIndex].compile_str_array.push(item);
        } else if (toComInd === 'field_left') {
          this.query_list[this.d_selectedQueryIndex].compile_str_array.splice(0, 0, item);
        } else {
          if (toComInd === this.query_list[this.d_selectedQueryIndex].compile_str_array.length - 1) {
            this.query_list[this.d_selectedQueryIndex].compile_str_array.push(item);
          } else {
            this.query_list[this.d_selectedQueryIndex].compile_str_array.splice(toComInd + 1, 0, item);
          }
        }
      } else if (this.d_startFromDragTypeField === 'own_list') {
        if (toComInd === 'field_right') {
          item = this.query_list[this.d_selectedQueryIndex].compile_str_array[fromInd];
          this.query_list[this.d_selectedQueryIndex].compile_str_array.splice(fromInd, 1);
          this.query_list[this.d_selectedQueryIndex].compile_str_array.push(item);
        } else if (toComInd === 'field_left') {
          item = this.query_list[this.d_selectedQueryIndex].compile_str_array[fromInd];
          this.query_list[this.d_selectedQueryIndex].compile_str_array.splice(fromInd, 1);
          this.query_list[this.d_selectedQueryIndex].compile_str_array.splice(0, 0, item);
        } else {
          let x = 0;
          if (toComInd > fromInd) {
            x = toComInd - 1;
          } else {
            x = toComInd;
          }
          item = this.query_list[this.d_selectedQueryIndex].compile_str_array[fromInd];
          if (toComInd === this.query_list[this.d_selectedQueryIndex].compile_str_array.length - 1) {
            this.query_list[this.d_selectedQueryIndex].compile_str_array.splice(fromInd, 1);
            this.query_list[this.d_selectedQueryIndex].compile_str_array.push(item);
          } else {
            this.query_list[this.d_selectedQueryIndex].compile_str_array.splice(fromInd, 1);
            this.query_list[this.d_selectedQueryIndex].compile_str_array.splice(x + 1, 0, item);
          }
        }
      }
      // this.f_controlCompileStrFirstError();
    },
    f_startDragDate: function (evt, ind, type) {
      this.d_startFromDragTypeDate = type;
      // console.log('evt ', evt);
      // console.log('com_ind ', com_ind);
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('fromInd', ind);
    },
    f_onDropCompileStrDate: function (evt, toComInd) {
      // console.log(com_ind);
      // console.log('this.d_startFromDragTypeDate ', this.d_startFromDragTypeDate);
      let fromInd = evt.dataTransfer.getData('fromInd');
      // console.log('fromInd', fromInd);
      // console.log('toComInd', toComInd);
      let item = '';
      if (this.d_startFromDragTypeDate === 'query') {
        item = fromInd;
        if (toComInd === 'compile_str_date_right') {
          this.compile_str_date.push(item);
        } else if (toComInd === 'compile_str_date_left') {
          this.compile_str_date.splice(0, 0, item);
        } else {
          if (toComInd === this.compile_str_date.length - 1) {
            this.compile_str_date.push(item);
          } else {
            this.compile_str_date.splice(toComInd + 1, 0, item);
          }
        }
      } else if (this.d_startFromDragTypeDate === 'operation') {
        item = fromInd;
        if (toComInd === 'compile_str_date_right') {
          this.compile_str_date.push(item);
        } else if (toComInd === 'compile_str_date_left') {
          this.compile_str_date.splice(0, 0, item);
        } else {
          if (toComInd === this.compile_str_date.length - 1) {
            console.log('last index');
            this.compile_str_date.push(item);
          } else {
            this.compile_str_date.splice(toComInd + 1, 0, item);
          }
        }
      } else if (this.d_startFromDragTypeDate === 'own_list') {
        if (toComInd === 'compile_str_date_right') {
          item = this.compile_str_date[fromInd];
          this.compile_str_date.splice(fromInd, 1);
          this.compile_str_date.push(item);
        } else if (toComInd === 'compile_str_date_left') {
          item = this.compile_str_date[fromInd];
          this.compile_str_date.splice(fromInd, 1);
          this.compile_str_date.splice(0, 0, item);
        } else {
          let x = 0;
          if (toComInd > fromInd) {
            x = toComInd - 1;
          } else {
            x = toComInd;
          }
          item = this.compile_str_date[fromInd];
          if (toComInd === this.compile_str_date.length - 1) {
            this.compile_str_date.splice(fromInd, 1);
            this.compile_str_date.push(item);
          } else {
            this.compile_str_date.splice(fromInd, 1);
            this.compile_str_date.splice(x + 1, 0, item);
          }
        }
      }
      // this.f_controlCompileStrFirstError();
    },
    f_onDropDeleteCompileIndexDate: function (evt, type) {
      let fromInd = evt.dataTransfer.getData('fromInd');
      this.compile_str_date.splice(fromInd, 1);
    },
    f_startDragText: function (evt, ind, type) {
      this.d_startFromDragTypeText = type;
      // console.log('evt ', evt);
      // console.log('com_ind ', com_ind);
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('fromInd', ind);
    },
    f_onDropDeleteCompileIndexText: function (evt, type) {
      let fromInd = evt.dataTransfer.getData('fromInd');
      this.compile_str_text.splice(fromInd, 1);
    },
    f_onDropCompileStrText: function (evt, toComInd) {
      // console.log('this.compile_str_text ', this.compile_str_text);
      // console.log('this.d_startFromDragTypeText ', this.d_startFromDragTypeText);
      let fromInd = evt.dataTransfer.getData('fromInd');
      // console.log('fromInd', fromInd);
      // console.log('toComInd', toComInd);
      let item = '';
      if (this.d_startFromDragTypeText === 'query') {
        item = fromInd;
        if (toComInd === 'compile_str_text_right') {
          this.compile_str_text.push(item);
        } else if (toComInd === 'compile_str_text_left') {
          this.compile_str_text.splice(0, 0, item);
        } else {
          if (toComInd === this.compile_str_text.length - 1) {
            this.compile_str_text.push(item);
          } else {
            this.compile_str_text.splice(toComInd + 1, 0, item);
          }
        }
      } else if (this.d_startFromDragTypeText === 'query_field') {
        item = fromInd;
        if (toComInd === 'compile_str_text_right') {
          this.compile_str_text.push(item);
        } else if (toComInd === 'compile_str_text_left') {
          this.compile_str_text.splice(0, 0, item);
        } else {
          if (toComInd === this.compile_str_text.length - 1) {
            this.compile_str_text.push(item);
          } else {
            this.compile_str_text.splice(toComInd + 1, 0, item);
          }
        }
      } else if (this.d_startFromDragTypeText === 'operation') {
        item = fromInd;
        if (toComInd === 'compile_str_text_right') {
          this.compile_str_text.push(item);
        } else if (toComInd === 'compile_str_text_left') {
          this.compile_str_text.splice(0, 0, item);
        } else {
          if (toComInd === this.compile_str_text.length - 1) {
            this.compile_str_text.push(item);
          } else {
            this.compile_str_text.splice(toComInd + 1, 0, item);
          }
        }
      } else if (this.d_startFromDragTypeText === 'own_list') {
        if (toComInd === 'compile_str_text_right') {
          item = this.compile_str_text[fromInd];
          this.compile_str_text.splice(fromInd, 1);
          this.compile_str_text.push(item);
        } else if (toComInd === 'compile_str_text_left') {
          item = this.compile_str_text[fromInd];
          this.compile_str_text.splice(fromInd, 1);
          this.compile_str_text.splice(0, 0, item);
        } else {
          let x = 0;
          if (toComInd > fromInd) {
            x = toComInd - 1;
          } else {
            x = toComInd;
          }
          item = this.compile_str_text[fromInd];
          if (toComInd === this.compile_str_text.length - 1) {
            this.compile_str_text.splice(fromInd, 1);
            this.compile_str_text.push(item);
          } else {
            this.compile_str_text.splice(fromInd, 1);
            this.compile_str_text.splice(x + 1, 0, item);
          }
        }
      }
      // this.f_controlCompileStrFirstError();
    },
    f_startDrag: function (evt, ind, type) {
      this.d_startFromDragType = type;
      // console.log('evt ', evt);
      // console.log('com_ind ', com_ind);
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('fromInd', ind);
    },
    f_onDropCompileStr: function (evt, toComInd) {
      // console.log(com_ind);
      // console.log('this.d_startFromDragType ', this.d_startFromDragType);
      let fromInd = evt.dataTransfer.getData('fromInd');
      // console.log('fromInd', fromInd);
      // console.log('toComInd', toComInd);
      let item = '';
      if (this.d_startFromDragType === 'query') {
        item = fromInd;
        if (toComInd === 'compile_str_array_right') {
          this.compile_str.push(item);
        } else if (toComInd === 'compile_str_array_left') {
          this.compile_str.splice(0, 0, item);
        } else {
          if (toComInd === this.compile_str.length - 1) {
            this.compile_str.push(item);
          } else {
            this.compile_str.splice(toComInd + 1, 0, item);
          }
        }
      } else if (this.d_startFromDragType === 'operation') {
        item = fromInd;
        if (toComInd === 'compile_str_array_right') {
          this.compile_str.push(item);
        } else if (toComInd === 'compile_str_array_left') {
          this.compile_str.splice(0, 0, item);
        } else {
          if (toComInd === this.compile_str.length - 1) {
            console.log('last index');
            this.compile_str.push(item);
          } else {
            this.compile_str.splice(toComInd + 1, 0, item);
          }
        }
      } else if (this.d_startFromDragType === 'own_list') {
        if (toComInd === 'compile_str_array_right') {
          item = this.compile_str[fromInd];
          this.compile_str.splice(fromInd, 1);
          this.compile_str.push(item);
        } else if (toComInd === 'compile_str_array_left') {
          item = this.compile_str[fromInd];
          this.compile_str.splice(fromInd, 1);
          this.compile_str.splice(0, 0, item);
        } else {
          let x = 0;
          if (toComInd > fromInd) {
            x = toComInd - 1;
          } else {
            x = toComInd;
          }
          item = this.compile_str[fromInd];
          if (toComInd === this.compile_str.length - 1) {
            this.compile_str.splice(fromInd, 1);
            this.compile_str.push(item);
          } else {
            this.compile_str.splice(fromInd, 1);
            this.compile_str.splice(x + 1, 0, item);
          }
        }
      }
      this.f_controlCompileStrFirstError();
    },
    f_onDropDeleteCompileIndex: function (evt, type) {
      let fromInd = evt.dataTransfer.getData('fromInd');
      this.compile_str.splice(fromInd, 1);
    },
    f_controlWdmrDateAlgorithmEligibility: function () {
      if (this.mode === 'view' && this.compile_str_date.length === 0) {
        return false;
      }
      for (let i in this.query_list) {
        if (this.query_list[i].date === '1') {
          return true;
        }
      }
      return true;
    },
    f_showTextAlgorithm: function () {
      if (this.mode === 'view' && (!this.compile_str_text || (this.compile_str_text && this.compile_str_text.length === 0))) {
        return false;
      }
      return true;
    },
    f_addDayDifferenceToCompileStr: function () {
      let days_dif = 'days[' + this.d_dayDifference.day.toString() + ']';
      this.f_addCompileStrDate(days_dif);
    },
    f_addPhraseToDateAlgorithm: function (phrase) {
      let phrase_item = 'phrase[' + phrase + ']';
      this.f_addCompileStrDate(phrase_item);
    },
    f_addDayDifference: function () {
      this.d_dayDifference.show = true;
      this.d_dayDifference.day = 0;
    },
    f_addTextToAlgoritmOperation: function () {
      let text = 'text[' + this.d_algorithmText.text + ']';
      this.compile_str_text.push(text);
    },
    f_addTextToAlgoritm: function () {
      this.d_algorithmText.show = true;
      this.d_algorithmText.text = '';
    },
    f_addManuelDate: function () {
      this.d_dateAlgorightm.show = true;
      this.d_dateAlgorightm.date = '';
    },
    f_addManuelDateToCompileStr: function () {
      let date_format = 'date[' + moment(this.d_dateAlgorightm.date).format('YYYY-MM-DDTHH:mm:ss') + ']';
      this.f_addCompileStrDate(date_format);
    },
    f_showOperator: function (op_type) {
      if (op_type === 'low') {
        return '<';
      } else if (op_type === 'low_or_equal') {
        return '<=';
      } else if (op_type === 'high') {
        return '>';
      } else if (op_type === 'high_or_equal') {
        return '>=';
      } else {
        return '';
      }
    },
    f_deleteQueryList: function () {
      this.query_list.splice(0, this.query_list.length);
      this.compile_str.splice(0, this.compile_str.length);
      this.$forceUpdate();
    },
    f_calculateStyleAlgoritm: function () {
      let height = 0;
      if (this.d_fullScreen) {
        height = window.innerHeight - 170;
      } else {
        height = window.innerHeight - this.algoritm_height;
      }
      return 'overflow-y: auto; height: ' + height.toString() + 'px; overflow-x: auto; margin-bottom: 0px; padding: 5px; font-size: 10px;';
    },
    f_selectQueryAndAdd: function () {
      this.d_showQueryList = true;
    },
    f_showQuery: function (query_ind) {
      this.d_selectedQueryIndex = query_ind;
      this.d_showQueryModal = true;
    },
    f_toggleShowMode: function () {
      if (this.d_showMode === 'left_to_right') {
        this.d_showMode = 'top_to_bottom';
      } else if (this.d_showMode === 'top_to_bottom') {
        this.d_showMode = 'left_to_right';
      }
      this.$forceUpdate();
    },
    f_addAnatomyInfoToField: function (field) {
      if (field.anatomy_use === 'this') {
        field.this_anatomy = JSON.parse(JSON.stringify(this.selected_anatomy_region));
      } else if (field.anatomy_use === 'parent') {
        field.this_parent = JSON.parse(JSON.stringify(this.selected_anatomy_parent));
      }
      this.$forceUpdate();
    },
    f_doesFieldIncludeAnatomy: function (field) {
      for (let i in field.loc) {
        if (field.loc[i].value === 'anatomy') {
          return true;
        }
      }
      return false;
    },
    ClearCompileStr: function () {
      this.compile_str.splice(0, this.compile_str.length);
      this.$forceUpdate();
    },
    f_clearDateCompileStr: function () {
      this.compile_str_date.splice(0, this.compile_str_date.length);
      this.$forceUpdate();
    },
    f_clearCompileStrText: function () {
      this.compile_str_text.splice(0, this.compile_str_text.length);
      this.$forceUpdate();
    },
    BackInCompileStr: function () {
      if (this.compile_str.length) {
        this.compile_str.splice(this.compile_str.length - 1, 1);
      }
    },
    f_backInCompileStrDate: function () {
      if (this.compile_str_date.length) {
        this.compile_str_date.splice(this.compile_str_date.length - 1, 1);
      }
    },
    f_backInCompileStrText: function () {
      if (this.compile_str_text.length) {
        this.compile_str_text.splice(this.compile_str_text.length - 1, 1);
      }
    },
    f_controlCompileStrFirstError: function () {
      this.d_errorCompileStr = '';
      if (this.compile_str.length > 0) {
        if (['and', 'or'].indexOf(this.compile_str[0]) !== -1) {
          this.d_errorCompileStr = 0;
        } else {
          for (let i in this.compile_str) {
            let current_ind = parseInt(i);
            let next_ind = '';
            if (parseInt(i) !== this.compile_str.length - 1) {
              next_ind = parseInt(i) + 1;
            }
          }
        }
      }
    },
    f_addToCompileStr: function (compile_str_array, key) {
      if (key === '(') {
        if (compile_str_array.length === 0 || ['and', 'or', '('].indexOf(compile_str_array[compile_str_array.length - 1]) !== -1) {
          compile_str_array.push(key);
        } else {
          alert(this.$t('wdm16.12691'));
        }
      } else if (key === ')') {
        if (compile_str_array.length > 0 && ['and', 'or', '('].indexOf(compile_str_array[compile_str_array.length - 1]) === -1) {
          if (compile_str_array[compile_str_array.length - 1] === ')') {
            if (this.CountSameItemsInArray(compile_str_array, ')') !== this.CountSameItemsInArray(compile_str_array, '(')) {
              compile_str_array.push(key);
            } else {
              alert(this.$t('wdm16.12691'));
            }
          } else {
            compile_str_array.push(key);
          }
        } else {
          alert(this.$t('wdm16.12691'));
        }
      } else if (key === 'and' || key === 'or') {
        if (compile_str_array.length > 0 && ['and', 'or', '('].indexOf(compile_str_array[compile_str_array.length - 1]) === -1) {
          compile_str_array.push(key);
          if (compile_str_array[compile_str_array.length - 2] === ')') {
            compile_str_array.push('(');
          }
        } else {
          alert(this.$t('wdm16.12691'));
        }
      }
      this.f_controlCompileStrFirstError();
    },
    f_addCompileStrDate: function (key) {
      /*
        key => 
        (, ), and, or, +, -, >, <, =, '>=', '<=', days(7), date(2020-01-01)
        this.compile_str_date = ['(', '(', 0, '>', 1, ')', 'and', '(', 2, '=', 3, ')', 'and', '(', 5, '+', 'days(10)', '<', 'date(2020-01-01)', ')', ')']
      */
      console.log(key);
      let is_key_day_difference = key.toString().indexOf('days') !== -1;
      let is_key_manuel_date = key.toString().indexOf('date') !== -1;
      let is_key_wdm_query = ['and', 'or', '(', ')', '+', '-', '>', '<', '>=', '<=', '='].indexOf(key) === -1 && key.toString().indexOf('days') === -1 && !is_key_manuel_date;
      let last_key = '';
      if (this.compile_str_date.length > 0) {
        last_key = this.compile_str_date[this.compile_str_date.length - 1];
      }
      if (last_key !== '') {
        let is_last_key_day_difference = last_key.toString().indexOf('days') !== -1;
        let is_last_key_manuel_date = last_key.toString().indexOf('date') !== -1;
        let is_last_key_wdm_query = ['and', 'or', '(', ')', '+', '-', '>', '<', '>=', '<=', '='].indexOf(last_key) === -1 && last_key.toString().indexOf('days') === -1 && !is_last_key_manuel_date;
        if (key === '(') {
          if (['and', 'or', '(', '+', '-', '<', '>', '>=', '<=', '='].indexOf(last_key) !== -1) {
            this.compile_str_date.push(key);
          } else {
            alert(this.$t('wdm16.12692'));
          }
        } else if (key === ')') {
          if (last_key === ')') {
            if (this.CountSameItemsInArray(this.compile_str_date, ')') !== this.CountSameItemsInArray(this.compile_str_date, '(')) {
              this.compile_str_date.push(key);
            } else {
              alert(this.$t('wdm16.12693'));
            }
          } else {
            if (['and', 'or', '(', '+', '-', '=', '<', '>', '>=', '<=', ].indexOf(last_key) !== -1) {
              alert(this.$t('wdm16.12694'));
            } else {
              // date, days, wdm query
              this.compile_str_date.push(key);
            }
          }
        } else if (['and', 'or'].indexOf(key) !== -1) {
          if (['and', 'or', '(', '+', '-', '>', '<', '>=', '<=', '='].indexOf(last_key) === -1 && !is_last_key_wdm_query) {
            this.compile_str_date.push(key);
            if (this.compile_str_date[this.compile_str_date.length - 2] === ')') {
              this.compile_str_date.push('(');
            }
          } else {
            alert(this.$t('wdm16.12695'));
          }
        } else if (['+', '-'].indexOf(key) !== -1) {
          if (is_last_key_wdm_query) {
            this.compile_str_date.push(key);
          } else {
            alert(this.$t('wdm16.12696'));
          }
        } else if (['>=', '<=', '>', '<', '='].indexOf(key) !== -1) {
          if (is_last_key_manuel_date || is_last_key_wdm_query) {
            this.compile_str_date.push(key);
          } else {
            alert(this.$t('wdm16.12696'));
          }
        } else if (is_key_wdm_query) {
          if (!is_last_key_wdm_query) {
            this.compile_str_date.push(key);
          } else {
            alert(this.$t('wdm16.12698'));
          }
        } else if (is_key_manuel_date) {
          if (!is_last_key_manuel_date) {
            this.compile_str_date.push(key);
          } else {
            alert(this.$t('wdm16.12699'));
          }
        } else if (is_key_day_difference) {
          if (!is_last_key_day_difference) {
            this.compile_str_date.push(key);
          } else {
            alert(this.$t('wdm16.12700'));
          }
        }
      } else {
        if (key === '(') {
          this.compile_str_date.push(key);
        } else if (is_key_wdm_query) {
          this.compile_str_date.push('(');
          this.compile_str_date.push(key);
        } else {
          alert(this.$t('wdm16.12701'));
        }
      }
    },
    CountSameItemsInArray: function (arr, val) {
      let count = 0;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] === val) {
          count++;
        }
      }
      return count;
    },
    field_tree_calculate: function () {
      RuleEngine.field_tree_calculate(this.query_list);
    },
    delete_field_in_query: function (field_index, query) {
      query.fields.splice(field_index, 1);
      let x = this.d_selectedQueryIndex.toString() + '_' + field_index.toString();
      let new_compile_str_text = [];
      for (let i in this.compile_str_text) {
        if (this.compile_str_text[i].indexOf('text[') !== -1) {
          new_compile_str_text.push(this.compile_str_text[i]);
        } else {
          // here is query index and field index area.
          if (this.compile_str_text[i].split('_')[0] !== this.d_selectedQueryIndex.toString()) {
            new_compile_str_text.push(this.compile_str_text[i]);
          } else {
            let new_y = '';
            if (this.compile_str_text[i] !== x) {
              if (parseInt(this.compile_str_text[i].split('_')[1]) > parseInt(field_index)) {
                new_y = this.d_selectedQueryIndex.toString() + '_' + (parseInt(this.compile_str_text[i].split('_')[1]) - 1).toString();
                new_compile_str_text.push(new_y);
              } else {
                new_compile_str_text.push(this.compile_str_text[i]);
              }
            }
          }
        }
      }
      this.compile_str_text.splice(0, this.compile_str_text.length);
      for (let i in new_compile_str_text) {
        this.compile_str_text.push(new_compile_str_text[i]);
      }
      if (query.fields.length === 0) {
        this.query_list.splice(this.d_selectedQueryIndex, 1);
        this.d_selectedQueryIndex = '';
        this.d_showQueryModal = false;
      }
      ClsRule._f_listParameterGroup(this.query_list);
      // RuleEngine.field_tree_calculate(this.query_list);
    },
    calculate_field_style (field_data) {
      let x = '';
      if (this.mode === 'edit') {
        x = 'margin-left: 30px;';
      }
      if (field_data.significant === 'true') {
        return x;
      } else {
        return 'background-color: #f1aeae;' + x;
      }
    },
    add_key_compile_str: function (type, key, query_ind = '') {
      let list_count = 0;
      let compile_str_array = [];
      if (type === 'query') {
        this.compile_str.splice(0, this.compile_str.length);
        compile_str_array = this.compile_str;
        list_count = this.query_list.length;
      } else if (type === 'field') {
        this.query_list[query_ind].compile_str_array = [];
        compile_str_array = this.query_list[query_ind].compile_str_array;
        list_count = this.query_list[query_ind].fields.length;
      }
      compile_str_array.push('(');
      for (let i = 0; i < list_count; i++) {
        compile_str_array.push(i);
        if (list_count - 1 !== i) { compile_str_array.push(key); }
      }
      compile_str_array.push(')');
      this.$forceUpdate();
    },
    AddThisQueryToOrder: function (q_ind) {
      if (this.query_order.length === 0) {
        this.query_order.push({ queries: [q_ind], day: 0, minute: 0, dif_type: 'none', time_relation: 'none' });
      } else {
        if (this.query_order[this.query_order.length - 1].queries.length === 1) {
          this.query_order[this.query_order.length - 1].queries.push(q_ind);
        } else {
          this.query_order.push({ queries: [q_ind], day: 0, minute: 0, dif_type: 'none', time_relation: 'none' });
        }
      }
    },
    f_deleteThisQuery: function (query_ind) {
      let new_compile_str = [];
      for (let i in this.compile_str) {
        if (this.compile_str[i].toString() !== query_ind.toString()) {
          new_compile_str.push(this.compile_str[i]);
        }
      }
      this.compile_str.splice(0, this.compile_str.length);
      for (let i in new_compile_str) {
        this.compile_str.push(new_compile_str[i]);
      }
      let new_compile_str_date = [];
      for (let i in this.compile_str_date) {
        if (this.compile_str_date[i].toString() !== query_ind.toString()) {
          new_compile_str_date.push(this.compile_str_date[i]);
        }
      }
      this.compile_str_date.splice(0, this.compile_str_date.length);
      for (let i in new_compile_str_date) {
        this.compile_str_date.push(new_compile_str_date[i]);
      }
      let new_compile_str_text = [];
      for (let i in this.compile_str_text) {
        if (this.compile_str_text[i].indexOf('text[') !== -1) {
          new_compile_str_text.push(this.compile_str_text[i]);
        } else {
          // here is query index and field index area.
          if (this.compile_str_text[i].split('_')[0] !== query_ind.toString()) {
            new_compile_str_text.push(this.compile_str_text[i]);
          }
        }
      }
      this.compile_str_text.splice(0, this.compile_str_text.length);
      for (let i in new_compile_str_text) {
        this.compile_str_text.push(new_compile_str_text[i]);
      }
      this.query_list.splice(query_ind, 1);
    },
    add_to_model: function (compile_str, q_ind) {
      if (compile_str.length === 0) {
        compile_str.push('(');
        compile_str.push(q_ind);
      } else {
        if (['(', 'or', 'and'].indexOf(compile_str[compile_str.length - 1]) !== -1) {
          compile_str.push(q_ind);
        } else {
          alert(this.$t('wdm16.12691'));
        }
      }
    }
  },
  watch: {
    'mother_children.forceUpdate': function () {
      this.$forceUpdate();
    }
  }
};

</script>

<style type="text/css">
.query-param {
  border-bottom: dotted 1px #20a8d8;
  padding-left: 15px;
}

.compilestr-parenthesis {
  font-size: 10px;
  color: #e605ff;
  font-family: Georgia, serif;
}

.compilestr-operation-and {
  text-align: center;
  font-size: 10px;
  width: 100%;
  color: black;
  font-family: Georgia, serif;
  margin: 2px;
}

.compilestr-operation-or {
  text-align: center;
  font-size: 10px;
  color: black;
  font-family: Georgia, serif;
  margin: 2px;
}

.query-compilestr-parenthesis {
  font-size: 20px;
  color: #df2c2c;
  font-family: Georgia, serif;
}

.query-compilestr-text {
  color: #df2c2c;
  font-family: Georgia, serif;
}

.query-compilestr-operation-and {
  font-size: 15px;
  color: #df2c2c;
  font-family: Georgia, serif;
}

.query-compilestr-operation-or {
  font-size: 15px;
  color: #df2c2c;
  font-family: Georgia, serif;
}

.query-compilestr-operation-text {
  color: #df2c2c;
  font-family: Georgia, serif;
}

.compilestr-caption {
  color: #39b2d5;
}

#rule_queries ul.dropdown-menu {
  background-color: white;
}

#rule_queries a.dropdown-item {
  color: black !important;
}

#field_queries ul.dropdown-menu {
  background-color: white;
}

#field_queries a.dropdown-item {
  color: black !important;
}

.normal-mode {}

.full-screen-mode {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

#rule-algorithm::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #d9e9ea;
}

#rule-algorithm::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

#rule-algorithm::-webkit-scrollbar-thumb {
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #4788ac;
}

</style>

